import { VideoMetaData } from './videos-types';

export enum AssetType {
  image = 'image',
  video = 'video',
  custom = 'custom',
  text = 'text'
}

export type VideoAssetElement = {
  id: number;
  name: string;
  path?: string;
  updated_date: Date | string | null;
  cdn_url: string;
  studio_url: string;
  mobile_url: string;
  web_url: string;
  streaming_video_url: string;
  meta_data?: VideoMetaData;
};

export type ImageAssetElement = {
  id: number;
  name: string;
  path: string;
  url: string;
  updated_date: Date | string | null;
};

export type StudioAsset = {
  id: string;
  type: AssetType;
  video: VideoAssetElement | null;
  image: ImageAssetElement | null;
  value: string | null;
  created_date: Date | string | null;
  updated_date: Date | string | null;
};
