import React from 'react';
import { Typography } from 'antd';
import './accessible-table.scss';

type AccessibleTableTextCellProps = {
  text: string;
};

const AccessibleTableTextCell = (props: AccessibleTableTextCellProps) => {
  const { text } = props;

  return (
    <Typography.Paragraph className="accessible-table-text-cell" ellipsis={{ rows: 2 }}>
      {text || '-'}
    </Typography.Paragraph>
  );
};

export default AccessibleTableTextCell;
