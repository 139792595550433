import React from 'react';
import { Button, Space } from 'antd';
import { DragOutlined, DeleteFilled, ExclamationCircleOutlined, VideoCameraOutlined, FileImageOutlined } from '@ant-design/icons';
import AccessibleTable from 'src/components/accessible-table';
import { Survey, SurveyStep } from 'src/types/survey-types';
import DragHandle from 'src/components/drag-handle';
import { getConfirmationModal } from 'src/utils/modals-utils';
import CheckTagButton from 'src/components/buttons/check-tag-button';
import MinusTagButton from 'src/components/buttons/minus-tag-button';

type SurveyStepsTableType = {
  record: Survey | null;
  tableHeight: number;
  onEditStep: (step: SurveyStep) => void;
  onDeleteStep: (stepId: string) => void;
  onReorderSteps: (orderedSteps: SurveyStep[]) => void;
};

export const SurveyStepsTable = ({ record, tableHeight, onReorderSteps, onDeleteStep, onEditStep }: SurveyStepsTableType) => {
  const data = record?.steps.map((step) => ({ ...step, index: step.order })) || [];

  const handleRowClock = (_event: any, tableRecord: Record<string, unknown>) => {
    onEditStep(tableRecord as SurveyStep);
  };

  const columns = [
    {
      key: 'delete',
      title: '',
      dataIndex: 'delete',
      fixed: true,
      width: 45,
      render: (_text: string, tableRecord: Record<string, unknown>) => {
        return (
          <Button
            type="primary"
            size="middle"
            icon={<DeleteFilled />}
            onClick={(event) => {
              event.stopPropagation();
              const { step_id, id } = tableRecord as SurveyStep;

              getConfirmationModal({
                width: 600,
                isDanger: true,
                okText: 'Delete',
                title: `Delete "${step_id}" step`,
                description: 'Are you sure you want to delete this step?',
                icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
                onConfirm: () => onDeleteStep(id)
              });
            }}
          />
        );
      }
    },
    {
      key: 'sort',
      width: 45,
      dataIndex: 'sort',
      fixed: true,
      className: 'drag-visible',
      title: () => <DragOutlined />,
      render: () => <DragHandle />
    },
    {
      key: 'index',
      width: 70,
      dataIndex: 'index',
      fixed: true,
      title: 'Index'
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      width: 90
    },
    {
      key: 'step_id',
      title: 'Step Id',
      dataIndex: 'step_id',
      width: 90,
      fixed: true
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      width: 90
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      width: 90
    },
    {
      key: 'is_optional',
      title: 'Optional',
      dataIndex: 'is_optional',
      width: 90,
      render: (value: any) => (value ? <CheckTagButton /> : <MinusTagButton />)
    },
    {
      key: 'assets',
      title: 'Assets',
      dataIndex: 'assets',
      width: 90,
      render: (_text: string, tableRecord: Record<string, any>) => {
        const withVideoAsset = tableRecord.video?.id && tableRecord.video?.id > 0;
        const withImageAsset = tableRecord.image?.id && tableRecord.image?.id > 0;

        return (
          <Space>
            {withVideoAsset && <VideoCameraOutlined />}
            {withImageAsset && <FileImageOutlined />}
          </Space>
        );
      }
    }
  ];

  return (
    <div style={{ height: tableHeight }}>
      <AccessibleTable
        isDraggable
        data={data}
        columns={columns}
        onSortEnd={(orderedData) => onReorderSteps(orderedData as SurveyStep[])}
        onRowClick={handleRowClock}
      />
    </div>
  );
};
