export enum ProcessingStatus {
  completed = 'completed',
  uncompleted = 'uncompleted'
}

export enum ContentStatus {
  created = 'created',
  unpublished = 'unpublished',
  published = 'published',
  archived = 'archived'
}
