import { getDefaultColorsMap, getDefaultOptionsList } from '../../utils/common-utils';

export type OptionListItem = { text: string; value: string; color?: string | undefined };

export const getDictionaryItemConfig = (
  dictionary: Record<string, unknown>,
  key: string,
  withColor = true
): {
  optionsList: OptionListItem[];
  colorsMap: Record<string, string | undefined>;
} => {
  const itemsList = dictionary[key];
  if (!Array.isArray(itemsList)) return { optionsList: [], colorsMap: {} };
  const optionsList = getDefaultOptionsList(itemsList, withColor);
  const colorsMap = getDefaultColorsMap(itemsList);
  return { optionsList, colorsMap };
};
