import React, { useEffect, useState } from 'react';
import { Button, Col, Input, List, Modal, Pagination, Row, Tooltip, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import api from '../../api';
import { NOOP } from '../../constants/common-constants';
import { Program } from '../../types/programs-types';
import { Workout } from '../../types/workout-types';
import { Resources } from '../../types/resources-types';
import { Instructor } from '../../types/instructors-types';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import './promotion-tile.scss';
import '../../components/basic-modal/basic-modal.scss';
import useDebounce from '../../hooks/use-debounce';
import { CondOperator } from '../../types/cond-operators';
import { ContentStatus } from '../../types/statuses-types';

export type TypeSelectedRecord = Program | Instructor | Workout;

type TypeSelectedRecordModalProps = {
  title: string;
  placeholder: string;
  resource: Resources | null;
  onSelectRecord?: (selectedRecord: TypeSelectedRecord) => void;
};

type RecordsListDataState = { records: TypeSelectedRecord[]; total: number };

const PromotionTileTypeRecordSelectionModal = (props: TypeSelectedRecordModalProps) => {
  const { resource, title, placeholder, onSelectRecord = NOOP } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [recordsListData, setRecordsListData] = React.useState<RecordsListDataState>({ records: [], total: 0 });
  const [apiParams, setApiParams] = useState<{ page: number; perPage: number }>({ page: 1, perPage: 50 });

  const debouncedSearchValue = useDebounce(searchInputValue);

  const { page, perPage } = apiParams;

  const onShowModal = () => setIsVisible(true);
  const onHideModal = () => setIsVisible(false);

  useEffect(() => {
    if (!resource) return;

    const filter = {
      [`searchBy||${resource === Resources.instructors ? 'first_name,last_name' : 'name'}`]: debouncedSearchValue,
      [`status||${CondOperator.NOT_IN}`]: [ContentStatus.archived]
    };
    const sort = { field: `${resource === Resources.instructors ? 'first_name' : 'name'}`, order: 'ASC' };

    api.common
      .getList(resource, { pagination: { page, perPage }, filter, sort })
      .then(({ data: records, total }) => setRecordsListData({ records, total }))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [resource, page, perPage, debouncedSearchValue]);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    setApiParams({ ...apiParams, page: 1 });
  };

  const onChangePagination = (selectedPage: number, selectedPerPage?: number | undefined) => {
    setApiParams({ ...apiParams, page: selectedPage, perPage: selectedPerPage || perPage });
  };

  const onSelect = (selectedRecord: TypeSelectedRecord) => {
    onSelectRecord(selectedRecord);
    onHideModal();
  };

  const getRecordItemParams = (item: TypeSelectedRecord) => {
    if (resource === Resources.workouts) return { name: (item as Workout).name };
    if (resource === Resources.programs) return { name: (item as Program).name };
    if (resource === Resources.instructors) return { name: `${(item as Instructor).first_name} ${(item as Instructor).last_name}` };
    return { name: '' };
  };

  return (
    <>
      <Tooltip placement="topRight" title={`Open select ${resource} modal`}>
        <Button className="promotion-tile-modal-button" onClick={onShowModal}>
          {title || placeholder} <DownOutlined />
        </Button>
      </Tooltip>
      <Modal
        centered
        destroyOnClose
        footer={[]}
        width={1000}
        visible={isVisible}
        wrapClassName="modal-wrap"
        title={`Search ${resource} by name`}
        onCancel={onHideModal}
      >
        <Row align="middle" justify="space-between" className="header-section">
          <Col flex={12}>
            <Input.Search autoFocus enterButton placeholder="search record by name" value={searchInputValue} onChange={onChangeSearchInput} />
          </Col>
        </Row>
        <div className="promotion-tile-type-records-list">
          <List
            dataSource={recordsListData.records}
            style={{ height: '100%' }}
            renderItem={(item) => {
              const { name } = getRecordItemParams(item);
              return (
                <List.Item onClick={() => onSelect(item)}>
                  <Typography.Text>{name}</Typography.Text>
                </List.Item>
              );
            }}
          />
        </div>
        <Row justify="end">
          <Pagination
            current={page}
            pageSize={perPage}
            total={recordsListData.total}
            pageSizeOptions={['25', '50', '75']}
            showTotal={(total) => `Total: ${total} images`}
            onChange={onChangePagination}
          />
        </Row>
      </Modal>
    </>
  );
};

export default PromotionTileTypeRecordSelectionModal;
