import { Resources } from '../types/resources-types';
import { CreateParams } from '../types/request-entities-types';
import { checkStatus, getRequestParams, HttpClient, RequestMethods } from './http-client';

const filesApi = (baseUrl: string, httpClient: HttpClient) => ({
  uploadImage: async (resource: Resources, params: CreateParams & { id?: string; subUrl?: string; withReload?: boolean }) => {
    const { data, subUrl } = params;
    const url = `${baseUrl}/${resource}`;
    const requestUrl = subUrl ? `${url}/${subUrl}` : url;

    const s3Response = await httpClient(requestUrl, { method: RequestMethods.post, isFile: true });
    const signedUrl = s3Response?.signedUrl;

    const requestParams = getRequestParams({ method: RequestMethods.put, body: data, isFile: true });
    return fetch(signedUrl, requestParams).then(checkStatus);
  },
  assets: async (params: { file: File }) => {
    const { file } = params;
    const { name } = file;
    const requestUrl = `${baseUrl}/assets/upload-url`;
    const createImageUrl = `${baseUrl}/${Resources.images}`;

    const { cdnUrl, signedUrl, path } = await httpClient(requestUrl, {
      method: RequestMethods.post,
      body: JSON.stringify({ size: file.size, fileExtension: file.name.includes('.') ? file.name.split('.').pop() : '' })
    });

    const requestParams = getRequestParams({ method: RequestMethods.put, body: file, isFile: true });
    return fetch(signedUrl, requestParams)
      .then(checkStatus)
      .then(async () => {
        const createdImageParams = { method: RequestMethods.post, body: JSON.stringify({ path, name }) };
        return httpClient(createImageUrl, createdImageParams);
      })
      .then((createdImage) => ({ ...createdImage, cdnUrl, path }));
  }
});

export default filesApi;
