import React, { useEffect, useState } from 'react';
import { SelectOutlined } from '@ant-design/icons';
import { Input, Row, Col, Card, Pagination, Radio, RadioChangeEvent, Image, Button, Tooltip, Modal } from 'antd';

import api from '../../api';
import useDebounce from '../../hooks/use-debounce';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { imageFallback } from '../../constants/image-fallback';
import { getErrorMessage } from '../../utils/errors';
import { NOOP } from '../../constants/common-constants';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { Image as ImageRecord } from '../../types/image-types';
import { ResourceAssignedTypes } from '../../types/common-types';
import { imageTypesList } from '../../utils/resources-dictionary-utils';
import './select-image-modal.scss';

type SelectImageModalProps = {
  title: string;
  onSelectImage?: (selectedImage: ImageRecord) => void;
};

const SelectImageModal = (props: SelectImageModalProps) => {
  const { title, onSelectImage = NOOP } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [imagesListData, setImagesListData] = useState<{ images: ImageRecord[]; total: number }>({ images: [], total: 0 });

  const debouncedSearchValue = useDebounce(searchInputValue);

  const [apiParams, setApiParams] = useState<{ page: number; perPage: number; imageType: ResourceAssignedTypes }>({
    page: 1,
    perPage: 50,
    imageType: ResourceAssignedTypes.notAssigned
  });

  const { page, perPage, imageType } = apiParams;

  const onShowModal = () => setIsVisible(true);
  const onHideModal = () => setIsVisible(false);

  useEffect(() => {
    api.common
      .getList(Resources.images, {
        pagination: { page, perPage },
        filter: {
          q: { not_assigned: imageType === ResourceAssignedTypes.notAssigned },
          'searchBy||name': debouncedSearchValue
        },
        sort: { field: 'name', order: 'ASC' }
      })
      .then(({ data: images, total }: any) => setImagesListData({ images, total }))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [page, imageType, perPage, debouncedSearchValue]);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    setApiParams({ ...apiParams, page: 1 });
  };

  const onChangePagination = (selectedPage: number, selectedPerPage?: number | undefined) => {
    setApiParams({ ...apiParams, page: selectedPage, perPage: selectedPerPage || perPage });
  };

  const onSetImageType = (e: RadioChangeEvent) => {
    setApiParams({ ...apiParams, imageType: e.target.value as ResourceAssignedTypes, page: 1 });
  };

  const onSelect = (selectedImage: ImageRecord) => {
    onSelectImage(selectedImage);
    onHideModal();
  };

  return (
    <>
      <Tooltip placement="topRight" title="Open select image modal">
        <Button icon={<SelectOutlined />} onClick={onShowModal}>
          {title}
        </Button>
      </Tooltip>
      <Modal
        centered
        destroyOnClose
        title={title}
        width={1000}
        visible={isVisible}
        footer={[]}
        wrapClassName="modal-wrap"
        bodyStyle={{ minHeight: '300px', maxHeight: '80vh' }}
        onCancel={onHideModal}
      >
        <div className="select-image-wrapper">
          <Row align="middle" justify="space-between" className="header-section">
            <Col flex={12}>
              <Input.Search
                autoFocus
                enterButton
                id="image-search-filter"
                placeholder="search image by name"
                value={searchInputValue}
                onChange={onChangeSearchInput}
              />
            </Col>
            <Col flex={8} className="header-filters">
              <Radio.Group onChange={onSetImageType} value={imageType}>
                {imageTypesList.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.text}
                  </Radio>
                ))}
              </Radio.Group>
            </Col>
          </Row>
          <div className="image-list-content">
            {imagesListData.images.map((image) => (
              <Card
                hoverable
                key={image.path}
                title={<Tooltip title={image.name}>{image.name}</Tooltip>}
                className="image-item-card"
                bodyStyle={{ padding: '5px 10px 15px' }}
                cover={<Image width={170} src={`${image.url}?width=600`} fallback={imageFallback} alt="" />}
              >
                <Button size="large" type="primary" icon={<SelectOutlined />} className="select-image-item-button" onClick={() => onSelect(image)}>
                  Select
                </Button>
              </Card>
            ))}
          </div>
          <Pagination
            current={page}
            pageSize={perPage}
            total={imagesListData.total}
            pageSizeOptions={['25', '50', '75']}
            showTotal={(total) => `Total: ${total} images`}
            onChange={onChangePagination}
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectImageModal;
