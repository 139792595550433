import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Typography } from 'antd';
import 'antd/dist/antd.css';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { UpdateParams } from '../../types/request-entities-types';
import { StudioWidget } from '../../types/studio-widgets';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { useItemSelector } from '../../hooks/use-selector';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { getFormattedDate } from '../../utils/date-utils';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';
import useLocationHandler from '../../hooks/use-location-handler';
import useStudioWidgetsAssetsTabs from './use-studio-widgets-assets-tabs';
import StubPage from '../../components/stub-page/stub-page';
import ContentTabs from '../../components/content-tabs/content-tabs';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';

const StudioWidgetsRecordPage = () => {
  const record = useItemSelector({ resource: Resources.studioWidgets }, getPageResourceRecord) as StudioWidget;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const timeZone = useSelector(selectUserTimeZone);
  const onSetRecord = useAction(setResourceRecord);

  const navigate = useNavigate();
  const { secondSubRoute: recordId } = useLocationHandler();

  useResourceRecordData({ recordId, resource: Resources.studioWidgets });

  const [form] = Form.useForm();
  const initialData = useMemo(() => ({ ...record, title: record?.title, message: record?.message }), [record]);

  const { tabsConfig, isAssetsChanged, changedAssetsData } = useStudioWidgetsAssetsTabs();
  const { isFormValuesChanged, changedFormValues, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });
  const isRecordChanged = isFormValuesChanged || isAssetsChanged;

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinish = () => {
    const params = {
      id: recordId,
      previousData: { id: recordId },
      data: { id: recordId, ...changedFormValues, ...changedAssetsData }
    } as UpdateParams;

    api.common
      .update(Resources.studioWidgets, params)
      .then((updatedWidget: StudioWidget) => {
        onSetRecord(updatedWidget);
        onDisplaySuccessNotification('Studio widget record was successfully updated');
        onReset();
        navigate(`/${Resources.studioWidgets}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.studioWidgets} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Studio Widget
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{record.id}</Form.Item>
        <Form.Item label="Widget type" rules={[{ required: true }]}>
          {record?.type}
        </Form.Item>
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="action" label="Action">
          <Input />
        </Form.Item>
        <Form.Item name="message" label="Message">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Created date">{getFormattedDate(record.created_date, timeZone)}</Form.Item>
        <Form.Item label="Updated date">{getFormattedDate(record.updated_date, timeZone)}</Form.Item>
        <Form.Item label="Assets">
          <ContentTabs tabsConfig={tabsConfig} />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isRecordChanged} onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isRecordChanged} onClick={onFinish}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default StudioWidgetsRecordPage;
