import React from 'react';
import { Col, Row, Select, Tag } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import cs from 'classnames';

import { NOOP } from '../../constants/common-constants';
import { getConfirmationModal } from '../../utils/modals-utils';
import './form-components.scss';

export type SelectionOptionItem = {
  value: string | number;
  text: string | number;
  color?: string;
  disabled?: boolean;
};

export type FormItemSelectionProps = {
  value?: string;
  confirmTitle?: string;
  confirmDescription?: string;
  className?: string;
  dropdownClassName?: string;
  placeholder?: string;
  showSearch?: boolean;
  isMultiple?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  size?: SizeType;
  withConfirmModal?: boolean;
  withDeleteBtn?: boolean;
  options: SelectionOptionItem[];
  onChange?: (value: string | null) => void;
};

const FormItemSelection = (props: FormItemSelectionProps) => {
  const {
    withConfirmModal = false,
    confirmTitle,
    confirmDescription,
    className,
    dropdownClassName,
    bordered = true,
    showSearch = true,
    size,
    placeholder,
    isMultiple,
    options = [],
    value,
    withDeleteBtn = false,
    onChange = NOOP,
    disabled
  } = props;

  const getConfirmDescription = (selectedValue: string) => {
    if (confirmDescription) return confirmDescription;
    const selectedOption = options.find((item) => [item.value, item.text].includes(selectedValue));
    if (!selectedOption) return 'Are you sure you want to apply selected option?';
    return (
      <div>
        <div>
          You have selected option: <b>{selectedOption?.text}</b>
        </div>
        <div>Are you sure you want to apply it?</div>
      </div>
    );
  };

  const confirm = (selectedValue: string) =>
    getConfirmationModal({
      width: 450,
      title: confirmTitle,
      description: getConfirmDescription(selectedValue),
      onConfirm: () => onChange(selectedValue)
    });

  const onFilterOption: FilterFunc<DefaultOptionType> = (input = '', option) => {
    if (!option) return true;
    const optionItem = options.find((item) => item.value.toString().toLowerCase().trim() === (option?.value ?? '').toString().toLowerCase().trim());
    if (!optionItem) return true;
    return (
      (optionItem?.text ?? '').toString().toLowerCase().trim().indexOf(input.toLowerCase().trim()) >= 0 ||
      (optionItem?.value ?? '').toString().toLowerCase().trim().indexOf(input.toLowerCase().trim()) >= 0
    );
  };

  const onRemoveItem = () => onChange(null);

  return (
    <Row align="middle" justify="space-between">
      <Col flex={withDeleteBtn ? 22 : 24}>
        <Select
          autoClearSearchValue
          showSearch={showSearch}
          className={className}
          disabled={disabled}
          bordered={bordered}
          size={size}
          dropdownClassName={dropdownClassName}
          mode={isMultiple ? 'multiple' : undefined}
          value={value}
          placeholder={placeholder}
          onChange={withConfirmModal ? confirm : onChange}
          filterOption={onFilterOption}
        >
          {options.map((item) => (
            <Select.Option
              key={item.value}
              className={cs('form-item-selection', { ['disabled']: item.disabled })}
              disabled={item.disabled}
              value={item.value}
            >
              {item.color ? (
                <Tag className="accessible-table-tags-cell" color={item.color}>
                  {item.text}
                </Tag>
              ) : (
                item.text
              )}
            </Select.Option>
          ))}
        </Select>
      </Col>
      {withDeleteBtn && (
        <Col flex={2} className="form-item-record-selection-delete-btn" onClick={onRemoveItem}>
          <CloseSquareOutlined />
        </Col>
      )}
    </Row>
  );
};

export default FormItemSelection;
