import { createStructuredSelector, Selector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../boot/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeUseStructuredSelector =
  <T extends { [key: string]: any }>(selectors: { [K in keyof T]: Selector<RootState, T[K]> }) =>
  () => {
    const selector = useMemo(() => createStructuredSelector<RootState, T>(selectors), []);
    return useSelector(selector);
  };
