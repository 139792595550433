import { useEffect } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';

import { useAction } from './use-actions';
import { setAuthData } from '../utils/auth-utils';
import { USER_PERMISSIONS_KEY } from '../config';
import { getResourcesPermissionsMap } from '../utils/permission-utils';
import { finishAuthentication, startAuthentication } from '../redux/permissions/permissions-reducer';

export const useAuthHandler = () => {
  const { isLoading, isAuthenticated, user, getIdTokenClaims } = useAuth0();

  const onStartAuthentication = useAction(startAuthentication);
  const onFinishAuthentication = useAction(finishAuthentication);

  useEffect(() => {
    const getAuthData = async (currentUser: User) => {
      onStartAuthentication();

      const tokenClaims = await getIdTokenClaims();
      const token = tokenClaims?.__raw || null;
      const permissionsMap = getResourcesPermissionsMap(currentUser[USER_PERMISSIONS_KEY]);

      if (tokenClaims) setAuthData(tokenClaims);
      onFinishAuthentication({ isAuthenticated: !!token, permissionsMap });
    };

    if (!isLoading && isAuthenticated && user) getAuthData(user);
    if (!isLoading && !isAuthenticated) onFinishAuthentication({ isAuthenticated: false, permissionsMap: null });
  }, [isLoading, isAuthenticated, getIdTokenClaims, user, onStartAuthentication, onFinishAuthentication]);
};
