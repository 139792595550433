import React, { useRef } from 'react';

import useOutsideClick from '../../hooks/use-outside-click';
import useExerciseOwnerFilter from './use-exercise-owner-filter';
import DropdownRecordSelectionListOverlay from '../../components/dropdown-record-selection-list/dropdown-record-selection-list-overlay';
import './exercises.scss';

type ExerciseOwnerFilterDropdownProps = {
  isVisible: boolean;
  activeFilters: string[];
  onSelectFilters: (filters: string[]) => void;
  onClose: () => void;
};

const ExerciseOwnerFilterDropdown = (props: ExerciseOwnerFilterDropdownProps) => {
  const { isVisible, activeFilters, onSelectFilters, onClose } = props;

  const filterDropdownRef = useRef(null);

  const { trainersList, selectedTrainers, searchInputValue, onChangeSearchInput, onSelectTrainer, onApplySelectedExercises } = useExerciseOwnerFilter(
    { isVisible, selectedItemsIds: activeFilters, onSelectItems: onSelectFilters, onClose }
  );

  useOutsideClick({ ref: filterDropdownRef, isActive: isVisible, callback: onClose });

  return (
    <div ref={filterDropdownRef} className="exercise-owner-filter-dropdown">
      <DropdownRecordSelectionListOverlay
        inputPlaceholder="Search trainer by name"
        itemsList={trainersList.map((trainer) => ({ ...trainer, name: `${trainer.first_name} ${trainer.last_name}` }))}
        selectedListItems={selectedTrainers.map((trainer) => ({ ...trainer, name: `${trainer.first_name} ${trainer.last_name}` }))}
        searchInputValue={searchInputValue}
        libraryTitle="Trainers library"
        selectedItemsTitle="Selected trainers"
        onChangeSearchInput={onChangeSearchInput}
        onSelectListItem={onSelectTrainer}
        onSaveSelectedItems={onApplySelectedExercises}
        onClose={onClose}
      />
    </div>
  );
};

export default ExerciseOwnerFilterDropdown;
