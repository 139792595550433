import React, { Ref, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { UpdateParams } from '../../types/request-entities-types';
import { getErrorMessage } from '../../utils/errors';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useItemSelector } from '../../hooks/use-selector';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';

import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import StubPage from '../../components/stub-page/stub-page';
import { StudioAssetsRecordForm, StudioAssetsRecordFormRef } from './studio-assets-record-form';
import { StudioAsset } from '../../types/studio-assets';

const StudioAssetsRecordPage = () => {
  const navigate = useNavigate();
  const record = useItemSelector({ resource: Resources.studioAssets }, getPageResourceRecord) as StudioAsset;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const onSetRecord = useAction(setResourceRecord);

  const { secondSubRoute: recordId } = useLocationHandler();

  useResourceRecordData({ recordId, resource: Resources.studioAssets });

  const formRef = useRef<StudioAssetsRecordFormRef>();

  const onFinish = (isSaveAndContinue?: boolean) => {
    const { nextFormValues, onClearChangedFormValues } = formRef.current || {};

    const params = { id: recordId, data: { id: recordId, ...nextFormValues }, previousData: { id: recordId } } as UpdateParams;

    api.common
      .update(Resources.studioAssets, params)
      .then((asset: StudioAsset) => {
        onSetRecord(asset);
        onClearChangedFormValues?.();
        onDisplaySuccessNotification('Studio asset record was successfully updated');
        if (!isSaveAndContinue) navigate(`/${Resources.studioAssets}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.studioAssets} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Studio Asset
      </Typography.Title>
      <StudioAssetsRecordForm ref={formRef as Ref<StudioAssetsRecordFormRef>} onFinish={onFinish} initialData={record} />
    </ResourceRecordContainer>
  );
};

export default StudioAssetsRecordPage;
