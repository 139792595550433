import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePreviousValue } from './use-previous-value';
import useResourceListUrlParams from './use-resource-list-url-params';
import { CondOperator } from '../types/cond-operators';

export type FilterTypes = Record<string, string>;
export type FilterType = keyof FilterTypes;
export type Filter = Record<FilterType, string[]>;
export type FilterOperators = Record<FilterType, CondOperator>;

type UseResourceListTableFiltersParams = {
  filterTypes: FilterTypes;
};

type UseResourceListTableFilters = {
  filters: Filter;
  filtersOperators: FilterOperators;
  activeFilterDropdown: FilterType | null;
  onResetFilters: () => void;
  onSetFilter: (filterType: FilterType, value: string[]) => void;
  onSetFilters: (nextFilters: Filter) => void;
  onFilterDropdownChange: (isActive: boolean, filterDropdownType: FilterType) => void;
  onCloseFilterDropdown: () => void;
};

const useResourceListTableFilters = ({ filterTypes }: UseResourceListTableFiltersParams): UseResourceListTableFilters => {
  const location = useLocation();
  const { isInitialized, additionalFilters, filtersOperators } = useResourceListUrlParams();
  const prevIsInitialized = usePreviousValue(isInitialized);

  const defaultFilterState = useMemo(() => Object.keys(filterTypes).reduce((acc, filterKey) => ({ ...acc, [filterKey]: [] }), {}), [filterTypes]);

  const prevQueries = usePreviousValue(location.search);
  const isQueriesSetDefault = prevQueries && !location.search;

  const initialFilterState = useMemo(
    () =>
      Object.keys(filterTypes).reduce((acc, filterType) => {
        const additionalFilterKey = Object.keys(additionalFilters).find((key) => filterType === key.split('||')[0]);
        return {
          ...acc,
          [filterType]: additionalFilterKey ? additionalFilters[additionalFilterKey] || [] : []
        };
      }, defaultFilterState),
    [filterTypes, additionalFilters, defaultFilterState]
  );

  const [filters, setFilters] = useState<Filter>(defaultFilterState);
  const [activeFilterDropdown, setActiveFilterDropdown] = useState<FilterType | null>(null);

  useEffect(() => {
    if ((!prevIsInitialized && isInitialized) || isQueriesSetDefault) {
      setFilters(initialFilterState || defaultFilterState);
    }
  }, [prevIsInitialized, isInitialized, initialFilterState, defaultFilterState, isQueriesSetDefault]);

  const getIsValidFilterType = useCallback((filterType: FilterType) => !!filterTypes[filterType], [filterTypes]);

  const onSetFilter = useCallback(
    (filterType: FilterType, value: string[]) => {
      if (!getIsValidFilterType(filterType)) return;
      return setFilters({ ...filters, [filterType]: value });
    },
    [filters, getIsValidFilterType]
  );

  const onSetFilters = useCallback(
    (nextFilters: Filter) => {
      const filtersKeys = Object.keys(nextFilters);
      if (filtersKeys.some((filterKey) => !getIsValidFilterType(filterKey))) return;
      return setFilters(nextFilters);
    },
    [getIsValidFilterType]
  );

  const onFilterDropdownChange = useCallback(
    (isActive: boolean, filterDropdownType: FilterType) => {
      if (!getIsValidFilterType(filterDropdownType)) return;
      setActiveFilterDropdown(isActive ? filterDropdownType : null);
    },
    [getIsValidFilterType]
  );

  const onResetFilters = useCallback(() => {
    setFilters(defaultFilterState);
  }, [defaultFilterState]);

  const onCloseFilterDropdown = () => setActiveFilterDropdown(null);

  return {
    filters,
    filtersOperators,
    activeFilterDropdown,
    onSetFilter,
    onSetFilters,
    onResetFilters,
    onFilterDropdownChange,
    onCloseFilterDropdown
  };
};

export default useResourceListTableFilters;
