import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getResourcesPermissionsMap, ResourcesPermissionsMap } from '../../utils/permission-utils';

const initialResourcesPermissionsMap = getResourcesPermissionsMap([]);

export interface PermissionsState {
  isLoading: boolean;
  isAuthenticated: boolean;
  permissionsMap: ResourcesPermissionsMap;
}

const initialState: PermissionsState = {
  isLoading: true,
  isAuthenticated: false,
  permissionsMap: initialResourcesPermissionsMap
};

export type FinishAuthenticationAction = PayloadAction<{ isAuthenticated: boolean; permissionsMap: ResourcesPermissionsMap | null }>;

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    startAuthentication: (state) => {
      state.isLoading = true;
      state.isAuthenticated = false;
      state.permissionsMap = initialResourcesPermissionsMap;
    },
    finishAuthentication: (state, action: FinishAuthenticationAction) => {
      const { isAuthenticated, permissionsMap } = action.payload;
      state.isLoading = false;
      state.isAuthenticated = isAuthenticated;
      state.permissionsMap = permissionsMap || initialResourcesPermissionsMap;
    }
  }
});

export const { startAuthentication, finishAuthentication } = permissionsSlice.actions;

export default permissionsSlice.reducer;
