import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LOCK_CONTAINER_ID } from '../../config';

function Login() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <div id={LOCK_CONTAINER_ID} />;
}

export default Login;
