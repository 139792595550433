import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Typography, Tag } from 'antd';
import ReactJson from 'react-json-view';
import 'antd/dist/antd.css';

import useLocationHandler from '../../hooks/use-location-handler';
import { Resources } from '../../types/resources-types';
import { TranscodingJob } from '../../types/transcoding-jobs-types';
import { useItemSelector } from '../../hooks/use-selector';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { getFormattedDate } from '../../utils/date-utils';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { transcodingJobStateColors } from '../../types/common-types';
import { commonFormValidateMessages } from '../../constants/form-constants';
import {
  selectResourceRecordError,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  getPageResourceRecord
} from '../../redux/resource-record/resource-record-selectors';

import StubPage from '../../components/stub-page/stub-page';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import AccessibleTableTags from '../../components/accessible-table/accessible-table-tags';

const TranscodingJobsRecordPage = () => {
  const timeZone = useSelector(selectUserTimeZone);
  const record = useItemSelector({ resource: Resources.transcodingJobs }, getPageResourceRecord) as TranscodingJob;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const { secondSubRoute: recordId } = useLocationHandler();

  useResourceRecordData({ recordId, resource: Resources.transcodingJobs });

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.transcodingJobs} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Transcoding Job
      </Typography.Title>
      <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} validateMessages={commonFormValidateMessages}>
        <Form.Item label="Id">{record.id}</Form.Item>
        <Form.Item label="State">
          <AccessibleTableTags tags={[record.state]} colorsMap={transcodingJobStateColors} />
        </Form.Item>
        {record.error && (
          <Form.Item label="Error">
            <Tag className="accessible-table-tags-cell" color="red">
              {record.error}
            </Tag>
          </Form.Item>
        )}
        <Form.Item label="Video">
          <AccessibleTableLinkedCell value={record.video?.name ?? ''} link={`/${Resources.videos}/${record.video?.id}`} />
        </Form.Item>
        <Form.Item label="Create time">{getFormattedDate(record.create_time, timeZone)}</Form.Item>
        <Form.Item label="Start time">{getFormattedDate(record.start_time, timeZone)}</Form.Item>
        <Form.Item label="End time">{getFormattedDate(record.end_time, timeZone)}</Form.Item>
        <Form.Item label="Config">
          <ReactJson src={record?.config} name={false} />
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default TranscodingJobsRecordPage;
