import React, { memo, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Col, Input, Row } from 'antd';
import { PlusOutlined, StopOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import { NOOP } from '../../constants/common-constants';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { useContainerSize } from '../../hooks/use-container-size';
import { DynamicColumnItem } from '../../hooks/use-resource-list-dynamic-columns';
import { selectResourceItems } from '../../redux/resource-list/resource-list-reducer';
import { useResourceListData } from '../../hooks/use-resource-list-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { AccessibleTableSelectionType, ColumnItem } from '../../components/accessible-table/accessible-table-types';
import {
  selectIsResourceListLoading,
  selectResourceList,
  selectResourceListError,
  selectResourceListIsError,
  selectResourceListTotalRows,
  selectResourceListType,
  selectSelectedItemsIds
} from '../../redux/resource-list/resource-list-selectors';

import useSearchInput from '../../hooks/use-search-input';
import AccessibleTable from '../../components/accessible-table';
import StubPage from '../../components/stub-page/stub-page';
import ResourceListDynamicColumnsMenu from './resource-list-dynamic-columns-menu';
import './resource-list-container.scss';
import { SizeType } from '../../types/common-types';
import { FixedType } from 'rc-table/lib/interface';
import ConfirmButton from '../../components/buttons/confirm-button';

export type ResourceListContentProps = {
  tableSize?: SizeType;
  resource: Resources;
  columns?: ColumnItem[];
  dynamicColumns?: DynamicColumnItem[];
  renderFilterTabs?: ReactElement;
  renderAdditionalFilters?: ReactElement;
  renderSelectedRowActions?: ReactElement;
  withCreate?: boolean;
  withDelete?: boolean;
  withCopy?: boolean;
  withDynamicColumns?: boolean;
  withResetFilters?: boolean;
  withPagination?: boolean;
  withStaticHeight?: boolean;
  children?: ReactElement[] | ReactElement;
  selectionType?: AccessibleTableSelectionType;
  withSearchFilter?: boolean;
  searchFilterPlaceholder?: string;
  isDraggable?: boolean;
  onSortEnd?: (data: Record<string, any>[]) => void;
  onCreateClick?: () => void;
  onCopyClick?: () => void;
  onResetFilters?: () => void;
  onChangeDynamicColumns?: (keys: string[]) => void;
  onRowClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, { id }: Record<string, any>) => void;
};

const selectResourceListState = createStructuredSelector({
  isResourceListLoading: selectIsResourceListLoading,
  resourceList: selectResourceList,
  totalRows: selectResourceListTotalRows,
  selectedItems: selectSelectedItemsIds,
  resourceListType: selectResourceListType,
  isError: selectResourceListIsError,
  errorMessage: selectResourceListError
});

function ResourceListContent(props: ResourceListContentProps) {
  const {
    tableSize,
    resource,
    columns: propColumns = [],
    withCreate = false,
    withDelete = false,
    withCopy = false,
    withDynamicColumns = false,
    withSearchFilter = false,
    withPagination = true,
    withStaticHeight = false,
    searchFilterPlaceholder = 'Input search text',
    renderAdditionalFilters,
    renderSelectedRowActions,
    renderFilterTabs,
    children,
    selectionType,
    isDraggable = false,
    withResetFilters = false,
    dynamicColumns = [],
    onRowClick = NOOP,
    onResetFilters = NOOP,
    onSortEnd = NOOP,
    onCreateClick = NOOP,
    onCopyClick = NOOP,
    onChangeDynamicColumns = NOOP
  } = props;

  const onSelectResourceItems = useAction(selectResourceItems);
  const [{ height: pageHeight }, setPageRef] = useContainerSize();
  const [{ height: headerHeight }, setHeaderRef] = useContainerSize();
  const [{ height: tHeaderHeight }, setTHeaderInnerRef] = useContainerSize();

  const tableHeight = useMemo(() => {
    if (withStaticHeight) return undefined;
    const tablePagination = withPagination ? 50 : 0;
    return pageHeight - headerHeight - tablePagination - tHeaderHeight;
  }, [withStaticHeight, pageHeight, headerHeight, withPagination, tHeaderHeight]);

  const { isError, errorMessage, resourceList, totalRows, selectedItems, isResourceListLoading, resourceListType } =
    useSelector(selectResourceListState);

  const loading = isResourceListLoading || (resourceListType && resource !== resourceListType);

  const accessibleTableData = useMemo(
    () => resourceList.map((item) => (isDraggable ? { ...item, index: item.id } : item)),
    [isDraggable, resourceList]
  );

  const { searchInputValue, page, rowsPerPage, onChangePage, onChangeRowsPerPage, onChangeSearchValue, onDelete } = useResourceListData(resource);

  const {
    searchValue,
    onFocus: onFocusSearchInput,
    onChangeSearchInput
  } = useSearchInput({ value: searchInputValue, onChange: onChangeSearchValue });

  if (isError) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={errorMessage} />;
  }

  const columns = useMemo(
    () =>
      [
        ...propColumns,
        withDelete && {
          width: 48,
          key: 'delete',
          title: '',
          dataIndex: 'id',
          fixed: 'right' as FixedType,
          render: (value: string) => (
            <ConfirmButton
              type="primary"
              isDanger
              title="Are you sure you want to delete this record?"
              icon={<DeleteOutlined />}
              onConfirm={() => onDelete(value)}
            />
          )
        }
      ].filter(Boolean) as ColumnItem[],
    [onDelete, propColumns, withDelete]
  );

  return (
    <div className="resource-list-page" ref={setPageRef}>
      <div ref={setHeaderRef}>
        {renderFilterTabs}
        <Row align="middle" justify="space-between" className="resource-list-header">
          {withSearchFilter && (
            <Col flex={3}>
              <Input.Search
                autoFocus
                enterButton
                value={searchValue}
                id={`${resource}-searchFilter`}
                placeholder={searchFilterPlaceholder}
                onFocus={onFocusSearchInput}
                onChange={onChangeSearchInput}
              />
            </Col>
          )}
          <Col flex={2} className="action-container">
            <Row align="middle" justify="end">
              <Col flex="auto" className="action-container">
                {renderSelectedRowActions}
              </Col>
              <Col flex="auto" className="action-container">
                {renderAdditionalFilters}
              </Col>
              {withResetFilters && (
                <Col flex="auto" className="action-container">
                  <Button icon={<StopOutlined />} onClick={onResetFilters}>
                    Reset Filters
                  </Button>
                </Col>
              )}
              {withCreate && (
                <Col flex="auto" className="action-container">
                  <Button icon={<PlusOutlined />} onClick={onCreateClick}>
                    Create
                  </Button>
                </Col>
              )}
              {withCopy && (
                <Col flex="auto" className="action-container">
                  <Button icon={<CopyOutlined />} onClick={onCopyClick}>
                    Copy
                  </Button>
                </Col>
              )}
              {withDynamicColumns && (
                <Col flex="auto" className="action-container">
                  <ResourceListDynamicColumnsMenu columns={columns} dynamicColumns={dynamicColumns} onChangeDynamicColumns={onChangeDynamicColumns} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <AccessibleTable
        size={tableSize}
        tHeaderInnerRef={setTHeaderInnerRef}
        loading={!!loading}
        yScroll={tableHeight}
        withPagination={withPagination}
        page={page}
        columns={columns}
        selectionType={selectionType}
        rowsPerPage={rowsPerPage}
        selectedItems={selectedItems}
        data={accessibleTableData}
        totalRows={totalRows}
        isDraggable={isDraggable}
        onSortEnd={onSortEnd}
        onRowClick={onRowClick}
        onChangePage={onChangePage}
        onSelectRows={onSelectResourceItems}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
      {children}
    </div>
  );
}

export default memo(ResourceListContent);
