export type VideoMetaDataSizes = {
  mobile: number;
  source: number;
  studio: number;
  web: number;
};

export type VideoMetaData = {
  duration: number;
  sizes: VideoMetaDataSizes;
  streams: Record<string, unknown>;
};

export type Video = {
  id: number;
  uuid: string;
  name: string;
  path?: string;
  streaming_video_url: string;
  cdn_url: string;
  studio_url: string;
  mobile_url: string;
  web_url: string;
  meta_data: VideoMetaData;
  is_archived: boolean;
  has_audio: boolean;
  aspect_ratio: string;
  created_date: Date | string | null;
  updated_date: Date | string | null;
};

export enum VideosTypes {
  all = 'all',
  notAssigned = 'notAssigned'
}

export enum AspectRatioTypes {
  all = 'all',
  '9:16' = '9:16',
  '16:9' = '16:9',
  'other' = 'other'
}

export enum HasVideoTypes {
  all = 'all',
  withVideo = 'withVideo',
  withoutVideo = 'withoutVideo'
}
