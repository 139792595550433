import React from 'react';
import { Form, Modal, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { Resources } from '../../types/resources-types';
import { getErrorMessage } from '../../utils/errors';
import { RequestError } from '../../api/http-client';
import StudioConfigRecordForm from './studio-config-record-form';

export default function StudioConfigNewRecordModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  return (
    <Modal
      width={700}
      visible={open}
      onCancel={onClose}
      onOk={async () => {
        await form.validateFields();

        try {
          await api.common.create(Resources.studioConfigs, { data: form.getFieldsValue() });
          onDisplaySuccessNotification('Studio config record was successfully created.');
          navigate(0);
        } catch (error: unknown) {
          const errorMessage = getErrorMessage(error as RequestError);
          onDisplayErrorNotification(errorMessage);
        }
      }}
    >
      <Typography.Title className="page-title" level={3}>
        Create Studio Config
      </Typography.Title>
      <StudioConfigRecordForm form={form} />
    </Modal>
  );
}
