import { Resources } from '../types/resources-types';
import { VidePlayers } from '../types/video-player-types';

/**
 *
 */
export enum LocalStorageKeys {
  idToken = 'id_token',
  accessToken = 'access_token',
  expiresAt = 'expires_at',
  timeZone = 'time_zone',
  videoPlayer = 'video_player',
  resourcesDynamicColumns = 'resources_dynamic_columns'
}

/**
 * AUTH
 */
export const setTokenToLS = (idToken: string) => localStorage.setItem(LocalStorageKeys.idToken, idToken);
export const getTokenFromLS = () => localStorage.getItem(LocalStorageKeys.idToken);

export const setAccessTokenToLS = (accessToken: string) => localStorage.setItem(LocalStorageKeys.accessToken, accessToken);
export const getAccessTokenFromLS = () => localStorage.getItem(LocalStorageKeys.accessToken);

export const setExpiresAtToLS = (expiresAt: string) => localStorage.setItem(LocalStorageKeys.expiresAt, expiresAt);
export const getExpiresAtFromLS = () => localStorage.getItem(LocalStorageKeys.expiresAt);

export const clearAuthData = () => {
  localStorage.removeItem(LocalStorageKeys.idToken);
  localStorage.removeItem(LocalStorageKeys.accessToken);
  localStorage.removeItem(LocalStorageKeys.expiresAt);
};

/**
 * TIME ZONE
 */
export const saveUserTimeZoneToLS = (timeZone: string) => localStorage.setItem(LocalStorageKeys.timeZone, timeZone);
export const getUserTimeZoneFromLS = () => localStorage.getItem(LocalStorageKeys.timeZone);

/**
 * VIDEO PLAYER
 */
export const saveVideoPlayerZoneToLS = (videoPlayer: string) => localStorage.setItem(LocalStorageKeys.videoPlayer, videoPlayer);
export const getUserVideoPlayerFromLS = () => localStorage.getItem(LocalStorageKeys.videoPlayer) as VidePlayers | undefined;

/**
 * RESOURCE LIST DYNAMIC COLUMNS
 */
const getResourcesDynamicColumnsFromLS = () => {
  const resourcesDynamicColumnsJSON = localStorage.getItem(LocalStorageKeys.resourcesDynamicColumns);
  if (!resourcesDynamicColumnsJSON) return null;
  return JSON.parse(resourcesDynamicColumnsJSON);
};
export const saveResourceListDynamicColumnsToLS = (resource: Resources, dynamicColumns: string[]) => {
  const resourcesDynamicColumns = getResourcesDynamicColumnsFromLS();
  const nextResourcesDynamicColumns = JSON.stringify({ ...resourcesDynamicColumns, [resource]: dynamicColumns.join(',') });
  localStorage.setItem(LocalStorageKeys.resourcesDynamicColumns, nextResourcesDynamicColumns);
};
export const getResourceListDynamicColumnsFromLS = (resource: Resources): string[] | null => {
  if (!resource) return null;
  const resourcesDynamicColumns = getResourcesDynamicColumnsFromLS();
  if (!resourcesDynamicColumns) return null;
  const dynamicColumns = resourcesDynamicColumns[resource];
  if (!dynamicColumns) return null;
  return dynamicColumns.split(',');
};
