import React, { useState } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import { Resources } from '../../types/resources-types';
import { COLUMN_DESCEND, Order } from '../../types/common-types';

import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';
import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import CreateContentPlanDialog from './content-plan-create-dialog';

const resource = Resources.contentPlans;
const initialSortData = { sortField: 'name', sortOrder: Order.asc };
const searchFilterFields = ['name'];

const ContentPlansListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  const contentPlansListColumns = [
    {
      width: 250,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left' as FixedType,
      isRequired: true,
      onHeaderCell,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      )
    },
    {
      width: 170,
      key: 'created_date',
      title: 'Created at',
      dataIndex: 'created_date',
      onHeaderCell,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated at',
      dataIndex: 'updated_date',
      onHeaderCell,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  return (
    <ResourceListContainer
      withCreate
      withSearchFilter
      withDynamicColumns
      resource={resource}
      columns={contentPlansListColumns}
      searchFilterPlaceholder="Search content plans by name..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
    >
      <CreateContentPlanDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default ContentPlansListPage;
