import { Alert } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

const { ErrorBoundary } = Alert;
import { store } from './boot/store';
import App from './boot/app';
import AuthContextProvider from './context-providers/auth-context-provider';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <AuthContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthContextProvider>
  </ErrorBoundary>
);
