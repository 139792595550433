import {
  ArmsHeights,
  ArmUsedEnum,
  armUsedEnumDisplayMap,
  bilateralLimbUsedEnumDisplayMap,
  categoriesColorsMap,
  CompletionType,
  complexitiesColorsMap,
  ExerciseComplexity,
  ExerciseType,
  LimbUsedEnum,
  limbUsedEnumDisplayMap,
  MovementFocus,
  MovementHugs,
  movementHugsColorsMap,
  movementHugsHugAnglesMap,
  MovementPattern,
  MovementSets,
  movementSetsColors,
  MuscleGroup,
  PlaneOfMotion,
  Position,
  PrimeMover,
  ResourceAssignedTypes,
  SessionType,
  sessionTypesColorsMap,
  SideUsageType,
  statusesColorsMap,
  unilateralLimbUsedEnumDisplayMap,
  UserDevicePlatform,
  WorkoutEquipment
} from '../types/common-types';
import { AspectRatioTypes, HasVideoTypes, VideosTypes } from '../types/videos-types';
import { ContentStatus } from '../types/statuses-types';
import { AssetType } from '../types/studio-assets';
import { TranscodingJobState } from '../types/transcoding-jobs-types';
import { WorkoutFilterCategory } from '../types/workout-filters-types';
import { PromotionTileType } from '../types/promotion-tile-types';
import { WidgetType } from '../types/studio-widgets';
import { ConfigType } from '../types/studio-configs';

import { capitalizeText, createArrayFromNumbersRange, getEnumColoredOptionsList, getEnumOptionsList, upperCaseText } from './common-utils';
import { TrainerCreatedTypes } from '../types/exercises-types';

export const resourcesDictionaryUtils = [
  { value: ContentStatus.created, text: 'Created' },
  { value: ContentStatus.unpublished, text: 'Unpublished' },
  { value: ContentStatus.published, text: 'Published' },
  { value: ContentStatus.archived, text: 'Archived' }
];
export const priorities = [
  { value: 10, text: 'Low' },
  { value: 20, text: 'Normal' },
  { value: 30, text: 'Height' }
];
export const promotionTileTypes = [
  { value: PromotionTileType.custom, text: 'Custom' },
  { value: PromotionTileType.program, text: 'Program' },
  { value: PromotionTileType.trainer, text: 'Trainer' },
  { value: PromotionTileType.workout, text: 'Workout' },
  { value: PromotionTileType.popup, text: 'Popup' }
];
export const imageTypesList = [
  { value: ResourceAssignedTypes.all, text: 'All, assigned and not' },
  { value: ResourceAssignedTypes.notAssigned, text: 'Not Assigned' }
];
export const isArchivedFiltersList = [
  { text: 'All, archived and not', value: undefined },
  { text: 'Archived', value: 'true' },
  { text: 'Not archived', value: 'false' }
];
export const isProgramExclusiveFiltersList = [
  { text: 'All, exclusive and not', value: undefined },
  { text: 'Exclusive', value: 'true' },
  { text: 'Not exclusive', value: 'false' }
];
export const hasVideoFiltersList = [
  { text: 'All, with video and not', value: HasVideoTypes.all },
  { text: 'With video', value: HasVideoTypes.withVideo },
  { text: 'Without video', value: HasVideoTypes.withoutVideo }
];
export const trainerCreatedFiltersList = [
  { text: 'All, trainer created and not', value: TrainerCreatedTypes.all },
  { text: 'Trainer created', value: TrainerCreatedTypes.trainerCreated },
  { text: 'Not trainer created', value: TrainerCreatedTypes.notTrainerCreated }
];
export const isFeaturedFiltersList = [
  { text: 'All, featured and not', value: undefined },
  { text: 'Featured', value: 'true' },
  { text: 'Not featured', value: 'false' }
];
export const isHasAudioFiltersList = [
  { text: 'All', value: undefined },
  { text: 'With audio', value: 'true' },
  { text: 'Without audio', value: 'false' }
];
export const aspectRatioFiltersList = [
  { text: 'All aspect ratio', value: AspectRatioTypes.all },
  { text: '9:16', value: AspectRatioTypes['9:16'] },
  { text: '16:9', value: AspectRatioTypes['16:9'] },
  { text: 'Other aspect ratio', value: AspectRatioTypes.other }
];
export const videosTypesList = [
  { value: VideosTypes.all, text: 'All assigned and not' },
  { value: VideosTypes.notAssigned, text: 'Not Assigned' }
];
export const workoutFilterCategoriesList = getEnumOptionsList(WorkoutFilterCategory);
export const focusesList = getEnumOptionsList(MovementFocus);
export const workoutEquipmentList = getEnumOptionsList(WorkoutEquipment);
export const assetTypeList = getEnumOptionsList(AssetType);
export const widgetTypeList = getEnumOptionsList(WidgetType);
export const configTypeList = getEnumOptionsList(ConfigType);
export const transcodingJobStateList = getEnumOptionsList(TranscodingJobState);
export const exerciseComplexityList = getEnumOptionsList(ExerciseComplexity);
export const sideUsageTypeList = getEnumOptionsList(SideUsageType);
export const exerciseTypeList = getEnumOptionsList(ExerciseType, capitalizeText);
export const positionsList = getEnumOptionsList(Position);
export const movementPatternsList = getEnumOptionsList(MovementPattern);
export const primeMoverList = getEnumOptionsList(PrimeMover);
export const armsHeightsList = getEnumOptionsList(ArmsHeights);
export const muscleGroupsList = getEnumOptionsList(MuscleGroup);
export const planeOfMotionList = getEnumOptionsList(PlaneOfMotion);
export const completionTypeList = getEnumOptionsList(CompletionType, capitalizeText);
export const liftVodCompletionTypeList = completionTypeList.filter((item) =>
  [CompletionType.reps, CompletionType.time].includes(item.value as CompletionType)
);
export const userDevicePlatformList = getEnumOptionsList(UserDevicePlatform, upperCaseText);
export const armUsedEnumList = Object.keys(armUsedEnumDisplayMap).map((item) => ({
  text: armUsedEnumDisplayMap[item as ArmUsedEnum],
  value: item
}));
export const limbUsedEnumList = Object.keys(limbUsedEnumDisplayMap).map((item) => ({
  text: limbUsedEnumDisplayMap[item as LimbUsedEnum],
  value: item
}));
export const bilateralLimbUsedEnumList = Object.keys(bilateralLimbUsedEnumDisplayMap).map((item) => ({
  text: limbUsedEnumDisplayMap[item as LimbUsedEnum],
  value: item
}));
export const unilateralLimbUsedEnumList = Object.keys(unilateralLimbUsedEnumDisplayMap).map((item) => ({
  text: limbUsedEnumDisplayMap[item as LimbUsedEnum],
  value: item
}));
export const movementSetsList = Object.keys(movementSetsColors).map((item: string | MovementSets) => ({
  text: item,
  value: Number(item),
  color: movementSetsColors[item as MovementSets]
}));

export const movementHugsList = Object.keys(MovementHugs).map((item: string) => ({
  text: `${item} - ${movementHugsHugAnglesMap[item]}°`,
  color: movementHugsColorsMap[item],
  value: item
}));

export const movementsRepsList = createArrayFromNumbersRange(1, 30).map((item: number) => ({
  text: `${item}`,
  value: item
}));

export const categoriesList = getEnumColoredOptionsList(categoriesColorsMap, capitalizeText);
export const complexitiesList = getEnumColoredOptionsList(complexitiesColorsMap);
export const sessionTypesList = [
  { text: 'VOD', value: SessionType.VideoonDemand, color: sessionTypesColorsMap[SessionType.VideoonDemand] },
  { text: SessionType.LiftVOD, value: SessionType.LiftVOD, color: sessionTypesColorsMap[SessionType.LiftVOD] },
  { text: SessionType.TpiVOD, value: SessionType.TpiVOD, color: sessionTypesColorsMap[SessionType.TpiVOD] },
  { text: SessionType.PlannedSession, value: SessionType.PlannedSession, color: sessionTypesColorsMap[SessionType.PlannedSession] },
  { text: SessionType.LiftPlannedSession, value: SessionType.LiftPlannedSession, color: sessionTypesColorsMap[SessionType.LiftPlannedSession] },
  { text: SessionType.TpiPlannedSession, value: SessionType.TpiPlannedSession, color: sessionTypesColorsMap[SessionType.TpiPlannedSession] },
  { text: SessionType.Rest, value: SessionType.Rest, color: sessionTypesColorsMap[SessionType.Rest] }
  // { text: 'CPS Template', value: SessionType.PlannedSessionTemplate, color: sessionTypesColorsMap[SessionType.PlannedSessionTemplate] }
];
export const statusesList = getEnumColoredOptionsList(statusesColorsMap, capitalizeText);
export const displayDurationsList = [
  { text: '5m', value: 5 * 60000 },
  { text: '10m', value: 10 * 60000 },
  { text: '15m', value: 15 * 60000 },
  { text: '20m', value: 20 * 60000 },
  { text: '25m', value: 25 * 60000 },
  { text: '30m', value: 30 * 60000 },
  { text: '35m', value: 35 * 60000 },
  { text: '40m', value: 40 * 60000 },
  { text: '45m', value: 45 * 60000 },
  { text: '50m', value: 50 * 60000 },
  { text: '55m', value: 55 * 60000 },
  { text: '60m', value: 60 * 60000 }
];

export const AvailableWorkoutTypes = [
  SessionType.VideoonDemand,
  SessionType.TpiVOD,
  SessionType.LiftVOD,
  SessionType.PlannedSession,
  SessionType.LiftPlannedSession,
  SessionType.TpiPlannedSession,
  SessionType.Rest
];

export const isCpsWorkoutType = (session?: SessionType) =>
  [SessionType.PlannedSession, SessionType.LiftPlannedSession, SessionType.TpiPlannedSession].some((s) => session === s);

export const isLiftWorkoutType = (session?: SessionType) => session === SessionType.LiftVOD || session === SessionType.LiftPlannedSession;
