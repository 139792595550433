import React, { useMemo } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { transcodingJobStateList } from '../../utils/resources-dictionary-utils';
import { COLUMN_DESCEND, Order, transcodingJobStateColors } from '../../types/common-types';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';

import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';

const resource = Resources.transcodingJobs;
const filterTypes = { state: 'state' };
const searchFilterFields = ['video.name'];
const initialSortData = { sortField: 'create_time', sortOrder: Order.desc };

const TranscodingJobsListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, activeFilterDropdown, onSetFilter, onResetFilters, onFilterDropdownChange } = useResourceListTableFilters({ filterTypes });
  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;
    const typeFilter = filters[filterTypes.state];
    apiFilters[`${filterTypes.state}||${CondOperator.IN}`] = typeFilter.length ? typeFilter : undefined;
    return apiFilters;
  }, [filters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const transcodingJobsColumns = [
    {
      width: 350,
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
      fixed: 'left' as FixedType,
      isRequired: true,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('id'),
      render: (value: string, record: Record<string, any>) => <AccessibleTableLinkedCell value={value} link={`/${resource}/${record?.id}`} />,
      onHeaderCell
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'state',
      title: 'State',
      isVisible: activeFilterDropdown === filterTypes.state,
      activeFilters: filters[filterTypes.state],
      filtersList: transcodingJobStateList,
      filterColorsMap: transcodingJobStateColors,
      filterDropdownType: filterTypes.state,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'video',
      title: 'Video',
      dataIndex: 'video',
      render: (value: string, record: Record<string, any>) => {
        const { id: videoItemId, name: videoItemName } = record?.video ?? {};
        return <AccessibleTableLinkedCell value={videoItemName} highlight={searchInputValue} link={`/${Resources.videos}/${videoItemId}`} />;
      }
    },
    {
      width: 170,
      key: 'create_time',
      title: 'Created time',
      dataIndex: 'create_time',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('create_time'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    }
  ];

  return (
    <ResourceListContainer
      withResetFilters
      withSearchFilter
      withDynamicColumns
      resource={resource}
      columns={transcodingJobsColumns}
      onResetFilters={onResetFilters}
    />
  );
};

export default TranscodingJobsListPage;
