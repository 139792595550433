import React, { Ref, useRef } from 'react';
import { Modal } from 'antd';
import { StudioAssetsRecordForm, StudioAssetsRecordFormRef } from './studio-assets-record-form';
import api from '../../api';
import { Resources } from '../../types/resources-types';
import { AssetType } from '../../types/studio-assets';

export function StudioAssetsNewRecordModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const formRef = useRef<StudioAssetsRecordFormRef>();

  const onFinish = () => {
    const formData = formRef.current?.form.getFieldsValue(true);

    const data = {
      ...formData,
      customUrl: formData.type === AssetType.custom ? formData.value : undefined,
      text: formData.type === AssetType.text ? formData.value : undefined
    };

    api.common.create(Resources.studioAssets, { data }).then(() => onClose());
  };

  return (
    <Modal width={800} title="Create Asset" footer={null} visible={open} onCancel={onClose}>
      <StudioAssetsRecordForm create ref={formRef as Ref<StudioAssetsRecordFormRef>} onFinish={onFinish} />
    </Modal>
  );
}
