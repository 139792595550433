import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import { getFormattedDate } from '../../utils/date-utils';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import FormItemVideo from '../../components/form-components/form-item-video';
import FormItemImage from '../../components/form-components/form-item-image';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { FormValues, useFormValues } from '../../hooks/use-form-values';
import { AssetType } from '../../types/studio-assets';
import useSelectImage from '../../hooks/use-select-image';
import useSelectVideo from '../../hooks/use-select-video';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { useSelector } from 'react-redux';
import { urlValidator } from '../../validators/url-validator';

export type StudioAssetsRecordFormRef = {
  form: FormInstance;
  nextFormValues: FormValues;
  onClearChangedFormValues: () => void;
};

type StudioAssetsRecordFormProps = {
  initialData?: FormValues;
  onFinish: (x: boolean) => void;
  create?: boolean;
};

export const StudioAssetsRecordForm = forwardRef<StudioAssetsRecordFormRef, StudioAssetsRecordFormProps>(function StudioAssetsRecordFormRender(
  { initialData, onFinish, create },
  ref
) {
  const timeZone = useSelector(selectUserTimeZone);
  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData: initialData || {} });

  const [form] = Form.useForm();
  const formData = form.getFieldsValue(true);
  const record = { ...initialData, ...formData };
  const { image, video, created_date, updated_date } = record;

  const [type, setType] = useState(record.type);

  const isVideoAssetType = type === AssetType.video;
  const isImageAssetType = type === AssetType.image || type === AssetType.text;
  const isTextAssetType = type === AssetType.text;
  const isCustomAssetType = type === AssetType.custom;

  const { assetImage, setAssetImage } = useSelectImage({ imageData: image });
  const { assetVideo, setAssetVideo } = useSelectVideo({ videoData: video });

  const isAssetsChanged = useMemo(() => {
    if (isImageAssetType && assetImage) return assetImage.url !== image?.url;
    if (isVideoAssetType && assetVideo) return assetVideo.id !== video?.id;
  }, [isImageAssetType, assetImage, image?.url, isVideoAssetType, assetVideo, video?.id]);

  const nextFormValues = useMemo(() => {
    if (isTextAssetType && assetImage) return isAssetsChanged ? { ...changedFormValues, image_id: assetImage.id } : changedFormValues;
    if (isImageAssetType && assetImage) return { ...changedFormValues, image_id: assetImage.id };
    if (isVideoAssetType && assetVideo) return { ...changedFormValues, video_id: assetVideo.id };
    return changedFormValues;
  }, [changedFormValues, isImageAssetType, isTextAssetType, isAssetsChanged, isVideoAssetType, assetVideo, assetImage]);

  const onResetAssets = () => {
    if (image && isImageAssetType) setAssetImage({ ...image, isUploaded: false });
    if (video && isVideoAssetType) setAssetVideo(video);
  };

  const onResetForm = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onReset = () => {
    onResetForm();
    onResetAssets();
  };

  useImperativeHandle(ref, () => ({
    nextFormValues,
    onClearChangedFormValues,
    form
  }));

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      initialValues={initialData}
      className="record-form"
      validateMessages={commonFormValidateMessages}
      onValuesChange={onValuesChange}
    >
      <Form.Item name="id" label="Id">
        {create ? <Input /> : initialData?.id}
      </Form.Item>
      {create ? (
        <Form.Item getValueFromEvent={(value) => value[0]} label="Asset type" rules={[{ required: true }]}>
          <Select
            onChange={(value) => {
              form.setFieldsValue({ type: value });
              setType(value);
            }}
            options={Object.keys(AssetType).map((value) => ({ label: value, value }))}
          />
        </Form.Item>
      ) : (
        <Form.Item getValueFromEvent={(value) => value[0]} label="Asset type" rules={[{ required: true }]}>
          {type}
        </Form.Item>
      )}
      {isTextAssetType && (
        <Form.Item name="value" label="Text">
          <Input.TextArea rows={4} />
        </Form.Item>
      )}
      {isCustomAssetType && (
        <Form.Item name="value" label="Link" rules={[{ type: 'url', required: true, validator: urlValidator }]}>
          <Input onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.setAttribute('autocomplete', 'off')} />
        </Form.Item>
      )}
      {created_date && <Form.Item label="Created date">{getFormattedDate(created_date, timeZone)}</Form.Item>}
      {updated_date && <Form.Item label="Updated date">{getFormattedDate(updated_date, timeZone)}</Form.Item>}
      {isImageAssetType && assetImage?.name && <Form.Item label="Image name">{assetImage.name}</Form.Item>}
      {isVideoAssetType && assetVideo?.name && <Form.Item label="Video name">{assetVideo.name}</Form.Item>}
      {isImageAssetType && (
        <Form.Item label="Image">
          <FormItemImage
            withResize={false}
            value={assetImage?.url}
            onChangeImageData={(data) => {
              setAssetImage(data);
              form.setFieldsValue({ imageId: data?.id });
            }}
          />
        </Form.Item>
      )}
      {isVideoAssetType && (
        <Form.Item label="Video" rules={[{ required: true }]}>
          <FormItemVideo
            value={assetVideo as UseVideoSourceSelectionParams}
            onChangeVideoData={(data) => {
              setAssetVideo(data);
              form.setFieldsValue({ videoId: data?.id });
            }}
          />
        </Form.Item>
      )}
      <Form.Item shouldUpdate colon={false} label=" ">
        {(params) => {
          const isFormTouched = isFormValuesChanged || isAssetsChanged;
          const hasErrors = !!params.getFieldsError().filter(({ errors }) => errors.length).length;
          const isSubmitDisabled = !isFormTouched || hasErrors;
          return (
            <Row align="middle" justify="end">
              <Space>
                {create ? null : (
                  <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormTouched} onClick={onReset}>
                    Discard changes
                  </Button>
                )}
                <Button type="primary" disabled={isSubmitDisabled} onClick={() => onFinish(false)}>
                  Save
                </Button>
                {create ? null : (
                  <Button type="primary" disabled={isSubmitDisabled} onClick={() => onFinish(true)}>
                    Save and continue to programming
                  </Button>
                )}
              </Space>
            </Row>
          );
        }}
      </Form.Item>
    </Form>
  );
});
