import React from 'react';
import { ContentTabItemConfig } from 'src/components/content-tabs/content-tabs';
import FormItemImage, { FormImageData } from 'src/components/form-components/form-item-image';
import FormItemVideo from 'src/components/form-components/form-item-video';
import useSelectImage from 'src/hooks/use-select-image';
import useSelectVideo from 'src/hooks/use-select-video';
import { UseVideoSourceSelectionParams } from 'src/hooks/use-video-source-selection';
import { VideoAssetElement } from 'src/types/studio-assets';
import { Survey, SurveyStep } from 'src/types/survey-types';

type ChangedAssetsData = {
  image_id?: number | null;
  video_id?: number | null;
};

type UseWorkoutAssetsTabs = {
  isAssetsChanged: boolean;
  isImageChanged: boolean;
  isAssetVideoChanged: boolean;
  changedAssetsData: ChangedAssetsData | null;
  assetImage: FormImageData | null;
  assetVideo: VideoAssetElement | null;
  tabsConfig: ContentTabItemConfig[];
  resetImageAsset: () => void;
  resetVideoAsset: () => void;
  resetAssets: () => void;
};

const useAssetsTabs = (record?: Survey | SurveyStep | null): UseWorkoutAssetsTabs => {
  const { assetImage, setAssetImage } = useSelectImage({ imageData: record?.image ?? null });
  const { assetVideo, setAssetVideo } = useSelectVideo({ videoData: record?.video ?? null });

  const isImageChanged = `${assetImage?.id ?? ''}` !== `${record?.image?.id ?? ''}`;
  const isAssetVideoChanged = `${assetVideo?.id ?? ''}` !== `${record?.video?.id ?? ''}`;

  const isAssetsChanged = isImageChanged || isAssetVideoChanged;

  const getChangedAssetsData = () => {
    const changedAssetsData = {} as ChangedAssetsData;
    if (!isAssetsChanged) return null;

    if (isImageChanged) changedAssetsData.image_id = assetImage ? Number(assetImage.id) : null;
    if (isAssetVideoChanged) changedAssetsData.video_id = assetVideo ? Number(assetVideo.id) : null;

    return changedAssetsData;
  };

  const resetImageAsset = () => {
    setAssetImage(record?.image ? { ...record.image, isUploaded: false } : null);
  };
  const resetVideoAsset = () => {
    setAssetVideo(record?.video ?? null);
  };
  const resetAssets = () => {
    resetImageAsset();
    resetVideoAsset();
  };

  const changedAssetsData = getChangedAssetsData();

  const tabsConfig = [
    {
      key: 'introVideo',
      title: 'Video',
      component: <FormItemVideo withRemove withTitle value={assetVideo as UseVideoSourceSelectionParams} onChangeVideoData={setAssetVideo} />
    },
    {
      key: 'image',
      title: 'Image',
      component: (
        <FormItemImage withRemove withTitle withResize={false} title={assetImage?.name} value={assetImage?.url} onChangeImageData={setAssetImage} />
      )
    }
  ];

  return {
    tabsConfig,
    assetImage,
    assetVideo,
    isAssetsChanged,
    isImageChanged,
    isAssetVideoChanged,
    changedAssetsData,
    resetImageAsset,
    resetVideoAsset,
    resetAssets
  };
};

export default useAssetsTabs;
