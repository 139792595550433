import React, { useRef } from 'react';

import useOutsideClick from '../../hooks/use-outside-click';
import useWorkoutTrainerFilter from './use-workout-trainer-filter';
import DropdownRecordSelectionListOverlay from '../../components/dropdown-record-selection-list/dropdown-record-selection-list-overlay';
import './workouts.scss';

type WorkoutTrainerFilterDropdownProps = {
  isVisible: boolean;
  activeFilters: string[];
  onSelectFilters: (filters: string[]) => void;
  onClose: () => void;
};

const WorkoutTrainerFilterDropdown = (props: WorkoutTrainerFilterDropdownProps) => {
  const { isVisible, activeFilters, onSelectFilters, onClose } = props;

  const filterDropdownRef = useRef(null);

  const { trainersList, selectedTrainers, searchInputValue, onChangeSearchInput, onSelectTrainer, onApplySelectedExercises } =
    useWorkoutTrainerFilter({ isVisible, selectedItemsIds: activeFilters, onSelectItems: onSelectFilters, onClose });

  useOutsideClick({ ref: filterDropdownRef, isActive: isVisible, callback: onClose });

  return (
    <div ref={filterDropdownRef} className="workout-trainer-filter-dropdown">
      <DropdownRecordSelectionListOverlay
        inputPlaceholder="Search trainer by name"
        itemsList={trainersList.map((item) => ({ ...item, name: `${item.first_name} ${item.last_name}` }))}
        selectedListItems={selectedTrainers.map((item) => ({ ...item, name: `${item.first_name} ${item.last_name}` }))}
        searchInputValue={searchInputValue}
        libraryTitle="Trainers library"
        selectedItemsTitle="Selected trainers"
        onChangeSearchInput={onChangeSearchInput}
        onSelectListItem={onSelectTrainer}
        onSaveSelectedItems={onApplySelectedExercises}
        onClose={onClose}
      />
    </div>
  );
};

export default WorkoutTrainerFilterDropdown;
