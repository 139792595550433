import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import api from '../api';
import { ContentStatus } from '../types/statuses-types';
import { Resources } from '../types/resources-types';
import { EntitiesSubUrls } from '../api/resources-entities-api';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../utils/notifications-utils';
import { getErrorMessage } from '../utils/errors';
import { RequestError } from '../api/http-client';
import { selectResourceRecord } from '../redux/resource-record/resource-record-selectors';
import { Workout } from '../types/workout-types';
import { Program } from '../types/programs-types';
import { Instructor } from '../types/instructors-types';
import { useAction } from '../hooks/use-actions';
import { setResourceRecord } from '../redux/resource-record/resource-record-reducer';
import { getFormattedDate } from '../utils/date-utils';
import { selectUserTimeZone } from '../redux/settings/settings-selectors';
import DatePickerWithTimeZone from './date-picker-with-time-zone/date-picker-with-time-zone';

type ResourceRecordSchedulePublishDateProps = {
  resource: Resources;
};

const ResourceRecordSchedulePublishDate = ({ resource }: ResourceRecordSchedulePublishDateProps) => {
  const record = useSelector(selectResourceRecord) as Workout | Program | Instructor;
  const timeZone = useSelector(selectUserTimeZone);
  const onSetWorkoutRecord = useAction(setResourceRecord);

  const onSchedulePublishDate = async (publishDate: moment.Moment | null) => {
    const scheduledPublishDate = moment(publishDate).format();

    if (!record || !publishDate) return;

    try {
      const response = await api.resourcesEntities.updateResourceEntity({
        resource,
        recordId: record.id,
        entitySubUrl: EntitiesSubUrls.schedulePublish,
        data: { scheduled_publish_date: scheduledPublishDate }
      });
      onSetWorkoutRecord({ ...record, ...response, scheduled_publish_date: scheduledPublishDate });
      onDisplaySuccessNotification('Schedule publish date was successfully set');
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error as RequestError);
      onDisplayErrorNotification(errorMessage);
    }
  };

  if (record.status !== ContentStatus.unpublished) {
    return <span>{getFormattedDate(record?.scheduled_publish_date, timeZone)}</span>;
  }

  return (
    <DatePickerWithTimeZone
      showTime
      className=""
      withConfirmModal
      confirmTitle="Are you sure you want to change the scheduled publish date?"
      disabledDate={(d) => !d || d.isBefore(moment().format())}
      placeholder="Scheduled publish date"
      value={record.scheduled_publish_date}
      onChange={onSchedulePublishDate}
    />
  );
};

export default ResourceRecordSchedulePublishDate;
