import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Order } from '../types/common-types';
import { useAction } from './use-actions';
import { getEncodedResourceListUrl } from '../api/api-utils';
import { setInitialization } from '../redux/resource-list/resource-list-reducer';
import { Resources } from '../types/resources-types';
import {
  defaultAdditionalFilters,
  defaultPage,
  defaultRowsPerPage,
  defaultSearchInputValue,
  defaultSearchKey
} from '../redux/resource-list/resource-list-constants';
import useResourceListUrlParams from './use-resource-list-url-params';

type UseResourceListInitialRequestParams = {
  resource: Resources;
  sortField: string;
  sortOrder: Order;
  searchFilterFields?: string[];
  initialFilters?: Record<string, any>;
};

const useResourceListInitialRequestParams = (params: UseResourceListInitialRequestParams) => {
  const { initialFilters = {}, resource, sortField, sortOrder, searchFilterFields = [] } = params;

  const navigate = useNavigate();
  const {
    isInitialized,
    sortField: urlSortField,
    sortOrder: urlSortOrder,
    page: urlPage,
    rowsPerPage: urlRowsPerPage,
    additionalFilters: urlAdditionalFilters,
    searchInputValue: urlSearchInputValue = defaultSearchInputValue
  } = useResourceListUrlParams();

  const onInitialize = useAction(setInitialization);
  const searchFilterKey = useMemo(() => `searchBy||${searchFilterFields.join(',')}`, [searchFilterFields]);
  const searchKey = searchFilterKey || defaultSearchKey;

  const order = urlSortOrder || sortOrder;
  const field = urlSortField || sortField;
  const page = urlPage || defaultPage;
  const perPage = urlRowsPerPage || defaultRowsPerPage;
  const filter = useMemo(
    () => ({
      ...defaultAdditionalFilters,
      ...initialFilters,
      ...urlAdditionalFilters,
      [searchKey]: isInitialized ? urlSearchInputValue : defaultSearchInputValue
    }),
    [initialFilters, urlAdditionalFilters, searchKey, urlSearchInputValue, isInitialized]
  );

  const initialRequestParams = useMemo(
    () => ({ sort: { order, field }, pagination: { page, perPage }, filter }),
    [order, field, page, perPage, filter]
  );

  useEffect(() => {
    if (!isInitialized) {
      const pageUrl = getEncodedResourceListUrl({ resource, params: initialRequestParams });
      navigate(`/${pageUrl}`);
      onInitialize();
    }
  }, [isInitialized, navigate, initialRequestParams, resource, searchFilterKey, onInitialize]);
};

export default useResourceListInitialRequestParams;
