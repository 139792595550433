import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../api';
import { Resources } from '../types/resources-types';
import { useAction } from '../hooks/use-actions';
import { RequestError } from '../api/http-client';
import { getErrorMessage } from '../utils/errors';
import { setResourceRecord } from '../redux/resource-record/resource-record-reducer';
import { selectResourceRecord } from '../redux/resource-record/resource-record-selectors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../utils/notifications-utils';
import CheckboxSelectionList from './checkbox-selection-list/checkbox-selection-list';
import { ResourceRecord } from 'src/types/resource-record-types';
import { SFields } from '@nestjsx/crud-request';

export type SelectResourceRecordProps<T extends ResourceRecord, TChild> = {
  collectionResource: Resources;
  isEditable?: boolean;
  confirmTitle?: string;
  confirmDescription?: string;
  getChildQueryFilter: () => SFields | undefined;
  getIdList: (record: T | null) => string[];
  addElement: (request: typeof api, record: T, element: TChild) => Promise<T>;
  removeElement: (request: typeof api, record: T, element: TChild) => Promise<T>;
};

const SelectResourceChildCollectionBase = <TRecord extends ResourceRecord, TChild extends { id: string; name: string }>(
  props: SelectResourceRecordProps<TRecord, TChild>
) => {
  const { confirmTitle, confirmDescription, isEditable = true, collectionResource } = props;

  const record = useSelector(selectResourceRecord) as TRecord | null;
  const onSetResourceRecord = useAction(setResourceRecord);

  const [collection, setCollection] = React.useState<TChild[]>([]);
  const ids = props.getIdList(record);
  const selectionOptions = collection.map((item) => ({ text: item.name, value: item.id }));

  useEffect(() => {
    api.common
      .getList(collectionResource, {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: props.getChildQueryFilter() ?? {}
      })
      .then(({ data: response }) => {
        setCollection(response);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  }, [collectionResource, props]);

  const getChildElementById = (id: string) => collection.find((item) => item.id === id);

  const onAddElement = (addedElementId: string) => {
    if (!record?.id) return;

    const added = getChildElementById(addedElementId);
    if (added) {
      props
        .addElement(api, record, added)
        .then((result: TRecord) => {
          onSetResourceRecord(result);
          onDisplaySuccessNotification(`Element ${result?.id ?? ''} was successfully added`);
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error);
          onDisplayErrorNotification(errorMessage);
        });
    }
  };

  const onRemoveElement = (removedElementId: string) => {
    if (!record?.id) return;
    const removed = getChildElementById(removedElementId)!;

    props
      .removeElement(api, record, removed)
      .then((result: TRecord) => {
        onSetResourceRecord(result);
        onDisplaySuccessNotification(`Element ${result?.id ?? ''} was successfully removed`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onChange = (value: string[]) => {
    const addedContentPlans = value.filter((item) => !ids.includes(item));
    const removedContentPlans = ids.filter((item) => !value.includes(item));
    if (addedContentPlans.length) return onAddElement(addedContentPlans[0]);
    if (removedContentPlans.length) return onRemoveElement(removedContentPlans[0]);
  };

  if (!isEditable) {
    return (
      <span style={{ width: '100%' }}>
        {selectionOptions
          .filter((item) => ids.includes(item.value))
          .map((item) => item.text)
          .join(', ')}
      </span>
    );
  }

  return (
    <CheckboxSelectionList
      isMultiple
      withConfirmModal
      value={ids}
      options={selectionOptions}
      confirmTitle={confirmTitle}
      confirmDescription={confirmDescription}
      onChange={(value) => onChange(value as string[])}
    />
  );
};

export default SelectResourceChildCollectionBase;
