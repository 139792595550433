import React from 'react';
import { Button, Divider, Form, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import useProgramAssetsTabs from './use-program-assets-tabs';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { stringValidator } from '../../validators/string-validator';

import ContentTabs from '../../components/content-tabs/content-tabs';
import SwitcherButton from '../../components/buttons/switcher-button';

type CreateProgramDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

type ProgramCreateRequestData = {
  name: string;
  description?: string;
  is_lift?: boolean;
  listing_image_large_id?: number;
  listing_image_small_id?: number;
  detail_image_large_id?: number;
  detail_image_small_id?: number;
  preview_video_id?: number;
};

const initialFormData = {
  name: '',
  description: '',
  is_lift: false,
  listing_image_large_id: undefined,
  listing_image_small_id: undefined,
  detail_image_large_id: undefined,
  detail_image_small_id: undefined,
  preview_video_id: undefined
};

const CreateProgramDialog = (props: CreateProgramDialogProps) => {
  const { isOpened, onClose } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {
    tabsConfig,
    changedAssetsData,
    setListingImageLarge,
    setListingImageSmall,
    setDetailImageLarge,
    setDetailImageSmall,
    setIntroAnimation,
    setPreviewVideo
  } = useProgramAssetsTabs();

  const { changedFormValues, onValuesChange } = useFormValues({ initialData: initialFormData });

  const onReset = () => {
    setListingImageLarge(null);
    setListingImageSmall(null);
    setDetailImageLarge(null);
    setDetailImageSmall(null);
    setIntroAnimation(null);
    setPreviewVideo(null);
    form.resetFields();
  };

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const programRecord = form.getFieldsValue(true) as ProgramCreateRequestData;
    const params = { data: { ...programRecord, ...changedAssetsData } } as CreateParams;

    api.common
      .create(Resources.programs, params)
      .then(({ data: { id: newRecordId } }) => {
        onDisplaySuccessNotification('Program record was successfully created');
        navigate(`/${Resources.programs}/${newRecordId}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      wrapClassName="modal-wrap"
      title="Create program record"
      onCancel={onCloseModal}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialFormData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="is_lift" label="Is lift" valuePropName="checked">
          <SwitcherButton />
        </Form.Item>

        <Form.Item name="is_collection" label="Is collection" valuePropName="checked">
          <SwitcherButton />
        </Form.Item>

        <Divider>Assets</Divider>
        <ContentTabs tabsConfig={tabsConfig} />
        <Divider />

        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={!changedFormValues.name}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProgramDialog;
