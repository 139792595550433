import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type WorkoutMovementHeaderActionsProps = {
  isBlocksExist: boolean;
  onCreateNewBlock: () => void;
  onCreateNewMovement: () => void;
  onCreateNewTransition: () => void;
};

const WorkoutMovementsHeaderActions = (props: WorkoutMovementHeaderActionsProps) => {
  const { isBlocksExist, onCreateNewMovement, onCreateNewBlock, onCreateNewTransition } = props;

  const menuItems = [
    { key: '2', label: 'Add block', onClick: onCreateNewBlock },
    { key: '1', label: 'Add movement', onClick: onCreateNewMovement },
    { key: '3', label: 'Add transition', onClick: onCreateNewTransition }
  ];

  if (!isBlocksExist) {
    return <Button onClick={onCreateNewBlock}>Create new block</Button>;
  }

  return (
    <Dropdown placement="bottomRight" overlay={<Menu items={menuItems} />}>
      <Button>
        Add new <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default WorkoutMovementsHeaderActions;
