import { Button, Form, FormInstance, Input, Select } from 'antd';
import React from 'react';
import { getFormattedDate } from 'src/utils/date-utils';
import { useSelector } from 'react-redux';
import { selectUserTimeZone } from 'src/redux/settings/settings-selectors';
import { FormValues } from '../../hooks/use-form-values';

const typeOptions = ['custom'].map((label) => ({ label, value: label }));

export default function StudioConfigRecordForm({
  form,
  record,
  onValuesChange,
  onReset,
  onFinish,
  isFormValuesChanged
}: {
  form?: FormInstance;
  record?: FormValues;
  onValuesChange?: (changedValues: FormValues) => void;
  onReset?: () => void;
  onFinish?: () => void;
  isFormValuesChanged?: boolean;
}) {
  const timeZone = useSelector(selectUserTimeZone);

  return (
    <Form form={form} layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} initialValues={record} onValuesChange={onValuesChange}>
      <Form.Item label="Id" name="id" rules={[{ required: true }]}>
        {record ? record.id : <Input />}
      </Form.Item>
      <Form.Item label="Config type" name="type" rules={[{ required: true }]}>
        {record ? record.type : <Select options={typeOptions} />}
      </Form.Item>
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name="config_value" label="Config Value">
        <Input />
      </Form.Item>
      {record && (
        <>
          <Form.Item label="Created date">{getFormattedDate(record.created_date, timeZone)}</Form.Item>
          <Form.Item label="Updated date">{getFormattedDate(record.updated_date, timeZone)}</Form.Item>
        </>
      )}

      {(onReset || onFinish) && (
        <Form.Item label=" " colon={false}>
          {onReset && (
            <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
              Reset
            </Button>
          )}
          {onFinish && (
            <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged} onClick={onFinish}>
              Save
            </Button>
          )}
        </Form.Item>
      )}
    </Form>
  );
}
