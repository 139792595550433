import { defaultColorsList } from '../../constants/common-constants';
import { CascaderSelectionOption } from '../../components/form-components/form-item-cascader-selection';
import { getAlphabeticalSort, getAlphabeticalSortBy } from '../../utils/common-utils';

export const getCategoryFocusesOptionsList = (categoryFocusesMap: Record<string, string[]>) =>
  getAlphabeticalSort(Object.keys(categoryFocusesMap)).reduce((acc: CascaderSelectionOption[], category: string, index: number) => {
    const categoryFocuses = categoryFocusesMap[category as keyof typeof categoryFocusesMap];
    const categoryFocusesOptions = categoryFocuses.map((focus: string) => ({ value: focus, label: focus, color: 'grey' }));
    const children = getAlphabeticalSortBy(categoryFocusesOptions, 'label');
    const categoryOption = { value: category, label: category, color: defaultColorsList[index], children };
    return [...acc, categoryOption] as CascaderSelectionOption[];
  }, []);
