import React, { useEffect, useState } from 'react';
import useDebounce from './use-debounce';

type SearchInputProps = {
  value?: string;
  autocomplete?: boolean;
  debounceDelay?: number;
  onChange: (value: string) => void;
};

type UseSearchInput = {
  searchValue: string;
  onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const useSearchInput = (props: SearchInputProps): UseSearchInput => {
  const { value = '', autocomplete = false, debounceDelay = 500, onChange } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, debounceDelay);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  useEffect(() => {
    onChange(debouncedSearchValue);
  }, [debouncedSearchValue, onChange]);

  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => !autocomplete && e.target.setAttribute('autocomplete', 'off');
  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  return { searchValue, onFocus, onChangeSearchInput };
};

export default useSearchInput;
