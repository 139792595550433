import { Button, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import cs from 'classnames';
import { NOOP } from '../../constants/common-constants';
import './accessible-table.scss';

type AccessibleTableTextAreaProps = {
  value?: string;
  textAreaRows?: number;
  onChange: (value: string) => void;
  onCheckIsValid?: (value: string) => boolean;
};

const AccessibleTableTextArea = (props: AccessibleTableTextAreaProps) => {
  const { value = '', textAreaRows = 1, onChange = NOOP, onCheckIsValid = () => true } = props;

  const [inputValue, setInputValue] = useState(value);

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => setInputValue(e.currentTarget.value);
  const onSaveChanges = () => onChange(inputValue);

  const isDisabled = useMemo(() => value.trim() === inputValue.trim() || !onCheckIsValid(inputValue), [value, inputValue, onCheckIsValid]);

  return (
    <Input.Group className="accessible-table-text-area" compact>
      <Input.TextArea
        className={cs({ ['errored-input-text-area']: !onCheckIsValid(inputValue) })}
        rows={textAreaRows}
        value={inputValue}
        onChange={onChangeInput}
      />
      <Button type="primary" className="accessible-table-text-area-button" disabled={isDisabled} icon={<SaveOutlined />} onClick={onSaveChanges} />
    </Input.Group>
  );
};

export default AccessibleTableTextArea;
