import { ContentStatus } from './statuses-types';
import { UserDevicePlatform } from './common-types';
import { Group } from './group-types';

export enum PromotionTileType {
  custom = 'custom',
  program = 'program',
  trainer = 'trainer',
  workout = 'workout',
  popup = 'popup'
}

export type PromotionTile = {
  created_date: string | null;
  updated_date: string | null;
  id: string;
  status: ContentStatus;
  started_at: string | null;
  expired_at: string | null;
  priority: number;
  name: string;
  type: PromotionTileType;
  action: string;
  description: string;
  user_id: string | null;
  image: string;
  platform: UserDevicePlatform;
  target_groups: Group[];
};

export type CopyPromotionTiles = {
  ids: [];
  toPlatform: string;
  status: string;
};
