import React from 'react';
import { Row, Tag } from 'antd';
import './dropdown-selection-tag-filters.scss';

type DropdownSelectionTagFiltersProps<T> = {
  title: string;
  filters: string[];
  options: { value: string; text: string }[];
  onChangeFilter: (filter: T, checked: boolean) => void;
};

const DropdownSelectionTagFilters = <T extends unknown>(props: DropdownSelectionTagFiltersProps<T>) => {
  const { title, filters, options, onChangeFilter } = props;

  return (
    <div className="dropdown-selection-tag-filters">
      <Row align="middle" className="section-title">
        {title}
      </Row>
      {options.map((item) => {
        const isChecked = filters.includes(item.value);
        return (
          <Tag key={item.text} color={isChecked ? 'processing' : 'default'} onClick={() => onChangeFilter(item.value as T, !isChecked)}>
            {item.text}
          </Tag>
        );
      })}
    </div>
  );
};

export default DropdownSelectionTagFilters;
