import React from 'react';
import { List, Row, Button } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

import { WorkoutMovement } from '../../types/workout-types';
import WorkoutVideoEditorListItem from './workout-video-editor-list-item';
import './workout-video-editor-page.scss';

type WorkoutVideoEditorSidebarProps = {
  movements: WorkoutMovement[];
  selectedItemIndex: number;
  onSelectRow: (index: number) => void;
  onJumpToMovement: (index: number) => void;
  onSetAsStart: () => void;
  onSetAsEnd: () => void;
};

const WorkoutVideoEditorSidebar = (props: WorkoutVideoEditorSidebarProps) => {
  const { movements, selectedItemIndex, onSelectRow, onJumpToMovement, onSetAsStart, onSetAsEnd } = props;

  return (
    <div className="workout-video-editor-sidebar">
      <Row justify="space-evenly">
        <Button disabled={selectedItemIndex === 0} icon={<PlayCircleOutlined />} onClick={onSetAsStart}>
          Set as start time
        </Button>
        <Button icon={<PauseCircleOutlined />} onClick={onSetAsEnd}>
          Set as end time
        </Button>
      </Row>
      <List size="small" className="movement-list-wrap" itemLayout="vertical" bordered style={{ overflowY: 'scroll' }}>
        {movements.map((movement, index) => (
          <WorkoutVideoEditorListItem
            key={`${movement.id}/${movement.order}`}
            movement={movement}
            isSelected={selectedItemIndex === index}
            onSelectRow={() => onSelectRow(index)}
            onJumpToMovement={() => onJumpToMovement(index)}
          />
        ))}
      </List>
    </div>
  );
};

export default WorkoutVideoEditorSidebar;
