import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Form, Input, Row, Typography, Tooltip, Badge, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';
import useWorkoutAssetsTabs from './use-workout-assets-tabs';
import { Exercise } from '../../types/exercises-types';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { getUniqArray } from '../../utils/common-utils';
import { UpdateParams } from '../../types/request-entities-types';
import { FormValues, useFormValues } from '../../hooks/use-form-values';
import { CondOperator } from '../../types/cond-operators';
import { ContentStatus } from '../../types/statuses-types';
import { getErrorMessage } from '../../utils/errors';
import { getFormattedDate } from '../../utils/date-utils';
import { useItemSelector } from '../../hooks/use-selector';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { getClovenTimeValue } from '../../utils/time-utils';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { getDictionaryItemConfig } from '../../redux/settings/settings-utils';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { makeUseStructuredSelector } from '../../hooks/use-structured-selector';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { getCategoryFocusesOptionsList } from './workout-utils';
import { selectDictionary, selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { MovementBlock, Workout, WorkoutMovement } from '../../types/workout-types';
import { complexitiesList, displayDurationsList, isLiftWorkoutType, sessionTypesList } from '../../utils/resources-dictionary-utils';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { SessionType, CompletionType, WorkoutEquipment, sessionTypesColorsMap } from '../../types/common-types';
import {
  getPageResourceRecord,
  selectResourceRecordError,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading
} from '../../redux/resource-record/resource-record-selectors';

import StubPage from '../../components/stub-page/stub-page';
import ContentTabs from '../../components/content-tabs/content-tabs';
import SwitcherButton from '../../components/buttons/switcher-button';
import FormItemSelection from '../../components/form-components/form-item-selection';
import AccessibleTableTags from '../../components/accessible-table/accessible-table-tags';
import CheckboxSelectionList from '../../components/checkbox-selection-list/checkbox-selection-list';
import UnderlineDividedTitle from '../../components/underline-divided-title/underline-divided-title';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import FormItemRecordSelection from '../../components/form-components/form-item-record-selection';
import ResourceRecordStatusSelection from '../../components/resource-record-status-selection';
import ResourceRecordSchedulePublishDate from '../../components/resource-record-schedule-publish-date';
import FormItemCascaderSelection from '../../components/form-components/form-item-cascader-selection';
import '../../styles/common-styles.scss';
import './workouts.scss';
import SelectWorkoutContentPlans from '../../components/select-workout-content-plans';

const useWorkoutRecordPageSelectors = makeUseStructuredSelector({
  dictionary: selectDictionary,
  timeZone: selectUserTimeZone,
  isLoading: selectIsResourceRecordLoading,
  isError: selectIsResourceRecordError,
  resourceErrorMessage: selectResourceRecordError
});

const WorkoutRecordPage = () => {
  const record = useItemSelector({ resource: Resources.workouts }, getPageResourceRecord) as Workout | null;
  const { dictionary, timeZone, isLoading, isError, resourceErrorMessage } = useWorkoutRecordPageSelectors();

  const navigate = useNavigate();
  const onSetWorkoutRecord = useAction(setResourceRecord);

  const [form] = Form.useForm();
  const { secondSubRoute: recordId } = useLocationHandler();

  const { optionsList: liftAttachmentsList } = getDictionaryItemConfig(dictionary, 'lift_attachment');
  const { optionsList: workoutEquipmentList } = getDictionaryItemConfig(dictionary, 'equipment');
  const categoryFocusesMap = (dictionary['category_focuses_tree'] as Record<string, string[]>) || {};
  const categoryFocusesOptionsList = getCategoryFocusesOptionsList(categoryFocusesMap);

  useResourceRecordData({ recordId, resource: Resources.workouts });

  const initialData = useMemo(
    () => ({
      ...record,
      trainer_id: record?.trainer?.id ?? undefined,
      category_focus: [record?.category, record?.focus].filter((item) => !!item),
      published_date: record?.published_date ? moment(record.published_date) : undefined,
      created_date: record?.created_date ? moment(record?.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  const movements = (record?.blocks ?? []).reduce((acc: WorkoutMovement[], block: MovementBlock) => [...acc, ...(block?.movements ?? [])], []);
  const movementsExercises = movements.reduce((acc: Exercise[], item) => (item?.exercise ? [...acc, item?.exercise] : acc), []);
  const exercisesEquipments = getUniqArray(movementsExercises.map((item) => item.equipment).filter((item) => item) as string[]);
  const movementsAttachments = getUniqArray(movements.map((item) => item.attachment).filter((item) => item) as string[]);

  const workoutEquipments = workoutEquipmentList.map((item) => ({
    ...item,
    isHighlighted: exercisesEquipments.includes(item.value as WorkoutEquipment)
  }));
  const workoutAttachments = liftAttachmentsList.map((item) => ({ ...item, isHighlighted: movementsAttachments.includes(item.value) }));

  const { tabsConfig, isAssetsChanged, changedAssetsData, assetVideo } = useWorkoutAssetsTabs(record);
  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const isAssetVideoSelected = changedAssetsData?.intro_video_id === assetVideo?.id;
  const videoDuration = isAssetVideoSelected ? assetVideo?.meta_data?.duration ?? 0 : initialData?.intro_video?.meta_data?.duration ?? 0;
  const { hours: durationHours, minutes: durationMinutes, seconds: durationSeconds } = getClovenTimeValue(videoDuration);

  // we skip reps based, because data can be not accurate for planned sessions
  const movementsDuration = movements.filter((m) => m.completion_type !== CompletionType.reps).reduce((acc, item) => acc + item.duration, 0);
  const { hours: movementDurationHours, minutes: movementDurationMinutes, seconds: movementDurationSeconds } = getClovenTimeValue(movementsDuration);

  const typeTag = sessionTypesList.find((item) => item.value === record?.session_type);
  const typeTagLabel = typeTag?.text;
  const typeColor = sessionTypesColorsMap[record?.session_type as SessionType];
  const colorsMap = typeColor && typeTagLabel ? { [typeTagLabel]: typeColor } : {};

  const [isProcessing, setIsProcessing] = useState(false);
  const formValues = useMemo(() => {
    const { category_focus, ...restFields } = changedFormValues;
    const [category, focus] = category_focus || [];
    const fieldsValues = { ...restFields, ...changedAssetsData } as FormValues;
    if (category) fieldsValues.category = category;
    if (focus) fieldsValues.focus = focus;
    return fieldsValues;
  }, [changedFormValues, changedAssetsData]);

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinish = (isSaveAndContinue?: boolean) => {
    const params = {
      id: recordId,
      previousData: { id: recordId },
      data: { id: recordId, ...formValues }
    } as UpdateParams;

    setIsProcessing(true);
    api.common
      .update(Resources.workouts, params)
      .then((updatedWorkout: Workout) => {
        setIsProcessing(false);
        onSetWorkoutRecord(updatedWorkout);
        onClearChangedFormValues();
        onDisplaySuccessNotification('Workout record was successfully updated');
        if (!isSaveAndContinue) navigate(`/${Resources.workouts}`);
      })
      .catch((error: RequestError) => {
        setIsProcessing(false);
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer
      className="workout-record-wrapper resource-record-page-wrapper"
      resource={Resources.workouts}
      isLoading={isLoading}
      record={record}
    >
      <Typography.Title className="page-title" level={3}>
        Change Workout
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        className="record-form"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onValuesChange={onValuesChange}
      >
        <UnderlineDividedTitle title="Configuration" />
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string', whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="production_id" label="ISCI" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Type">
          <AccessibleTableTags tags={typeTagLabel ? [typeTagLabel] : []} colorsMap={colorsMap} />
        </Form.Item>
        <Form.Item name="category_focus" label="Category & Focus" rules={[{ required: true }]}>
          <FormItemCascaderSelection allowClear={false} options={categoryFocusesOptionsList} />
        </Form.Item>
        <Form.Item label="Trainer" name="trainer_id">
          <FormItemRecordSelection
            sortFieldKey="first_name"
            searchBy="first_name,last_name"
            placeholder="Select trainer"
            inputPlaceholder="Search trainer by first name or last name"
            libraryTitle="Trainers library"
            selectedItemsTitle="Selected trainers"
            resource={Resources.instructors}
            additionalFilters={{ [`status||${CondOperator.IN}`]: [ContentStatus.published, ContentStatus.unpublished] }}
          />
        </Form.Item>
        <Form.Item name="complexity" label="Level">
          <FormItemSelection options={complexitiesList} />
        </Form.Item>
        <Divider />
        <Form.Item
          name="equipment"
          label={
            <Tooltip placement="topLeft" title="Equipment highlighted in bold is equipment that is present in the movements exercises">
              <Badge count={<InfoCircleOutlined />} offset={[15, 0]}>
                Equipment
              </Badge>
            </Tooltip>
          }
        >
          <CheckboxSelectionList isMultiple options={workoutEquipments} />
        </Form.Item>
        <Form.Item
          name="lift_attachments"
          hidden={!isLiftWorkoutType(record?.session_type)}
          label={
            <Tooltip placement="topLeft" title="Attachments highlighted in bold is attachments that is present in the movements">
              <Badge count={<InfoCircleOutlined />} offset={[15, 0]}>
                Attachments
              </Badge>
            </Tooltip>
          }
        >
          <CheckboxSelectionList isMultiple options={workoutAttachments} />
        </Form.Item>
        <UnderlineDividedTitle title="Durations" />
        <Form.Item label="Video duration">
          {record?.intro_video ? `${durationHours} hours, ${durationMinutes} minutes and ${durationSeconds} seconds` : 'Video not selected'}
        </Form.Item>
        <Form.Item label="Movements duration">
          {movements.length
            ? `${movementDurationHours} hours, ${movementDurationMinutes} minutes and ${movementDurationSeconds} seconds`
            : 'Movements list is empty'}
        </Form.Item>
        <Form.Item name="display_duration" label="Display duration" rules={[{ required: true }]}>
          <FormItemSelection options={displayDurationsList} />
        </Form.Item>
        <UnderlineDividedTitle title="Where Else to Find" />
        <Form.Item name="is_in_program" label="Is in program" valuePropName="checked" rules={[{ required: true }]}>
          <SwitcherButton />
        </Form.Item>
        <Form.Item name="is_recommended" label="Is featured" valuePropName="checked" rules={[{ required: true }]}>
          <SwitcherButton />
        </Form.Item>
        <UnderlineDividedTitle title="History" />
        <Form.Item label="Created">{getFormattedDate(record.created_date, timeZone)}</Form.Item>
        <Form.Item label="Last Updates">{getFormattedDate(record.updated_date, timeZone)}</Form.Item>
        <Form.Item label="Published Date">{getFormattedDate(record.published_date, timeZone)}</Form.Item>
        <UnderlineDividedTitle title="Visual assets" />
        <Form.Item>
          <ContentTabs tabsConfig={tabsConfig} />
        </Form.Item>
        <UnderlineDividedTitle title="Configuration changes" />

        <Form.Item shouldUpdate colon={false} label=" ">
          {(params) => {
            const isFormTouched = isFormValuesChanged || isAssetsChanged;
            const hasErrors = !!params.getFieldsError().filter(({ errors }) => errors.length).length;
            const isSubmitDisabled = !isFormTouched || hasErrors;
            return (
              <Row align="middle" justify="end">
                <Space>
                  <Button htmlType="button" disabled={!isFormTouched || isProcessing} onClick={onReset}>
                    Discard changes
                  </Button>
                  <Button type="primary" disabled={isSubmitDisabled || isProcessing} onClick={() => onFinish()}>
                    Save
                  </Button>
                  <Button type="primary" disabled={isSubmitDisabled || isProcessing} onClick={() => onFinish(true)}>
                    Save and continue editing
                  </Button>
                </Space>
              </Row>
            );
          }}
        </Form.Item>

        <UnderlineDividedTitle title="Movements" />
        <Form.Item label="Navigate to movements page">
          <Row align="middle" justify="end">
            <Button htmlType="button" type="primary" onClick={() => window.open(`/workouts/${record.id}/movements`, '_blank')}>
              Go to programming
            </Button>
          </Row>
        </Form.Item>
        <UnderlineDividedTitle title="Immediate actions" />
        <Form.Item label="Content plans">
          <SelectWorkoutContentPlans resource={Resources.workouts} />
        </Form.Item>
        <Form.Item label="Status">
          <ResourceRecordStatusSelection resource={Resources.workouts} className="workout-record-status-selection" />
        </Form.Item>
        <Form.Item label="Scheduled Publish date">
          <ResourceRecordSchedulePublishDate resource={Resources.workouts} />
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default WorkoutRecordPage;
