import React, { useEffect, useState } from 'react';
import { DeleteOutlined, DownloadOutlined, EditOutlined, UploadOutlined, ExclamationCircleOutlined, CopyOutlined } from '@ant-design/icons';

import { CompletionType } from '../../../types/common-types';
import { WorkoutMovement } from '../../../types/workout-types';
import { useContainerSize } from '../../../hooks/use-container-size';
import { getConfirmationModal } from '../../../utils/modals-utils';
import '../workout-movemnts.scss';

type WorkoutMovementTableContextMenuProps = {
  isVisible: boolean;
  position: { x: number; y: number };
  rowData: WorkoutMovement | null;
  onOpenInsertMovementDrawer: (blockId: string, order?: number) => void;
  onOpenInsertTransitionDrawer: (order: number, blockId: string) => void;
  onDeleteMovement: (blockId: string, movementId: string) => void;
  onOpenEditMovementDrawer: (movement: WorkoutMovement) => void;
  onOpenEditTransitionDrawer: (movement: WorkoutMovement) => void;
  onOpenCreateNewMovement: (editMovement?: WorkoutMovement) => void;
  onOpenCreateNewTransition: (editMovement?: WorkoutMovement) => void;
};

const WorkoutMovementTableContextMenu = (props: WorkoutMovementTableContextMenuProps) => {
  const {
    rowData,
    isVisible,
    position,
    onOpenInsertMovementDrawer,
    onOpenInsertTransitionDrawer,
    onOpenEditMovementDrawer,
    onOpenEditTransitionDrawer,
    onDeleteMovement,
    onOpenCreateNewMovement,
    onOpenCreateNewTransition
  } = props;

  const movementOrder = rowData?.order ?? 0;
  const movementBlockId = `${rowData?.block_id ?? ''}`;

  const [{ width, height }, setRef] = useContainerSize();

  const [leftPosition, setLeftPosition] = useState<number>(position.x);
  const [topPosition, setTopPosition] = useState<number>(position.y);

  useEffect(() => {
    const isWithinLeftPosition = position.x < window.innerWidth - width;
    const isWithinTopPosition = position.y < window.innerHeight - height;

    setLeftPosition(isWithinLeftPosition ? position.x : position.x - width);
    setTopPosition(isWithinTopPosition ? position.y : position.y - height);
  }, [width, height, position]);

  const onOpenInsertAboveMovementDrawer = () => {
    if (!rowData) return;
    onOpenInsertMovementDrawer(movementBlockId, movementOrder - 2);
  };

  const onOpenInsertBelowMovementDrawer = () => {
    if (!rowData) return;
    onOpenInsertMovementDrawer(movementBlockId, movementOrder + 1);
  };

  const onOpenInsertAboveTransitionDrawer = () => {
    if (!rowData) return;
    onOpenInsertTransitionDrawer(movementOrder - 2, movementBlockId);
  };

  const onOpenInsertBelowTransitionDrawer = () => {
    if (!rowData) return;
    onOpenInsertTransitionDrawer(movementOrder + 1, movementBlockId);
  };

  const onOpenEditRowDrawer = () => {
    if (!rowData) return;
    const isTransition = rowData.completion_type === CompletionType.transition;
    return isTransition ? onOpenEditTransitionDrawer(rowData) : onOpenEditMovementDrawer(rowData);
  };

  const onOpenCloneRowDrawer = () => {
    if (!rowData) return;
    const isTransition = rowData.completion_type === CompletionType.transition;
    return isTransition ? onOpenCreateNewTransition(rowData) : onOpenCreateNewMovement(rowData);
  };

  const onConfirmDelete = () =>
    getConfirmationModal({
      width: 600,
      isDanger: true,
      okText: 'Delete',
      title: `Delete "${rowData?.exercise?.name}"`,
      description: 'Are you sure you want to delete this movement',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onConfirm: () => onDeleteMovement(movementBlockId, `${rowData?.id}`)
    });

  if (!isVisible || !rowData || (!leftPosition && !topPosition)) return null;

  return (
    <ul ref={setRef} className="workout-movement-table-context-menu" style={{ left: `${leftPosition}px`, top: `${topPosition}px` }}>
      <li onClick={onOpenEditRowDrawer}>
        <EditOutlined />
        <span className="label">Edit</span>
      </li>
      <li onClick={onOpenCloneRowDrawer}>
        <CopyOutlined />
        <span className="label">Clone</span>
      </li>
      <li onClick={onOpenInsertAboveMovementDrawer}>
        <UploadOutlined />
        <span className="label">Insert movement above</span>
      </li>
      <li onClick={onOpenInsertBelowMovementDrawer}>
        <DownloadOutlined />
        <span className="label">Insert movement below</span>
      </li>
      <li onClick={onOpenInsertAboveTransitionDrawer}>
        <UploadOutlined />
        <span className="label">Insert transition above</span>
      </li>
      <li onClick={onOpenInsertBelowTransitionDrawer}>
        <DownloadOutlined />
        <span className="label">Insert transition below</span>
      </li>
      <li onClick={onConfirmDelete}>
        <DeleteOutlined className="danger" />
        <span className="label danger">Remove</span>
      </li>
    </ul>
  );
};

export default WorkoutMovementTableContextMenu;
