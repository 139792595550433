import React, { useMemo } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Select } from 'antd';
import 'antd/dist/antd.css';

type TimeZonePickerProps = {
  value?: string;
  onChange: (nextTimeZone: string) => void;
};

const defaultTimeZone = momentTimezone.tz.guess();

const TimeZonePicker = (props: TimeZonePickerProps) => {
  const { value = defaultTimeZone, onChange } = props;

  const renderOptions = useMemo(() => {
    const timezones = moment.tz.names();
    const mappedValues = {} as Record<string, string[]>;
    const regions = [] as string[];

    timezones.map((timezone) => {
      const splitTimezone = timezone.split('/');
      const region = splitTimezone[0];
      if (!mappedValues[region]) {
        mappedValues[region] = [];
        regions.push(region);
      }
      mappedValues[region].push(timezone);
    });

    return regions.map((region) => (
      <Select.OptGroup key={region} title={<div style={{ fontSize: 30 }}>{region}</div>}>
        {mappedValues[region].map((timezone) => (
          <Select.Option key={timezone} value={timezone}>
            {timezone}
          </Select.Option>
        ))}
      </Select.OptGroup>
    ));
  }, []);

  return (
    <Select
      style={{ width: 400 }}
      defaultValue={momentTimezone.tz.guess()}
      value={value}
      showSearch
      optionFilterProp="children"
      placeholder="Search to Select"
      filterOption={(input, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())}
      onChange={onChange}
    >
      {renderOptions}
    </Select>
  );
};

export default TimeZonePicker;
