import { VidePlayers } from '../types/video-player-types';

export const videoPlayersConfigMap = {
  [VidePlayers.iina]: {
    value: VidePlayers.iina,
    text: 'IINA (recommended)',
    btnLabel: 'IINA',
    scheme: 'iina://weblink?url='
  },
  [VidePlayers.vlc]: {
    value: VidePlayers.vlc,
    text: 'VLC (local url schema settings required)',
    btnLabel: 'VLC',
    scheme: 'vlc://'
  }
};

export const videoPlayersOptionsList = Object.values(videoPlayersConfigMap).map((item) => item);
