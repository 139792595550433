import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import settingsSaga from '../redux/settings/settings-saga';
import settingsReducer from '../redux/settings/settings-reducer';
import resourceListReducer from '../redux/resource-list/resource-list-reducer';
import resourceRecordReducer from '../redux/resource-record/resource-record-reducer';
import permissionsReducer from '../redux/permissions/permissions-reducer';

const sagaMiddleware = createSagaMiddleware();
const middleware = [thunkMiddleware, sagaMiddleware];

const reducer = {
  settings: settingsReducer,
  resourceList: resourceListReducer,
  resourceRecord: resourceRecordReducer,
  permissions: permissionsReducer
};

export const store = configureStore({ reducer, middleware });
sagaMiddleware.run(settingsSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
