import { ImageAssetElement, VideoAssetElement } from './studio-assets';

export enum WidgetType {
  warning = 'warning',
  alert = 'alert',
  fault = 'fault'
}

export type StudioWidget = {
  id: string;
  type: WidgetType;
  title?: string;
  message: string;
  action?: string | null;
  video: VideoAssetElement | null;
  image: ImageAssetElement | null;
  created_date: Date | string | null;
  updated_date: Date | string | null;
};
