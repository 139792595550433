import React from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';

import { NOOP } from '../../constants/common-constants';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { getConfirmationModal } from '../../utils/modals-utils';
import { MomentDateFormat } from '../../utils/date-utils';

type DatePickerWithTimeZoneProps = {
  format?: string;
  value?: string | null;
  showTime?: boolean;
  withConfirmModal?: boolean;
  placeholder?: string;
  className?: string;
  confirmTitle?: string;
  disabledDate?: (date: Moment) => boolean;
  onChange?: (value: moment.Moment | null) => void;
};

const disabledDateNOOP = () => false;

const DatePickerWithTimeZone = (props: DatePickerWithTimeZoneProps) => {
  const {
    format = MomentDateFormat.usDate,
    value,
    confirmTitle,
    withConfirmModal = false,
    placeholder,
    className,
    showTime,
    disabledDate = disabledDateNOOP,
    onChange = NOOP
  } = props;

  const timeZone = useSelector(selectUserTimeZone);
  const displayDate = value ? moment(value).tz(timeZone) : null;

  const onChangeDate = (nextDate: moment.Moment | null) => {
    onChange(nextDate ? moment.utc(nextDate) : nextDate);
  };

  const onChangeDatePicker = (nextDate: moment.Moment | null) => {
    if (!withConfirmModal) return onChangeDate(nextDate);
    return getConfirmationModal({
      width: 450,
      title: confirmTitle,
      onConfirm: () => onChangeDate(nextDate)
    });
  };

  return (
    <DatePicker
      format={format}
      disabledDate={disabledDate}
      className={className}
      value={displayDate}
      placeholder={placeholder}
      showTime={showTime}
      onChange={onChangeDatePicker}
    />
  );
};

export default DatePickerWithTimeZone;
