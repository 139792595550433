import React, { useEffect, useMemo } from 'react';

import { NOOP } from '../../../constants/common-constants';
import { Exercise } from '../../../types/exercises-types';
import { LimbUsedEnum, SideUsageType } from '../../../types/common-types';
import { bilateralLimbUsedEnumList, limbUsedEnumList, unilateralLimbUsedEnumList } from '../../../utils/resources-dictionary-utils';
import FormItemSelection from '../../../components/form-components/form-item-selection';

type LimbUsedFormItemSelectionProps = {
  value?: string;
  exercise?: Exercise;
  onChange?: (value: string | null) => void;
};

const LimbUsedFormItemSelection = (props: LimbUsedFormItemSelectionProps) => {
  const { value, exercise, onChange = NOOP } = props;

  const limbsOptionsList = useMemo(() => {
    if (exercise?.side_usage_type === SideUsageType.bilateral) return bilateralLimbUsedEnumList;
    if (exercise?.side_usage_type === SideUsageType.unilateral) return unilateralLimbUsedEnumList;
    return limbUsedEnumList;
  }, [exercise]);

  const isValidValue = useMemo(() => !!limbsOptionsList.find((item) => item.value === value), [value, limbsOptionsList]);
  const defaultLimbOptionItem = useMemo(() => limbsOptionsList.find((item) => item.value !== LimbUsedEnum.none), [limbsOptionsList]);

  useEffect(() => {
    if (!isValidValue && defaultLimbOptionItem) {
      onChange(defaultLimbOptionItem.value);
    }
  }, [exercise, value, isValidValue, defaultLimbOptionItem, onChange]);

  return <FormItemSelection value={value} options={limbsOptionsList} onChange={onChange} />;
};

export default LimbUsedFormItemSelection;
