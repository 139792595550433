import jwtDecode from 'jwt-decode';
import { IdToken } from '@auth0/auth0-react';
import { Permission } from '../types/resources-types';
import { USER_PERMISSIONS_KEY } from '../config';
import { getAccessTokenFromLS, getExpiresAtFromLS, getTokenFromLS, setAccessTokenToLS, setExpiresAtToLS, setTokenToLS } from './local-storage-utils';

/**
 *
 */
export const isTokenValid = (token: string | null, expiredDate: string | null) => {
  const date = +new Date();
  const isExpired = Number(expiredDate) <= date;

  return token && expiredDate && !isExpired;
};

/**
 *
 */
export const getIsTokenShouldBeUpdated = (tokenClaims: IdToken) => {
  const token = getTokenFromLS();
  const expiredDate = getExpiresAtFromLS();
  const isValid = isTokenValid(token, expiredDate);
  const newIdToken = tokenClaims?.__raw;
  const isTokenChanged = token !== newIdToken;

  return !token || !isValid || isTokenChanged;
};

/**
 *
 */
export const setAuthData = (tokenClaims: IdToken) => {
  const { __raw: idToken, aud: accessToken, exp: expDate } = tokenClaims;
  const shouldUpdateToken = getIsTokenShouldBeUpdated(tokenClaims);

  if (!idToken) return;
  if (shouldUpdateToken) setTokenToLS(idToken);
  if (accessToken && !getAccessTokenFromLS()) setAccessTokenToLS(accessToken);
  if (expDate && !getExpiresAtFromLS()) setExpiresAtToLS(JSON.stringify(expDate * 1000 + new Date().getTime()));
};

/**
 *
 */
export const getPermissionsFromToken = () => {
  const idToken = getTokenFromLS();
  if (!idToken) return 'Missed auth token';

  const decoded = jwtDecode<{ [USER_PERMISSIONS_KEY]: Permission[] }>(idToken);
  return decoded[USER_PERMISSIONS_KEY];
};
