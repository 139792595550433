import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../boot/store';
import { Resources } from '../../types/resources-types';
import { ResourceRecord } from '../../types/resource-record-types';
import { ResourceRecordState } from './resource-record-reducer';

export const selectResourceRecordState = (state: RootState): ResourceRecordState => state.resourceRecord;

export const selectIsResourceRecordLoading = (state: RootState): boolean => selectResourceRecordState(state).isLoading;
export const selectIsResourceRecordError = (state: RootState): boolean => selectResourceRecordState(state).isError;
export const selectResourceRecordError = (state: RootState): string => selectResourceRecordState(state).errorMessage;
export const selectResourceRecord = (state: RootState): ResourceRecord | null => selectResourceRecordState(state).record;
export const selectResourceRecordType = (state: RootState): Resources | null => selectResourceRecordState(state).resource;

export const getPageResourceRecord = createSelector(
  selectResourceRecord,
  selectResourceRecordType,
  (state: RootState, { resource }: { resource: Resources }) => resource,
  (record, resource, pageResource) => (resource === pageResource ? record : null)
);
