import React from 'react';
import { Link } from 'react-router-dom';
import HighlightedText from '../highlighted-text';

type AccessibleTableLinkedCellProps = {
  value: string;
  link: string;
  highlight?: string;
};

const AccessibleTableLinkedCell = (props: AccessibleTableLinkedCellProps) => {
  const { value, link, highlight = '' } = props;

  return (
    <Link to={link}>
      <HighlightedText text={value} highlight={highlight} />
    </Link>
  );
};

export default AccessibleTableLinkedCell;
