import React, { useEffect } from 'react';
import { CMS_APP_VERSION } from '../config';
import api from '../api';
import { NotificationTypes, openNotification } from '../utils/notifications-utils';
import { Button } from 'antd';

export const useVersionHandler = (delay: number) => {
  useEffect(() => {
    const interval = setInterval(() => {
      api.settings
        .getManifest()
        .then(({ version }) => {
          if (!version || version === CMS_APP_VERSION) return;
          openNotification({
            type: NotificationTypes.warning,
            message: 'Page refresh is required',
            description: 'The new version was released',
            duration: delay / 1_000 - 1,
            btn: (
              <Button type="link" size="large" onClick={() => location.reload()}>
                Refresh
              </Button>
            )
          });
        })
        .catch((error) => console.error('error', error));
    }, delay);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
