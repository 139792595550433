import React from 'react';
import { Drawer } from 'antd';
import { SurveyStep } from 'src/types/survey-types';
import { SurveyStepForm } from './survey-step-form';

type SurveyStepDrawerProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: (surveyStep: SurveyStep) => void;
  record: SurveyStep | null;
};

export const SurveyStepDrawer = (props: SurveyStepDrawerProps) => {
  const { isOpened, onClose, onConfirm, record } = props;

  return (
    <Drawer
      destroyOnClose
      width={800}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      title={record ? 'Update survey step record' : 'Create survey step record'}
      onClose={onClose}
    >
      <SurveyStepForm record={record} onClose={onClose} onConfirm={onConfirm} />
    </Drawer>
  );
};
