import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResourceRecord } from '../../types/resource-record-types';
import { Resources } from '../../types/resources-types';

export interface ResourceRecordState {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  record: ResourceRecord | null;
  resource: Resources | null;
}

const initialState: ResourceRecordState = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  record: null,
  resource: null
};

export const resourceRecordSlice = createSlice({
  name: 'resourceRecord',
  initialState,
  reducers: {
    startFetchingResourceRecord: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    setResourceRecord: (state, action: PayloadAction<ResourceRecord>) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = '';
      state.record = action.payload;
    },
    setRecordResourceType: (state, action: PayloadAction<Resources | null>) => {
      state.resource = action.payload;
    },
    setResourceRecordError: (state, action: PayloadAction<string>) => {
      state.isError = false;
      state.errorMessage = action.payload;
      state.isLoading = false;
      state.record = null;
      state.resource = null;
    },
    clearResourceRecordData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = '';
      state.record = null;
      state.resource = null;
    }
  }
});

export const { startFetchingResourceRecord, setResourceRecord, setRecordResourceType, setResourceRecordError, clearResourceRecordData } =
  resourceRecordSlice.actions;

export default resourceRecordSlice.reducer;
