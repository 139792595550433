import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Form, Input, Typography } from 'antd';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { DeleteParams, UpdateParams } from '../../types/request-entities-types';
import { RequestError } from '../../api/http-client';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { jsonValidator } from '../../validators/json-validator';
import { stringValidator } from '../../validators/string-validator';
import { useItemSelector } from '../../hooks/use-selector';
import { tryParseJsonObject } from '../../utils/common-utils';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { WorkoutFilter, WorkoutFilterCategory } from '../../types/workout-filters-types';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';

import StubPage from '../../components/stub-page/stub-page';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import WorkoutFilterConditionFormField from './workout-filter-condition-form-field';
import ConfirmButton from '../../components/buttons/confirm-button';

const WorkoutFilterRecordPage = () => {
  const record = useItemSelector({ resource: Resources.workoutFilters }, getPageResourceRecord) as WorkoutFilter | null;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const { secondSubRoute: recordId } = useLocationHandler();
  const onSetRecord = useAction(setResourceRecord);

  useResourceRecordData({ recordId, resource: Resources.workoutFilters });

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const formInitialData = {
    name: record?.name ?? '',
    category: record?.category ?? WorkoutFilterCategory.STUDIO,
    description: record?.description ?? '',
    short_description: record?.short_description ?? '',
    condition: JSON.stringify(record?.condition ?? {})
  };

  const { changedFormValues, onValuesChange } = useFormValues({ initialData: formInitialData });
  const onFinish = () => {
    const params = {
      id: recordId,
      previousData: { id: recordId },
      data: { id: recordId, ...changedFormValues }
    } as UpdateParams;

    if (changedFormValues.hasOwnProperty('condition')) {
      params.data.condition = tryParseJsonObject(params.data.condition);
    }

    api.common
      .update(Resources.workoutFilters, params)
      .then((nextRecordData: WorkoutFilter) => {
        onSetRecord(nextRecordData);
        onDisplaySuccessNotification('Workout filter record was successfully edited!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onDelete = () => {
    const params = { id: recordId, previousData: { id: recordId } } as DeleteParams;

    api.common
      .delete(Resources.workoutFilters, params)
      .then(() => {
        onDisplaySuccessNotification('Workout filter record was successfully deleted');
        navigate(`/${Resources.workoutFilters}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.workoutFilters} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Workout filter
      </Typography.Title>
      <Divider />
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ ...record, condition: JSON.stringify(record.condition) }}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="category" label="Category">
          <Input readOnly bordered={false} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="short_description" label="Short description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item name="condition" label="Condition" rules={[{ required: true, type: 'string' }, { validator: jsonValidator }]}>
          <WorkoutFilterConditionFormField />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <ConfirmButton
            isDanger
            buttonLabel="Delete"
            okText="Delete"
            title="Delete workout filter record"
            description="Are you sure you want to delete workout filter record?"
            type="primary"
            onConfirm={onDelete}
          />
          <Button type="primary" htmlType="submit" disabled={!Object.keys(changedFormValues).length} style={{ margin: '0 8px' }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default WorkoutFilterRecordPage;
