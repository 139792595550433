import React from 'react';

import { EntitiesSubUrls } from 'src/api/resources-entities-api';
import SelectResourceChildCollectionBase, { SelectResourceRecordProps } from 'src/components/select-resource-child-collection-base';
import { Group } from 'src/types/group-types';
import { PromotionTile } from 'src/types/promotion-tile-types';
import { Resources } from 'src/types/resources-types';

export type SelectResourceRecordPromotionTargetProps = Pick<SelectResourceRecordProps<PromotionTile, Group>, 'isEditable' | 'confirmDescription'>;

const SelectResourceRecordTargetGroups = (props: SelectResourceRecordPromotionTargetProps) => {
  return (
    <SelectResourceChildCollectionBase<PromotionTile, Group>
      getIdList={(promo) => promo?.target_groups.map((g) => g.id) ?? []}
      getChildQueryFilter={() => ({
        'type||$eq': 'sql'
      })}
      collectionResource={Resources.groups}
      addElement={(request, record, element) =>
        request.resourcesEntities
          .createResourceEntity({
            resource: Resources.promotionTiles,
            recordId: record.id,
            entitySubUrl: EntitiesSubUrls.promotionTargetGroups,
            data: { group_id: element.id }
          })
          .then(() => {
            const updatedGroups = [...record.target_groups, element];
            return { ...record, target_groups: updatedGroups };
          })
      }
      removeElement={(request, record, element) =>
        request.resourcesEntities
          .removeResourceEntity({
            resource: Resources.promotionTiles,
            recordId: record.id,
            entitySubUrl: `${EntitiesSubUrls.promotionTargetGroups}/${element.id}`
          })
          .then(() => {
            const updatedGroups = record.target_groups.filter((item) => item.id !== element.id);
            return { ...record, target_groups: updatedGroups };
          })
      }
      {...props}
    />
  );
};

export default SelectResourceRecordTargetGroups;
