import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal } from 'antd';

import api from '../../api';
import { Exercise } from '../../types/exercises-types';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { ExerciseType } from '../../types/common-types';
import { getErrorMessage } from '../../utils/errors';
import { selectDictionary } from '../../redux/settings/settings-selectors';
import { VideoAssetElement } from '../../types/studio-assets';
import { getDictionaryItemConfig } from '../../redux/settings/settings-utils';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { stringValidator } from '../../validators/string-validator';
import {
  armsHeightsList,
  exerciseComplexityList,
  exerciseTypeList,
  movementPatternsList,
  muscleGroupsList,
  planeOfMotionList,
  positionsList,
  primeMoverList,
  sideUsageTypeList,
  workoutEquipmentList
} from '../../utils/resources-dictionary-utils';

import FormItemVideo from '../../components/form-components/form-item-video';
import FormItemSelection from '../../components/form-components/form-item-selection';

type CreateExerciseDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

const CreateExerciseDialog = (props: CreateExerciseDialogProps) => {
  const { isOpened, onClose } = props;

  const dictionary = useSelector(selectDictionary);
  const { optionsList: planExcerciseAttachmentsList } = getDictionaryItemConfig(dictionary, 'lift_attachment', false);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [studioAssetVideo, setStudioAssetVideo] = useState<VideoAssetElement | undefined | null>();
  const [isLift, setIsLift] = useState(false);

  const getFormValues = (formFieldsValues: Record<string, unknown>) => {
    const formValues = { ...formFieldsValues };

    ['muscle_group_1', 'muscle_group_2', 'muscle_group_3', 'secondary_plane_of_motion', 'attachment', 'height_scheme'].forEach((key) => {
      if (formFieldsValues.hasOwnProperty(key) && (!formFieldsValues[key] || !isLift)) {
        formValues[key] = null;
      }
    });

    if (formValues.equipment === 'N/A') formValues.equipment = null;
    if (!isLift) delete formValues.number_arms_used;
    if (isLift) formValues.number_arms_used = formValues.number_arms_used || 1;
    if (studioAssetVideo) formValues.intro_video_id = studioAssetVideo.id;
    return formValues;
  };

  const onReset = () => {
    form.resetFields();
    setStudioAssetVideo(null);
  };

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onTypeChange = (value: string | null) => {
    setIsLift(value === ExerciseType.LIFT);
  };

  const onFinish = () => {
    const exerciseRecord = getFormValues(form.getFieldsValue(true)) as Exercise;
    const params = { data: exerciseRecord } as CreateParams;

    api.common
      .create(Resources.exercises, params)
      .then(({ data: { id: newExerciseId } }) => {
        onDisplaySuccessNotification('Exercise record was successfully created');
        navigate(`/${Resources.exercises}/${newExerciseId}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      maskClosable={false}
      visible={isOpened}
      wrapClassName="modal-wrap"
      title="Create exercise record"
      onCancel={onCloseModal}
    >
      <Form form={form} layout="horizontal" validateMessages={commonFormValidateMessages} onFinish={onFinish}>
        <Form.Item name="type" label="Exercise type" rules={[{ required: true }]}>
          <FormItemSelection options={exerciseTypeList} onChange={onTypeChange} />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="complexity" label="Complexity" rules={[{ required: true }]}>
          <FormItemSelection options={exerciseComplexityList} />
        </Form.Item>
        <Form.Item name="number_arms_used" label="Number cables used" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection
            options={[
              { value: 1, text: 1 },
              { value: 2, text: 2 }
            ]}
          />
        </Form.Item>
        <Form.Item name="side_usage_type" label="Side Usage Type" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection options={sideUsageTypeList} />
        </Form.Item>
        <Form.Item name="position" label="Position" rules={[{ required: true }]}>
          <FormItemSelection options={positionsList} />
        </Form.Item>
        <Form.Item name="movement_pattern" label="Movement pattern" rules={[{ required: true }]}>
          <FormItemSelection options={movementPatternsList} />
        </Form.Item>
        <Form.Item name="secondary_movement_pattern" label="Secondary movement pattern">
          <FormItemSelection options={movementPatternsList} />
        </Form.Item>
        <Form.Item name="prime_mover_1" label="Prime mover 1">
          <FormItemSelection options={primeMoverList} />
        </Form.Item>
        <Form.Item name="prime_mover_2" label="Prime mover 2">
          <FormItemSelection options={primeMoverList} />
        </Form.Item>
        <Form.Item name="prime_mover_3" label="Prime mover 3">
          <FormItemSelection options={primeMoverList} />
        </Form.Item>
        <Form.Item name="height_scheme" label="Height scheme" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection options={[...armsHeightsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="muscle_group_1" label="Muscle group 1" hidden={!isLift}>
          <FormItemSelection options={[...muscleGroupsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="muscle_group_2" label="Muscle group 2" hidden={!isLift}>
          <FormItemSelection options={[...muscleGroupsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="muscle_group_3" label="Muscle group 3" hidden={!isLift}>
          <FormItemSelection options={[...muscleGroupsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="plane_of_motion" label="Plane of motion" rules={[{ required: true }]}>
          <FormItemSelection options={planeOfMotionList} />
        </Form.Item>
        <Form.Item name="secondary_plane_of_motion" label="Secondary plane of motion">
          <FormItemSelection options={[...planeOfMotionList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="attachment" label="Attachment" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection options={[...planExcerciseAttachmentsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="alternative_attachments" label="Alternative Attachments" hidden={!isLift}>
          <FormItemSelection options={planExcerciseAttachmentsList} isMultiple />
        </Form.Item>
        <Form.Item name="equipment" label="Equipment" rules={[{ required: !isLift }]} hidden={isLift}>
          <FormItemSelection options={[...workoutEquipmentList, { text: 'N/A', value: 'N/A' }]} />
        </Form.Item>
        <Form.Item label="Video name">{studioAssetVideo?.name}</Form.Item>
        <Form.Item label="Video">
          <FormItemVideo withRemove value={studioAssetVideo as UseVideoSourceSelectionParams} onChangeVideoData={setStudioAssetVideo} />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateExerciseDialog;
