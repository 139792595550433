import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import httpClient, { RequestError, RequestMethods } from 'src/api/http-client';
import { EntitiesSubUrls } from 'src/api/resources-entities-api';
import { useAction } from 'src/hooks/use-actions';
import { setResourceRecord } from 'src/redux/resource-record/resource-record-reducer';
import { Resources } from 'src/types/resources-types';
import { Survey, SurveyStep } from 'src/types/survey-types';
import { getErrorMessage } from 'src/utils/errors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from 'src/utils/notifications-utils';
import { CMS_API_URL } from '../../../config';

type UseSurveyStepsProps = {
  record: Survey | null;
  onStepUpdated: () => void;
};

export const useSurveySteps = ({ record, onStepUpdated }: UseSurveyStepsProps) => {
  const recordId = record?.id;
  const navigate = useNavigate();

  const onSetResourceRecord = useAction(setResourceRecord);

  const onCreateStep = (step: SurveyStep) => {
    if (!recordId) {
      return;
    }

    const requestParams = {
      recordId: recordId,
      resource: Resources.surveys,
      entitySubUrl: `${EntitiesSubUrls.steps}`,
      method: RequestMethods.post,
      data: { ...step, keep_draft: true }
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Survey) => {
        onSetResourceRecord(updatedRecord);
        onStepUpdated();
        onDisplaySuccessNotification('Step was successfully created!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onUpdateStep = (step: SurveyStep) => {
    if (!recordId || !step.id) {
      return;
    }

    const requestParams = {
      recordId: recordId,
      resource: Resources.surveys,
      entitySubUrl: `${EntitiesSubUrls.steps}/${step.id}`,
      method: RequestMethods.patch,
      data: { ...step, keep_draft: true }
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Survey) => {
        onSetResourceRecord(updatedRecord);
        onStepUpdated();
        onDisplaySuccessNotification('Steps was successfully updated!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onDeleteStep = (id: string) => {
    if (!recordId) {
      return;
    }

    const requestParams = {
      recordId: recordId,
      resource: Resources.surveys,
      entitySubUrl: `${EntitiesSubUrls.steps}/${id}?keep_draft=true`,
      method: RequestMethods.delete
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Survey) => {
        onSetResourceRecord(updatedRecord);
        onDisplaySuccessNotification('Step was successfully deleted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onReorderSteps = (orderedSteps: SurveyStep[]) => {
    if (!recordId) {
      return;
    }

    const ids = orderedSteps.map(({ id }) => id);

    const requestParams = {
      recordId: recordId,
      resource: Resources.surveys,
      entitySubUrl: `${EntitiesSubUrls.steps}/${EntitiesSubUrls.order}`,
      method: RequestMethods.put,
      data: { ids }
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Survey) => {
        onSetResourceRecord(updatedRecord);
        onDisplaySuccessNotification('Steps was successfully reordered!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onPublish = () => {
    if (!recordId) {
      return;
    }

    httpClient(`${CMS_API_URL}/${Resources.surveys}/${recordId}/publish`, { method: RequestMethods.post })
      .then(() => {
        onDisplaySuccessNotification('Survey was successfully published!');
        navigate(`/${Resources.surveys}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return { onReorderSteps, onCreateStep, onDeleteStep, onUpdateStep, onPublish };
};
