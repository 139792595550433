import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Button, Input, Divider, Row } from 'antd';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { FormValues } from '../../hooks/use-form-values';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { getErrorMessage } from '../../utils/errors';
import { stringValidator } from '../../validators/string-validator';
import { selectDictionary } from '../../redux/settings/settings-selectors';
import { getDictionaryItemConfig } from '../../redux/settings/settings-utils';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { getCategoryFocusesOptionsList } from './workout-utils';
import { SessionType } from '../../types/common-types';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { complexitiesList, displayDurationsList, sessionTypesList } from '../../utils/resources-dictionary-utils';

import ContentTabs from '../../components/content-tabs/content-tabs';
import SwitcherButton from '../../components/buttons/switcher-button';
import FormItemSelection from '../../components/form-components/form-item-selection';
import useWorkoutAssetsTabs from './use-workout-assets-tabs';
import UnderlineDividedTitle from '../../components/underline-divided-title/underline-divided-title';
import CheckboxSelectionList from '../../components/checkbox-selection-list/checkbox-selection-list';
import FormItemRecordSelection from '../../components/form-components/form-item-record-selection';
import FormItemCascaderSelection from '../../components/form-components/form-item-cascader-selection';
import './workouts.scss';
import { CondOperator } from '../../types/cond-operators';
import { ContentStatus } from '../../types/statuses-types';

type CreateWorkoutDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

const initialFormData = {
  is_in_program: false,
  is_recommended: false,
  display_duration: 5 * 60 * 1000,
  session_type: SessionType.VideoonDemand,
  description: ''
};

const CreateWorkoutDialog = (props: CreateWorkoutDialogProps) => {
  const { isOpened, onClose } = props;

  const dictionary = useSelector(selectDictionary);

  const { optionsList: workoutEquipmentList } = getDictionaryItemConfig(dictionary, 'equipment');
  const { optionsList: liftAttachmentsList } = getDictionaryItemConfig(dictionary, 'lift_attachment');
  const categoryFocusesMap = (dictionary['category_focuses_tree'] as Record<string, string[]>) || {};
  const categoryFocusesOptionsList = getCategoryFocusesOptionsList(categoryFocusesMap);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { tabsConfig, changedAssetsData } = useWorkoutAssetsTabs();
  const [sessionType, setSessionType] = React.useState<SessionType | null>(null);
  const isAttachmentsAvailable = sessionType === SessionType.LiftVOD || sessionType === SessionType.LiftPlannedSession;

  const getFormValues = useCallback(
    (formFields: FormValues) => {
      const { category_focus, ...restFields } = formFields;
      const [category, focus] = category_focus || [];
      const fieldsValues = { ...restFields, ...changedAssetsData } as FormValues;
      if (category) fieldsValues.category = category;
      if (focus) fieldsValues.focus = focus;
      return fieldsValues;
    },
    [changedAssetsData]
  );

  const onFormValuesChange = (changedValues: FormValues) => {
    if (changedValues.session_type) setSessionType(changedValues.session_type);
  };

  const onReset = () => form.resetFields();

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const formValues = getFormValues(form.getFieldsValue(true));
    const params = { data: { ...formValues } } as CreateParams;

    api.common
      .create(Resources.workouts, params)
      .then(({ data: { id: newWorkoutId } }) => {
        onDisplaySuccessNotification('Workout record was successfully created');
        navigate(`/${Resources.workouts}/${newWorkoutId}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      className="record-form"
      wrapClassName="modal-wrap"
      title="Create workout record"
      onCancel={onCloseModal}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialFormData}
        validateMessages={commonFormValidateMessages}
        onValuesChange={onFormValuesChange}
        onFinish={onFinish}
      >
        <UnderlineDividedTitle title="Configuration" />
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="production_id" label="ISCI" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="session_type" label="Type" rules={[{ type: 'string' }, { required: true }]}>
          <FormItemSelection options={sessionTypesList} />
        </Form.Item>
        <Form.Item name="category_focus" label="Category & Focus" rules={[{ required: true }]}>
          <FormItemCascaderSelection allowClear={false} options={categoryFocusesOptionsList} />
        </Form.Item>
        <Form.Item label="Trainer" name="trainer_id">
          <FormItemRecordSelection
            sortFieldKey="first_name"
            searchBy="first_name,last_name"
            placeholder="Select trainer"
            inputPlaceholder="Search trainer by first name or last name"
            libraryTitle="Trainers library"
            selectedItemsTitle="Selected trainers"
            resource={Resources.instructors}
            additionalFilters={{ [`status||${CondOperator.IN}`]: [ContentStatus.published, ContentStatus.unpublished] }}
          />
        </Form.Item>
        <Form.Item name="complexity" label="Level">
          <FormItemSelection withDeleteBtn options={complexitiesList} />
        </Form.Item>
        <Divider />
        <Form.Item name="equipment" label="Equipment">
          <CheckboxSelectionList isMultiple options={workoutEquipmentList} />
        </Form.Item>
        {isAttachmentsAvailable && (
          <Form.Item label="Attachments" name="lift_attachments">
            <CheckboxSelectionList isMultiple options={liftAttachmentsList} />
          </Form.Item>
        )}
        <Divider />
        <Form.Item label="Video duration">Auto generated</Form.Item>
        <Form.Item label="Movements duration">Auto generated</Form.Item>
        <Form.Item label="Display duration" name="display_duration" rules={[{ required: true }]}>
          <FormItemSelection options={displayDurationsList} />
        </Form.Item>
        <Divider />
        <Form.Item name="is_in_program" label="Is in program" valuePropName="checked" rules={[{ required: true }]}>
          <SwitcherButton />
        </Form.Item>
        <Form.Item name="is_recommended" label="Is featured" valuePropName="checked" rules={[{ required: true }]}>
          <SwitcherButton />
        </Form.Item>
        <UnderlineDividedTitle title="History" />
        <Form.Item label="Created">Auto generated</Form.Item>
        <Form.Item label="Last Updates">Auto generated</Form.Item>
        <UnderlineDividedTitle title="Visual assets" />
        <Form.Item>
          <ContentTabs tabsConfig={tabsConfig} />
        </Form.Item>
        <Divider />
        <Form.Item label=" " colon={false}>
          <Row align="middle" justify="end">
            <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateWorkoutDialog;
