import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { getFormattedDate, MomentDateFormat } from '../../utils/date-utils';

type AccessibleTableDateCellProps = {
  date: string;
  format?: MomentDateFormat;
};

const AccessibleTableDateCell = (props: AccessibleTableDateCellProps) => {
  const { date, format = MomentDateFormat.usShortDateWithTime } = props;

  const timeZone = useSelector(selectUserTimeZone);
  const displayValue = getFormattedDate(date, timeZone, format);

  return <span>{displayValue}</span>;
};

export default AccessibleTableDateCell;
