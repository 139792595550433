import React, { useMemo, useState } from 'react';
import { Space } from 'antd';
import { FixedType } from 'rc-table/lib/interface';
import { FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { useItemSelector } from '../../hooks/use-selector';
import { FilterSelectionType } from '../../components/accessible-table/accessible-table-filter-select';
import { selectDictionaryItemConfig } from '../../redux/settings/settings-selectors';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';
import { DurationType, getFormattedDurationTime, TimeDisplayFormat } from '../../utils/time-utils';
import { COLUMN_DESCEND, complexitiesColorsMap, Order, SessionType, sessionTypesColorsMap, statusesColorsMap } from '../../types/common-types';
import {
  complexitiesList,
  isFeaturedFiltersList,
  isProgramExclusiveFiltersList,
  sessionTypesList,
  statusesList
} from '../../utils/resources-dictionary-utils';

import useAssetsFilters from './use-assets-filters';
import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';
import ResourceListContainer from '../../containers/resource-list-container';
import CheckTagButton from '../../components/buttons/check-tag-button';
import MinusTagButton from '../../components/buttons/minus-tag-button';
import CreateWorkoutDialog from './workout-create-dialog';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import WorkoutTrainerFilterDropdown from './workout-trainer-filter-dropdown';
import HighlightedText from '../../components/highlighted-text';
import AccessibleTableTags from '../../components/accessible-table/accessible-table-tags';
import WorkoutListAssetsFilters, { HasWorkoutImageTypes, HasWorkoutVideoTypes } from './workout-list-assets-filters';

const resource = Resources.workouts;

const filterTypes = {
  status: 'status',
  category: 'category',
  assets: 'assets',
  intro_video_id: 'intro_video_id',
  ['image.id']: 'image.id',
  focus: 'focus',
  complexity: 'complexity',
  session_type: 'session_type',
  forme_equipment: 'forme_equipment',
  is_in_program: 'is_in_program',
  is_recommended: 'is_recommended',
  ['trainer.id']: 'trainer.id'
};

const defaultFilters = {
  ...Object.keys(filterTypes).reduce((acc, key) => ({ ...acc, [key]: [] }), {}),
  [filterTypes.intro_video_id]: [HasWorkoutVideoTypes.all],
  [filterTypes['image.id']]: [HasWorkoutImageTypes.all]
};

const filtersAssetsList = [
  { value: filterTypes.intro_video_id, text: 'Video', textIcon: <VideoCameraOutlined /> },
  { value: filterTypes['image.id'], text: 'Image', textIcon: <FileImageOutlined /> }
];

const initialSortData = { sortField: 'updated_date', sortOrder: Order.desc };
const searchFilterFields = ['name', 'production_id'];

const WorkoutsListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, activeFilterDropdown, filtersOperators, onSetFilter, onSetFilters, onFilterDropdownChange } = useResourceListTableFilters({
    filterTypes: filterTypes
  });

  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  const { filterOptions, assetsFilters, onSetAssetFilter } = useAssetsFilters({ filters, filtersOperators, filterTypes, onSetFilters });

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;

    const statusFilter = filters[filterTypes.status];
    const categoryFilter = filters[filterTypes.category];
    const focusFilter = filters[filterTypes.focus];
    const complexityFilter = filters[filterTypes.complexity];
    const sessionTypeFilter = filters[filterTypes.session_type];
    const formeEquipmentFilter = filters[filterTypes.forme_equipment];
    const [isInProgramFilter] = filters[filterTypes.is_in_program];
    const [isFeatured] = filters[filterTypes.is_recommended];
    const trainerIdFilter = filters[filterTypes['trainer.id']];

    apiFilters[`${filterTypes.status}||${CondOperator.IN}`] = statusFilter.length ? statusFilter : undefined;
    apiFilters[`${filterTypes.category}||${CondOperator.IN}`] = categoryFilter.length ? categoryFilter : undefined;
    apiFilters[`${filterTypes.focus}||${CondOperator.IN}`] = focusFilter.length ? focusFilter : undefined;
    apiFilters[`${filterTypes.complexity}||${CondOperator.IN}`] = complexityFilter.length ? complexityFilter : undefined;
    apiFilters[`${filterTypes.session_type}||${CondOperator.IN}`] = sessionTypeFilter.length ? sessionTypeFilter : undefined;
    apiFilters[`${filterTypes.forme_equipment}||${CondOperator.IN}`] = formeEquipmentFilter.length ? formeEquipmentFilter : undefined;
    apiFilters[`${filterTypes.is_in_program}||${CondOperator.EQUALS}`] = isInProgramFilter;
    apiFilters[`${filterTypes.is_recommended}||${CondOperator.EQUALS}`] = isFeatured;
    apiFilters[`${filterTypes['trainer.id']}||${CondOperator.IN}`] = trainerIdFilter.length ? trainerIdFilter : undefined;

    return { ...apiFilters, ...assetsFilters };
  }, [filters, assetsFilters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const onResetFilters = () => onSetFilters(defaultFilters);

  const { optionsList: categoriesList, colorsMap: categoriesColorsMap } = useItemSelector({ key: 'category' }, selectDictionaryItemConfig);
  const { optionsList: focusesList } = useItemSelector({ key: 'movement_focus', withColor: false }, selectDictionaryItemConfig);

  const workoutsColumns = [
    {
      width: 249,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      fixed: 'left' as FixedType,
      isRequired: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    {
      width: 170,
      key: 'production_id',
      title: 'ISCI',
      dataIndex: 'production_id',
      sorter: true,
      fixed: 'left' as FixedType,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('production_id'),
      render: (value: string) => <HighlightedText text={value} highlight={searchInputValue} />,
      onHeaderCell
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'category',
      title: 'Category',
      isVisible: activeFilterDropdown === filterTypes.category,
      activeFilters: filters[filterTypes.category],
      filtersList: categoriesList,
      filterColorsMap: categoriesColorsMap,
      filterDropdownType: filterTypes.category,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 90,
      key: 'assets',
      title: 'Assets',
      isVisible: activeFilterDropdown === filterTypes.assets,
      activeFilters: filterOptions,
      filtersList: filtersAssetsList,
      filterDropdownType: filterTypes.assets,
      CustomFilterDropdown: WorkoutListAssetsFilters,
      cellRender: (value: any, record: Record<string, any>) => {
        const withVideoAsset = record?.intro_video_id && record?.intro_video_id > 0;
        const withImageAsset = record?.image && record?.image?.id && record?.image?.id > 0;
        return (
          <Space>
            {withVideoAsset && <VideoCameraOutlined />}
            {withImageAsset && <FileImageOutlined />}
          </Space>
        );
      },
      onSetFilter: onSetAssetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'focus',
      title: 'Focus',
      colored: false,
      bordered: false,
      isVisible: activeFilterDropdown === filterTypes.focus,
      activeFilters: filters[filterTypes.focus],
      filtersList: focusesList,
      filterDropdownType: filterTypes.focus,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'trainer.first_name',
      title: 'Trainer',
      isVisible: activeFilterDropdown === filterTypes['trainer.id'],
      activeFilters: filters[filterTypes['trainer.id']],
      filtersList: statusesList,
      filterDropdownType: filterTypes['trainer.id'],
      filterColorsMap: statusesColorsMap,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('trainer.first_name'),
      CustomFilterDropdown: WorkoutTrainerFilterDropdown,
      cellRender: (trainer: string, item: Record<string, any>) => (
        <span>
          {item?.trainer?.first_name} {item?.trainer?.last_name}
        </span>
      ),
      onHeaderCell,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'complexity',
      title: 'Level',
      isVisible: activeFilterDropdown === filterTypes.complexity,
      activeFilters: filters[filterTypes.complexity],
      filtersList: complexitiesList,
      filterColorsMap: complexitiesColorsMap,
      filterDropdownType: filterTypes.complexity,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 100,
      key: 'display_duration',
      title: 'Length',
      dataIndex: 'display_duration',
      onHeaderCell,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('display_duration'),
      render: (value: string) => <span>{getFormattedDurationTime(Number(value), DurationType.milliSeconds, TimeDisplayFormat.hm)}</span>
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'session_type',
      title: 'Type',
      isVisible: activeFilterDropdown === filterTypes.session_type,
      activeFilters: filters[filterTypes.session_type],
      filtersList: sessionTypesList,
      filterColorsMap: sessionTypesColorsMap,
      filterDropdownType: filterTypes.session_type,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('session_type'),
      cellRender: (value: string) => {
        const typeTag = sessionTypesList.find((item) => item.value === value);
        const typeColor = sessionTypesColorsMap[value as SessionType];
        if (!typeTag) return <span />;
        const colorsMap = typeColor ? { [typeTag.text]: typeColor } : {};
        return <AccessibleTableTags tags={[typeTag.text]} colorsMap={colorsMap} />;
      },
      onSetFilter,
      onFilterDropdownChange,
      onHeaderCell
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'status',
      title: 'Status',
      isVisible: activeFilterDropdown === filterTypes.status,
      activeFilters: filters[filterTypes.status],
      filtersList: statusesList,
      filterDropdownType: filterTypes.status,
      filterColorsMap: statusesColorsMap,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('status'),
      onSetFilter,
      onFilterDropdownChange,
      onHeaderCell
    }),
    getAccessibleTableColumnConfig({
      width: 140,
      key: 'is_in_program',
      title: 'Program Excl',
      isVisible: activeFilterDropdown === filterTypes.is_in_program,
      activeFilters: filters[filterTypes.is_in_program],
      filtersList: isProgramExclusiveFiltersList,
      filterDropdownType: filterTypes.is_in_program,
      selectionType: FilterSelectionType.radio,
      cellRender: (value: any) => (value ? <CheckTagButton /> : <MinusTagButton />),
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 140,
      key: 'is_recommended',
      title: 'Is featured',
      isVisible: activeFilterDropdown === filterTypes.is_recommended,
      activeFilters: filters[filterTypes.is_recommended],
      filtersList: isFeaturedFiltersList,
      filterDropdownType: filterTypes.is_recommended,
      selectionType: FilterSelectionType.radio,
      cellRender: (value: any) => (value ? <CheckTagButton /> : <MinusTagButton />),
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'published_date',
      title: 'Published Date',
      dataIndex: 'published_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('published_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    },
    {
      width: 170,
      sorter: true,
      key: 'updated_date',
      title: 'Last Edits',
      dataIndex: 'updated_date',
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      onHeaderCell,
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  return (
    <ResourceListContainer
      withCreate
      withResetFilters
      withSearchFilter
      withDynamicColumns
      resource={resource}
      columns={workoutsColumns}
      searchFilterPlaceholder="Search workouts by name or production id..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onResetFilters={onResetFilters}
    >
      <CreateWorkoutDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default WorkoutsListPage;
