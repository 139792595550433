import React from 'react';
import CheckTagButton from './check-tag-button';
import MinusTagButton from './minus-tag-button';
import './buttons.scss';
import { NOOP } from '../../constants/common-constants';

type SwitcherButtonProps = {
  checked?: boolean;
  onChange?: (value: boolean) => void;
};

const SwitcherButton = (props: SwitcherButtonProps) => {
  const { checked = false, onChange = NOOP } = props;

  return (
    <span className="switcher-button">
      <CheckTagButton isActive={checked} onClick={() => onChange(true)} />
      <MinusTagButton isActive={!checked} onClick={() => onChange(false)} />
    </span>
  );
};

export default SwitcherButton;
