import { MutableRefObject, useMemo, useRef, useState } from 'react';
import { Player } from 'video-react';

import api from '../../api';
import useWorkoutMovementsData from '../workout-movements/hooks/use-workout-movements-data';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { EntitiesSubUrls } from '../../api/resources-entities-api';
import { getErrorMessage } from '../../utils/errors';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { Workout, WorkoutMovement } from '../../types/workout-types';
import { VideoCodec, VideoSourceItem } from '../../components/video-player/video-player';
import { RequestError, RequestMethods } from '../../api/http-client';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';

type UseWorkoutVideoEditor = {
  record: Workout | null;
  isLoading: boolean;
  movements: WorkoutMovement[];
  videoPlayerRef: MutableRefObject<Player | null>;
  selectedItemIndex: number;
  source: VideoSourceItem;
  onSelectRow: (index: number) => void;
  onJumpToMovement: (index: number) => void;
  onSetAsStart: () => void;
  onSetAsEnd: () => void;
};

const useWorkoutVideoEditor = (): UseWorkoutVideoEditor => {
  const { record, isLoading } = useWorkoutMovementsData();
  const onSetResourceRecord = useAction(setResourceRecord);

  const videoPlayerRef = useRef<Player | null>(null);

  const source = useMemo(
    () => ({
      isHLS: false,
      value: 'web_url',
      codec: VideoCodec.h264,
      text: 'Web download version',
      url: record?.intro_video?.web_url || '',
      isSupported: !!record?.intro_video?.web_url
    }),
    [record?.intro_video?.web_url]
  );

  const movements = useMemo(() => (record?.blocks ?? []).flatMap((block) => block.movements), [record?.blocks]);

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const selectedMovement = movements[selectedItemIndex];

  const onEditMovement = (editedMovement: Partial<WorkoutMovement>) => {
    if (!record?.id || !editedMovement.id) return;

    const requestParams = {
      recordId: record.id,
      resource: Resources.workouts,
      method: RequestMethods.patch,
      entitySubUrl: `${EntitiesSubUrls.movements}/${editedMovement.id}`,
      data: editedMovement
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Workout) => {
        onSetResourceRecord({ ...record, blocks: updatedRecord.blocks });
        onDisplaySuccessNotification('Movements was successfully edited!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onSelectRow = (index: number) => {
    if (!videoPlayerRef?.current) return;
    if (selectedItemIndex === index) return;
    setSelectedItemIndex(index);
    videoPlayerRef.current?.pause();
  };

  const onJumpToMovement = (index: number) => {
    if (!videoPlayerRef?.current) return;
    const nextMovement = movements[index];
    if (nextMovement) {
      videoPlayerRef.current?.seek(nextMovement.start_time / 1000);
      setSelectedItemIndex(index);
    }
  };

  const onSetAsStart = () => {
    if (!videoPlayerRef?.current) return;
    videoPlayerRef?.current?.pause();
    if (selectedItemIndex < 1) return;

    const state = videoPlayerRef?.current?.getState() as any;
    const time = Math.round(state.player.currentTime * 1000);
    const prevMovement = movements[selectedItemIndex - 1];

    if (time < prevMovement.start_time) {
      return; // TODO: Selected start time is less then previous movement start time
    }

    const duration = time - prevMovement.start_time;
    return onEditMovement({ id: prevMovement.id, duration });
  };

  const onSetAsEnd = () => {
    const state = videoPlayerRef?.current?.getState() as any;
    const time = Math.round(state.player.currentTime * 1000);
    const duration = time - (selectedMovement?.start_time || 0);

    if (duration < 0) {
      return; // TODO: Selected end time is less then duration time
    }

    onEditMovement({ id: selectedMovement?.id, duration });

    if (movements.length - selectedItemIndex > 1) {
      setSelectedItemIndex(selectedItemIndex + 1);
    }
  };

  return {
    record,
    isLoading,
    movements,
    videoPlayerRef,
    selectedItemIndex,
    source,
    onSelectRow,
    onJumpToMovement,
    onSetAsStart,
    onSetAsEnd
  };
};

export default useWorkoutVideoEditor;
