import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal } from 'antd';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { ContentStatus } from '../../types/statuses-types';
import { getErrorMessage } from '../../utils/errors';
import { UserDevicePlatform } from '../../types/common-types';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { PromotionTile, PromotionTile as PromotionTileRecordType, PromotionTileType } from '../../types/promotion-tile-types';
import { promotionTileTypes, resourcesDictionaryUtils, userDevicePlatformList } from '../../utils/resources-dictionary-utils';
import { stringValidator } from '../../validators/string-validator';

import FormItemSelection from '../../components/form-components/form-item-selection';
import PromotionTileActionLinkCreator from './promotion-tile-action-link-creator';
import FormItemImage, { FormImageData } from '../../components/form-components/form-item-image';
import RangePickerWithTimeZone from '../../components/range-picker/range-picker-with-time-zone';
import moment from 'moment';

type CreatePromotionTileDialogProps = {
  platform: UserDevicePlatform;
  isOpened: boolean;
  onClose: () => void;
};

const initialFormImageData = {
  id: null,
  isUploaded: false,
  path: null
};

const CreatePromotionTileDialog = (props: CreatePromotionTileDialogProps) => {
  const { platform, isOpened, onClose } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [formImageData, setFormImageData] = useState<FormImageData | null>(initialFormImageData);
  const [entityId, setEntityId] = useState<string | null>(null);

  const initialFormData = useMemo(
    () => ({
      status: ContentStatus.created,
      type: PromotionTileType.custom,
      description: '',
      platform,
      startsExpiresRange: null
    }),
    [platform]
  );

  useEffect(() => {
    if (isOpened) form.setFieldsValue(initialFormData);
  }, [form, isOpened, initialFormData]);

  const getFormValues = useCallback(
    (
      formValues: Partial<
        Omit<PromotionTileRecordType, 'started_at' | 'expired_at'> & {
          startsExpiresRange: (string | null)[];
        }
      >
    ) => {
      const { startsExpiresRange, ...restFormValues } = formValues;
      const [started_at, expired_at] = startsExpiresRange || [];
      const promotionTileRecord: Partial<PromotionTileRecordType> = { ...restFormValues };

      if (started_at && expired_at) {
        promotionTileRecord.started_at = started_at;
        promotionTileRecord.expired_at = expired_at;
      }

      const isCustomType = promotionTileRecord.type === PromotionTileType.custom;
      const additionalData = isCustomType || !entityId ? {} : { entity_id: entityId };
      if (!formImageData || !formImageData.isUploaded || !formImageData.path) return { ...promotionTileRecord, ...additionalData };
      return { ...promotionTileRecord, ...additionalData, image: formImageData.path };
    },
    [formImageData, entityId]
  );

  const onClearFormImageData = () => setFormImageData(formImageData);

  const onReset = () => {
    form.resetFields();
    onClearFormImageData();
  };

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const promotionTileRecord = getFormValues(form.getFieldsValue(true)) as PromotionTile;

    if (promotionTileRecord.type === PromotionTileType.custom) {
      promotionTileRecord.action = encodeURI(promotionTileRecord.action);
    }

    const params = { data: promotionTileRecord } as CreateParams;

    api.common
      .create(Resources.promotionTiles, params)
      .then(() => {
        onDisplaySuccessNotification('Promotion Tile record was successfully created');
        navigate(0);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      wrapClassName="modal-wrap"
      title="Create promotion tile record"
      onCancel={onCloseModal}
    >
      <Form form={form} layout="horizontal" validateMessages={commonFormValidateMessages} onFinish={onFinish}>
        <Form.Item name="platform" label="Platform" initialValue={platform} rules={[{ required: true }]}>
          <FormItemSelection disabled options={userDevicePlatformList} />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <FormItemSelection disabled options={resourcesDictionaryUtils} />
        </Form.Item>
        <Form.Item name="startsExpiresRange" label="Starts / Expires on" rules={[{ required: true }]}>
          <RangePickerWithTimeZone
            showTime
            placeholder={['Starts on', 'Expires on']}
            disabledDate={(d) => !d || (d && d.isBefore(moment(), 'day'))}
          />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string', validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <FormItemSelection options={promotionTileTypes} />
        </Form.Item>
        <Form.Item
          label="Action"
          rules={[{ required: true }, { type: 'string' }]}
          shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}
        >
          {(params) => (
            <Form.Item name="action" rules={[{ required: true, type: 'string', validator: stringValidator }]}>
              <PromotionTileActionLinkCreator type={params.getFieldValue('type') as PromotionTileType} onSetEntityId={setEntityId} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="image" label="Image" rules={[{ required: true }]}>
          <FormItemImage onChangeImageData={setFormImageData} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePromotionTileDialog;
