import React, { useEffect } from 'react';
import Hls from 'hls.js';

type VideoHLSSourceProps = {
  video?: any;
  source: string;
  videoType?: string;
  autoPlay?: boolean;
};

const VideoHLSSource = (props: VideoHLSSourceProps) => {
  const { source, video, videoType = 'application/x-mpegURL', autoPlay = false } = props;
  const hls = new Hls();

  useEffect(() => {
    if (Hls.isSupported() && video) {
      hls.loadSource(source);
      hls.attachMedia(video);
      if (autoPlay) {
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video, autoPlay]);

  useEffect(
    () => () => {
      if (hls) hls.destroy();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <source src={source} type={videoType} />;
};

export default VideoHLSSource;
