import React, { useMemo, useState } from 'react';
import { FixedType } from 'rc-table/lib/interface';
import { Tabs } from 'antd';

import api from '../../api';
import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

import { Order } from '../../types/common-types';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { useSelector } from 'react-redux';
import { DragOutlined } from '@ant-design/icons';
import { RequestError } from '../../api/http-client';
import { CondOperator } from '../../types/cond-operators';
import { getErrorMessage } from '../../utils/errors';
import { setResourceList } from '../../redux/resource-list/resource-list-reducer';
import { selectResourceListTotalRows } from '../../redux/resource-list/resource-list-selectors';
import { WorkoutFilter, WorkoutFilterCategory } from '../../types/workout-filters-types';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';

import DragHandle from '../../components/drag-handle';
import CreateWorkoutFilterDialog from './workout-filter-create-dialog';
import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import AccessibleTableTextCell from '../../components/accessible-table/accessible-table-text-cell';

const resource = Resources.workoutFilters;
const categoryTabs = [WorkoutFilterCategory.STUDIO, WorkoutFilterCategory.LIFT, WorkoutFilterCategory.TPI, WorkoutFilterCategory.STUDIO_LIFT];

const filterTypes = { category: 'category' };
const categoryFilterKey = `${filterTypes.category}||${CondOperator.IN}`;
const initialCategoryFilter = [WorkoutFilterCategory.STUDIO];

const initialFilters = { [categoryFilterKey]: initialCategoryFilter };
const defaultTableFilters = { [filterTypes.category]: initialCategoryFilter };
const initialSortData = { sortField: 'order', sortOrder: Order.asc };
const searchFilterFields = ['name'];

const WorkoutFilterListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const totalRows = useSelector(selectResourceListTotalRows);
  const onSetResourceList = useAction(setResourceList);

  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);
  const { filters, onSetFilters } = useResourceListTableFilters({ filterTypes });
  const [workoutFilterCategory] = filters[filterTypes.category];

  useResourceListInitialRequestParams({ ...initialSortData, initialFilters, resource, searchFilterFields });
  useResourceListTableSorting({ ...initialSortData, resource });

  const additionalFilters: Record<string, any> = useMemo(() => {
    const categoryFilter = filters[filterTypes.category];
    return { [categoryFilterKey]: categoryFilter && categoryFilter.length ? categoryFilter : initialCategoryFilter };
  }, [filters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const onSortEnd = (orderedWorkoutFiltersList: Record<string, any>[]) => {
    onSetResourceList({ resource, resourceList: orderedWorkoutFiltersList as WorkoutFilter[], totalRows });

    api.common
      .updateListOrder(resource, {
        ids: orderedWorkoutFiltersList.map((item) => item.id),
        extra: {
          category: workoutFilterCategory
        }
      })
      .then(({ data, total }) => {
        onSetResourceList({ resource, resourceList: data as WorkoutFilter[], totalRows: total });
        onDisplaySuccessNotification('Workout filter list was successfully sorted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onChangeTab = (nextCategoryFilter: string) => {
    onSetFilters({ ...defaultTableFilters, [filterTypes.category]: [nextCategoryFilter] });
  };

  const workoutFilterColumns = [
    {
      width: 70,
      key: 'sort',
      dataIndex: 'sort',
      fixed: 'left' as FixedType,
      isRequired: true,
      className: 'drag-visible',
      title: () => <DragOutlined />,
      render: () => <DragHandle />
    },
    {
      width: 250,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left' as FixedType,
      isRequired: true,
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      )
    },
    {
      width: 300,
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      render: (value: string) => <AccessibleTableTextCell text={value} />
    },
    {
      width: 250,
      key: 'short_description',
      title: 'Short description',
      dataIndex: 'short_description',
      render: (value: string) => <AccessibleTableTextCell text={value} />
    },
    {
      width: 170,
      key: 'created_date',
      title: 'Created date',
      dataIndex: 'created_date',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated date',
      dataIndex: 'updated_date',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  return (
    <ResourceListContainer
      isDraggable
      withCreate
      withSearchFilter
      withDynamicColumns
      withPagination={false}
      resource={resource}
      columns={workoutFilterColumns}
      searchFilterPlaceholder="Search workout filters by name..."
      renderFilterTabs={
        <Tabs onChange={onChangeTab} activeKey={workoutFilterCategory}>
          {categoryTabs.map((categoryTab) => (
            <Tabs.TabPane tab={categoryTab.toUpperCase()} key={categoryTab} />
          ))}
        </Tabs>
      }
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onSortEnd={onSortEnd}
    >
      <CreateWorkoutFilterDialog
        isOpened={isCreateDialogOpened}
        category={workoutFilterCategory as WorkoutFilterCategory}
        onClose={() => setIsCreateDialogOpened(false)}
      />
    </ResourceListContainer>
  );
};

export default WorkoutFilterListPage;
