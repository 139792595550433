import React, { useCallback } from 'react';
import { Input, Space, Tooltip, Transfer } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import { TransferDirection } from 'antd/es/transfer';
import BasicModal from '../basic-modal/basic-modal';
import './transfer-modal.scss';
import '../../styles/common-styles.scss';

type TransferSourceItem = Record<string, any> & { key: string; elementId?: string };

type TransferModalProps = {
  withActions?: boolean;
  withCancelAction?: boolean;
  modalTitle: string;
  transferSectionsTitles: string[];
  modalButtonTitle: string;
  inputPlaceholder: string;
  searchInputValue: string;
  targetKeys: string[];
  selectedKeys: string[];
  transferSource: TransferSourceItem[];
  totalSource?: number;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTransfer: (extTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => void;
  onSelectChange: (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => void;
  onSuccess?: () => void;
  onCloseModalCallBack?: () => void;
};

const TransferModal = (props: TransferModalProps) => {
  const {
    withActions,
    withCancelAction = true,
    transferSectionsTitles,
    modalTitle,
    modalButtonTitle,
    inputPlaceholder,
    searchInputValue,
    targetKeys,
    selectedKeys,
    transferSource,
    totalSource,
    onChangeSearchInput,
    onChangeTransfer,
    onSelectChange,
    onSuccess,
    onCloseModalCallBack
  } = props;

  const leftLabel = useCallback(
    (info: { selectedCount: number; totalCount: number }) => {
      const { selectedCount, totalCount } = info;
      return <span>{`${selectedCount ? selectedCount + '/' : ''}${totalCount} of ${totalSource}`}</span>;
    },
    [totalSource]
  );

  return (
    <BasicModal
      withActions={withActions}
      withCancelAction={withCancelAction}
      key="model"
      tooltipLabel=""
      modalWidth={800}
      buttonType="default"
      title={modalTitle}
      buttonTitle={modalButtonTitle}
      buttonIcon={<FieldTimeOutlined />}
      onSuccess={onSuccess}
      onCancelCallBack={onCloseModalCallBack}
    >
      <Space className="ant-space-full-width" direction="vertical" size="large">
        <Input.Search autoFocus enterButton placeholder={inputPlaceholder} value={searchInputValue} onChange={onChangeSearchInput} />
        <Transfer
          oneWay
          showSelectAll={false}
          className="transfer-modal-wrap"
          titles={transferSectionsTitles}
          render={(item) => (
            <Tooltip title={item.comment}>
              <span>{item.name}</span>
            </Tooltip>
          )}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          selectAllLabels={[leftLabel]}
          dataSource={transferSource}
          onChange={onChangeTransfer}
          onSelectChange={onSelectChange}
        />
      </Space>
    </BasicModal>
  );
};

export default TransferModal;
