import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { FixedType } from 'rc-table/lib/interface';

import { Resources } from '../../types/resources-types';
import { imageTypesList } from '../../utils/resources-dictionary-utils';
import { COLUMN_DESCEND, Order, ResourceAssignedTypes } from '../../types/common-types';

import ImagesUploadModal from '../../components/images-upload-modal/images-upload-modal';
import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableImageCell from '../../components/accessible-table/accessible-table-image-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';
import HighlightedText from '../../components/highlighted-text';

const resource = Resources.images;
const searchFilterFields = ['name'];
const initialFilters = { q: { not_assigned: false } };
const initialSortData = { resource: Resources.images, sortField: 'name', sortOrder: Order.asc };

const ImagesListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();

  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);
  const [imageTypeFilter, setImageTypeFilter] = React.useState<ResourceAssignedTypes>(ResourceAssignedTypes.all);

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { isInitialized, additionalFilters: urlFilters } = useResourceListUrlParams();

  const { not_assigned: notAssignedFilter } = urlFilters.q || { not_assigned: 'false' };
  const initialImageTypeFilter = notAssignedFilter === 'true' ? ResourceAssignedTypes.notAssigned : ResourceAssignedTypes.all;
  const additionalFilters = useMemo(() => ({ q: { not_assigned: imageTypeFilter === ResourceAssignedTypes.notAssigned } }), [imageTypeFilter]);

  useEffect(() => {
    if (!isInitialized) setImageTypeFilter(initialImageTypeFilter);
  }, [isInitialized, initialImageTypeFilter]);

  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields, initialFilters });
  useResourceListAdditionalFilters({ resource, additionalFilters });

  const imagesListColumns = [
    {
      width: 70,
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
      fixed: 'left' as FixedType,
      isRequired: true,
      render: (value: string, record: Record<string, any>) => <AccessibleTableLinkedCell value={value} link={`/${resource}/${record?.id}`} />
    },
    {
      width: 120,
      key: 'url',
      title: 'Preview',
      dataIndex: 'url',
      render: (value: string) => <AccessibleTableImageCell value={value} />
    },
    {
      width: 300,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('name'),
      render: (value: string) => <HighlightedText text={value} highlight={searchInputValue} />,
      onHeaderCell
    },
    {
      width: 170,
      key: 'created_date',
      title: 'Created at',
      dataIndex: 'created_date',
      onHeaderCell,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated at',
      dataIndex: 'updated_date',
      onHeaderCell,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  const renderAdditionalFilters = (
    <Select
      style={{ width: 200 }}
      defaultValue={imageTypeFilter || ''}
      onChange={(filter) => filter && setImageTypeFilter(filter as ResourceAssignedTypes)}
    >
      {imageTypesList.map(({ value, text }) => (
        <Select.Option key={value} value={value}>
          {text}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <ResourceListContainer
      withCreate
      withSearchFilter
      resource={resource}
      columns={imagesListColumns}
      renderAdditionalFilters={renderAdditionalFilters}
      searchFilterPlaceholder="Search images by name..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
    >
      <ImagesUploadModal isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default ImagesListPage;
