import React from 'react';
import { Workout } from '../types/workout-types';
import { Program } from '../types/programs-types';
import { Resources } from '../types/resources-types';
import { Instructor } from '../types/instructors-types';
import { ContentPlan } from '../types/content-plans-types';
import { EntitiesSubUrls } from '../api/resources-entities-api';
import SelectResourceChildCollectionBase from './select-resource-child-collection-base';

type SelectResourceRecordContentPlansProps = {
  resource: Resources;
  isEditable?: boolean;
  confirmDescription?: string;
};

const SelectResourceRecordContentPlans = (props: SelectResourceRecordContentPlansProps) => {
  return (
    <SelectResourceChildCollectionBase<Workout | Instructor | Program, ContentPlan>
      collectionResource={Resources.contentPlans}
      getChildQueryFilter={() => undefined}
      getIdList={(record) => record?.content_plans.map((cp) => cp.id) ?? []}
      isEditable={props.isEditable}
      confirmTitle="Are you confirming the content plan change?"
      confirmDescription={props.confirmDescription}
      addElement={(req, record, contentPlan) =>
        req.resourcesEntities
          .createResourceEntity({
            resource: props.resource,
            recordId: record.id,
            entitySubUrl: EntitiesSubUrls.contentPlans,
            data: { content_plan_id: contentPlan.id }
          })
          .then(() => {
            const nextContentPlanWorkouts = [...record.content_plans, contentPlan];
            return { ...record, content_plans: nextContentPlanWorkouts } as Workout | Instructor | Program;
          })
      }
      removeElement={(req, record, contentPlan) =>
        req.resourcesEntities
          .removeResourceEntity({
            resource: props.resource,
            recordId: record.id,
            entitySubUrl: `${EntitiesSubUrls.contentPlans}/${contentPlan.id}`
          })
          .then(() => {
            const nextContentPlanWorkouts = record.content_plans.filter((item) => item.id !== contentPlan.id);
            return { ...record, content_plans: nextContentPlanWorkouts };
          })
      }
    />
  );
};

export default SelectResourceRecordContentPlans;
