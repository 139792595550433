import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Form, Image, Input, Typography, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'antd/dist/antd.css';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { Image as ImageRecord } from '../../types/image-types';
import { RequestError } from '../../api/http-client';
import { DeleteParams, UpdateParams } from '../../types/request-entities-types';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { useItemSelector } from '../../hooks/use-selector';
import { stringValidator } from '../../validators/string-validator';
import { EntitiesSubUrls } from '../../api/resources-entities-api';
import { getFormattedDate } from '../../utils/date-utils';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectResourceRecordError,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading
} from '../../redux/resource-record/resource-record-selectors';
import StubPage from '../../components/stub-page/stub-page';
import useLocationHandler from '../../hooks/use-location-handler';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import ConfirmButton from '../../components/buttons/confirm-button';

const ImageRecordPage = () => {
  const record = useItemSelector({ resource: Resources.images }, getPageResourceRecord) as ImageRecord | null;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const timeZone = useSelector(selectUserTimeZone);
  const { secondSubRoute: recordId } = useLocationHandler();
  const onSetImageRecord = useAction(setResourceRecord);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isAssigned, setIsAssigned] = useState<boolean | null>(null);

  useEffect(() => {
    if (!recordId) return;

    api.resourcesEntities
      .getResourceEntity({ recordId, resource: Resources.images, entitySubUrl: EntitiesSubUrls.isAssigned })
      .then((response: { isAssigned: boolean }) => setIsAssigned(response.isAssigned))
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
        setIsAssigned(false);
      });
  }, [recordId]);

  const initialData = useMemo(
    () => ({
      ...record,
      created_date: record?.created_date ? moment(record.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  useResourceRecordData({ recordId, resource: Resources.images });

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinish = () => {
    const params = { id: recordId, data: { id: recordId, ...changedFormValues }, previousData: { id: recordId } } as UpdateParams;

    api.common
      .update(Resources.images, params)
      .then((updatedImageRecord: ImageRecord) => {
        onSetImageRecord(updatedImageRecord);
        onDisplaySuccessNotification('Image record was successfully updated');
        onReset();
        navigate(`/${Resources.images}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onRemoveImage = () => {
    if (typeof isAssigned !== 'boolean' || isAssigned) return;

    api.common
      .delete(Resources.images, { id: recordId, previousData: { id: recordId } } as DeleteParams)
      .then(() => {
        onDisplaySuccessNotification('Image record was successfully deleted');
        navigate(`/${Resources.images}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.images} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Image
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item validateFirst={false} label="Created date">
          <span>{getFormattedDate(record?.created_date ?? '', timeZone)}</span>
        </Form.Item>
        <Form.Item validateFirst={false} label="Updated date">
          <span>{getFormattedDate(record?.updated_date ?? '', timeZone)}</span>
        </Form.Item>
        <Form.Item label="Path">
          {record?.url && (
            <Card
              hoverable
              className="form-item-image-card"
              bodyStyle={{ padding: '5px 10px 15px' }}
              cover={<Image width={300} src={`${record?.url}?width=600`} alt="" />}
            />
          )}
          {record?.path}
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Row align="middle" justify="end" className="full-width">
            {typeof isAssigned === 'boolean' && !isAssigned && (
              <ConfirmButton
                isDanger
                description="This operation is can not be reverted"
                title="Are you sure you want to delete this image?"
                buttonLabel="Remove"
                okText="Remove"
                icon={<DeleteOutlined />}
                onConfirm={onRemoveImage}
              />
            )}
            <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
              Reset
            </Button>
            <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged}>
              Save
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default ImageRecordPage;
