export enum ConfigType {
  warning = 'warning',
  alert = 'alert',
  fault = 'fault'
}

export type StudioConfig = {
  id: string;
  type: ConfigType;
  title?: string;
  description?: string;
  config_value?: string;
  created_date: Date | string | null;
  updated_date: Date | string | null;
};
