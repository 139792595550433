import React, { useCallback, useEffect, useMemo } from 'react';

import api from '../api';
import { NOOP } from '../constants/common-constants';
import { Resources } from '../types/resources-types';
import { RequestError } from '../api/http-client';
import { getErrorMessage } from '../utils/errors';
import { onDisplayErrorNotification } from '../utils/notifications-utils';
import { ResourceRecord } from '../types/resource-record-types';
import { CondOperator } from '../types/cond-operators';
import { usePreviousValue } from './use-previous-value';
import { getNormalizedResourceItemsList } from '../utils/common-utils';
import { ListItem } from '../components/dropdown-record-selection-list/dropdown-record-selection-list';

type UseDropdownRecordSelection<T> = {
  itemsList: ListItem[];
  selectedItems: ListItem[];
  selectedListItems: ListItem[];
  searchInputValue: string;
  inputPlaceholder: string;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectItem: (exercise: T) => void;
  onApplySelectedExercises: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type useDropdownRecordSelectionParams = {
  destroySelectedItems?: boolean;
  isSingleModeSelection?: boolean;
  resource: Resources;
  searchBy: string;
  sortFieldKey: string;
  isVisible: boolean;
  placeholder?: string;
  additionalFilters?: Record<string, any>;
  selectedItemsIds: string[];
  onSelectItems: (element: string[]) => void;
  onClose: () => void;
};

const useDropdownRecordSelection = <T extends ResourceRecord>(params: useDropdownRecordSelectionParams): UseDropdownRecordSelection<T> => {
  const {
    resource,
    searchBy,
    isVisible,
    sortFieldKey,
    selectedItemsIds,
    placeholder = 'Search',
    destroySelectedItems = true,
    isSingleModeSelection = false,
    additionalFilters,
    onClose,
    onSelectItems = NOOP
  } = params;

  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [itemsList, setItemsList] = React.useState<T[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<T[]>([]);
  const [selectedListItems, setSelectedListItems] = React.useState<T[]>([]);

  const normalizedItemsList = getNormalizedResourceItemsList(resource, itemsList);
  const normalizedSelectedItems = getNormalizedResourceItemsList(resource, selectedItems);
  const normalizedSelectedListItems = getNormalizedResourceItemsList(resource, selectedListItems);

  const [selectedItem] = normalizedSelectedItems;
  const [selectedListItem] = normalizedSelectedListItems;

  const prevIsVisible = usePreviousValue(isVisible);

  const selectedItemsRequestData = useMemo(
    () => ({
      pagination: { page: 1, perPage: 50 },
      sort: { field: sortFieldKey, order: 'ASC' },
      filter: { [`id||${CondOperator.IN}`]: selectedItemsIds.length ? selectedItemsIds : [] }
    }),
    [sortFieldKey, selectedItemsIds]
  );

  const itemsListRequestData = useMemo(
    () => ({
      pagination: { page: 1, perPage: 50 },
      sort: { field: sortFieldKey, order: 'ASC' },
      filter: { [`searchBy||${searchBy}`]: searchInputValue, ...(additionalFilters || {}) }
    }),
    [sortFieldKey, searchBy, searchInputValue, additionalFilters]
  );

  const onSetInitialDataItems = useCallback((initialDataItems: (T & { key: string | number })[]) => {
    setSelectedItems(initialDataItems);
    setSelectedListItems(initialDataItems);
  }, []);

  useEffect(() => {
    const isJustClosed = prevIsVisible && !isVisible;
    if (isJustClosed) setSearchInputValue('');
    if (isJustClosed && destroySelectedItems) setSelectedListItems([]);
  }, [destroySelectedItems, isVisible, prevIsVisible]);

  useEffect(() => {
    if (!selectedItemsIds.length) {
      onSetInitialDataItems([]);
      return;
    }

    api.common
      .getList(resource, selectedItemsRequestData)
      .then(({ data: response }: { data: T[] }) => {
        onSetInitialDataItems(response.map((item) => ({ ...item, key: item.id })));
      })
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [isVisible, resource, selectedItemsIds, selectedItemsRequestData, onSetInitialDataItems]);

  useEffect(() => {
    if (!isVisible) return;

    api.common
      .getList(resource, itemsListRequestData)
      .then(({ data: response }: { data: T[] }) => setItemsList(response.map((item) => ({ ...item, key: item.id }))))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [itemsListRequestData, resource, isVisible]);

  const inputPlaceholder = useMemo(
    () => (isVisible ? selectedListItem?.name || selectedItem?.name || placeholder : selectedItem?.name || placeholder),
    [isVisible, placeholder, selectedListItem, selectedItem]
  );

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value);

  const onSelectItem = useCallback(
    (item: T) => {
      if (isSingleModeSelection) return setSelectedListItems([item]);
      const isChecked = !!selectedListItems.find(({ id }) => item.id === id);
      if (!isChecked) return setSelectedListItems([...selectedListItems, item]);
      return setSelectedListItems(selectedListItems.filter((i) => i.id !== item.id));
    },
    [isSingleModeSelection, selectedListItems]
  );

  const onApplySelectedExercises = useCallback(() => {
    onSelectItems(selectedListItems.map(({ id }) => `${id}`));
    onClose();
  }, [selectedListItems, onSelectItems, onClose]);

  return {
    itemsList: normalizedItemsList,
    selectedItems: normalizedSelectedItems,
    selectedListItems: normalizedSelectedListItems,
    searchInputValue,
    inputPlaceholder,
    onChangeSearchInput,
    onSelectItem,
    onApplySelectedExercises
  };
};

export default useDropdownRecordSelection;
