import { CondOperator, QuerySort, RequestQueryBuilder, SCondition } from '@nestjsx/crud-request';

import { Resources } from '../types/resources-types';
import { HttpClient, RequestMethods } from './http-client';
import { composeSearch, countDiff, getEncodedResourceListUrl, mergeEncodedQueries, paramsSerializer } from './api-utils';
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateListOrderParams,
  UpdateManyParams,
  UpdateParams
} from '../types/request-entities-types';

const commonApi = (baseUrl: string, httpClient: HttpClient) => ({
  getList: (resource: Resources, params: GetListParams) => {
    const encodedUrl = getEncodedResourceListUrl({ resource, params });
    const requestUrl = `${baseUrl}/${encodedUrl}`;

    return httpClient(requestUrl);
  },

  getOne: (resource: Resources, params: GetOneParams & { subUrl?: string }) => {
    const { id, subUrl } = params;
    const url = `${baseUrl}/${resource}/${id}`;
    const requestUrl = subUrl ? `${url}/${subUrl}` : url;

    return httpClient(requestUrl);
  },

  getMany: (resource: Resources, params: GetManyParams) => {
    const filterParams = { field: 'id', operator: CondOperator.IN, value: `${params.ids}` };
    const query = RequestQueryBuilder.create().setFilter(filterParams).query();
    const requestUrl = `${baseUrl}/${resource}?${query}`;

    return httpClient(requestUrl);
  },

  getManyReference: (resource: Resources, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...otherFilters } = params.filter || {};
    const search: SCondition = composeSearch(otherFilters);
    search.$and?.push({ [params.target]: { [CondOperator.EQUALS]: params.id } });

    const encodedQueryParams = paramsSerializer(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({ search })
      .sortBy(params.sort as QuerySort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const requestUrl = `${baseUrl}/${resource}?${query}`;

    return httpClient(requestUrl);
  },

  create: async (resource: Resources, params: CreateParams & { id?: string; subUrl?: string }) => {
    const { data, subUrl } = params;
    const url = `${baseUrl}/${resource}`;
    const requestUrl = subUrl ? `${url}/${subUrl}` : url;

    return httpClient(requestUrl, {
      method: RequestMethods.post,
      body: JSON.stringify(data)
    }).then((response) => ({ data: response }));
  },

  update: (resource: Resources, params: UpdateParams & { subUrl?: string }) => {
    const { id, subUrl } = params;
    const url = `${baseUrl}/${resource}/${id}`;
    const requestUrl = subUrl ? `${url}/${subUrl}` : url;

    const data = countDiff(params.data, params.previousData);
    return httpClient(requestUrl, {
      method: RequestMethods.patch,
      body: JSON.stringify(data)
    });
  },

  updateMany: (resource: Resources, params: UpdateManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${baseUrl}/${resource}/${id}`, {
          method: RequestMethods.put,
          body: JSON.stringify(params.data)
        })
      )
    ),

  updateListOrder: (resource: Resources, params: UpdateListOrderParams) => {
    const { ids, subUrl = 'order', extra = {} } = params;
    const requestUrl = `${baseUrl}/${resource}/${subUrl}`;

    return httpClient(requestUrl, {
      method: RequestMethods.put,
      body: JSON.stringify({ ids, ...extra })
    });
  },

  delete: (resource: Resources, params: DeleteParams & { subUrl?: string }) => {
    const { id, subUrl } = params;
    const url = `${baseUrl}/${resource}/${id}`;
    const requestUrl = subUrl ? `${url}/${subUrl}` : url;

    return httpClient(requestUrl, {
      method: RequestMethods.delete
    }).then((response) => ({ data: { ...response, id } }));
  },

  deleteMany: (resource: Resources, params: DeleteManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${baseUrl}/${resource}/${id}`, {
          method: RequestMethods.delete
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json) }))
});

export default commonApi;
