import { Resources } from '../types/resources-types';

export type ResourceConfig = {
  key: Resources;
  title: string;
  route: string;
  isReadAllowed: boolean;
  isCreateAllowed: boolean;
  isDeleteAllowed: boolean;
  isUpdateAllowed: boolean;
};

export const resourcesRoutesMap = {
  [Resources.cms]: '',
  [Resources.promotionTiles]: '/promotion-tiles',
  [Resources.images]: '/images',
  [Resources.workouts]: '/workouts',
  [Resources.workoutFilters]: '/workout-filters',
  [Resources.contentPlans]: '/content-plans',
  [Resources.videos]: '/videos',
  [Resources.studioAssets]: '/studio-assets',
  [Resources.studioWidgets]: '/studio-widgets',
  [Resources.studioConfigs]: '/studio-configs',
  [Resources.programs]: '/programs',
  [Resources.instructors]: '/instructors',
  [Resources.transcodingJobs]: '/transcoding-jobs',
  [Resources.exercises]: '/exercises',
  [Resources.surveys]: '/surveys',
  [Resources.groups]: '/groups'
};

export const resourcesNamesMap = {
  [Resources.cms]: '',
  [Resources.promotionTiles]: 'Promotion tiles',
  [Resources.images]: 'Images',
  [Resources.workouts]: 'Workouts',
  [Resources.workoutFilters]: 'Workout Filters',
  [Resources.contentPlans]: 'Content plans',
  [Resources.videos]: 'Videos',
  [Resources.studioAssets]: 'Studio Assets',
  [Resources.studioWidgets]: 'Studio Widgets',
  [Resources.studioConfigs]: 'Studio Configs',
  [Resources.programs]: 'Programs',
  [Resources.instructors]: 'Instructors',
  [Resources.transcodingJobs]: 'Transcoding Jobs',
  [Resources.exercises]: 'Exercises',
  [Resources.surveys]: 'Surveys',
  [Resources.groups]: 'Groups'
};

export const resourcesList = Object.values(Resources).filter((resource) => resource !== Resources.cms);

export const resourcesConfig: ResourceConfig[] = resourcesList.map((resource) => ({
  key: resource,
  title: resourcesNamesMap[resource],
  route: resourcesRoutesMap[resource],
  isReadAllowed: false,
  isCreateAllowed: false,
  isDeleteAllowed: false,
  isUpdateAllowed: false
}));
