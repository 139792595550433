import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import { ButtonType } from 'antd/lib/button/button';
import { getConfirmationModal } from '../../utils/modals-utils';

type ConfirmButtonProps = {
  buttonLabel?: string;
  title?: string;
  description?: string;
  type?: ButtonType;
  isDanger?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
  width?: number;
  icon?: React.ReactNode;
  cancelText?: string;
  okText?: string;
  onConfirm: () => void;
};

const ConfirmButton = (props: ConfirmButtonProps) => {
  const {
    type,
    icon,
    width = 500,
    isDanger = false,
    disabled = false,
    tooltipTitle,
    title = 'Confirm',
    buttonLabel,
    description,
    okText = 'Confirm',
    cancelText = 'Cancel',
    onConfirm
  } = props;

  const confirm = () =>
    getConfirmationModal({
      title,
      width,
      description,
      okText,
      cancelText,
      isDanger,
      onConfirm
    });

  const renderButton = () => (
    <Button disabled={disabled} icon={icon} danger={isDanger} type={type} htmlType="button" onClick={confirm}>
      {buttonLabel}
    </Button>
  );

  return <Space>{tooltipTitle ? <Tooltip title={tooltipTitle}>{renderButton()}</Tooltip> : renderButton()}</Space>;
};

export default ConfirmButton;
