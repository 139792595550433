import React, { useCallback, useMemo } from 'react';
import AccessibleTableInputNumberEditCell from '../../components/accessible-table/accessible-table-input-number-edit-cell';
import { DurationType } from '../../utils/time-utils';
import { NOOP } from '../../constants/common-constants';
import { InputNumber } from 'antd';

type WorkoutMovementDurationInputProps = {
  value?: number | string;
  minNumber?: number;
  maxNumber?: number;
  withSaveButton?: boolean;
  valueDuration?: DurationType;
  displayDuration?: DurationType;
  onChange?: (nextDuration: number) => void;
};

const WorkoutMovementDurationInput = (props: WorkoutMovementDurationInputProps) => {
  const {
    value,
    minNumber = 0,
    withSaveButton = true,
    valueDuration = DurationType.milliSeconds,
    displayDuration = DurationType.seconds,
    maxNumber = 3599,
    onChange = NOOP
  } = props;

  const numericValue = value ? Number(value) : 0;
  const isSecondsDisplayDuration = useMemo(() => displayDuration === DurationType.seconds, [displayDuration]);
  const isSecondsValueDuration = useMemo(() => valueDuration === DurationType.seconds, [valueDuration]);

  const time = useMemo(() => {
    if (isSecondsDisplayDuration && isSecondsValueDuration) return numericValue;
    if (isSecondsDisplayDuration && !isSecondsValueDuration) return numericValue / 1000;
    if (isSecondsValueDuration) return numericValue * 1000;
    return numericValue;
  }, [numericValue, isSecondsDisplayDuration, isSecondsValueDuration]);

  const onChangeDuration = useCallback(
    (nextDuration: number) => {
      if (isSecondsDisplayDuration && isSecondsValueDuration) return onChange(nextDuration);
      if (isSecondsDisplayDuration && !isSecondsValueDuration) return onChange(nextDuration * 1000);
      if (isSecondsValueDuration) return onChange(nextDuration / 1000);
      return onChange(nextDuration);
    },
    [onChange, isSecondsDisplayDuration, isSecondsValueDuration]
  );

  if (!withSaveButton) {
    return <InputNumber className="full-width" value={time} min={minNumber} max={maxNumber} onChange={onChangeDuration} />;
  }

  return (
    <AccessibleTableInputNumberEditCell value={time} minNumber={minNumber} maxNumber={maxNumber} className="full-width" onChange={onChangeDuration} />
  );
};

export default WorkoutMovementDurationInput;
