import React from 'react';
import { isValidRegExp } from '../utils/common-utils';

type HighlightedTextProps = {
  text?: string | null;
  highlight?: string;
  color?: string;
  backgroundColor?: string;
};

const HighlightedText = (props: HighlightedTextProps) => {
  const { text, highlight = '', backgroundColor = 'yellow', color } = props;
  const isValidForRegExp = isValidRegExp(highlight);

  if (!text || !highlight || !isValidForRegExp) return <span>{text}</span>;

  const regExp = new RegExp(`(${highlight})`, 'gi');
  const partsList = text.toString().split(regExp);
  const parts = partsList.map((part, index) => ({ id: index, part }));

  const renderPart = ({ id, part }: { id: number; part: string }) => {
    const isMatch = part.toLowerCase() === highlight.toLowerCase();
    const style = isMatch ? { backgroundColor, color } : {};
    return (
      <span key={id} style={style}>
        {part}
      </span>
    );
  };

  return <span> {parts.map(renderPart)} </span>;
};

export default HighlightedText;
