import { useMemo } from 'react';
import { CondOperator } from 'src/types/cond-operators';
import { Filter, FilterOperators, FilterType, FilterTypes } from 'src/hooks/use-resource-list-table-filters';
import { HasSurveyImageTypes, HasSurveyVideoTypes } from '../survey-list-assets-filter';

type UseAssetsFiltersParams = {
  filters: Filter;
  filtersOperators: FilterOperators;
  filterTypes: FilterTypes;
  onSetFilters: (nextFilters: Filter) => void;
};

const useAssetsFilters = (params: UseAssetsFiltersParams) => {
  const { filters, filtersOperators, filterTypes, onSetFilters } = params;

  const videoFilter = useMemo(() => {
    const activeVideoFilter = filters[filterTypes['video.id']];
    return Array.isArray(activeVideoFilter) ? activeVideoFilter : [activeVideoFilter];
  }, [filters, filterTypes]);

  const imageFilter = useMemo(() => {
    const activeImageFilter = filters[filterTypes['image.id']];
    return Array.isArray(activeImageFilter) ? activeImageFilter : [activeImageFilter];
  }, [filters, filterTypes]);

  const videoFilterOption = useMemo(() => {
    const operator = filtersOperators[filterTypes['video.id']];
    const withVideo = videoFilter[0] === HasSurveyVideoTypes.withVideo;
    const withoutVideo = videoFilter[0] === HasSurveyVideoTypes.withoutVideo;
    const isGreaterThan = operator === CondOperator.GREATER_THAN;
    const isLowerThenOrNull = operator === CondOperator.LOWER_THAN_OR_NULL;

    if (withoutVideo || (isLowerThenOrNull && `${videoFilter[0]}` === '0')) return [HasSurveyVideoTypes.withoutVideo];
    if (withVideo || (isGreaterThan && `${videoFilter[0]}` === '0')) return [HasSurveyVideoTypes.withVideo];
    return [HasSurveyVideoTypes.all];
  }, [videoFilter, filtersOperators, filterTypes]);

  const imageFilterOption = useMemo(() => {
    const operator = filtersOperators[filterTypes['image.id']];
    const withImage = imageFilter[0] === HasSurveyImageTypes.withImage;
    const withoutImage = imageFilter[0] === HasSurveyImageTypes.withoutImage;
    const isGreaterThan = operator === CondOperator.GREATER_THAN;
    const isLowerThenOrNull = operator === CondOperator.LOWER_THAN_OR_NULL;

    if (withoutImage || (isLowerThenOrNull && `${imageFilter[0]}` === '0')) return [HasSurveyImageTypes.withoutImage];
    if (withImage || (isGreaterThan && `${imageFilter[0]}` === '0')) return [HasSurveyImageTypes.withImage];
    return [HasSurveyImageTypes.all];
  }, [imageFilter, filtersOperators, filterTypes]);

  const videoFilters = useMemo(() => {
    switch (videoFilter[0]) {
      case HasSurveyVideoTypes.withVideo:
        return {
          [`${filterTypes['video.id']}||${CondOperator.GREATER_THAN}`]: '0',
          [`${filterTypes['video.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      case HasSurveyVideoTypes.withoutVideo:
        return {
          [`${filterTypes['video.id']}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes['video.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: '0'
        };
      case HasSurveyVideoTypes.all:
        return {
          [`${filterTypes['video.id']}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes['video.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      default:
        return {};
    }
  }, [videoFilter, filterTypes]);

  const imageFilters = useMemo(() => {
    switch (imageFilter[0]) {
      case HasSurveyImageTypes.withImage:
        return {
          [`${filterTypes['image.id']}||${CondOperator.GREATER_THAN}`]: '0',
          [`${filterTypes['image.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      case HasSurveyImageTypes.withoutImage:
        return {
          [`${filterTypes['image.id']}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes['image.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: '0'
        };
      case HasSurveyImageTypes.all:
        return {
          [`${filterTypes['image.id']}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes['image.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      default:
        return {};
    }
  }, [imageFilter, filterTypes]);

  const filterOptions = useMemo(() => [...videoFilterOption, ...imageFilterOption], [videoFilterOption, imageFilterOption]);

  const assetsFilters = useMemo(() => ({ ...videoFilters, ...imageFilters }), [videoFilters, imageFilters]);

  const onSetAssetFilter = (filterType: FilterType, value: Record<string, any>) => {
    if (filterType !== filterTypes.assets) return;

    onSetFilters({
      ...filters,
      [filterTypes['video.id']]: [value.videoFilter],
      [filterTypes['image.id']]: [value.imageFilter]
    });
  };

  return { filterOptions, assetsFilters, onSetAssetFilter };
};

export default useAssetsFilters;
