import { Button, Divider, Form, Input, Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import { RequestError } from 'src/api/http-client';
import ContentTabs from 'src/components/content-tabs/content-tabs';
import { commonFormValidateMessages } from 'src/constants/form-constants';
import { useFormValues } from 'src/hooks/use-form-values';
import { CreateParams } from 'src/types/request-entities-types';
import { Resources } from 'src/types/resources-types';
import { getErrorMessage } from 'src/utils/errors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from 'src/utils/notifications-utils';
import { stringValidator } from 'src/validators/string-validator';
import useAssetsTabs from './hooks/use-assets-tabs';

type SurveyCreateDialogType = {
  isOpened: boolean;
  onClose: () => void;
};

const initialFormData = {};

export const SurveyCreateDialog = (props: SurveyCreateDialogType) => {
  const { isOpened, onClose } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { changedFormValues, onValuesChange, isFormValuesChanged } = useFormValues({ initialData: initialFormData });

  const { tabsConfig, changedAssetsData, isAssetsChanged, resetAssets } = useAssetsTabs();

  const nextFormValues: Record<string, unknown> = {
    ...changedFormValues,
    ...changedAssetsData
  };

  const isDataChanged = isFormValuesChanged || isAssetsChanged;

  const onCloseModal = () => {
    form.resetFields();
    resetAssets();
    onClose();
  };

  const onFinish = () => {
    const params = {
      data: {
        ...nextFormValues,
        draft: true,
        active: false
      }
    } as CreateParams;

    api.common
      .create(Resources.surveys, params)
      .then(({ data: { id } }) => {
        onDisplaySuccessNotification('Survey record was successfully created');
        navigate(`/${Resources.surveys}/${id}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      wrapClassName="modal-wrap"
      title="Create survey record"
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialFormData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="title" label="Title" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>

        <Divider>Assets</Divider>
        <ContentTabs tabsConfig={tabsConfig} />
        <Divider />

        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isDataChanged}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
