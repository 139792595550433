import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Button, Input } from 'antd';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { getErrorMessage } from '../../utils/errors';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { stringValidator } from '../../validators/string-validator';

type CreateContentPlanDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

const initialFormData = {};

const CreateContentPlanDialog = (props: CreateContentPlanDialogProps) => {
  const { isOpened, onClose } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onReset = () => form.resetFields();

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const params = { data: form.getFieldsValue(true) } as CreateParams;

    api.common
      .create(Resources.contentPlans, params)
      .then(({ data: { id: newContentPlanId } }) => {
        onDisplaySuccessNotification('Content plan record was successfully created');
        navigate(`/${Resources.contentPlans}/${newContentPlanId}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      maskClosable={false}
      visible={isOpened}
      wrapClassName="modal-wrap"
      title="Create content plan record"
      onCancel={onCloseModal}
    >
      <Form form={form} layout="horizontal" initialValues={initialFormData} validateMessages={commonFormValidateMessages} onFinish={onFinish}>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string', max: 255 }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateContentPlanDialog;
