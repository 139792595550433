import React from 'react';
import { Button, Space, Typography, Row, Col } from 'antd';
import { DeleteOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { NOOP } from '../../constants/common-constants';
import { AspectRatioTypes, Video, VideosTypes } from '../../types/videos-types';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import SelectVideoModal from '../select-video-modal/select-video-modal';
import VideoPlayerWithTroubleShooting from '../video-player/video-player-with-trouble-shooting';
import './form-components.scss';

type FormItemImageProps = {
  withTitle?: boolean;
  withRemove?: boolean;
  value?: UseVideoSourceSelectionParams;
  defaultAspectRatioType?: AspectRatioTypes;
  defaultVideoType?: VideosTypes;
  onChange?: (value: any) => void;
  onChangeVideoData?: (formVideoData: Video | null) => void;
};

const FormItemVideo = (props: FormItemImageProps) => {
  const { value, withTitle = false, withRemove = false, defaultVideoType, defaultAspectRatioType, onChange = NOOP, onChangeVideoData = NOOP } = props;

  const onSelectVideo = (videoItem: Video) => {
    if (!videoItem || !videoItem.id) return;
    const video_id = videoItem.id.toString();
    onChange(video_id);
    onChangeVideoData(videoItem);
  };

  const onRemoveVideo = () => {
    onChange(null);
    onChangeVideoData(null);
  };

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      {withTitle && <Typography.Title level={5}>{value?.name}</Typography.Title>}
      {value ? <VideoPlayerWithTroubleShooting data={value} /> : <VideoCameraOutlined style={{ fontSize: 150 }} />}
      <Row align="middle" gutter={16}>
        <Col>
          <SelectVideoModal
            defaultVideoType={defaultVideoType}
            defaultAspectRatioType={defaultAspectRatioType}
            title="Select video"
            onSelectVideo={onSelectVideo}
          />
        </Col>
        {withRemove && value && (
          <Col>
            <Button danger icon={<DeleteOutlined />} onClick={onRemoveVideo}>
              Remove
            </Button>
          </Col>
        )}
      </Row>
    </Space>
  );
};

export default FormItemVideo;
