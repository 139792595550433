import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import { useAction } from '../../hooks/use-actions';
import { useAuthHandler } from '../../hooks/use-auth-handler';
import { useVersionHandler } from '../../hooks/use-version-handler';
import { selectIsAuthenticationLoading } from '../../redux/permissions/permissions-selectors';
import { setIsMenuCollapsed } from '../../redux/settings/settings-reducer';
import { selectIsSideMenuCollapsed } from '../../redux/settings/settings-selectors';
import { BASE_LAYOUT_CONTENT_PADDING, BASE_LAYOUT_HEADER_HEIGHT } from '../../constants/style-constants';

import Loader from '../../components/loader';
import Breadcrumbs from './breadcrumbs';
import SettingsMenu from './settings-menu';

import 'antd/dist/antd.css';
import './base-layout.scss';

type LayoutProps = {
  children: ReactNode;
};

function PageLayout(props: LayoutProps) {
  const { children } = props;

  useAuthHandler();
  useVersionHandler(30_000);

  const isLoading = useSelector(selectIsAuthenticationLoading);
  const isMenuCollapsed = useSelector(selectIsSideMenuCollapsed);

  const onSetIsMenuCollapsed = useAction(setIsMenuCollapsed);
  const onToggleMenu = () => onSetIsMenuCollapsed(!isMenuCollapsed);

  const headerStyles = { height: BASE_LAYOUT_HEADER_HEIGHT };
  const contentStyles = { padding: BASE_LAYOUT_CONTENT_PADDING, height: `calc(100% - ${BASE_LAYOUT_HEADER_HEIGHT}px)` };

  return (
    <Layout.Content>
      <Layout.Header className="content-header" style={headerStyles}>
        <div className="content-header-left-block">
          <div className="trigger" onClick={onToggleMenu}>
            {isMenuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
          <Breadcrumbs />
        </div>
        <SettingsMenu />
      </Layout.Header>
      <Layout.Content className="layout-content" style={contentStyles}>
        {isLoading ? <Loader /> : children}
      </Layout.Content>
    </Layout.Content>
  );
}

export default PageLayout;
