import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../api';
import { Workout } from '../types/workout-types';
import { Resources } from '../types/resources-types';
import { ContentPlan } from '../types/content-plans-types';
import { RequestError } from '../api/http-client';
import { getErrorMessage } from '../utils/errors';
import { EntitiesSubUrls } from '../api/resources-entities-api';
import { selectResourceRecord } from '../redux/resource-record/resource-record-selectors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../utils/notifications-utils';
import { Select } from 'antd';
import styled from '@emotion/styled';
import { getConfirmationModal } from '../utils/modals-utils';
import { setResourceRecord } from '../redux/resource-record/resource-record-reducer';
import { useAction } from '../hooks/use-actions';

enum contentPlanMode {
  none = 'none',
  viewable = 'viewable',
  playable = 'playable'
}

const contentPlanModeOptions = Object.keys(contentPlanMode).map((value) => ({ value, label: value }));

type SelectWorkoutContentPlansProps = {
  resource: Resources;
};

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const Item = styled.div`
  width: 200px;
`;

const BoldSpan = styled.span`
  font-weight: bolder;
`;

const SelectWorkoutContentPlans = (props: SelectWorkoutContentPlansProps) => {
  const { resource } = props;

  const record = useSelector(selectResourceRecord) as Workout;
  const onSetResourceRecord = useAction(setResourceRecord);

  const [contentPlansList, setContentPlansList] = React.useState<ContentPlan[]>([]);
  const contentPlans = contentPlansList.map(({ id, name }) => {
    const isPlayable = record.playable_in_content_plans.includes(id);
    const isViewable = record.content_plans.find((contentPlan) => contentPlan.id === id);

    return {
      id,
      name,
      value: (isPlayable ? 'playable' : isViewable ? 'viewable' : 'none') as unknown as contentPlanMode
    };
  });

  useEffect(() => {
    api.common
      .getList(Resources.contentPlans, {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      })
      .then(({ data: response }) => {
        setContentPlansList(response);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  }, []);

  const onChange = ({ id, name, value }: { id: string; name: string; value: contentPlanMode }) => {
    getConfirmationModal({
      width: 450,
      title: 'Are you confirming the content plan change?',
      description: (
        <>
          <div>
            Selected option: <BoldSpan>{name}</BoldSpan>
          </div>
          <div>
            New state: <BoldSpan>{value}</BoldSpan>
          </div>
          <div>Are you sure you want to apply it?</div>
        </>
      ),
      onConfirm: () => {
        const handleError = (error: RequestError) => {
          const errorMessage = getErrorMessage(error);
          onDisplayErrorNotification(errorMessage);
        };

        if (value === contentPlanMode.none) {
          api.resourcesEntities
            .removeResourceEntity({
              resource,
              recordId: record.id,
              entitySubUrl: `${EntitiesSubUrls.contentPlans}/${id}`
            })
            .then(() => {
              onSetResourceRecord({
                ...record,
                content_plans: record.content_plans.filter((item) => item.id !== id),
                playable_in_content_plans: record.playable_in_content_plans.filter((playableId) => playableId !== id)
              });
              onDisplaySuccessNotification(`Content plan ${name ?? ''} was successfully removed`);
            })
            .catch(handleError);
        } else {
          const playable = value === contentPlanMode.playable;

          api.resourcesEntities
            .createResourceEntity({
              resource,
              recordId: record.id,
              entitySubUrl: EntitiesSubUrls.contentPlans,
              data: { content_plan_id: id, playable }
            })
            .then(() => {
              const contentPlan = contentPlansList.find((cp) => cp.id === id);
              const newContentPlans = [...record.content_plans, contentPlan].filter(Boolean) as ContentPlan[];
              const newPlayableIn = playable
                ? [...record.playable_in_content_plans, id]
                : record.playable_in_content_plans.filter((playableId) => playableId !== id);

              onSetResourceRecord({ ...record, content_plans: newContentPlans, playable_in_content_plans: newPlayableIn });
              onDisplaySuccessNotification(`Content plan ${name ?? ''} was successfully added`);
            })
            .catch(handleError);
        }
      }
    });
  };

  return (
    <Container>
      {contentPlans.map(({ id, value, name }) => {
        return (
          <Item key={id}>
            <span>{name}</span>
            <Select onChange={(v: contentPlanMode) => onChange({ id, name, value: v })} options={contentPlanModeOptions} value={value} />
          </Item>
        );
      })}
    </Container>
  );
};

export default SelectWorkoutContentPlans;
