import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { useItemSelector } from '../../hooks/use-selector';
import { selectIsAuthenticationLoading, getResourcePermissions } from '../../redux/permissions/permissions-selectors';
import ResourceListContent, { ResourceListContentProps } from './resource-list-content';
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from '../../constants/messages';
import useResourceListDynamicColumns from '../../hooks/use-resource-list-dynamic-columns';
import Loader from '../../components/loader';
import StubPage from '../../components/stub-page/stub-page';

function ResourceListContainer({
  resource,
  columns = [],
  withDelete = false,
  withCreate = false,
  withCopy = false,
  withDynamicColumns = false,
  ...rest
}: ResourceListContentProps) {
  const isLoading = useSelector(selectIsAuthenticationLoading);
  const { isReadAllowed, isCreateAllowed, isDeleteAllowed } = useItemSelector({ resource }, getResourcePermissions);
  const { dynamicColumns, resourceListColumns, onChangeDynamicColumns } = useResourceListDynamicColumns(columns, resource);

  if (isLoading) {
    return <Loader />;
  }

  if (!isReadAllowed) {
    return <StubPage mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE} secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE} />;
  }

  return (
    <ResourceListContent
      withDynamicColumns={withDynamicColumns}
      withDelete={withDelete && isDeleteAllowed}
      withCreate={withCreate && isCreateAllowed}
      withCopy={withCopy && isCreateAllowed}
      resource={resource}
      dynamicColumns={dynamicColumns}
      columns={resourceListColumns}
      onChangeDynamicColumns={onChangeDynamicColumns}
      {...rest}
    />
  );
}

export default memo(ResourceListContainer);
