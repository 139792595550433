import React from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import { DeleteOutlined, DownOutlined, FileAddOutlined, UpOutlined } from '@ant-design/icons';

import { MovementBlock } from '../../../types/workout-types';
import { EditBlockNameParams, ReorderBlocksParams } from '../hooks/use-workout-movements';
import ConfirmButton from '../../../components/buttons/confirm-button';
import EditableTypographyTitle from '../../../components/editable-typography-title';
import { arrayMove } from 'react-sortable-hoc';

type WorkoutMovementsTableTitleProps = {
  blocksIds: string[];
  movementBlock: MovementBlock;
  onRemoveBlock: (blockId: string) => void;
  onEditBlockName: (editBlockNameParams: EditBlockNameParams) => void;
  onReorderBlocks: (reorderBlocksParams: ReorderBlocksParams) => void;
  onOpenInsertMovementDrawer: (blockId: string, order?: number) => void;
};

const WorkoutMovementsTableTitle = (props: WorkoutMovementsTableTitleProps) => {
  const { blocksIds, movementBlock, onRemoveBlock, onEditBlockName, onReorderBlocks, onOpenInsertMovementDrawer } = props;
  const { id, name, movements } = movementBlock;

  const blockIndex = blocksIds.findIndex((item) => item == id);
  const canMoveAbove = blocksIds[0] !== id;
  const canMoveBelow = blocksIds[blocksIds.length - 1] !== id;

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex === newIndex) return;
    const nextData = arrayMove([...blocksIds], oldIndex, newIndex).filter((item) => !!item);
    onReorderBlocks({ ids: nextData });
  };

  const onMoveBlockAbove = () => onSortEnd({ oldIndex: blockIndex, newIndex: blockIndex - 1 });
  const onMoveBlockBelow = () => onSortEnd({ oldIndex: blockIndex, newIndex: blockIndex + 1 });
  const onAddMovementToBlock = () => onOpenInsertMovementDrawer(id);

  return (
    <Row align="middle" justify="space-between">
      <Row align="middle" gutter={16}>
        <Col>
          <EditableTypographyTitle value={name} onChange={(value) => onEditBlockName({ id: id, name: value })} />
        </Col>
      </Row>
      <Row align="middle" gutter={8}>
        <Col>
          <Tooltip title="Add movement">
            <Button icon={<FileAddOutlined />} onClick={onAddMovementToBlock} />
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={canMoveAbove ? 'Move block above' : 'Block cannot be moved above'}>
            <Button disabled={!canMoveAbove} icon={<UpOutlined />} onClick={onMoveBlockAbove} />
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={canMoveBelow ? 'Move block below' : 'Block cannot be moved below'}>
            <Button disabled={!canMoveBelow} icon={<DownOutlined />} onClick={onMoveBlockBelow} />
          </Tooltip>
        </Col>
        <Col>
          <ConfirmButton
            isDanger
            disabled={!!movements.length}
            tooltipTitle={!!movements.length ? 'For delete block, all movements should be deleted first' : 'Delete block'}
            title="Are you sure you want to delete this block?"
            icon={<DeleteOutlined />}
            onConfirm={() => onRemoveBlock(id)}
          />
        </Col>
      </Row>
    </Row>
  );
};

export default WorkoutMovementsTableTitle;
