import React from 'react';
import { Tabs } from 'antd';
import { NOOP } from '../../constants/common-constants';
import './content-tabs.scss';
import { TabPosition } from 'rc-tabs/lib/interface';
import { TabsType } from 'antd/lib/tabs';

const { TabPane } = Tabs;

export type ContentTabItemConfig = {
  key: string;
  title: string;
  component: JSX.Element;
};

type ProgramsAssetsTabsProps = {
  tabsConfig: ContentTabItemConfig[];
  tabPosition?: TabPosition;
  tabsType?: TabsType;
  tabBarExtraContent?: React.ReactNode;
  onChangeActiveTab?: (tabKey: string) => void;
};

const ContentTabs = (props: ProgramsAssetsTabsProps) => {
  const { tabsConfig, tabsType = 'card', tabPosition = 'top', tabBarExtraContent, onChangeActiveTab = NOOP } = props;

  return (
    <Tabs
      destroyInactiveTabPane
      style={{ width: '100%' }}
      type={tabsType}
      tabPosition={tabPosition}
      onChange={onChangeActiveTab}
      tabBarExtraContent={tabBarExtraContent}
    >
      {tabsConfig.map((tabConfig) => (
        <TabPane style={{ width: '100%' }} tab={tabConfig.title} key={tabConfig.key}>
          <div className="content-tab-page">{tabConfig.component}</div>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default ContentTabs;
