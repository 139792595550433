import React, { LegacyRef, useEffect, useMemo } from 'react';
import { VideoCameraOutlined, FileImageOutlined } from '@ant-design/icons';
import { Button, Row, Radio, RadioChangeEvent, Space } from 'antd';
import { NOOP } from 'src/constants/common-constants';
import { usePreviousValue } from 'src/hooks/use-previous-value';

export enum HasSurveyVideoTypes {
  all = 'allWithVideoAndNot',
  withVideo = 'withVideo',
  withoutVideo = 'withoutVideo'
}

export enum HasSurveyImageTypes {
  all = 'allWithImageAndNot',
  withImage = 'withImage',
  withoutImage = 'withoutImage'
}

type SurveyListAssetsFiltersProps = {
  isVisible: boolean;
  innerRef?: LegacyRef<HTMLDivElement>;
  activeFilters: string[];
  onSelectFilters: (filters: string[] | Record<string, any>) => void;
  onClose: () => void;
};

const SurveyListAssetsFilters = (props: SurveyListAssetsFiltersProps) => {
  const { isVisible, activeFilters, innerRef = NOOP, onSelectFilters, onClose } = props;

  const prevIsVisible = usePreviousValue(isVisible);
  const prevInitialFilters = usePreviousValue(activeFilters);

  const assetsFilters = useMemo(() => {
    const activeVideoFilters = activeFilters.filter((item) =>
      Object.values(HasSurveyVideoTypes).includes(item as HasSurveyVideoTypes)
    ) as HasSurveyVideoTypes[];
    const activeImageFilters = activeFilters.filter((item) =>
      Object.values(HasSurveyImageTypes).includes(item as HasSurveyImageTypes)
    ) as HasSurveyImageTypes[];

    return {
      activeVideoFilter: activeVideoFilters.length ? activeVideoFilters[0] : HasSurveyVideoTypes.all,
      activeImageFilter: activeImageFilters.length ? activeImageFilters[0] : HasSurveyImageTypes.all
    };
  }, [activeFilters]);

  const [videoFilter, setVideoFilter] = React.useState<HasSurveyVideoTypes>(HasSurveyVideoTypes.all);
  const [imageFilter, setImageFilter] = React.useState<HasSurveyImageTypes>(HasSurveyImageTypes.all);

  useEffect(() => {
    const isJustClosed = !isVisible && prevIsVisible;
    const isFiltersChanged = activeFilters !== prevInitialFilters;

    if (isJustClosed || isFiltersChanged) {
      setVideoFilter(assetsFilters.activeVideoFilter);
      setImageFilter(assetsFilters.activeImageFilter);
    }
  }, [isVisible, prevIsVisible, activeFilters, prevInitialFilters, assetsFilters]);

  const onApplyFilter = () => {
    onSelectFilters({ videoFilter, imageFilter });
    onClose();
  };

  const onChangeVideoFilter = (e: RadioChangeEvent) => setVideoFilter(e.target.value as HasSurveyVideoTypes);
  const onChangeImageFilter = (e: RadioChangeEvent) => setImageFilter(e.target.value as HasSurveyImageTypes);

  return (
    <div ref={innerRef} className="column-filters-selection-wrap">
      <Row align="middle" justify="start" style={{ marginBottom: 5 }}>
        <Space size="middle">
          <VideoCameraOutlined style={{ fontSize: 18 }} />
          <Radio.Group onChange={onChangeVideoFilter} value={videoFilter}>
            <Radio value={HasSurveyVideoTypes.all}>All</Radio>
            <Radio value={HasSurveyVideoTypes.withVideo}>With</Radio>
            <Radio value={HasSurveyVideoTypes.withoutVideo}>Without</Radio>
          </Radio.Group>
        </Space>
      </Row>
      <Row align="middle" justify="start" style={{ marginBottom: 5 }}>
        <Space size="middle">
          <FileImageOutlined style={{ fontSize: 18 }} />
          <Radio.Group onChange={onChangeImageFilter} value={imageFilter}>
            <Radio value={HasSurveyImageTypes.all}>All</Radio>
            <Radio value={HasSurveyImageTypes.withImage}>With</Radio>
            <Radio value={HasSurveyImageTypes.withoutImage}>Without</Radio>
          </Radio.Group>
        </Space>
      </Row>
      <div className="apply-filter-actions">
        <Button type="primary" onClick={onApplyFilter}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default SurveyListAssetsFilters;
