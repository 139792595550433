import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Order } from '../types/common-types';
import { Resources } from '../types/resources-types';
import { GetListParams } from '../types/request-entities-types';
import { getEncodedResourceListUrl } from '../api/api-utils';
import useResourceListUrlParams from './use-resource-list-url-params';

const useResourceListUrlActions = (resource: Resources) => {
  const navigate = useNavigate();

  const { requestParams, searchFilterKey, isInitialized } = useResourceListUrlParams();

  const onUpdatePageUrl = useCallback(
    (nextRequestParams: GetListParams) => {
      if (!isInitialized || !resource) return;
      const pageUrl = getEncodedResourceListUrl({ resource, params: nextRequestParams });
      navigate(`/${pageUrl}`);
    },
    [isInitialized, resource, navigate]
  );

  const onChangePage = useCallback(
    (page: number) => {
      if (!requestParams) return;
      const nextRequestParams = { ...requestParams, pagination: { ...requestParams.pagination, page } };
      onUpdatePageUrl(nextRequestParams as GetListParams);
    },
    [requestParams, onUpdatePageUrl]
  );

  const onChangeRowsPerPage = useCallback(
    (perPage: number) => {
      if (!requestParams) return;
      const nextRequestParams = { ...requestParams, pagination: { page: 1, perPage } };
      onUpdatePageUrl(nextRequestParams as GetListParams);
    },
    [requestParams, onUpdatePageUrl]
  );

  const onChangeSearchValue = useCallback(
    (searchValue: string) => {
      if (!requestParams) return;
      const currentSearchValue = requestParams.filter[searchFilterKey];
      if (currentSearchValue === searchValue) return;
      const nextRequestParams = {
        ...requestParams,
        filter: { ...requestParams.filter, [searchFilterKey]: searchValue },
        pagination: { ...requestParams.pagination, page: 1 }
      };
      onUpdatePageUrl(nextRequestParams as GetListParams);
    },
    [requestParams, searchFilterKey, onUpdatePageUrl]
  );

  const onChangeAdditionalFilters = useCallback(
    (additionalFilters: Record<string, any>) => {
      if (!requestParams) return;
      const nextRequestParams = {
        ...requestParams,
        filter: { ...requestParams.filter, ...additionalFilters },
        pagination: { ...requestParams.pagination, page: 1 }
      };
      onUpdatePageUrl(nextRequestParams as GetListParams);
    },
    [requestParams, onUpdatePageUrl]
  );

  const onChangeSortField = useCallback(
    (sortField: string) => {
      if (!requestParams) return;
      const nextRequestParams = {
        ...requestParams,
        sort: { ...requestParams.sort, field: sortField },
        pagination: { ...requestParams.pagination, page: 1 }
      };
      onUpdatePageUrl(nextRequestParams);
    },
    [requestParams, onUpdatePageUrl]
  );

  const onChangeSortOrder = useCallback(
    (sortOrder: Order) => {
      if (!requestParams) return;
      const nextRequestParams = {
        ...requestParams,
        sort: { ...requestParams.sort, order: sortOrder },
        pagination: { ...requestParams.pagination, page: 1 }
      };
      onUpdatePageUrl(nextRequestParams as GetListParams);
    },
    [requestParams, onUpdatePageUrl]
  );

  const onChangeSort = useCallback(
    ({ sortField, sortOrder }: { sortField: string; sortOrder: Order }) => {
      if (!requestParams) return;
      const nextRequestParams = {
        ...requestParams,
        sort: { field: sortField, order: sortOrder },
        pagination: { ...requestParams.pagination, page: 1 }
      };
      onUpdatePageUrl(nextRequestParams);
    },
    [requestParams, onUpdatePageUrl]
  );

  return {
    onChangePage,
    onChangeSearchValue,
    onChangeAdditionalFilters,
    onChangeSortField,
    onChangeSortOrder,
    onChangeSort,
    onChangeRowsPerPage
  };
};

export default useResourceListUrlActions;
