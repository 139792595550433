export enum WorkoutFilterCategory {
  STUDIO = 'studio',
  LIFT = 'lift',
  TPI = 'tpi',
  STUDIO_LIFT = 'studio_lift'
}

export type WorkoutFilter = {
  id: string;
  name: string;
  description: string;
  short_description: string;
  condition: Record<string, unknown>;
  order: number;
  category: WorkoutFilterCategory;
  created_date: string;
  updated_date: string;
};
