import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FixedType } from 'rc-table/lib/interface';
import { Tabs } from 'antd';
import { DragOutlined } from '@ant-design/icons';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { CondOperator } from '../../types/cond-operators';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { PromotionTile } from '../../types/promotion-tile-types';
import { setResourceList } from '../../redux/resource-list/resource-list-reducer';
import { selectResourceListTotalRows } from '../../redux/resource-list/resource-list-selectors';
import { promotionTileTypes, statusesList } from '../../utils/resources-dictionary-utils';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';
import { Order, statusesColorsMap, UserDevicePlatform } from '../../types/common-types';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';

import DragHandle from '../../components/drag-handle';
import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import CreatePromotionTileDialog from './promotion-tile-create-dialog';
import CopyPromotionTileDialog from './promotion-tile-copy-dialog';
import useResourceListTableFilters, { Filter } from '../../hooks/use-resource-list-table-filters';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

const resource = Resources.promotionTiles;
const platformTabs = [
  UserDevicePlatform.studio,
  UserDevicePlatform.ios,
  UserDevicePlatform.web,
  UserDevicePlatform.android,
  UserDevicePlatform.studio_lift
];
const searchFilterFields = ['name'];

const filterTypes = { status: 'status', type: 'type', platform: 'platform' };
const initialPlatformFilter = [UserDevicePlatform.studio];
const typeFilterKey = `${filterTypes.type}||${CondOperator.IN}`;
const statusFilterKey = `${filterTypes.status}||${CondOperator.IN}`;
const platformFilterKey = `${filterTypes.platform}||${CondOperator.IN}`;

const initialSortData = { sortField: 'order', sortOrder: Order.asc };
const initialFilters = { [typeFilterKey]: undefined, [statusFilterKey]: undefined, [platformFilterKey]: initialPlatformFilter };
const defaultTableFilters = { [filterTypes.type]: [], [filterTypes.status]: [], [filterTypes.platform]: initialPlatformFilter };

const PromotionTilesListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const totalRows = useSelector(selectResourceListTotalRows);
  const onSetResourceList = useAction(setResourceList);
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);
  const [isCopyDialogOpened, setIsCopyDialogOpened] = useState(false);
  const { filters, activeFilterDropdown, onSetFilter, onFilterDropdownChange, onSetFilters } = useResourceListTableFilters({ filterTypes });
  useResourceListInitialRequestParams({ ...initialSortData, initialFilters, resource, searchFilterFields });

  const [activePlatform] = filters[filterTypes.platform];

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;

    const typeFilter = filters[filterTypes.type];
    const statusFilter = filters[filterTypes.status];
    const platformFilter = filters[filterTypes.platform];

    apiFilters[typeFilterKey] = typeFilter && typeFilter.length ? typeFilter : undefined;
    apiFilters[statusFilterKey] = statusFilter && statusFilter.length ? statusFilter : undefined;
    apiFilters[platformFilterKey] = platformFilter && platformFilter.length ? platformFilter : initialPlatformFilter;

    return apiFilters;
  }, [filters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const promotionTilesColumns = [
    {
      width: 70,
      key: 'sort',
      dataIndex: 'sort',
      fixed: 'left' as FixedType,
      isRequired: true,
      className: 'drag-visible',
      title: () => <DragOutlined />,
      render: () => <DragHandle />
    },
    {
      width: 250,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left' as FixedType,
      isRequired: true,
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      )
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'status',
      title: 'Status',
      isVisible: activeFilterDropdown === filterTypes.status,
      activeFilters: filters[filterTypes.status],
      filtersList: statusesList,
      filterDropdownType: filterTypes.status,
      filterColorsMap: statusesColorsMap,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'type',
      title: 'Type',
      isVisible: activeFilterDropdown === filterTypes.type,
      activeFilters: filters[filterTypes.type],
      filtersList: promotionTileTypes,
      filterDropdownType: filterTypes.type,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'started_at',
      title: 'Started at',
      dataIndex: 'started_at',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 170,
      key: 'expired_at',
      title: 'Expired at',
      dataIndex: 'expired_at',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  const onSortEnd = (orderedPromotionTileList: Record<string, any>[]) => {
    onSetResourceList({ resource, resourceList: orderedPromotionTileList as PromotionTile[], totalRows });

    api.common
      .updateListOrder(resource, { ids: orderedPromotionTileList.map((item) => item.id), extra: { platform: activePlatform } })
      .then(({ data, total }) => {
        onSetResourceList({ resource, resourceList: data as PromotionTile[], totalRows: total });
        onDisplaySuccessNotification('Promotion tile list was successfully sorted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onChangeTab = (nextPlatformFilter: string) => {
    onSetFilters({ ...defaultTableFilters, [filterTypes.platform]: [nextPlatformFilter] } as Filter);
  };

  const onResetPromotionTilesFilters = () => {
    onSetFilters({ ...defaultTableFilters, [filterTypes.platform]: [activePlatform] } as Filter);
  };

  return (
    <ResourceListContainer
      isDraggable
      withCreate
      withCopy
      withResetFilters
      withSearchFilter
      withDynamicColumns
      withPagination={false}
      resource={resource}
      columns={promotionTilesColumns}
      searchFilterPlaceholder="Search promotion tiles by name..."
      renderFilterTabs={
        <Tabs destroyInactiveTabPane activeKey={activePlatform} onChange={onChangeTab}>
          {platformTabs.map((item) => (
            <Tabs.TabPane tab={item.toUpperCase()} key={item} />
          ))}
        </Tabs>
      }
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onCopyClick={() => setIsCopyDialogOpened(true)}
      onResetFilters={onResetPromotionTilesFilters}
      onSortEnd={onSortEnd}
    >
      <CreatePromotionTileDialog
        isOpened={isCreateDialogOpened}
        platform={activePlatform as UserDevicePlatform}
        onClose={() => setIsCreateDialogOpened(false)}
      />
      <CopyPromotionTileDialog
        isOpened={isCopyDialogOpened}
        from_platform={activePlatform as UserDevicePlatform}
        to_platform={activePlatform as UserDevicePlatform}
        onClose={() => setIsCopyDialogOpened(false)}
      />
    </ResourceListContainer>
  );
};

export default PromotionTilesListPage;
