import {
  ExerciseComplexity,
  ExerciseType,
  MovementPattern,
  MuscleGroup,
  PlaneOfMotion,
  Position,
  PrimeMover,
  SideUsageType,
  WorkoutEquipment
} from './common-types';
import { Video } from './videos-types';

export type ExerciseOwner = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  image: string | null;
};

export type Exercise = {
  id: string;
  type: ExerciseType;
  created_date: Date | null | string;
  updated_date: Date | null | string;
  name: string;
  complexity: ExerciseComplexity;
  number_arms_used: number;
  position: Position | null;
  movement_pattern: MovementPattern | null;
  secondary_movement_pattern: MovementPattern | null;
  is_movement: boolean;
  muscle_group_1: MuscleGroup | null;
  muscle_group_2: MuscleGroup | null;
  muscle_group_3: MuscleGroup | null;
  prime_mover_1: PrimeMover | null;
  prime_mover_2: PrimeMover | null;
  prime_mover_3: PrimeMover | null;
  height_scheme: string | null;
  plane_of_motion: PlaneOfMotion;
  secondary_plane_of_motion: PlaneOfMotion | null;
  attachment: string | null;
  alternative_attachments: string[];
  equipment: WorkoutEquipment | null;
  is_public: boolean;
  planSets: Record<string, unknown>[];
  userResistances: Record<string, unknown>[];
  owner: ExerciseOwner;
  intro_video: Video;
  side_usage_type?: SideUsageType;
};

export enum TrainerCreatedTypes {
  all = 'all',
  trainerCreated = 'trainerCreated',
  notTrainerCreated = 'notTrainerCreated'
}
