import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, FormInstance } from 'antd';
import moment from 'moment';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';

import { Video } from '../../types/videos-types';
import { useAction } from '../../hooks/use-actions';
import { Resources } from '../../types/resources-types';
import { ContentPlan } from '../../types/content-plans-types';
import { UpdateParams } from '../../types/request-entities-types';
import { TranscodingJob } from '../../types/transcoding-jobs-types';
import { getErrorMessage } from '../../utils/errors';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { EntitiesSubUrls } from '../../api/resources-entities-api';
import { useItemSelector } from '../../hooks/use-selector';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { FormValues, useFormValues } from '../../hooks/use-form-values';
import { RequestError, RequestMethods } from '../../api/http-client';
import { getPageResourceRecord, selectIsResourceRecordLoading } from '../../redux/resource-record/resource-record-selectors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';

type InitialData = Omit<Video, 'created_date' | 'updated_date'> & {
  created_date: moment.Moment | undefined;
  updated_date: moment.Moment | undefined;
};

type UseVideoRecord = {
  record: Video;
  isLoading: boolean;
  form: FormInstance<any>;
  initialData: InitialData;
  studioSize: number;
  duration: number;
  timeZone: string;
  isFormValuesChanged: boolean;
  transcodingJobs: TranscodingJob[];
  onReset: () => void;
  onFinish: () => void;
  onValuesChange: (changedValues: FormValues) => void;
  onArchiveVideo: () => void;
};

const useVideoRecord = (): UseVideoRecord => {
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const record = useItemSelector({ resource: Resources.videos }, getPageResourceRecord) as Video;
  const timeZone = useSelector(selectUserTimeZone);
  const { secondSubRoute: recordId } = useLocationHandler();
  const onSetResourceRecord = useAction(setResourceRecord);
  const studioSize = record?.meta_data?.sizes?.studio;
  const duration = record?.meta_data?.duration;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const initialData = useMemo(
    () => ({
      ...record,
      created_date: record?.created_date ? moment(record.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  useResourceRecordData({ recordId, resource: Resources.videos });

  const [transcodingJobs, setTranscodingJobs] = useState<TranscodingJob[]>([]);

  useEffect(() => {
    if (!record?.name) return;

    const filter = { [`searchBy||video.name`]: record?.name };
    const sort = { field: 'id', order: 'ASC' };

    api.common
      .getList(Resources.transcodingJobs, { pagination: { page: 1, perPage: 50 }, filter, sort })
      .then(({ data: records = [] }) => {
        const videoTranscodingJobs = records.filter((item: TranscodingJob) => `${item?.video?.id}` === `${record?.id}`);
        if (videoTranscodingJobs) setTranscodingJobs(videoTranscodingJobs);
      })
      .catch(() => onDisplayErrorNotification(`Can't fetch transcoding job information for this video`));
  }, [record?.name, record?.id]);

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinish = () => {
    const params = { id: recordId, data: { id: recordId, ...changedFormValues }, previousData: { id: recordId } } as UpdateParams;

    api.common
      .update(Resources.videos, params)
      .then((contentPlanRecord: ContentPlan) => {
        onSetResourceRecord(contentPlanRecord);
        onDisplaySuccessNotification('Video record was successfully updated');
        onReset();
        navigate(`/${Resources.videos}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onArchiveVideo = () => {
    if (!record) return;

    const requestParams = {
      recordId: record.id,
      resource: Resources.videos,
      entitySubUrl: EntitiesSubUrls.archive,
      method: RequestMethods.post
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then(() => {
        onSetResourceRecord({ ...record, is_archived: true });
        onDisplaySuccessNotification('Video was successfully archived!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return {
    record,
    isLoading,
    form,
    initialData,
    studioSize,
    duration,
    timeZone,
    isFormValuesChanged,
    transcodingJobs,
    onReset,
    onFinish,
    onValuesChange,
    onArchiveVideo
  };
};

export default useVideoRecord;
