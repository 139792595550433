import React from 'react';

import { StudioWidget } from '../../types/studio-widgets';
import { VideoAssetElement } from '../../types/studio-assets';
import { getPageResourceRecord } from '../../redux/resource-record/resource-record-selectors';
import { ContentTabItemConfig } from '../../components/content-tabs/content-tabs';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import { useItemSelector } from '../../hooks/use-selector';
import { Resources } from '../../types/resources-types';
import useSelectImage from '../../hooks/use-select-image';
import useSelectVideo from '../../hooks/use-select-video';
import FormItemVideo from '../../components/form-components/form-item-video';
import FormItemImage, { FormImageData } from '../../components/form-components/form-item-image';

type ChangedAssetsData = {
  image_id?: number | null;
  video_id?: number | null;
};

type UseStudioWidgetsAssetsTabs = {
  isAssetsChanged: boolean;
  isImageChanged: boolean;
  isAssetVideoChanged: boolean;
  changedAssetsData: ChangedAssetsData | null;
  assetImage: FormImageData | null;
  assetVideo: VideoAssetElement | null;
  tabsConfig: ContentTabItemConfig[];
};

const useStudioWidgetsAssetsTabs = (): UseStudioWidgetsAssetsTabs => {
  const record = useItemSelector({ resource: Resources.studioWidgets }, getPageResourceRecord) as StudioWidget;

  const { assetImage, setAssetImage } = useSelectImage({ imageData: record?.image });
  const { assetVideo, setAssetVideo } = useSelectVideo({ videoData: record?.video });

  const isImageChanged = `${assetImage?.id ?? ''}` !== `${record?.image?.id ?? ''}`;
  const isAssetVideoChanged = `${assetVideo?.id ?? ''}` !== `${record?.video?.id ?? ''}`;

  const isAssetsChanged = isImageChanged || isAssetVideoChanged;

  const getChangedAssetsData = () => {
    const changedAssetsData = {} as ChangedAssetsData;
    if (!isAssetsChanged) return null;

    if (isImageChanged) changedAssetsData.image_id = assetImage ? Number(assetImage.id) : null;
    if (isAssetVideoChanged) changedAssetsData.video_id = assetVideo ? Number(assetVideo.id) : null;

    return changedAssetsData;
  };

  const changedAssetsData = getChangedAssetsData();

  const tabsConfig = [
    {
      key: 'image',
      title: 'Image',
      component: (
        <FormItemImage withRemove withTitle withResize={false} title={assetImage?.name} value={assetImage?.url} onChangeImageData={setAssetImage} />
      )
    },
    {
      key: 'video',
      title: 'Video',
      component: <FormItemVideo withRemove withTitle value={assetVideo as UseVideoSourceSelectionParams} onChangeVideoData={setAssetVideo} />
    }
  ];

  return {
    tabsConfig,
    assetImage,
    assetVideo,
    isAssetsChanged,
    isImageChanged,
    isAssetVideoChanged,
    changedAssetsData
  };
};

export default useStudioWidgetsAssetsTabs;
