import React, { LegacyRef, useEffect, useMemo } from 'react';
import { Button, Checkbox, Divider, Radio, Tag } from 'antd';
import cs from 'classnames';

import { NOOP } from '../../constants/common-constants';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './accessible-table.scss';
import { usePreviousValue } from '../../hooks/use-previous-value';

export enum FilterSelectionType {
  radio = 'radio',
  checkbox = 'checkbox'
}

type AccessibleTableFilterSelectProps = {
  innerRef?: LegacyRef<HTMLDivElement>;
  className?: string;
  isVisible?: boolean;
  withCheckAll?: boolean;
  selectionType?: FilterSelectionType;
  initialFilters: string[];
  options: { value: any; text: string; color?: string; textIcon?: React.ReactNode; isDisabled?: boolean }[];
  onSelectFilters: (filters: string[]) => void;
};

const AccessibleTableFilterSelect = (props: AccessibleTableFilterSelectProps) => {
  const {
    innerRef = NOOP,
    isVisible,
    withCheckAll,
    initialFilters,
    options,
    selectionType = FilterSelectionType.checkbox,
    className,
    onSelectFilters
  } = props;

  const prevIsVisible = usePreviousValue(isVisible);
  const prevInitialFilters = usePreviousValue(initialFilters);
  const [checkedList, setCheckedList] = React.useState<string[]>([]);
  const [checkAll, setCheckAll] = React.useState(false);

  const allValuesList = options.map((item) => item.value);
  const isCheckedAll = useMemo(() => allValuesList.every((item) => checkedList.includes(item)), [checkedList, allValuesList]);

  useEffect(() => {
    const isJustClosed = !isVisible && prevIsVisible;
    const isFiltersChanged = initialFilters !== prevInitialFilters;
    if (isJustClosed || isFiltersChanged) setCheckedList(initialFilters);
  }, [isVisible, prevIsVisible, initialFilters, prevInitialFilters]);

  useEffect(() => {
    if (isCheckedAll !== checkAll) {
      setCheckAll(isCheckedAll);
    }
  }, [isCheckedAll, checkAll]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? allValuesList : []);
    setCheckAll(e.target.checked);
  };

  const onChangeCheckboxGroup = (list: CheckboxValueType[]) => {
    setCheckedList(list as string[]);
  };

  const onChangeRadioBoxGroup = (e: RadioChangeEvent) => {
    setCheckedList([e.target.value]);
  };

  const onApplyFilter = () => {
    onSelectFilters(checkedList);
  };

  return (
    <div ref={innerRef} className={cs('column-filters-selection-wrap', className)}>
      {withCheckAll && selectionType === FilterSelectionType.checkbox && (
        <>
          <Checkbox onChange={onCheckAllChange} checked={checkAll}>
            Check all
          </Checkbox>
          <Divider />
        </>
      )}
      {selectionType === FilterSelectionType.checkbox && (
        <Checkbox.Group className="column-filters-selection-group" value={checkedList} onChange={onChangeCheckboxGroup}>
          {options.map((option) => (
            <Checkbox key={`${option.value}/${option.text}`} value={option.value} disabled={option.isDisabled}>
              {option.color ? (
                <Tag className="accessible-table-tags-cell" color={option.color}>
                  <span className="text-icon">{option?.textIcon}</span>
                  {option.text}
                </Tag>
              ) : (
                <>
                  <span className="text-icon">{option?.textIcon}</span>
                  {option.text}
                </>
              )}
            </Checkbox>
          ))}
        </Checkbox.Group>
      )}
      {selectionType === FilterSelectionType.radio && (
        <Radio.Group className="column-filters-selection-group" value={checkedList[0]} onChange={onChangeRadioBoxGroup}>
          {options.map((option) => (
            <Radio key={`${option.value}/${option.text}`} value={option.value} disabled={option.isDisabled}>
              {option.color ? (
                <Tag className="accessible-table-tags-cell" color={option.color}>
                  <span className="text-icon">{option?.textIcon}</span>
                  {option.text}
                </Tag>
              ) : (
                <>
                  <span className="text-icon">{option?.textIcon}</span>
                  {option.text}
                </>
              )}
            </Radio>
          ))}
        </Radio.Group>
      )}
      <div className="apply-filter-actions">
        <Button type="primary" onClick={onApplyFilter}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default AccessibleTableFilterSelect;
