import { ArmUsedEnum } from '../../types/common-types';
import { armUsedEnumList } from '../../utils/resources-dictionary-utils';

export const getArmUsedOptionsList = (isLiftVod: boolean, numberArmsUsed?: number | null) => {
  let availableArmUsedEnums = [] as ArmUsedEnum[];
  if (!isLiftVod || !numberArmsUsed) availableArmUsedEnums = [];
  if (numberArmsUsed === 1) availableArmUsedEnums = [ArmUsedEnum.left, ArmUsedEnum.right];
  if (numberArmsUsed === 2) availableArmUsedEnums = [ArmUsedEnum.both];
  return armUsedEnumList.filter((item) => availableArmUsedEnums.includes(item.value as ArmUsedEnum));
};

export const getInitialArmUsedValue = (isLiftVod: boolean, numberArmsUsed?: number | null) => {
  if (!isLiftVod || !numberArmsUsed) return ArmUsedEnum.none;
  if (numberArmsUsed === 1) return ArmUsedEnum.left;
  if (numberArmsUsed === 2) return ArmUsedEnum.both;
  return ArmUsedEnum.none;
};
