import React from 'react';

import { Instructor } from '../../types/instructors-types';
import { VideosTypes } from '../../types/videos-types';
import { VideoAssetElement } from '../../types/studio-assets';
import { ContentTabItemConfig } from '../../components/content-tabs/content-tabs';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import useSelectImage from '../../hooks/use-select-image';
import useSelectVideo from '../../hooks/use-select-video';
import FormItemVideo from '../../components/form-components/form-item-video';
import FormItemImage, { FormImageData } from '../../components/form-components/form-item-image';

type ChangedAssetsData = {
  face_image_id?: number | null;
  full_image_id?: number | null;
  intro_video_id?: number | null;
};

type UseProgramAssetsTabs = {
  isAssetsChanged: boolean;
  isFaceImageChanged: boolean;
  isFullImageChanged: boolean;
  isAssetVideoChanged: boolean;
  changedAssetsData: ChangedAssetsData | null;
  faceImage: FormImageData | null;
  fullImage: FormImageData | null;
  assetVideo: VideoAssetElement | null;
  tabsConfig: ContentTabItemConfig[];
};

const useInstructorAssetsTabs = (record?: Instructor | null): UseProgramAssetsTabs => {
  const { assetImage: faceImage, setAssetImage: setFaceImage } = useSelectImage({ imageData: record?.face_image ?? null });
  const { assetImage: fullImage, setAssetImage: setFullImage } = useSelectImage({ imageData: record?.full_image ?? null });
  const { assetVideo, setAssetVideo } = useSelectVideo({ videoData: record?.intro_video ?? null });

  const isFaceImageChanged = `${faceImage?.id ?? ''}` !== `${record?.face_image?.id ?? ''}`;
  const isFullImageChanged = `${fullImage?.id ?? ''}` !== `${record?.full_image?.id ?? ''}`;
  const isAssetVideoChanged = `${assetVideo?.id ?? ''}` !== `${record?.intro_video?.id ?? ''}`;

  const isAssetsChanged = isFaceImageChanged || isFullImageChanged || isAssetVideoChanged;

  const getChangedAssetsData = () => {
    const changedAssetsData = {} as ChangedAssetsData;
    if (!isAssetsChanged) return null;

    if (isFaceImageChanged) changedAssetsData.face_image_id = faceImage ? Number(faceImage.id) : null;
    if (isFullImageChanged) changedAssetsData.full_image_id = fullImage ? Number(fullImage.id) : null;
    if (isAssetVideoChanged) changedAssetsData.intro_video_id = assetVideo ? Number(assetVideo.id) : null;

    return changedAssetsData;
  };

  const changedAssetsData = getChangedAssetsData();

  const tabsConfig = [
    {
      key: 'listingImageLarge',
      title: 'Listing image large',
      component: <FormItemImage withTitle withResize={false} title={fullImage?.name} value={fullImage?.url} onChangeImageData={setFullImage} />
    },
    {
      key: 'listingImage small',
      title: 'Listing image small',
      component: <FormItemImage withTitle withResize={false} title={faceImage?.name} value={faceImage?.url} onChangeImageData={setFaceImage} />
    },
    {
      key: 'previewVideo',
      title: 'Preview video',
      component: (
        <FormItemVideo
          withTitle
          defaultVideoType={VideosTypes.notAssigned}
          value={assetVideo as UseVideoSourceSelectionParams}
          onChangeVideoData={setAssetVideo}
        />
      )
    }
  ];

  return {
    tabsConfig,
    faceImage,
    fullImage,
    assetVideo,
    isAssetsChanged,
    changedAssetsData,
    isFaceImageChanged,
    isFullImageChanged,
    isAssetVideoChanged
  };
};

export default useInstructorAssetsTabs;
