import { ImageAssetElement, VideoAssetElement } from './studio-assets';

export enum SurveyStepType {
  YESNO = 'yesno',
  SELECT = 'select',
  TIMER = 'timer',
  REGULAR = 'regular',
  FILE_UPLOAD = 'file_upload',
  MULTI_SELECT = 'multi_select'
}

export type SurveyStep = {
  id: string;
  step_id: string;
  category: string;
  type: SurveyStepType;
  title: string;
  question: string;
  order: number;
  image?: ImageAssetElement;
  video?: VideoAssetElement;
  is_optional?: boolean;
  description?: string;
  additional_fields?: Record<string, unknown>;
};

export type Survey = {
  id: string;
  created_date: Date | null | string;
  updated_date: Date | null | string;
  title: string;
  name: string;
  description: string;
  steps: SurveyStep[];
  image: ImageAssetElement;
  video: VideoAssetElement;
  additional_info: Record<string, unknown>;
};
