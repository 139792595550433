import { useEffect } from 'react';

import { Resources } from '../types/resources-types';
import { usePreviousValue } from './use-previous-value';
import useResourceListUrlActions from './use-resource-list-url-actions';

type UseResourceListAdditionalFilters = {
  resource: Resources;
  additionalFilters: Record<string, any>;
};

const useResourceListAdditionalFilters = (params: UseResourceListAdditionalFilters) => {
  const { resource, additionalFilters } = params;

  const { onChangeAdditionalFilters } = useResourceListUrlActions(resource);
  const prevAdditionalFilters = usePreviousValue(additionalFilters);

  useEffect(() => {
    if (additionalFilters !== prevAdditionalFilters) {
      onChangeAdditionalFilters(additionalFilters);
    }
  }, [additionalFilters, prevAdditionalFilters, onChangeAdditionalFilters]);
};

export default useResourceListAdditionalFilters;
