import React from 'react';
import { useSelector } from 'react-redux';

import api from '../api';
import { Workout } from '../types/workout-types';
import { Program } from '../types/programs-types';
import { Resources } from '../types/resources-types';
import { useAction } from '../hooks/use-actions';
import { Instructor } from '../types/instructors-types';
import { RequestError } from '../api/http-client';
import { EntitiesSubUrls } from '../api/resources-entities-api';
import { getErrorMessage } from '../utils/errors';
import { setResourceRecord } from '../redux/resource-record/resource-record-reducer';
import { selectResourceRecord } from '../redux/resource-record/resource-record-selectors';
import { resourcesDictionaryUtils } from '../utils/resources-dictionary-utils';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../utils/notifications-utils';
import FormItemSelection from './form-components/form-item-selection';

type ResourceRecordStatusSelectionProps = {
  resource: Resources;
  className?: string;
};

const ResourceRecordStatusSelection = ({ resource, className = '' }: ResourceRecordStatusSelectionProps) => {
  const record = useSelector(selectResourceRecord) as Workout | Instructor | Program;

  const onSetRecord = useAction(setResourceRecord);

  const onChangeStatus = (status: string | null) => {
    if (!record || !status) return;

    const requestParams = {
      resource,
      recordId: record.id,
      entitySubUrl: EntitiesSubUrls.status,
      data: { status }
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord) => {
        onSetRecord({ ...record, ...updatedRecord });
        onDisplaySuccessNotification('Status was successfully updated!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <FormItemSelection
      withConfirmModal
      confirmTitle="Are you confirming the status change?"
      className={className}
      value={record?.status}
      options={resourcesDictionaryUtils}
      onChange={onChangeStatus}
    />
  );
};

export default ResourceRecordStatusSelection;
