import React from 'react';
import { Drawer, Form, Button, Col, Row, Space } from 'antd';

import { useFormValues } from '../../../hooks/use-form-values';
import { WorkoutMovement } from '../../../types/workout-types';
import { BlockDrawerParams, BlockDrawerType } from '../hooks/use-workout-movements';
import FormItemTimeInput from '../../../components/form-components/form-item-time-input';
import FormItemSelection from '../../../components/form-components/form-item-selection';
import WorkoutMovementDurationInput from '../workout-movement-duration-input';
import '../workout-movemnts.scss';

type EditTransitionDrawerProps = {
  params: BlockDrawerParams;
  blocksOptionsList: { value: string | number; text: string }[];
  onClose: () => void;
  onEditTransition: (movement: Partial<WorkoutMovement>) => void;
};

const EditTransitionDrawer = (props: EditTransitionDrawerProps) => {
  const { blocksOptionsList, params, onClose, onEditTransition } = props;
  const { type: drawerType, movement } = params;

  const isVisible = drawerType === BlockDrawerType.editTransition;
  if (!isVisible || !movement) return null;

  const { id, block_id, end_time, duration } = movement;

  const [form] = Form.useForm();

  const initialData = {
    block_id: `${block_id}`,
    duration
  };

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinishEditTransitionForm = () => {
    onEditTransition({ id, ...changedFormValues });
    onClose();
  };

  return (
    <Drawer
      maskClosable={false}
      destroyOnClose
      title="Edit transition"
      width={800}
      onClose={onClose}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        className="create-movement-block-form"
        onFinish={onFinishEditTransitionForm}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Form.Item name="block_id" className="full-width" label="Select block" rules={[{ required: true, message: 'Please select movement' }]}>
            <FormItemSelection options={blocksOptionsList} />
          </Form.Item>
        </Row>
        <Row align="middle" gutter={16}>
          <Col span={8}>
            <Form.Item label="End timestamp" className="full-width">
              <FormItemTimeInput typingAllowed disabled value={`${end_time}`} size="middle" className="full-width" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="duration" label="Duration" className="full-width">
              <WorkoutMovementDurationInput withSaveButton={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Space>
            <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
              Reset
            </Button>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged}>
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditTransitionDrawer;
