import React, { useEffect, useState } from 'react';
import { SelectOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Pagination, RadioChangeEvent, Row, Space, Tooltip } from 'antd';

import api from '../../api';
import useDebounce from '../../hooks/use-debounce';
import { NOOP } from '../../constants/common-constants';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CondOperator } from '../../types/cond-operators';
import { getErrorMessage } from '../../utils/errors';
import { AspectRatioTypes, Video, VideosTypes } from '../../types/videos-types';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { aspectRatioFiltersList, videosTypesList } from '../../utils/resources-dictionary-utils';
import SelectVideoItem from './select-video-item';
import RadioGroupDropdown from '../radio-group-dropdown/radio-group-dropdown';
import './select-video-modal.scss';

type SelectVideoModalProps = {
  title: string;
  defaultVideoType?: VideosTypes;
  defaultAspectRatioType?: AspectRatioTypes;
  onSelectVideo?: (selectedVideo: Video) => void;
};

const SelectVideoModal = (props: SelectVideoModalProps) => {
  const { title, defaultVideoType = VideosTypes.all, defaultAspectRatioType = AspectRatioTypes['9:16'], onSelectVideo = NOOP } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [videosListData, setVideosListData] = React.useState<{ videos: Video[]; total: number }>({ videos: [], total: 0 });

  const debouncedSearchValue = useDebounce(searchInputValue);

  const [apiParams, setApiParams] = useState<{
    page: number;
    perPage: number;
    videoType: VideosTypes | null;
    aspectRatioType: AspectRatioTypes | null;
  }>({
    page: 1,
    perPage: 50,
    videoType: defaultVideoType,
    aspectRatioType: defaultAspectRatioType
  });

  const { page, perPage, videoType, aspectRatioType } = apiParams;

  const onShowModal = () => setIsVisible(true);
  const onHideModal = () => setIsVisible(false);

  useEffect(() => {
    const apiFilters = {
      'searchBy||name': debouncedSearchValue,
      [`is_archived||${CondOperator.EQUALS}`]: 'false',
      [`aspect_ratio||${CondOperator.NOT_IN}`]: undefined,
      [`aspect_ratio||${CondOperator.EQUALS}`]: undefined,
      q: { not_assigned: videoType === VideosTypes.notAssigned }
    } as Record<any, any>;

    if (aspectRatioType !== null) {
      if (aspectRatioType !== AspectRatioTypes.other) {
        apiFilters[`aspect_ratio||${CondOperator.NOT_IN}`] = undefined;
        apiFilters[`aspect_ratio||${CondOperator.EQUALS}`] = aspectRatioType === AspectRatioTypes.all ? undefined : aspectRatioType;
      }

      if (aspectRatioType === AspectRatioTypes.other) {
        apiFilters[`aspect_ratio||${CondOperator.EQUALS}`] = undefined;
        apiFilters[`aspect_ratio||${CondOperator.NOT_IN}`] = [AspectRatioTypes['9:16'], AspectRatioTypes['16:9']];
      }
    }

    api.common
      .getList(Resources.videos, {
        filter: apiFilters,
        sort: { field: 'name', order: 'ASC' },
        pagination: { page, perPage }
      })
      .then(({ data: videos, total }: any) => setVideosListData({ videos, total }))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [page, perPage, videoType, aspectRatioType, debouncedSearchValue]);

  const selectedVideoTypeItem = videosTypesList.find((item) => item.value === videoType);
  const selectedAspectRatioItem = aspectRatioFiltersList.find((item) => item.value === aspectRatioType);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    setApiParams({ ...apiParams, page: 1, perPage });
  };

  const onChangePagination = (selectedPage: number, selectedPerPage?: number | undefined) => {
    setApiParams({ ...apiParams, page: selectedPage, perPage: selectedPerPage || apiParams.perPage });
  };

  const onSetVideoType = (e: RadioChangeEvent) => {
    setApiParams({ ...apiParams, page: 1, videoType: e.target.value as VideosTypes });
  };

  const onSetAspectRatioType = (e: RadioChangeEvent) => {
    setApiParams({ ...apiParams, page: 1, aspectRatioType: e.target.value as AspectRatioTypes });
  };

  const onSelect = (selectedVideo: Video) => {
    onSelectVideo(selectedVideo);
    onHideModal();
  };

  return (
    <>
      <Tooltip placement="topRight" title="Open select video modal">
        <Button icon={<SelectOutlined />} onClick={onShowModal}>
          {title}
        </Button>
      </Tooltip>
      <Modal
        centered
        destroyOnClose
        title={title}
        width={1000}
        visible={isVisible}
        footer={[]}
        wrapClassName="modal-wrap"
        bodyStyle={{ minHeight: '300px', maxHeight: '80vh' }}
        onCancel={onHideModal}
      >
        <div className="select-video-wrapper">
          <Row align="middle" justify="space-between" className="header-section">
            <Col flex={12}>
              <Input.Search
                autoFocus
                enterButton
                id="video-search-filter"
                placeholder="search video by name"
                value={searchInputValue}
                onChange={onChangeSearchInput}
              />
            </Col>
            <Col flex={8} className="header-filters">
              <Space>
                <RadioGroupDropdown
                  title={selectedVideoTypeItem?.text}
                  value={videoType}
                  radioGroupList={videosTypesList}
                  onChangeRadioGroup={onSetVideoType}
                />
                <RadioGroupDropdown
                  title={selectedAspectRatioItem?.text}
                  value={aspectRatioType}
                  radioGroupList={aspectRatioFiltersList}
                  onChangeRadioGroup={onSetAspectRatioType}
                />
              </Space>
            </Col>
          </Row>
          <div className="video-list-content">
            {videosListData.videos.map((video) => (
              <SelectVideoItem key={video.id} video={video} onSelect={() => onSelect(video)} />
            ))}
          </div>
          <Pagination
            current={page}
            pageSize={perPage}
            total={videosListData.total}
            pageSizeOptions={['25', '50', '75']}
            showTotal={(total) => `Total: ${total} videos`}
            onChange={onChangePagination}
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectVideoModal;
