import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../boot/store';
import { SettingsState, Themes } from './settings-reducer';
import { VidePlayers } from '../../types/video-player-types';
import { getDictionaryItemConfig } from './settings-utils';

export const selectSettingsState = (state: RootState): SettingsState => state.settings;

export const selectTheme = (state: RootState): Themes => selectSettingsState(state).theme;
export const selectUserTimeZone = (state: RootState): string => selectSettingsState(state).userTimeZone;
export const selectIsSideMenuCollapsed = (state: RootState): boolean => selectSettingsState(state).isSideMenuCollapsed;
export const selectApiVersion = (state: RootState): string => selectSettingsState(state).apiVersion;
export const selectDictionary = (state: RootState): Record<string, unknown> => selectSettingsState(state).dictionary;
export const selectVideoPlayer = (state: RootState): VidePlayers => selectSettingsState(state).videoPlayer;

export const selectDictionaryItemConfig = createSelector(
  selectDictionary,
  (state: RootState, { key, withColor = true }: { key: string; withColor?: boolean }) => ({ key, withColor }),
  (dictionary, { key, withColor }) => getDictionaryItemConfig(dictionary as Record<string, string[]>, key, withColor)
);
