import React, { memo, useCallback, useMemo } from 'react';
import { Table } from 'antd';
import { Key } from 'antd/es/table/interface';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import 'antd/dist/antd.css';
import cs from 'classnames';

import { NOOP } from '../../constants/common-constants';
import { AccessibleTableProps } from './accessible-table-types';
import { BASE_BLUE_COLOR } from '../../constants/style-constants';

const PAGINATION_SIZE_OPTIONS = ['25', '50', '100', '150'];

const TableSortableItem = SortableElement((props: any) => <tr {...props} />);
const TableSortableContainer = SortableContainer((props: any) => <tbody {...props} />);

const AccessibleTable = (props: AccessibleTableProps) => {
  const {
    stickyHeader = false,
    stickyTitle = false,
    size = 'small',
    innerRef,
    rowKey = 'id',
    tBodyInnerRef,
    tHeaderInnerRef,
    data,
    columns,
    loading,
    showHeader,
    bordered,
    selectedItems,
    page,
    isDraggable,
    selectionType,
    xScroll = '100%',
    yScroll = 'auto',
    withPagination = false,
    totalRows = 50,
    rowsPerPage = 50,
    renderTitle,
    renderFooter,
    tableClassName,
    rowClassName,
    onChangePage = NOOP,
    onChangeRowsPerPage = NOOP,
    onSelectRows = NOOP,
    onSortEnd = NOOP,
    onRowClick = NOOP,
    onRowDoubleClick = NOOP,
    onRowContextMenu = NOOP,
    onRowMouseEnter = NOOP,
    onRowMouseLeave = NOOP
  } = props;

  const onChange = (nextPage: number, nextPageSize?: number) => {
    if (nextPage !== page) onChangePage(nextPage);
    if (nextPageSize && nextPageSize !== rowsPerPage) onChangeRowsPerPage(nextPageSize);
  };

  const rowSelection = {
    type: selectionType,
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys: Key[]) => onSelectRows(selectedRowKeys as string[])
  };

  const pagination = {
    total: totalRows,
    showTotal: (total: number) => <span style={{ color: BASE_BLUE_COLOR }}>{total} results</span>,
    current: page,
    defaultCurrent: page,
    defaultPageSize: 25,
    pageSize: rowsPerPage,
    pageSizeOptions: PAGINATION_SIZE_OPTIONS,
    onChange
  };

  const onSortRowsEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      if (oldIndex !== newIndex) {
        const nextData = arrayMove([...data], oldIndex, newIndex);
        onSortEnd(nextData);
      }
    },
    [data, onSortEnd]
  );

  const DraggableContainer = useCallback(
    (draggableContainerProps: any) => (
      <TableSortableContainer
        ref={tBodyInnerRef}
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortRowsEnd}
        {...draggableContainerProps}
      />
    ),
    [tBodyInnerRef, onSortRowsEnd]
  );

  const HeaderWrapper = useCallback((headerWrapperProps: any) => <tbody ref={tHeaderInnerRef} {...headerWrapperProps} />, [tHeaderInnerRef]);

  const dataIndexes = useMemo(() => data.map((item) => item.index), [data]);

  const DraggableBodyRow = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ className, style, ...draggableBodyRowProps }: any) => {
      const index = dataIndexes.findIndex((dataItemIndex) => dataItemIndex === draggableBodyRowProps['data-row-key']);
      return <TableSortableItem index={index} className={className} {...draggableBodyRowProps} />;
    },
    [dataIndexes]
  );

  const tableComponents = useMemo(
    () => ({
      header: { wrapper: HeaderWrapper },
      body: { wrapper: DraggableContainer, row: DraggableBodyRow }
    }),
    [HeaderWrapper, DraggableContainer, DraggableBodyRow]
  );

  const renderSummary = useCallback(
    (summaryParams: any) => <Table.Summary fixed={stickyHeader ? 'top' : undefined} {...summaryParams} />,
    [stickyHeader]
  );

  const onRow = useCallback(
    (record: Record<string, any>, rowIndex?: number) => ({
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => onRowClick(e, record, rowIndex),
      onDoubleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => onRowDoubleClick(e, record, rowIndex),
      onContextMenu: (e: React.MouseEvent<HTMLElement, MouseEvent>) => onRowContextMenu(e, record, rowIndex),
      onMouseEnter: (e: React.MouseEvent<HTMLElement, MouseEvent>) => onRowMouseEnter(e, record, rowIndex),
      onMouseLeave: (e: React.MouseEvent<HTMLElement, MouseEvent>) => onRowMouseLeave(e, record, rowIndex)
    }),
    [onRowClick, onRowDoubleClick, onRowContextMenu, onRowMouseEnter, onRowMouseLeave]
  );

  return (
    <Table
      sticky={stickyHeader}
      size={size}
      ref={innerRef}
      loading={loading}
      tableLayout="fixed"
      columns={columns}
      showHeader={showHeader}
      bordered={bordered}
      dataSource={data}
      title={renderTitle}
      footer={renderFooter}
      className={cs('accessible-table', tableClassName, { ['sticky-table-title']: stickyTitle })}
      scroll={{ x: xScroll, y: yScroll }}
      rowKey={isDraggable ? 'index' : rowKey}
      components={tableComponents}
      pagination={withPagination ? pagination : false}
      rowSelection={selectionType ? rowSelection : undefined}
      summary={renderSummary}
      rowClassName={rowClassName}
      onRow={onRow}
    />
  );
};

export default memo(AccessibleTable);
