import { QuerySort, RequestQueryBuilder } from '@nestjsx/crud-request';
import { stringify, parse } from 'query-string';
import { omitBy } from '../utils/helpers';
import { CondOperator, SFields, SCondition } from '../types/cond-operators';
import { GetListParams } from '../types/request-entities-types';
import { Resources } from '../types/resources-types';
import { Order } from '../types/common-types';

export const countDiff = (o1: Record<string, any>, o2: Record<string, any>): Record<string, any> => omitBy(o1, (v, k) => o2[k] === v);

export const paramsSerializer = <TParams>(params: TParams): string => stringify(params);

export const mergeEncodedQueries = (...encodedQueries: string[]) => encodedQueries.map((query) => query).join('&');

export const composeSearch = (paramsFilter: any): SCondition => {
  const result: SCondition = { [CondOperator.AND]: [] };

  for (const key of Object.keys(paramsFilter)) {
    const value = paramsFilter[key];
    let [field, ops] = key.split('||');

    if (field === 'searchBy') {
      const searchBy = ops.split(',');
      const filter = { [CondOperator.OR]: searchBy.map((f) => ({ [f]: { $contL: value } })) };
      result[CondOperator.AND]?.push(filter);
      continue;
    }

    if (!ops) {
      if (typeof paramsFilter[key] === 'number' || paramsFilter[key].match(/^\d+$/)) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }

    const filter: SFields = { [field]: { [ops]: value } };
    result[CondOperator.AND]?.push(filter);
  }

  return result;
};

export const getRequestParamsFromQuery = (pageQuery: string) => {
  if (!pageQuery) return null;

  const parseQuery = parse(pageQuery);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { limit: rowsPerPage, page, offset, s: search, 'sort[0]': sortData, ...queryFilters } = parseQuery;
  const { $and: filters } = JSON.parse(search as string);
  const [sortField, sortOrder] = (sortData as string).split(',');

  const { searchKey, searchInputValue, additionalFilters } = (filters as Record<string, any>[]).reduce(
    (acc: Record<string, any>, item: Record<string, any>) => {
      const [filterKey] = Object.keys(item);
      const filterData = item[filterKey];

      if (filterKey === CondOperator.OR) {
        const searchFields = filterData.reduce((res: string, i: Record<string, any>) => {
          const [searchItemKey] = Object.keys(i);
          return res ? `${res},${searchItemKey}` : searchItemKey;
        }, '');
        const searchValueKey = `searchBy||${searchFields}`;
        const [searchInputData] = Object.values(filterData[0]);
        const searchValue = (searchInputData as Record<CondOperator, string>)[CondOperator.CONTAINS_LOW];
        return { ...acc, searchKey: searchValueKey, searchInputValue: searchValue };
      }

      const [condOperator] = Object.keys(filterData);
      if (!condOperator) return acc;

      const additionalFiltersData = {
        ...acc.additionalFilters,
        [`${filterKey}||${condOperator}`]: filterData[condOperator]
      };

      return { ...acc, additionalFilters: additionalFiltersData };
    },
    {
      searchKey: '',
      searchInputValue: '',
      additionalFilters: {}
    }
  );

  return {
    sortField,
    sortOrder: sortOrder as Order,
    page: Number(page),
    rowsPerPage: Number(rowsPerPage),
    searchKey,
    searchInputValue,
    additionalFilters: { ...additionalFilters, q: { ...(queryFilters || {}) } }
  };
};

export const getEncodedResourceListUrl = ({ resource, params }: { resource: Resources; params: GetListParams }): string => {
  const { sort, pagination } = params;
  const { page, perPage } = pagination;
  const { q: queryParams, ...filter } = params.filter || {};
  const encodedQueryParams = paramsSerializer(queryParams);
  const encodedQueryFilter = RequestQueryBuilder.create({ search: composeSearch(filter) })
    .setLimit(perPage)
    .setPage(page)
    .sortBy(sort as QuerySort)
    .setOffset((page - 1) * perPage)
    .query();
  const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
  return `${resource}?${query}`;
};
