import React from 'react';
import { Cascader, Tag } from 'antd';

import { NOOP } from '../../constants/common-constants';
import { BaseOptionType, SingleValueType } from 'rc-cascader/lib/Cascader';
import './form-components.scss';

export type CascaderSelectionOption = {
  value: string | number;
  label: string;
  color?: string;
  children?: CascaderSelectionOption[];
};

type FormItemCascaderSelectionProps = {
  value?: string[];
  className?: string;
  placeholder?: string;
  bordered?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  options: CascaderSelectionOption[];
  onChange?: (value: string[]) => void;
};

const DEFAULT_ITEM_COLOR = '#108ee9';

const FormItemCascaderSelection = (props: FormItemCascaderSelectionProps) => {
  const { className, bordered = true, placeholder, options = [], value, allowClear = true, disabled, onChange = NOOP } = props;

  const displayRender = (label: string[], selectedOptions?: BaseOptionType[]) => {
    if (!selectedOptions) return label;
    return selectedOptions
      .filter((item) => !!item)
      .map((item, index) => (
        <Tag
          key={item?.value}
          className="accessible-table-tags-cell"
          color={item?.color && index === 0 ? item?.color || DEFAULT_ITEM_COLOR : DEFAULT_ITEM_COLOR}
        >
          {item?.label ?? ''}
        </Tag>
      ));
  };

  const onChangeCascader = (cascaderValue: SingleValueType) => onChange(cascaderValue as string[]);

  return (
    <Cascader
      value={value}
      allowClear={allowClear}
      className={className}
      dropdownClassName="form-item-cascader-selection-dropdown"
      disabled={disabled}
      bordered={bordered}
      options={options}
      placeholder={placeholder}
      displayRender={displayRender}
      onChange={onChangeCascader}
    />
  );
};

export default FormItemCascaderSelection;
