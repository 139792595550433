import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserTimeZoneFromLS, getUserVideoPlayerFromLS, saveUserTimeZoneToLS, saveVideoPlayerZoneToLS } from '../../utils/local-storage-utils';
import { VidePlayers } from '../../types/video-player-types';
import momentTimezone from 'moment-timezone';

export enum Themes {
  white = 'white',
  black = 'black'
}

const defaultTimeZone = momentTimezone.tz.guess();
const defaultVideoPlayer = VidePlayers.vlc;

export interface SettingsState {
  theme: Themes;
  videoPlayer: VidePlayers;
  userTimeZone: string;
  isSideMenuCollapsed: boolean;
  apiVersion: string;
  dictionary: Record<string, unknown>;
}

const initialState: SettingsState = {
  videoPlayer: getUserVideoPlayerFromLS() || defaultVideoPlayer,
  userTimeZone: getUserTimeZoneFromLS() || defaultTimeZone,
  theme: Themes.white,
  isSideMenuCollapsed: false,
  apiVersion: '',
  dictionary: {}
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUserTimeZone: (state, action: PayloadAction<string>) => {
      saveUserTimeZoneToLS(action.payload);
      state.userTimeZone = action.payload;
    },
    setTheme: (state, action: PayloadAction<Themes>) => {
      state.theme = action.payload;
    },
    setIsMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isSideMenuCollapsed = action.payload;
    },
    setApiVersion: (state, action: PayloadAction<string>) => {
      state.apiVersion = action.payload;
    },
    setDictionary: (state, action: PayloadAction<Record<string, string[]>>) => {
      state.dictionary = action.payload;
    },
    setVideoPlayer: (state, action: PayloadAction<VidePlayers>) => {
      saveVideoPlayerZoneToLS(action.payload);
      state.videoPlayer = action.payload;
    }
  }
});

export const { setUserTimeZone, setTheme, setIsMenuCollapsed, setApiVersion, setDictionary, setVideoPlayer } = settingsSlice.actions;

export default settingsSlice.reducer;
