import { CMS_API_URL, CMS_COMMON_API_URL } from '../config';
import filesApi from './files-api';
import commonApi from './common-api';
import httpClient from './http-client';
import resourcesEntitiesApi from './resources-entities-api';
import settings from './settings';
import config from './config';
import exercisesApi from './exercises-api';

export default {
  files: filesApi(CMS_API_URL!, httpClient),
  settings: settings(CMS_API_URL!, httpClient),
  config: config(CMS_COMMON_API_URL!, httpClient),
  common: commonApi(CMS_API_URL!, httpClient),
  exercises: exercisesApi(CMS_API_URL!, httpClient),
  resourcesEntities: resourcesEntitiesApi(CMS_API_URL!, httpClient)
};
