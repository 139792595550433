import React from 'react';

import { Program } from '../../types/programs-types';
import { AspectRatioTypes } from '../../types/videos-types';
import { VideoAssetElement } from '../../types/studio-assets';
import { ContentTabItemConfig } from '../../components/content-tabs/content-tabs';
import useSelectImage from '../../hooks/use-select-image';
import useSelectVideo from '../../hooks/use-select-video';
import FormItemVideo from '../../components/form-components/form-item-video';
import FormItemImage, { FormImageData } from '../../components/form-components/form-item-image';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';

type ChangedAssetsData = {
  listing_image_large_id?: number | null;
  listing_image_small_id?: number | null;
  detail_image_large_id?: number | null;
  detail_image_small_id?: number | null;
  preview_video_id?: number | null;
  intro_animation_id?: number | null;
};

type UseProgramAssetsTabs = {
  isAssetsChanged: boolean;
  isListingImageLargeChanged: boolean;
  setListingImageLarge: (studioAssetImage: FormImageData | null) => void;
  setListingImageSmall: (studioAssetImage: FormImageData | null) => void;
  setDetailImageLarge: (studioAssetImage: FormImageData | null) => void;
  setDetailImageSmall: (studioAssetImage: FormImageData | null) => void;
  setPreviewVideo: (studioAssetImage: VideoAssetElement | null) => void;
  setIntroAnimation: (studioAssetImage: VideoAssetElement | null) => void;
  isListingImageSmallChanged: boolean;
  isDetailImageLargeChanged: boolean;
  isDetailImageSmallChanged: boolean;
  isPreviewVideoChanged: boolean;
  isIntroAnimationChanged: boolean;
  changedAssetsData: ChangedAssetsData | null;
  listingImageLarge: FormImageData | null;
  listingImageSmall: FormImageData | null;
  detailImageLarge: FormImageData | null;
  detailImageSmall: FormImageData | null;
  previewVideo: VideoAssetElement | null;
  introAnimation: VideoAssetElement | null;
  tabsConfig: ContentTabItemConfig[];
};

const useProgramAssetsTabs = (record?: Program | null): UseProgramAssetsTabs => {
  const { assetImage: listingImageLarge, setAssetImage: setListingImageLarge } = useSelectImage({ imageData: record?.listing_image_large ?? null });
  const { assetImage: listingImageSmall, setAssetImage: setListingImageSmall } = useSelectImage({ imageData: record?.listing_image_small ?? null });
  const { assetImage: detailImageLarge, setAssetImage: setDetailImageLarge } = useSelectImage({ imageData: record?.detail_image_large ?? null });
  const { assetImage: detailImageSmall, setAssetImage: setDetailImageSmall } = useSelectImage({ imageData: record?.detail_image_small ?? null });
  const { assetVideo: previewVideo, setAssetVideo: setPreviewVideo } = useSelectVideo({ videoData: record?.preview_video ?? null });
  const { assetVideo: introAnimation, setAssetVideo: setIntroAnimation } = useSelectVideo({ videoData: record?.intro_animation ?? null });

  const isListingImageLargeChanged = `${listingImageLarge?.id ?? ''}` !== `${record?.listing_image_large?.id ?? ''}`;
  const isListingImageSmallChanged = `${listingImageSmall?.id ?? ''}` !== `${record?.listing_image_small?.id ?? ''}`;
  const isDetailImageLargeChanged = `${detailImageLarge?.id ?? ''}` !== `${record?.detail_image_large?.id ?? ''}`;
  const isDetailImageSmallChanged = `${detailImageSmall?.id ?? ''}` !== `${record?.detail_image_small?.id ?? ''}`;
  const isPreviewVideoChanged = `${previewVideo?.id ?? ''}` !== `${record?.preview_video?.id ?? ''}`;
  const isIntroAnimationChanged = `${introAnimation?.id ?? ''}` !== `${record?.intro_animation?.id ?? ''}`;

  const isAssetsChanged =
    isListingImageLargeChanged ||
    isListingImageSmallChanged ||
    isDetailImageLargeChanged ||
    isDetailImageSmallChanged ||
    isPreviewVideoChanged ||
    isIntroAnimationChanged;

  const getChangedAssetsData = () => {
    const changedAssetsData = {} as ChangedAssetsData;
    if (!isAssetsChanged) return null;

    if (isListingImageLargeChanged) changedAssetsData.listing_image_large_id = listingImageLarge ? Number(listingImageLarge.id) : null;
    if (isListingImageSmallChanged) changedAssetsData.listing_image_small_id = listingImageSmall ? Number(listingImageSmall.id) : null;
    if (isDetailImageLargeChanged) changedAssetsData.detail_image_large_id = detailImageLarge ? Number(detailImageLarge.id) : null;
    if (isDetailImageSmallChanged) changedAssetsData.detail_image_small_id = detailImageSmall ? Number(detailImageSmall.id) : null;
    if (isPreviewVideoChanged) changedAssetsData.preview_video_id = previewVideo ? Number(previewVideo.id) : null;
    if (isIntroAnimationChanged) changedAssetsData.intro_animation_id = introAnimation ? Number(introAnimation.id) : null;

    return changedAssetsData;
  };

  const changedAssetsData = getChangedAssetsData();

  const tabsConfig = [
    {
      key: 'listingImageLarge',
      title: 'Listing image large',
      component: (
        <FormItemImage
          withTitle
          withRemove
          withResize={false}
          title={listingImageLarge?.name}
          value={listingImageLarge?.url}
          onChangeImageData={setListingImageLarge}
        />
      )
    },
    {
      key: 'listingImage small',
      title: 'Listing image small',
      component: (
        <FormItemImage
          withTitle
          withRemove
          withResize={false}
          title={listingImageSmall?.name}
          value={listingImageSmall?.url}
          onChangeImageData={setListingImageSmall}
        />
      )
    },
    {
      key: 'detailImageLarge',
      title: 'Detail image large',
      component: (
        <FormItemImage
          withTitle
          withRemove
          withResize={false}
          title={detailImageLarge?.name}
          value={detailImageLarge?.url}
          onChangeImageData={setDetailImageLarge}
        />
      )
    },
    {
      key: 'detailImage small',
      title: 'Detail image small',
      component: (
        <FormItemImage
          withTitle
          withRemove
          withResize={false}
          title={detailImageSmall?.name}
          value={detailImageSmall?.url}
          onChangeImageData={setDetailImageSmall}
        />
      )
    },
    {
      key: 'previewVideo',
      title: 'Trailer',
      component: (
        <FormItemVideo
          withTitle
          withRemove
          defaultAspectRatioType={AspectRatioTypes['16:9']}
          value={previewVideo as UseVideoSourceSelectionParams}
          onChangeVideoData={setPreviewVideo}
        />
      )
    },
    {
      key: 'IntroAnimation',
      title: 'Intro Animation',
      component: (
        <FormItemVideo
          withTitle
          withRemove
          defaultAspectRatioType={AspectRatioTypes['9:16']}
          value={introAnimation as UseVideoSourceSelectionParams}
          onChangeVideoData={setIntroAnimation}
        />
      )
    }
  ];

  return {
    tabsConfig,
    listingImageLarge,
    listingImageSmall,
    detailImageLarge,
    detailImageSmall,
    previewVideo,
    introAnimation,
    isAssetsChanged,
    changedAssetsData,
    isListingImageLargeChanged,
    setListingImageLarge,
    setListingImageSmall,
    setDetailImageLarge,
    setDetailImageSmall,
    setPreviewVideo,
    setIntroAnimation,
    isListingImageSmallChanged,
    isDetailImageLargeChanged,
    isDetailImageSmallChanged,
    isPreviewVideoChanged,
    isIntroAnimationChanged
  };
};

export default useProgramAssetsTabs;
