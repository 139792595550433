import React from 'react';
import TimeInput from '../time-input/time-input';
import SelectionTimeInput from '../time-input/selection-time-input';
import { NOOP } from '../../constants/common-constants';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type FormItemSelectionProps = {
  value?: string;
  size?: SizeType;
  className?: string;
  disabled?: boolean;
  typingAllowed?: boolean;
  onChange?: (value: number) => void;
};

const FormItemTimeInput = (props: FormItemSelectionProps) => {
  const { className, typingAllowed, disabled, value = 0, size, onChange = NOOP } = props;

  if (typingAllowed) {
    return <TimeInput disabled={disabled} size={size} className={className} durationTime={Number(value)} onChange={onChange} />;
  }

  return <SelectionTimeInput disabled={disabled} size={size} className={className} durationTime={Number(value)} onChange={onChange} />;
};

export default FormItemTimeInput;
