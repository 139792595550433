import React, { useMemo } from 'react';
import { Radio, Row, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import './dropdown-selection-radio-filters.scss';

type DropdownSelectionRadioFiltersProps<T> = {
  title: string;
  filter: T;
  disabled?: boolean;
  tooltip?: string;
  options: { value: T; text: string }[];
  onChangeFilter: (e: RadioChangeEvent) => void;
};

const DropdownSelectionRadioFilters = <T extends unknown>(props: DropdownSelectionRadioFiltersProps<T>) => {
  const { title, filter, tooltip, disabled, options, onChangeFilter } = props;

  const renderRadioGroup = useMemo(
    () => (
      <Radio.Group onChange={onChangeFilter} value={filter}>
        {options.map((item) => (
          <Radio key={item.text} disabled={disabled} value={item.value} className="filter-radio-item">
            {item.text}
          </Radio>
        ))}
      </Radio.Group>
    ),
    [filter, options, disabled, onChangeFilter]
  );

  return (
    <div className="dropdown-selection-radio-filters">
      <Row align="middle" className="section-title">
        {title}
      </Row>
      {tooltip ? (
        <Tooltip placement="topLeft" title={tooltip}>
          {renderRadioGroup}
        </Tooltip>
      ) : (
        renderRadioGroup
      )}
    </div>
  );
};

export default DropdownSelectionRadioFilters;
