import { useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../../api';
import { Exercise } from '../../../types/exercises-types';
import { Resources } from '../../../types/resources-types';
import { useAction } from '../../../hooks/use-actions';
import { EntitiesSubUrls, ResourceEntityParams } from '../../../api/resources-entities-api';
import { getErrorMessage } from '../../../utils/errors';
import { selectDictionary } from '../../../redux/settings/settings-selectors';
import { setResourceRecord } from '../../../redux/resource-record/resource-record-reducer';
import { Workout, WorkoutMovement } from '../../../types/workout-types';
import { RequestError, RequestMethods } from '../../../api/http-client';
import { getDictionaryItemConfig, OptionListItem } from '../../../redux/settings/settings-utils';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../../utils/notifications-utils';
import { ArmUsedEnum, CompletionType, ExerciseType, LimbUsedEnum, MovementHugs, SideUsageType } from '../../../types/common-types';

export type CreateMovementsFormValues = {
  isLiftVod: boolean;
  block_id: string;
  movements: string[];
  duration: number;
  set: number;
  order: number | null;
  hug?: string | null;
  attachment?: string | null;
  limb_used?: LimbUsedEnum | null;
  arm_used?: ArmUsedEnum | null;
  reps?: number;
  completion_type?: CompletionType;
  resistance?: number;
};

export type EditBlockNameParams = {
  id: string;
  name: string;
};

export type CreateTransitionFormValues = {
  block_id: string;
  duration: number;
  order?: number | null;
};

export type ReorderBlocksParams = {
  ids: string[];
};

export type CreateMovementRequestData = {
  completion_type: CompletionType;
  block_id: string;
  exercise_id?: string;
  workout_id: number;
  arm_used: ArmUsedEnum;
  reps?: number;
  resistance?: number;
  order?: number;
  duration?: number;
  notes?: string;
  set?: number;
  hug?: string | null;
  attachment?: string | null;
  limb_used?: LimbUsedEnum | null;
};

export type CreateMovementItem = {
  block_id: string | null;
  workout_id: string;
  arm_used: ArmUsedEnum;
  completion_type: CompletionType;
  set?: number;
  set_end_time?: number;
  duration?: number;
  exercise_id?: string;
  order?: number | null;
};

export enum BlockDrawerType {
  createBlock = 'create_block',
  createMovement = 'create_movement',
  createTransition = 'create_transition',
  editMovement = 'edit_movement',
  editTransition = 'edit_transition'
}

export type BlockDrawerParams = {
  type: BlockDrawerType | null;
  order: number | null;
  blockId: string | null;
  movement: WorkoutMovement | null;
};

type UseWorkoutMovementsCreateBlock = {
  liftAttachmentsList: OptionListItem[];
  blockDrawerParams: BlockDrawerParams;
  onOpenCreateBlock: () => void;
  onOpenCreateMovement: () => void;
  onOpenCreateTransition: () => void;
  onCloseDrawerBlocks: () => void;
  onCreateMovement: (formData: CreateMovementsFormValues) => void;
  onCreateTransition: (formData: CreateTransitionFormValues) => void;
  onCreateMovementsBlock: (exercises: (Exercise & { set: number })[], blockName: string) => void;
  onReorderBlockMovements: (blockId: string, nextBlockMovementsOrder: WorkoutMovement[]) => void;
  onDeleteMovement: (blockId: string, movementId: string) => void;
  onEditMovement: (movement: Partial<WorkoutMovement>) => void;
  onOpenInsertMovementDrawer: (blockId: string, order?: number) => void;
  onOpenInsertTransitionDrawer: (order: number, blockId: string) => void;
  onOpenEditMovementDrawer: (editMovement: WorkoutMovement) => void;
  onOpenEditTransitionDrawer: (editMovement: WorkoutMovement) => void;
  onRemoveBlock: (blockId: string) => void;
  onEditBlockName: (editBlockNameParams: EditBlockNameParams) => void;
  onReorderBlocks: (reorderBlocksParams: ReorderBlocksParams) => void;
};

type UseWorkoutMovementsCreateBlockParams = {
  record: Workout;
};

const initialBlockDrawerParams = {
  type: null,
  order: null,
  blockId: null,
  movement: null
};

const useWorkoutMovements = ({ record }: UseWorkoutMovementsCreateBlockParams): UseWorkoutMovementsCreateBlock => {
  const { id: recordId } = record || {};

  const dictionary = useSelector(selectDictionary);
  const onSetResourceRecord = useAction(setResourceRecord);
  const { optionsList: liftAttachmentsList } = getDictionaryItemConfig(dictionary, 'lift_attachment');

  const [blockDrawerParams, setBlockDrawerParams] = useState<BlockDrawerParams>(initialBlockDrawerParams);

  const onOpenCreateBlock = () => setBlockDrawerParams({ ...initialBlockDrawerParams, type: BlockDrawerType.createBlock });
  const onOpenCreateMovement = (editMovement?: WorkoutMovement) => {
    setBlockDrawerParams({ ...initialBlockDrawerParams, type: BlockDrawerType.createMovement, movement: editMovement || null });
  };
  const onOpenCreateTransition = (editMovement?: WorkoutMovement) => {
    setBlockDrawerParams({ ...initialBlockDrawerParams, type: BlockDrawerType.createTransition, movement: editMovement || null });
  };
  const onOpenInsertMovementDrawer = (blockId: string, order?: number) => {
    setBlockDrawerParams({ type: BlockDrawerType.createMovement, order: order || null, blockId, movement: null });
  };
  const onOpenInsertTransitionDrawer = (order: number, blockId: string) => {
    setBlockDrawerParams({ type: BlockDrawerType.createTransition, order, blockId, movement: null });
  };
  const onOpenEditMovementDrawer = (editMovement: WorkoutMovement) => {
    setBlockDrawerParams({ type: BlockDrawerType.editMovement, movement: editMovement, order: null, blockId: null });
  };
  const onOpenEditTransitionDrawer = (editMovement: WorkoutMovement) => {
    setBlockDrawerParams({ type: BlockDrawerType.editTransition, movement: editMovement, order: null, blockId: null });
  };

  const onCloseDrawerBlocks = () => setBlockDrawerParams(initialBlockDrawerParams);

  const getMovementsFromExercises = (
    exercises: (Exercise & { set: number; completion_type?: CompletionType; duration?: number; reps?: number })[],
    selectedBlockId?: string | null
  ) => {
    const blockId = selectedBlockId || null;

    const exercisesMovements = exercises.reduce((acc: CreateMovementItem[], item) => {
      const { id: exercise_id, set, type, completion_type = CompletionType.time, duration, reps } = item;
      const isLiftExercise = type === ExerciseType.LIFT;

      const exerciseAttachments = [item?.attachment, ...(item?.alternative_attachments ?? [])].filter(Boolean);
      const attachmentsList = liftAttachmentsList.filter(({ value }) => exerciseAttachments.includes(value));

      const sets = Array.from({ length: set }, (_, index) => index + 1);
      const movements = sets.reduce((res: CreateMovementItem[], setIndex) => {
        const studioMovementData = {
          duration,
          reps,
          exercise_id,
          set: setIndex,
          completion_type,
          block_id: blockId,
          workout_id: recordId,
          arm_used: ArmUsedEnum.none
        };

        const liftVodMovementData = {
          hug: MovementHugs.a,
          attachment: attachmentsList.length === 1 ? attachmentsList[0].value : null,
          limb_used: item?.side_usage_type === SideUsageType.bilateral ? LimbUsedEnum.both : LimbUsedEnum.none,
          arm_used: item.number_arms_used === 2 ? ArmUsedEnum.both : ArmUsedEnum.none
        };

        const movement = isLiftExercise ? { ...studioMovementData, ...liftVodMovementData } : studioMovementData;
        // as of 07/27/23 it has been not to add default transition between movements in a block
        // const transition = {
        //   set: setIndex,
        //   block_id: blockId,
        //   workout_id: recordId,
        //   duration: 0,
        //   arm_used: ArmUsedEnum.none,
        //   completion_type: CompletionType.transition
        // };
        // return [...res, movement, transition];
        return [...res, movement];
      }, []);

      return [...acc, ...movements];
    }, []);

    return exercisesMovements.sort((a, b) => {
      const aSet = a.set as number;
      const bSet = b.set as number;
      if (aSet < bSet) return -1;
      if (aSet > bSet) return 1;
      return 0;
    });
  };

  const createMovementRequest = (requestData: CreateMovementRequestData) => {
    const isTransition = requestData.completion_type === CompletionType.transition;
    const notification = `Workout ${isTransition ? 'transition' : 'movement'} was successfully added to block`;

    api.resourcesEntities
      .createResourceEntity({
        resource: Resources.workouts,
        recordId: recordId,
        entitySubUrl: EntitiesSubUrls.movements,
        data: requestData
      })
      .then((response: Workout) => {
        onSetResourceRecord(response);
        onCloseDrawerBlocks();
        onDisplaySuccessNotification(notification);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onCreateMovementsBlock = (exercises: (Exercise & { set: number; completion_type?: CompletionType })[], blockName: string) => {
    if (!recordId) return;

    const requestParams = {
      recordId: recordId,
      resource: Resources.workouts,
      entitySubUrl: EntitiesSubUrls.blocks,
      data: { name: blockName, movements: getMovementsFromExercises(exercises) }
    };

    api.resourcesEntities
      .createResourceEntity(requestParams)
      .then((updatedRecord: Workout) => {
        onSetResourceRecord(updatedRecord);
        onCloseDrawerBlocks();
        onDisplaySuccessNotification('Movements block was successfully created!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onCreateMovement = (formData: CreateMovementsFormValues) => {
    const {
      isLiftVod,
      set,
      movements,
      block_id,
      duration,
      order = null,
      hug = null,
      attachment,
      limb_used,
      arm_used,
      reps = 0,
      completion_type,
      resistance = 0
    } = formData;
    const [exercise_id] = movements;

    const completionType = completion_type ? { completion_type } : {};

    const studioData = {
      set,
      exercise_id,
      order: order ?? undefined,
      block_id,
      workout_id: Number(recordId),
      duration,
      completion_type: CompletionType.time,
      arm_used: arm_used || ArmUsedEnum.none,
      resistance,
      ...completionType
    };

    const liftVodData = {
      hug,
      attachment,
      limb_used,
      reps,
      ...completionType
    };

    createMovementRequest(isLiftVod ? { ...studioData, ...liftVodData } : studioData);
  };

  const onCreateTransition = (formData: CreateTransitionFormValues) => {
    const { block_id, duration, order = null } = formData;

    const block = record.blocks.find(({ id }) => `${id}` === `${block_id}`);
    if (!block) return;

    const transitionOrder = !block.movements.length ? 1 : block.movements[block.movements.length - 1].order + 1;

    createMovementRequest({
      block_id,
      workout_id: Number(recordId),
      arm_used: ArmUsedEnum.none,
      duration,
      completion_type: CompletionType.transition,
      order: order !== null ? order : transitionOrder
    });
  };

  const onReorderBlockMovements = (blockId: string, nextBlockMovementsOrder: WorkoutMovement[]) => {
    if (!record) return;

    const nextMovementsOrderIds = nextBlockMovementsOrder.map(({ id }) => id);
    const nextBlocks = record.blocks.map((block) => (block.id !== blockId ? block : { ...block, movements: nextBlockMovementsOrder }));

    onSetResourceRecord({ ...record, blocks: nextBlocks });

    const requestParams = {
      recordId: recordId,
      resource: Resources.workouts,
      entitySubUrl: EntitiesSubUrls.movementsOrder,
      data: { block_id: blockId, ids: nextMovementsOrderIds }
    };

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Workout) => {
        onSetResourceRecord(updatedRecord);
        onDisplaySuccessNotification('Movements was successfully sorted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const getEditMovementRequestData = (editedMovement: Partial<WorkoutMovement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: editedMovementId, ...editedFields } = editedMovement;

    if (!editedMovement.block_id) return editedFields;

    const movementBlock = record.blocks.find(({ id }) => id === editedMovement.block_id);
    const blockMovements = movementBlock?.movements ?? [];

    if (!movementBlock || !blockMovements.length) return editedFields;

    const lastMovementOrder = blockMovements[blockMovements.length - 1].order;
    return { ...editedFields, order: lastMovementOrder + 1 };
  };

  const onEditMovement = (editedMovement: Partial<WorkoutMovement>) => {
    if (!record || !editedMovement.id) return;

    const requestData = getEditMovementRequestData(editedMovement);

    const requestParams = {
      recordId: recordId,
      resource: Resources.workouts,
      method: RequestMethods.patch,
      entitySubUrl: `${EntitiesSubUrls.movements}/${editedMovement.id}`,
      data: requestData
    };

    onSetResourceRecord({
      ...record,
      blocks: record.blocks.map((block) => {
        const blockMovementsIds = block.movements.map((item) => item.id);
        const isTargetBlock = blockMovementsIds.includes(editedMovement.id!);
        if (!isTargetBlock) return block;
        return {
          ...block,
          movements: block.movements.map((item) => {
            if (item.id !== editedMovement.id) return item;
            return { ...item, ...requestData };
          })
        };
      })
    });

    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Workout) => {
        onSetResourceRecord(updatedRecord);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onDeleteMovement = (blockId: string, movementId: string) => {
    if (!record) return;

    const requestParams = {
      recordId,
      resource: Resources.workouts,
      entitySubUrl: `${EntitiesSubUrls.movements}/${movementId}`
    };

    api.resourcesEntities
      .removeResourceEntity(requestParams)
      .then((updatedRecord: Workout) => {
        onSetResourceRecord(updatedRecord);
        onDisplaySuccessNotification('Movement was successfully deleted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onRemoveBlock = (blockId: string) => {
    const block = (record?.blocks ?? []).find(({ id }) => `${id}` === `${blockId}`);
    if (!block || !!block.movements.length) return;

    const requestParams = {
      recordId: recordId,
      resource: Resources.workouts,
      entitySubUrl: `${EntitiesSubUrls.blocks}/${blockId}?force=false`
    };

    api.resourcesEntities
      .removeResourceEntity(requestParams)
      .then(() => {
        onSetResourceRecord({ ...record, blocks: record.blocks.filter(({ id }) => `${id}` !== `${blockId}`) });
        onCloseDrawerBlocks();
        onDisplaySuccessNotification('Movements block was successfully deleted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onUpdatedBlocks = (requestParams: ResourceEntityParams, message: string) => {
    api.resourcesEntities
      .updateResourceEntity(requestParams)
      .then((updatedRecord: Workout) => {
        onSetResourceRecord(updatedRecord);
        onDisplaySuccessNotification(message);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onEditBlockName = ({ id, name }: EditBlockNameParams) => {
    if (!recordId) return;

    onUpdatedBlocks(
      {
        recordId: recordId,
        method: RequestMethods.patch,
        resource: Resources.workouts,
        entitySubUrl: `${EntitiesSubUrls.blocks}/${id}`,
        data: { name }
      },
      'Movements block was successfully updated!'
    );
  };

  const onReorderBlocks = ({ ids }: ReorderBlocksParams) => {
    if (!recordId) return;

    onUpdatedBlocks(
      {
        recordId: recordId,
        resource: Resources.workouts,
        entitySubUrl: EntitiesSubUrls.collapsedBlocksOrder,
        data: { ids }
      },
      'Movements blocks was successfully reordered!'
    );
  };

  return {
    liftAttachmentsList,
    blockDrawerParams,
    onOpenCreateBlock,
    onOpenCreateMovement,
    onOpenCreateTransition,
    onCloseDrawerBlocks,
    onCreateMovementsBlock,
    onCreateMovement,
    onCreateTransition,
    onReorderBlockMovements,
    onEditMovement,
    onDeleteMovement,
    onOpenInsertMovementDrawer,
    onOpenInsertTransitionDrawer,
    onOpenEditMovementDrawer,
    onOpenEditTransitionDrawer,
    onRemoveBlock,
    onEditBlockName,
    onReorderBlocks
  };
};

export default useWorkoutMovements;
