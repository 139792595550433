import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Input, Row, Typography } from 'antd';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';

import { Exercise } from '../../types/exercises-types';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { ExerciseType } from '../../types/common-types';
import { useFormValues } from '../../hooks/use-form-values';
import { useItemSelector } from '../../hooks/use-selector';
import { getErrorMessage } from '../../utils/errors';
import { usePreviousValue } from '../../hooks/use-previous-value';
import { getFormattedDate } from '../../utils/date-utils';
import { VideoAssetElement } from '../../types/studio-assets';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { getDictionaryItemConfig } from '../../redux/settings/settings-utils';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { UpdateParams } from '../../types/request-entities-types';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import { selectDictionary, selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';
import {
  armsHeightsList,
  exerciseComplexityList,
  sideUsageTypeList,
  movementPatternsList,
  muscleGroupsList,
  planeOfMotionList,
  positionsList,
  primeMoverList,
  workoutEquipmentList
} from '../../utils/resources-dictionary-utils';
import { stringValidator } from '../../validators/string-validator';

import StubPage from '../../components/stub-page/stub-page';
import FormItemSelection from '../../components/form-components/form-item-selection';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import FormItemVideo from '../../components/form-components/form-item-video';
import CheckTagButton from '../../components/buttons/check-tag-button';
import MinusTagButton from '../../components/buttons/minus-tag-button';

const ExercisesRecordPage = () => {
  const dictionary = useSelector(selectDictionary);
  const timeZone = useSelector(selectUserTimeZone);
  const record = useItemSelector({ resource: Resources.exercises }, getPageResourceRecord) as Exercise | null;
  const prevRecord = usePreviousValue(record);
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const { secondSubRoute: recordId } = useLocationHandler();
  const onSetExerciseRecord = useAction(setResourceRecord);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { optionsList: planExcerciseAttachmentsList } = getDictionaryItemConfig(dictionary, 'lift_attachment', false);

  useResourceRecordData({ recordId, resource: Resources.exercises });

  const initialData = useMemo(
    () => ({
      ...record,
      equipment: record?.equipment === null ? 'N/A' : record?.equipment,
      created_date: record?.created_date ? moment(record.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });
  const [studioAssetVideo, setStudioAssetVideo] = useState<VideoAssetElement | undefined | null>();
  const [isLift, setIsLift] = useState(false);

  const isStudioAssetVideoChanged = `${studioAssetVideo?.id ?? ''}` !== `${record?.intro_video?.id ?? ''}`;
  const isFormChanged = isFormValuesChanged || isStudioAssetVideoChanged;

  useEffect(() => {
    if (record) {
      setIsLift(record.type === ExerciseType.LIFT);
    }
  }, [record, prevRecord]);

  useEffect(() => {
    if (record?.intro_video) setStudioAssetVideo(record.intro_video);
  }, [record?.intro_video]);

  const nextFormValues = useMemo(() => {
    const formValues = { ...changedFormValues };

    ['muscle_group_1', 'muscle_group_2', 'muscle_group_3', 'secondary_plane_of_motion', 'attachment', 'equipment', 'height_scheme'].forEach((key) => {
      if (changedFormValues.hasOwnProperty(key) && (!changedFormValues[key] || changedFormValues[key] === 'N/A')) {
        formValues[key] = null;
      }
    });

    if (isStudioAssetVideoChanged) {
      formValues.intro_video_id = studioAssetVideo?.id ?? null;
    }

    return formValues;
  }, [changedFormValues, isStudioAssetVideoChanged, studioAssetVideo]);

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
    if (record?.intro_video) setStudioAssetVideo(record.intro_video);
  };

  const onFinish = () => {
    const params = { id: recordId, data: { id: recordId, ...nextFormValues }, previousData: { id: recordId } } as UpdateParams;

    api.common
      .update(Resources.exercises, params)
      .then((updatedExercise: Exercise) => {
        onSetExerciseRecord(updatedExercise);
        onDisplaySuccessNotification('Exercise record was successfully updated');
        onReset();
        navigate(`/${Resources.exercises}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.exercises} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Exercises
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item label="Created date">{getFormattedDate(record.created_date, timeZone)}</Form.Item>
        <Form.Item label="Updated date">{getFormattedDate(record.updated_date, timeZone)}</Form.Item>
        <Form.Item label="Exercise type">{record.type}</Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="complexity" label="Complexity">
          <FormItemSelection withDeleteBtn options={exerciseComplexityList} />
        </Form.Item>
        <Form.Item name="number_arms_used" label="Number cables used" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection
            options={[
              { value: 1, text: 1 },
              { value: 2, text: 2 }
            ]}
          />
        </Form.Item>
        <Form.Item name="side_usage_type" label="Side Usage Type" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection options={sideUsageTypeList} />
        </Form.Item>
        <Form.Item name="position" label="Position" rules={[{ required: true }]}>
          <FormItemSelection options={positionsList} />
        </Form.Item>
        <Form.Item name="movement_pattern" label="Movement pattern" rules={[{ required: true }]}>
          <FormItemSelection options={movementPatternsList} />
        </Form.Item>
        <Form.Item name="secondary_movement_pattern" label="Secondary movement pattern">
          <FormItemSelection options={movementPatternsList} />
        </Form.Item>
        <Form.Item name="prime_mover_1" label="Prime mover 1">
          <FormItemSelection options={primeMoverList} />
        </Form.Item>
        <Form.Item name="prime_mover_2" label="Prime mover 2">
          <FormItemSelection options={primeMoverList} />
        </Form.Item>
        <Form.Item name="prime_mover_3" label="Prime mover 3">
          <FormItemSelection options={primeMoverList} />
        </Form.Item>
        <Form.Item name="height_scheme" label="Height scheme" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection options={[...armsHeightsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="muscle_group_1" label="Muscle group 1" hidden={!isLift}>
          <FormItemSelection options={[...muscleGroupsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="muscle_group_2" label="Muscle group 2" hidden={!isLift}>
          <FormItemSelection options={[...muscleGroupsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="muscle_group_3" label="Muscle group 3" hidden={!isLift}>
          <FormItemSelection options={[...muscleGroupsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="plane_of_motion" label="Plane of motion" rules={[{ required: true }]}>
          <FormItemSelection options={planeOfMotionList} />
        </Form.Item>
        <Form.Item name="secondary_plane_of_motion" label="Secondary plane of motion">
          <FormItemSelection options={[...planeOfMotionList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="attachment" label="Attachment" rules={[{ required: isLift }]} hidden={!isLift}>
          <FormItemSelection options={[...planExcerciseAttachmentsList, { text: 'N/A', value: '' }]} />
        </Form.Item>
        <Form.Item name="alternative_attachments" label="Alternative Attachments" hidden={!isLift}>
          <FormItemSelection options={planExcerciseAttachmentsList} isMultiple />
        </Form.Item>
        <Form.Item name="equipment" label="Equipment" rules={[{ required: !isLift }]} hidden={isLift}>
          <FormItemSelection options={isLift ? workoutEquipmentList : [...workoutEquipmentList, { text: 'N/A', value: 'N/A' }]} />
        </Form.Item>
        <Form.Item name="is_public" label="Is public">
          {record.is_public ? <CheckTagButton /> : <MinusTagButton />}
        </Form.Item>
        <Form.Item name="trainer_created" label="Trainer created">
          {!record.is_public && record.owner ? <CheckTagButton /> : <MinusTagButton />}
        </Form.Item>
        {record?.owner && (
          <Form.Item name="created_by" label="Created by">
            {`${record.owner?.first_name} ${record.owner?.last_name}`}
          </Form.Item>
        )}
        <Form.Item label="Video name">{studioAssetVideo?.name}</Form.Item>
        <Form.Item label="Video">
          <FormItemVideo withRemove value={studioAssetVideo as UseVideoSourceSelectionParams} onChangeVideoData={setStudioAssetVideo} />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Row align="middle" justify="space-between">
            <div>
              <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormChanged} onClick={onReset}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit" disabled={!isFormChanged}>
                Save
              </Button>
            </div>
          </Row>
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default ExercisesRecordPage;
