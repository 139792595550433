import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Resources } from '../types/resources-types';
import {
  INCORRECT_URL_ERROR,
  LIMITED_PERMISSIONS_MAIN_MESSAGE,
  LIMITED_PERMISSIONS_SECONDARY_MESSAGE,
  PAGE_NOT_FOUND_MESSAGE,
  SELECT_RESOURCE_MESSAGE,
  WELCOME_MESSAGE
} from '../constants/messages';

import Login from '../pages/login';
import StubPage from '../components/stub-page/stub-page';
import ImagesListPage from '../pages/images/images-list-page';
import ImageRecordPage from '../pages/images/image-record-page';
import WorkoutsListPage from '../pages/workouts/workouts-list-page';
import WorkoutRecordPage from '../pages/workouts/workout-record-page';
import PromotionTilesListPage from '../pages/promotion-tiles/promotion-tiles-list-page';
import PromotionTileRecordPage from '../pages/promotion-tiles/promotion-tile-record-page';
import ContentPlanRecordPage from '../pages/content-plans/content-plan-record-page';
import ContentPlansListPage from '../pages/content-plans/content-plans-list-page';
import VideosListPage from '../pages/videos/videos-list-page';
import VideoRecordPage from '../pages/videos/video-record-page';
import StudioAssetsListPage from '../pages/studio-assets/studio-assets-list-page';
import StudioAssetsRecordPage from '../pages/studio-assets/studio-assets-record-page';
import ProgramsListPage from '../pages/programs/programs-list-page';
import ProgramRecordPage from '../pages/programs/program-record-page';
import InstructorsListPage from '../pages/instructors/instructors-list-page';
import InstructorRecordPage from '../pages/instructors/instructor-record-page';
import TranscodingJobsListPage from '../pages/transcoding-jobs/transcoding-jobs-list-page';
import TranscodingJobsRecordPage from '../pages/transcoding-jobs/transcoding-jobs-record-page';
import ExercisesListPage from '../pages/exercises/exercises-list-page';
import ExercisesRecordPage from '../pages/exercises/exercises-record-page';
import WorkoutFilterRecordPage from '../pages/workout-filters/workout-filter-record-page';
import WorkoutMovementPage from '../pages/workout-movements/workout-movement-page';
import WorkoutFilterListPage from '../pages/workout-filters/workout-filter-list-page';
import StudioWidgetsListPage from '../pages/studio-widgets/studio-widgets-list-page';
import StudioWidgetsRecordPage from '../pages/studio-widgets/studio-widgets-record-page';
import StudioConfigsListPage from '../pages/studio-configs/studio-configs-list-page';
import StudioConfigsRecordPage from '../pages/studio-configs/studio-configs-record-page';
import WorkoutVideoEditorPage from '../pages/workout-video-editor-page/workout-video-editor-page';
import SurveyListPage from '../pages/surveys/surveys-list-page';
import SurveyRecordPage from '../pages/surveys/survey-record-page';
import SurveyStepsPage from '../pages/survey-step-page/survey-steps-page';

const Router = () => (
  <Routes>
    <Route path="/" element={<StubPage mainMessage={WELCOME_MESSAGE} secondaryMessage={SELECT_RESOURCE_MESSAGE} />} />
    <Route
      path="/restricted"
      element={<StubPage mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE} secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE} />}
    />
    <Route path="/login" element={<Login />} />
    <Route path={`/${Resources.promotionTiles}`} element={<PromotionTilesListPage />} />
    <Route path={`/${Resources.promotionTiles}/:promotionTileId`} element={<PromotionTileRecordPage />} />
    <Route path={`/${Resources.images}`} element={<ImagesListPage />} />
    <Route path={`/${Resources.images}/:imageId`} element={<ImageRecordPage />} />
    <Route path={`/${Resources.workouts}`} element={<WorkoutsListPage />} />
    <Route path={`/${Resources.workouts}/:workoutId`} element={<WorkoutRecordPage />} />
    <Route path={`/${Resources.workouts}/:workoutId/movements`} element={<WorkoutMovementPage />} />
    <Route path={`/${Resources.workouts}/:workoutId/video-editor`} element={<WorkoutVideoEditorPage />} />
    <Route path={`/${Resources.workoutFilters}`} element={<WorkoutFilterListPage />} />
    <Route path={`/${Resources.workoutFilters}/:workoutFilterId`} element={<WorkoutFilterRecordPage />} />
    <Route path={`/${Resources.contentPlans}`} element={<ContentPlansListPage />} />
    <Route path={`/${Resources.contentPlans}/:contentPlanId`} element={<ContentPlanRecordPage />} />
    <Route path={`/${Resources.videos}`} element={<VideosListPage />} />
    <Route path={`/${Resources.videos}/:videoId`} element={<VideoRecordPage />} />
    <Route path={`/${Resources.studioAssets}`} element={<StudioAssetsListPage />} />
    <Route path={`/${Resources.studioAssets}/:studioAssetsId`} element={<StudioAssetsRecordPage />} />
    <Route path={`/${Resources.studioWidgets}`} element={<StudioWidgetsListPage />} />
    <Route path={`/${Resources.studioWidgets}/:studioWidgetId`} element={<StudioWidgetsRecordPage />} />
    <Route path={`/${Resources.studioConfigs}`} element={<StudioConfigsListPage />} />
    <Route path={`/${Resources.studioConfigs}/:studioConfigId`} element={<StudioConfigsRecordPage />} />
    <Route path={`/${Resources.programs}`} element={<ProgramsListPage />} />
    <Route path={`/${Resources.programs}/:programId`} element={<ProgramRecordPage />} />
    <Route path={`/${Resources.instructors}`} element={<InstructorsListPage />} />
    <Route path={`/${Resources.instructors}/:instructorId`} element={<InstructorRecordPage />} />
    <Route path={`/${Resources.transcodingJobs}`} element={<TranscodingJobsListPage />} />
    <Route path={`/${Resources.transcodingJobs}/:transcodingJobId`} element={<TranscodingJobsRecordPage />} />
    <Route path={`/${Resources.exercises}`} element={<ExercisesListPage />} />
    <Route path={`/${Resources.exercises}/:exerciseId`} element={<ExercisesRecordPage />} />
    <Route path={`/${Resources.surveys}`} element={<SurveyListPage />} />
    <Route path={`/${Resources.surveys}/:surveyId`} element={<SurveyRecordPage />} />
    <Route path={`/${Resources.surveys}/:surveyId/steps`} element={<SurveyStepsPage />} />
    <Route path="/*" element={<StubPage mainMessage={PAGE_NOT_FOUND_MESSAGE} secondaryMessage={INCORRECT_URL_ERROR} />} />
  </Routes>
);

export default Router;
