import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../boot/store';
import { Resources } from '../../types/resources-types';
import { PermissionsState } from './permissions-reducer';
import { ResourcesPermissionsMap } from '../../utils/permission-utils';

export const selectPermissionsState = (state: RootState): PermissionsState => state.permissions;

export const selectIsAuthenticationLoading = (state: RootState): boolean => selectPermissionsState(state).isLoading;
export const selectIsAuthenticated = (state: RootState): boolean => selectPermissionsState(state).isAuthenticated;
export const selectPermissionsMap = (state: RootState): ResourcesPermissionsMap => selectPermissionsState(state).permissionsMap;

export const getResourcePermissions = createSelector(
  selectPermissionsMap,
  (state: RootState, { resource }: { resource: Resources }) => resource,
  (permissionsMap, resource) => {
    const { isReadAllowed, isUpdateAllowed, isCreateAllowed, isDeleteAllowed } = permissionsMap[resource] || {};
    return { isReadAllowed, isUpdateAllowed, isCreateAllowed, isDeleteAllowed };
  }
);
