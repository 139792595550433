export const stableSort = <T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getValueByPath = <T>(item: T, orderBy: string) => {
  const fields = orderBy.split('.');
  return fields.reduce((acc: any, field) => acc && acc[field], item);
};

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (getValueByPath(b, orderBy as string) < getValueByPath(a, orderBy as string)) return -1;
  if (getValueByPath(b, orderBy as string) > getValueByPath(a, orderBy as string)) return 1;
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: string,
  orderBy: Key
): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
