import { useState } from 'react';
import { getDifferenceFields } from '../utils/common-utils';

export type FormValues = Record<string, any>;

type UseFormValuesProps = {
  initialData: FormValues;
};

type UseFormValues = {
  changedFormValues: FormValues;
  isFormValuesChanged: boolean;
  onValuesChange: (changedValues: FormValues) => void;
  onClearChangedFormValues: () => void;
};

export const useFormValues = (props: UseFormValuesProps): UseFormValues => {
  const { initialData } = props;

  const [changedFormValues, setChangedFormValues] = useState<FormValues>({});
  const isFormValuesChanged = !!Object.keys(changedFormValues).length;

  const onValuesChange = (changedValues: FormValues) => {
    setChangedFormValues(getDifferenceFields(initialData, { ...changedFormValues, ...changedValues }));
  };

  const onClearChangedFormValues = () => setChangedFormValues({});

  return {
    changedFormValues,
    isFormValuesChanged,
    onValuesChange,
    onClearChangedFormValues
  };
};
