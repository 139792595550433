import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout, Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FileImageOutlined, LaptopOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import { Resources } from '../../types/resources-types';
import { getSubroutes } from '../../utils/router-utils';
import { clearAuthData } from '../../utils/local-storage-utils';
import { ResourceConfig } from '../../constants/resources-constants';
import { CMS_APP_VERSION } from '../../config';
import { selectApiVersion, selectIsSideMenuCollapsed } from '../../redux/settings/settings-selectors';
import { selectIsAuthenticated, selectIsAuthenticationLoading, selectPermissionsMap } from '../../redux/permissions/permissions-selectors';
import './base-layout.scss';

type SideSection = {
  sectionTitle: string;
  sectionIcon: React.ReactElement;
  sectionResources: Resources[];
};

const SIDE_MENU_CONFIG: SideSection[] = [
  {
    sectionTitle: 'Studio',
    sectionIcon: <LaptopOutlined />,
    sectionResources: [Resources.promotionTiles, Resources.studioAssets, Resources.studioWidgets, Resources.studioConfigs]
  },
  {
    sectionTitle: 'Assets',
    sectionIcon: <FileImageOutlined />,
    sectionResources: [Resources.images, Resources.videos, Resources.transcodingJobs]
  },
  {
    sectionTitle: 'Content',
    sectionIcon: <UserOutlined />,
    sectionResources: [
      Resources.workouts,
      Resources.workoutFilters,
      Resources.contentPlans,
      Resources.programs,
      Resources.instructors,
      Resources.exercises,
      Resources.surveys
    ]
  }
];

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  const isLoading = useSelector(selectIsAuthenticationLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const resourcesPermissionsMap = useSelector(selectPermissionsMap);
  const isMenuCollapsed = useSelector(selectIsSideMenuCollapsed);

  const apiVersion = useSelector(selectApiVersion);
  const { main: activeResource } = getSubroutes(location);

  const [selectedKey, setSelectedKey] = useState<string>('');
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const allowedResource = Object.values(resourcesPermissionsMap).find((resourceConfig) => resourceConfig.isReadAllowed);
  const allowedResourceRoute = allowedResource?.route || '';
  const currentPath = location?.pathname;

  useEffect(() => {
    const isBadPath = !currentPath || currentPath === '/';

    if (!isLoading) {
      if (!isAuthenticated) navigate('/login');
      if (isAuthenticated && isBadPath) navigate(allowedResourceRoute);
    }
  }, [isAuthenticated, isLoading, navigate, allowedResourceRoute, currentPath]);

  const sideMenuConfig = SIDE_MENU_CONFIG.map((section) => {
    const sectionItems = section.sectionResources.reduce((acc, item) => {
      const itemConfig = resourcesPermissionsMap[item];
      return itemConfig ? [...acc, itemConfig] : acc;
    }, [] as ResourceConfig[]);

    return { ...section, sectionItems };
  });

  const submenuKeys = sideMenuConfig.map((item) => item.sectionTitle);
  const getActiveSection = sideMenuConfig.find((section) => section.sectionResources.includes(activeResource as Resources));
  const defaultSelectedKey = activeResource || '';
  const defaultOpenKey = getActiveSection?.sectionTitle ?? '';

  useEffect(() => {
    setOpenKeys((prevOpenKeys) => [...prevOpenKeys, defaultOpenKey]);
  }, [defaultOpenKey]);

  useEffect(() => {
    if (selectedKey !== defaultSelectedKey) {
      setSelectedKey(defaultSelectedKey);
    }
  }, [selectedKey, defaultSelectedKey]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1) || '';
    if (submenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
    clearAuthData();
  };

  const configurableMenuItems = sideMenuConfig.map((section) => {
    const { sectionTitle, sectionIcon, sectionItems } = section;
    return {
      key: sectionTitle,
      icon: sectionIcon,
      label: sectionTitle,
      children: sectionItems.map((sectionItem) => {
        const { key, title, route } = sectionItem;
        return { key: key, label: <Link to={route}>{title}</Link> };
      })
    };
  });

  const logoutMenuItem = {
    key: 'logout',
    label: 'Logout',
    icon: <LogoutOutlined />,
    onClick: onLogout
  };

  const menuItems = [...configurableMenuItems, logoutMenuItem];

  return (
    <Layout.Sider width={250} trigger={null} collapsible collapsed={isMenuCollapsed}>
      <Menu
        mode="inline"
        theme="dark"
        style={{ height: 'calc(100% - 150px)' }}
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        items={menuItems}
        onOpenChange={onOpenChange}
      />
      {!isMenuCollapsed && (
        <div className="side-menu-footer">
          <span className="side-menu-footer-title">Versions</span>
          <div>
            <span className="side-menu-footer-subtitle">CMS: </span>
            {CMS_APP_VERSION}
          </div>
          <div>
            <span className="side-menu-footer-subtitle">API: </span>
            {apiVersion}
          </div>
        </div>
      )}
    </Layout.Sider>
  );
};

export default SideMenu;
