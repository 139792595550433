export const NOOP = (): void => {
  //
};

export const defaultColorsList = [
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'yellow',
  'brown',
  'gray',
  'seagreen',
  'violet',
  'silver',
  'bronze',
  'black'
];
