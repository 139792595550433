import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { DeleteOutlined, CreditCardOutlined } from '@ant-design/icons';
import cs from 'classnames';

import api from '../../api';
import { NOOP } from '../../constants/common-constants';
import { Resources } from '../../types/resources-types';
import { useItemSelector } from '../../hooks/use-selector';
import { selectIsAuthenticationLoading, getResourcePermissions } from '../../redux/permissions/permissions-selectors';
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from '../../constants/messages';
import Loader from '../../components/loader';
import StubPage from '../../components/stub-page/stub-page';
import './resource-record-container.scss';

type ResourceRecordContainerProps = {
  innerRef?: (element: HTMLElement | null) => void;
  record: Record<string, any> | null;
  resource: Resources;
  children: React.ReactNode;
  isLoading: boolean;
  withToolbar?: boolean;
  withCreate?: boolean;
  withDelete?: boolean;
  className?: string;
  onCreate?: () => void;
};

function ResourceRecordContainer(props: ResourceRecordContainerProps) {
  const { innerRef = NOOP, record, resource, children, withCreate, withDelete, className, onCreate = NOOP } = props;

  const { isReadAllowed } = useItemSelector({ resource }, getResourcePermissions);
  const isLoading = useSelector(selectIsAuthenticationLoading);

  const withActions = withDelete || withCreate;

  const onDelete = () => {
    const recordId = record?.id;
    if (recordId) api.common.delete(resource, { id: recordId });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isReadAllowed) {
    return <StubPage mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE} secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE} />;
  }

  if (!record) {
    return null;
  }

  return (
    <div ref={innerRef} id="page-container" className={cs('page-container', className)}>
      {withActions && (
        <div className="actions">
          {withDelete && (
            <Button icon={<DeleteOutlined />} onClick={onDelete}>
              Delete
            </Button>
          )}
          {withCreate && (
            <Button icon={<CreditCardOutlined />} onClick={onCreate}>
              Create
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
}

export default ResourceRecordContainer;
