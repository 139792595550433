import { RuleObject, ValidatorRule } from 'rc-field-form/lib/interface';
import { stringHasTheWhiteSpaceOrNot } from '../utils/common-utils';

const URL_PROTOCOLS = ['http:', 'https:'];

export const urlValidator: ValidatorRule['validator'] = (rule: RuleObject & { field?: string }, value = '') => {
  let url;

  const { field, required: isRequired } = rule;
  const fieldLabel = field ? `Field '${field}'` : 'Field';
  const requiredMessage = `${fieldLabel} is required`;
  const invalidUrlMessage = `${fieldLabel} should be a valid URL link`;
  const hasSpacesMessage = "Valid URL shouldn't contain spaces at the beginning or at the end";
  const hasEmptySpaces = stringHasTheWhiteSpaceOrNot(value);

  if (!value) return isRequired ? Promise.reject(requiredMessage) : Promise.resolve();
  if (hasEmptySpaces) return Promise.reject(hasSpacesMessage);

  try {
    url = new URL(value);
  } catch (_) {
    return Promise.reject(invalidUrlMessage);
  }

  if (URL_PROTOCOLS.includes(url.protocol)) return Promise.resolve();
  return Promise.reject(invalidUrlMessage);
};
