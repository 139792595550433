import { useSelector } from 'react-redux';
import { Workout } from '../../../types/workout-types';
import { Resources } from '../../../types/resources-types';
import { useItemSelector } from '../../../hooks/use-selector';
import { useResourceRecordData } from '../../../hooks/use-resource-record-data';
import { getPageResourceRecord, selectIsResourceRecordLoading } from '../../../redux/resource-record/resource-record-selectors';
import useLocationHandler from '../../../hooks/use-location-handler';

type UseWorkoutMovement = {
  record: Workout;
  isLoading: boolean;
  blocksOptionsList: { value: string; text: string }[];
};

const useWorkoutMovementsData = (): UseWorkoutMovement => {
  const record = useItemSelector({ resource: Resources.workouts }, getPageResourceRecord) as Workout;
  const isLoading = useSelector(selectIsResourceRecordLoading);

  const { secondSubRoute: recordId } = useLocationHandler();
  const blocksOptionsList = (record?.blocks ?? []).map((block) => ({ value: `${block.id}`, text: block.name }));

  useResourceRecordData({ recordId, resource: Resources.workouts });

  return { record, isLoading, blocksOptionsList };
};

export default useWorkoutMovementsData;
