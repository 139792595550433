import { useMemo } from 'react';

import { CondOperator } from '../../types/cond-operators';
import { Filter, FilterOperators, FilterType, FilterTypes } from '../../hooks/use-resource-list-table-filters';
import { HasWorkoutImageTypes, HasWorkoutVideoTypes } from './workout-list-assets-filters';

type UseAssetsFiltersParams = {
  filters: Filter;
  filtersOperators: FilterOperators;
  filterTypes: FilterTypes;
  onSetFilters: (nextFilters: Filter) => void;
};

const useAssetsFilters = (params: UseAssetsFiltersParams) => {
  const { filters, filtersOperators, filterTypes, onSetFilters } = params;

  const introVideoFilter = useMemo(() => {
    const activeIntroVideoFilter = filters[filterTypes.intro_video_id];
    return Array.isArray(activeIntroVideoFilter) ? activeIntroVideoFilter : [activeIntroVideoFilter];
  }, [filters, filterTypes.intro_video_id]);

  const imageFilter = useMemo(() => {
    const activeImageFilter = filters[filterTypes['image.id']];
    return Array.isArray(activeImageFilter) ? activeImageFilter : [activeImageFilter];
  }, [filters, filterTypes]);

  const introVideoFilterOption = useMemo(() => {
    const operator = filtersOperators[filterTypes.intro_video_id];
    const withVideo = introVideoFilter[0] === HasWorkoutVideoTypes.withVideo;
    const withoutVideo = introVideoFilter[0] === HasWorkoutVideoTypes.withoutVideo;
    const isGreaterThan = operator === CondOperator.GREATER_THAN;
    const isLowerThenOrNull = operator === CondOperator.LOWER_THAN_OR_NULL;

    if (withoutVideo || (isLowerThenOrNull && `${introVideoFilter[0]}` === '0')) return [HasWorkoutVideoTypes.withoutVideo];
    if (withVideo || (isGreaterThan && `${introVideoFilter[0]}` === '0')) return [HasWorkoutVideoTypes.withVideo];
    return [HasWorkoutVideoTypes.all];
  }, [introVideoFilter, filtersOperators, filterTypes]);

  const imageFilterOption = useMemo(() => {
    const operator = filtersOperators[filterTypes['image.id']];
    const withImage = imageFilter[0] === HasWorkoutImageTypes.withImage;
    const withoutImage = imageFilter[0] === HasWorkoutImageTypes.withoutImage;
    const isGreaterThan = operator === CondOperator.GREATER_THAN;
    const isLowerThenOrNull = operator === CondOperator.LOWER_THAN_OR_NULL;

    if (withoutImage || (isLowerThenOrNull && `${imageFilter[0]}` === '0')) return [HasWorkoutImageTypes.withoutImage];
    if (withImage || (isGreaterThan && `${imageFilter[0]}` === '0')) return [HasWorkoutImageTypes.withImage];
    return [HasWorkoutImageTypes.all];
  }, [imageFilter, filtersOperators, filterTypes]);

  const introVideoFilters = useMemo(() => {
    switch (introVideoFilter[0]) {
      case HasWorkoutVideoTypes.withVideo:
        return {
          [`${filterTypes.intro_video_id}||${CondOperator.GREATER_THAN}`]: '0',
          [`${filterTypes.intro_video_id}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      case HasWorkoutVideoTypes.withoutVideo:
        return {
          [`${filterTypes.intro_video_id}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes.intro_video_id}||${CondOperator.LOWER_THAN_OR_NULL}`]: '0'
        };
      case HasWorkoutVideoTypes.all:
        return {
          [`${filterTypes.intro_video_id}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes.intro_video_id}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      default:
        return {};
    }
  }, [introVideoFilter, filterTypes.intro_video_id]);

  const imageFilters = useMemo(() => {
    switch (imageFilter[0]) {
      case HasWorkoutImageTypes.withImage:
        return {
          [`${filterTypes['image.id']}||${CondOperator.GREATER_THAN}`]: '0',
          [`${filterTypes['image.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      case HasWorkoutImageTypes.withoutImage:
        return {
          [`${filterTypes['image.id']}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes['image.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: '0'
        };
      case HasWorkoutImageTypes.all:
        return {
          [`${filterTypes['image.id']}||${CondOperator.GREATER_THAN}`]: undefined,
          [`${filterTypes['image.id']}||${CondOperator.LOWER_THAN_OR_NULL}`]: undefined
        };
      default:
        return {};
    }
  }, [imageFilter, filterTypes]);

  const filterOptions = useMemo(() => [...introVideoFilterOption, ...imageFilterOption], [introVideoFilterOption, imageFilterOption]);

  const assetsFilters = useMemo(() => ({ ...introVideoFilters, ...imageFilters }), [introVideoFilters, imageFilters]);

  const onSetAssetFilter = (filterType: FilterType, value: Record<string, any>) => {
    if (filterType !== filterTypes.assets) return;

    onSetFilters({
      ...filters,
      [filterTypes.intro_video_id]: [value.videoFilter],
      [filterTypes['image.id']]: [value.imageFilter]
    });
  };

  return { filterOptions, assetsFilters, onSetAssetFilter };
};

export default useAssetsFilters;
