import React, { ReactElement, useCallback, useMemo } from 'react';
import { Button, Input, List, Space, Typography, Row, Col } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import cs from 'classnames';
import './dropdown-record-selection-list.scss';

type ListItem = { id: string | number; name: string; nanoId?: string };

type DropdownRecordSelectionListOverlayProps<T> = {
  allowDuplicates?: boolean;
  isSingleModeSelection?: boolean;
  inputPlaceholder?: string;
  libraryTitle?: string;
  selectedItemsTitle?: string;
  itemsList?: T[];
  selectedListItems?: T[];
  searchSuffixComponent?: React.ReactNode;
  searchInputValue: string;
  customRenderListItemTitle?: (item: T) => ReactElement;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectListItem: (exercise: T, isDuplicate?: boolean) => void;
  onClose: () => void;
  onSaveSelectedItems: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const DropdownRecordSelectionListOverlay = <T extends ListItem>(props: DropdownRecordSelectionListOverlayProps<T>) => {
  const {
    allowDuplicates,
    isSingleModeSelection = false,
    inputPlaceholder = 'Search items by name',
    selectedItemsTitle = 'Selected items',
    itemsList = [],
    selectedListItems = [],
    searchSuffixComponent,
    searchInputValue,
    libraryTitle = 'Items library',
    customRenderListItemTitle,
    onChangeSearchInput,
    onSelectListItem,
    onClose,
    onSaveSelectedItems
  } = props;

  const renderListItemTitle = useCallback(
    (item: T) => (customRenderListItemTitle ? customRenderListItemTitle(item) : item.name),
    [customRenderListItemTitle]
  );

  const renderListItemIcons = useCallback((duplicatesCount = 0) => {
    if (!duplicatesCount) return null;
    const duplicatesIcons = Array.from({ length: duplicatesCount }, (_, i) => ({ index: i }));
    return (
      <Row align="middle" justify="end" className="full-width">
        {duplicatesIcons.map((i) => (
          <Col key={i.index}>
            <CheckOutlined />
          </Col>
        ))}
      </Row>
    );
  }, []);

  const renderListItem = useCallback(
    (item: T, duplicatesCount = 0) => (
      <List.Item
        key={item.nanoId || item.id}
        onClick={() => onSelectListItem(item, allowDuplicates && !!duplicatesCount)}
        className={cs('dropdown-record-selection-list-item', { selected: !!duplicatesCount })}
      >
        <Row align="middle" justify="space-between" className="full-width">
          <Col>
            <div className="item-label">{renderListItemTitle(item)}</div>
          </Col>
          <Col>{renderListItemIcons(duplicatesCount)}</Col>
        </Row>
      </List.Item>
    ),
    [allowDuplicates, renderListItemIcons, renderListItemTitle, onSelectListItem]
  );

  const renderItemsListSection = useMemo(() => {
    if (isSingleModeSelection) {
      return (
        <div className="dropdown-record-selection-list-section">
          <Typography.Title level={5}>{libraryTitle}</Typography.Title>
          <List
            size="small"
            dataSource={itemsList}
            className="dropdown-record-selection-list"
            renderItem={(item: T) => renderListItem(item, selectedListItems.filter(({ id }) => item.id === id).length)}
          />
        </div>
      );
    }

    return (
      <div className="dropdown-record-selection-list-section-wrap">
        <div className="dropdown-record-selection-list-section">
          <Typography.Title level={5}>{libraryTitle}</Typography.Title>
          <List
            size="small"
            dataSource={itemsList}
            className="dropdown-record-selection-list"
            renderItem={(item: T) => renderListItem(item, selectedListItems.filter(({ id }) => item.id === id).length)}
          />
        </div>
        <div className="dropdown-record-selection-list-section">
          <Typography.Title level={5}>{selectedItemsTitle}</Typography.Title>
          <List
            size="small"
            dataSource={selectedListItems}
            className="dropdown-record-selection-list"
            renderItem={(item: T) => renderListItem(item)}
          />
        </div>
      </div>
    );
  }, [isSingleModeSelection, itemsList, selectedListItems, renderListItem, libraryTitle, selectedItemsTitle]);

  return (
    <Space className="ant-space-full-width" direction="vertical" size="large" onClick={(e) => e.preventDefault()}>
      <Input.Search
        autoFocus
        enterButton
        value={searchInputValue}
        placeholder={inputPlaceholder}
        onChange={onChangeSearchInput}
        suffix={searchSuffixComponent}
      />
      {renderItemsListSection}
      <Space>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={onSaveSelectedItems}>
          Save
        </Button>
      </Space>
    </Space>
  );
};

export default DropdownRecordSelectionListOverlay;
