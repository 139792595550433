import { Exercise, ExerciseOwner } from '../types/exercises-types';

export const getUniqueOwnersFromExercises = (exercises: Exercise[]) => {
  const uniqueOwners: { [key: string]: ExerciseOwner & { key: string } } = {};
  for (const { owner } of exercises) {
    if (owner && !uniqueOwners[owner.id]) {
      uniqueOwners[owner.id] = { ...owner, key: owner.id };
    }
  }

  return Object.values(uniqueOwners);
};
