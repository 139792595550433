import api from '../../api';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { TypedDispatch, TypedThunk } from '../../types/redux-types';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { setRecordResourceType, setResourceRecord, setResourceRecordError, startFetchingResourceRecord } from './resource-record-reducer';

export const fetchResourceRecord =
  (recordId: string, resource: Resources): TypedThunk =>
  (dispatch: TypedDispatch) => {
    dispatch(startFetchingResourceRecord());

    api.common
      .getOne(resource, { id: recordId })
      .then((record) => {
        dispatch(setResourceRecord(record));
        dispatch(setRecordResourceType(resource));
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        dispatch(setResourceRecordError(errorMessage));
        onDisplayErrorNotification(errorMessage);
      });
  };
