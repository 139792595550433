import React, { useState } from 'react';
import { Button, Input, Typography, Row, Col } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { NOOP } from '../constants/common-constants';

declare const TITLE_ELE_LIST: [1, 2, 3, 4, 5];

type AccessibleTableInputEditCellProps = {
  value?: string;
  size?: SizeType;
  level?: typeof TITLE_ELE_LIST[number];
  onChange?: (value: string) => void;
};

const EditableTypographyTitle = (props: AccessibleTableInputEditCellProps) => {
  const { size, value = '', level = 5, onChange = NOOP } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);

  const isDisabled = inputValue === value || !inputValue || !inputValue.trim();

  const onStartEditing = () => setIsEditing(true);
  const onFinishEditing = () => setIsEditing(false);
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);
  const onSaveChanges = () => {
    if (isDisabled) return;
    onChange(inputValue);
    onFinishEditing();
  };

  if (!isEditing) {
    return (
      <Typography.Title
        level={level}
        style={{ marginBottom: 0 }}
        editable={{
          triggerType: ['icon', 'text'],
          onStart: onStartEditing,
          onEnd: onFinishEditing
        }}
      >
        {inputValue}
      </Typography.Title>
    );
  }

  return (
    <Row align="middle" gutter={4}>
      <Col>
        <Input size={size} value={inputValue} onChange={onChangeInput} onPressEnter={onSaveChanges} />
      </Col>
      <Col>
        <Button type="primary" disabled={isDisabled} icon={<SaveOutlined />} onClick={onSaveChanges} />
      </Col>
      <Col>
        <Button icon={<CloseOutlined />} onClick={onFinishEditing} />
      </Col>
    </Row>
  );
};

export default EditableTypographyTitle;
