import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resources } from '../../types/resources-types';
import { ResourceListRecords } from '../../types/resource-list-records-types';

export interface ResourceListState {
  resource: Resources | null;
  isInitialized: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  resourceList: ResourceListRecords[];
  totalRows: number;
  selectedItemsIds: string[];
  resourceListDataKey: string | null;
}

const initialState: ResourceListState = {
  resource: null,
  isInitialized: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
  resourceList: [],
  totalRows: 0,
  selectedItemsIds: [],
  resourceListDataKey: null
};

type SetResourceListPayload = {
  resource: Resources;
  resourceList: ResourceListRecords[];
  totalRows: number;
  resourceListDataKey?: string;
};

export const resourceListSlice = createSlice({
  name: 'resourceList',
  initialState,
  reducers: {
    startFetchingResourceList: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = '';
      state.resourceListDataKey = null;
    },
    setResourceList: (state, action: PayloadAction<SetResourceListPayload>) => {
      const { resource, resourceList, totalRows, resourceListDataKey } = action.payload;
      return {
        ...state,
        resource,
        isLoading: false,
        isError: false,
        errorMessage: '',
        resourceList,
        totalRows,
        selectedItemsIds: [],
        resourceListDataKey: resourceListDataKey || state.resourceListDataKey
      };
    },
    setResourceListError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.resourceListDataKey = null;
    },
    clearResourceListData: () => {
      return initialState;
    },
    selectResourceItems: (state, action: PayloadAction<string[]>) => {
      state.selectedItemsIds = action.payload;
    },
    selectResourceItem: (state, action: PayloadAction<string>) => {
      state.selectedItemsIds = [...state.selectedItemsIds, action.payload];
    },
    setInitialization: (state) => {
      state.isInitialized = true;
    }
  }
});

export const { startFetchingResourceList, setResourceList, setResourceListError, clearResourceListData, selectResourceItems, setInitialization } =
  resourceListSlice.actions;

export default resourceListSlice.reducer;
