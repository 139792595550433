import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getRequestParamsFromQuery } from '../api/api-utils';
import { selectResourceListIsInitialized } from '../redux/resource-list/resource-list-selectors';
import { defaultPage, defaultRowsPerPage, defaultSortField, defaultSortOrder } from '../redux/resource-list/resource-list-constants';

const useResourceListUrlParams = () => {
  const location = useLocation();
  const urlParams = useMemo(() => getRequestParamsFromQuery(location.search), [location.search]);
  const isResourceListIsInitialized = useSelector(selectResourceListIsInitialized);
  const isInitialized = isResourceListIsInitialized && !!urlParams;

  const { sortField, sortOrder, page, rowsPerPage, searchFilterKey, additionalFilters, searchInputValue } = useMemo(
    () => ({
      sortField: urlParams?.sortField,
      sortOrder: urlParams?.sortOrder,
      page: urlParams?.page ?? defaultPage,
      rowsPerPage: urlParams?.rowsPerPage ?? defaultRowsPerPage,
      searchFilterKey: urlParams?.searchKey ?? '',
      additionalFilters: urlParams?.additionalFilters || {},
      searchInputValue: urlParams?.searchInputValue || ''
    }),
    [urlParams]
  );

  const filtersOperators = useMemo(
    () =>
      Object.keys(additionalFilters).reduce((acc, filter) => {
        const [filterKey, filterOperator] = filter.split('||');
        if (!filterKey) return acc;
        return { ...acc, [filterKey]: filterOperator || null };
      }, {}),
    [additionalFilters]
  );

  const requestParams = useMemo(() => {
    if (!isInitialized) return null;

    return {
      sort: { field: sortField || defaultSortField, order: sortOrder || defaultSortOrder },
      pagination: { page, perPage: rowsPerPage },
      filter: { ...additionalFilters, [searchFilterKey]: searchInputValue.trim() } as Record<string, any>
    };
  }, [isInitialized, sortField, sortOrder, page, rowsPerPage, additionalFilters, searchFilterKey, searchInputValue]);

  return {
    sortField,
    sortOrder,
    page,
    rowsPerPage,
    urlParams,
    requestParams,
    searchFilterKey,
    additionalFilters,
    searchInputValue,
    isInitialized,
    filtersOperators
  };
};

export default useResourceListUrlParams;
