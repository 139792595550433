import React from 'react';
import { Button, Col, List, Row, Tag } from 'antd';
import { FastForwardOutlined } from '@ant-design/icons';
import cs from 'classnames';

import { WorkoutMovement } from '../../types/workout-types';
import { getClovenTimeValue } from '../../utils/time-utils';
import './workout-video-editor-page.scss';

type WorkoutVideoEditorListItemProps = {
  isSelected: boolean;
  movement: WorkoutMovement;
  onSelectRow: () => void;
  onJumpToMovement: () => void;
};

const WorkoutVideoEditorListItem = (props: WorkoutVideoEditorListItemProps) => {
  const { isSelected, movement, onSelectRow, onJumpToMovement } = props;

  const { minutes: startTimeMinutes, seconds: startTimeSeconds } = getClovenTimeValue(movement.start_time);
  const { minutes: endTimeMinutes, seconds: endTimeSeconds } = getClovenTimeValue(movement.end_time);

  return (
    <List.Item className={cs({ ['movement-list-item-selected']: isSelected })} onClick={onSelectRow}>
      <Row align="middle" justify="start" gutter={10}>
        <Col>
          <Button icon={<FastForwardOutlined />} onClick={onJumpToMovement} />
        </Col>
        <Col>
          <Tag color="blue">{`${startTimeMinutes}:${startTimeSeconds}`}</Tag>
          <Tag color="blue">{`${endTimeMinutes}:${endTimeSeconds}`}</Tag>
        </Col>
        <Col>
          <span>{movement.exercise.name}</span>
        </Col>
      </Row>
    </List.Item>
  );
};

export default WorkoutVideoEditorListItem;
