import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { DatabaseOutlined } from '@ant-design/icons';
import { Modal, Button, Tooltip } from 'antd';

import { NOOP } from '../../constants/common-constants';
import { ButtonType } from 'antd/lib/button/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import './basic-modal.scss';

type BasicModalProps = {
  title: string;
  children: React.ReactNode;
  modalWidth?: number;
  buttonTitle?: string;
  withActions?: boolean;
  withCancelAction?: boolean;
  isOnSuccessDisabled?: boolean;
  tooltipLabel?: string;
  okText?: string;
  buttonSize?: SizeType;
  buttonType?: ButtonType;
  buttonIcon?: ReactElement;
  footerActions?: ReactElement[];
  onSuccess?: () => void;
  onCancelCallBack?: () => void;
};

const BasicModal = (props: BasicModalProps) => {
  const {
    title,
    children,
    tooltipLabel = 'Open modal',
    withActions = true,
    withCancelAction = false,
    buttonType = 'primary',
    buttonSize = 'middle',
    okText = 'Ok',
    footerActions,
    isOnSuccessDisabled = false,
    buttonTitle,
    buttonIcon,
    modalWidth,
    onSuccess = NOOP,
    onCancelCallBack = NOOP
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  const onShowModal = () => setIsVisible(true);

  const onHandleOk = useCallback(() => {
    onSuccess();
    setIsVisible(false);
  }, [onSuccess]);

  const onHandleCancel = useCallback(() => {
    setIsVisible(false);
    onCancelCallBack();
  }, [onCancelCallBack]);

  const modalActions = useMemo(() => {
    if (!withActions) return [];
    if (footerActions) return footerActions;
    if (withCancelAction)
      return [
        <Button key="back" onClick={onHandleCancel}>
          Cancel
        </Button>
      ];

    return [
      <Button key="back" onClick={onHandleCancel}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" disabled={isOnSuccessDisabled} onClick={onHandleOk}>
        {okText}
      </Button>
    ];
  }, [withCancelAction, withActions, okText, footerActions, isOnSuccessDisabled, onHandleOk, onHandleCancel]);

  return (
    <>
      <Tooltip placement="topRight" title={tooltipLabel}>
        <Button type={buttonType} size={buttonSize} icon={buttonIcon || <DatabaseOutlined />} onClick={onShowModal}>
          {buttonTitle}
        </Button>
      </Tooltip>
      <Modal
        centered
        destroyOnClose
        title={title}
        width={modalWidth}
        visible={isVisible}
        footer={modalActions}
        wrapClassName="modal-wrap"
        bodyStyle={{ minHeight: '300px', maxHeight: '80vh' }}
        onOk={onHandleOk}
        onCancel={onHandleCancel}
      >
        {children}
      </Modal>
    </>
  );
};

export default BasicModal;
