import { useEffect, useMemo, useState } from 'react';
import { ColumnItem } from '../components/accessible-table/accessible-table-types';
import { ColumnTitle } from 'antd/lib/table/interface';
import { Resources } from '../types/resources-types';
import { getResourceListDynamicColumnsFromLS, saveResourceListDynamicColumnsToLS } from '../utils/local-storage-utils';

export type DynamicColumnItem = { value: string; text: ColumnTitle<Record<string, any>> };

type UseResourceListDynamicColumns = {
  dynamicColumns: DynamicColumnItem[];
  resourceListColumns: ColumnItem[];
  onChangeDynamicColumns: (keys: string[]) => void;
};

const useResourceListDynamicColumns = (columnsList: ColumnItem[], resource: Resources): UseResourceListDynamicColumns => {
  const requiredColumnsKeys = useMemo(
    () => columnsList.reduce((acc: string[], { key, isRequired }) => (isRequired ? [...acc, key] : acc), []),
    [columnsList]
  );
  const columnsListKeys = useMemo(() => columnsList.map(({ key }) => key), [columnsList]);
  const dynamicColumns = useMemo(
    () =>
      columnsList
        .filter((v) => typeof v.title === 'string')
        .map(({ key, title }) => ({
          value: key,
          text: title,
          isDisabled: requiredColumnsKeys?.includes(key)
        })),
    [columnsList, requiredColumnsKeys]
  );
  const [columnsKeys, setColumnsKeys] = useState<string[]>([]);

  useEffect(() => {
    const dynamicColumnsFromLS = getResourceListDynamicColumnsFromLS(resource);
    const initialDynamicColumns = dynamicColumnsFromLS || columnsListKeys;
    setColumnsKeys(initialDynamicColumns);
  }, [resource, columnsListKeys]);

  const resourceListColumns = useMemo(() => {
    return columnsList.filter(({ key }) => columnsKeys.includes(key));
  }, [columnsKeys, columnsList]);

  const onChangeDynamicColumns = (selectedKeys: string[]) => {
    setColumnsKeys(selectedKeys);
    saveResourceListDynamicColumnsToLS(resource, selectedKeys);
  };

  return {
    dynamicColumns,
    resourceListColumns,
    onChangeDynamicColumns
  };
};

export default useResourceListDynamicColumns;
