import React, { memo, useMemo, useState } from 'react';
import moment from 'moment';
import { TimePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import cs from 'classnames';
import 'antd/dist/antd.css';

import { DurationType, getFormattedDurationTime, TimeDisplayFormat } from '../../utils/time-utils';
import './time-input.scss';

type TimeInputProps = {
  durationTime: number;
  format?: string;
  maxTime?: number;
  minTime?: number;
  className?: string;
  minTimeError?: string;
  maxTimeError?: string;
  size?: SizeType;
  allowClear?: boolean;
  disabled?: boolean;
  durationType?: DurationType;
  onChange: (timeValue: number) => void;
};

const SelectionTimeInput = (props: TimeInputProps) => {
  const {
    durationTime,
    maxTime = +Infinity,
    format = 'mm:ss',
    minTime = 0,
    disabled,
    size = 'small',
    minTimeError = 'Selected time is less then available minimum time',
    maxTimeError = 'Selected time is hire then available maximum time',
    className,
    allowClear,
    durationType = DurationType.milliSeconds,
    onChange
  } = props;

  const [selectedTime, setSelectedTime] = useState<number>(durationTime);

  const isSelectedTimeLessThenMinTime = minTime > selectedTime;
  const isSelectedTimeHireThenMinTime = selectedTime > maxTime;
  const isSelectedTimeInRange = !isSelectedTimeLessThenMinTime && !isSelectedTimeHireThenMinTime;

  const isSecondsDurationType = durationType === DurationType.seconds;
  const formattedTimeSeconds = getFormattedDurationTime(durationTime, durationType);
  const defaultValue = moment(formattedTimeSeconds, TimeDisplayFormat.hms);

  const getTimeValue = (timeValue: moment.Moment) => {
    const formattedTime = moment(timeValue).format(TimeDisplayFormat.hms);
    return isSecondsDurationType ? moment.duration(formattedTime).asSeconds() : moment.duration(formattedTime).asMilliseconds();
  };

  const getErrorMessage = useMemo(() => {
    if (isSelectedTimeLessThenMinTime) return minTimeError;
    if (isSelectedTimeHireThenMinTime) return maxTimeError;
    return '';
  }, [isSelectedTimeLessThenMinTime, isSelectedTimeHireThenMinTime, minTimeError, maxTimeError]);

  const renderErrorMessage = () => (
    <span className="error-message">
      <ExclamationCircleOutlined className="icon" /> {getErrorMessage}
    </span>
  );

  const onSelectTime = (timeValue: moment.Moment) => setSelectedTime(getTimeValue(timeValue));
  const onOpenChange = (isOpen: boolean) => !isOpen && setSelectedTime(durationTime);
  const onChangeTimePicker = () => isSelectedTimeInRange && onChange(selectedTime);

  return (
    <TimePicker
      inputReadOnly
      showHour={false}
      format={format}
      size={size}
      showNow={false}
      disabled={disabled}
      allowClear={allowClear}
      value={defaultValue}
      className={cs(className, { error: !isSelectedTimeInRange })}
      popupClassName={cs({ error: !isSelectedTimeInRange })}
      renderExtraFooter={!isSelectedTimeInRange ? renderErrorMessage : undefined}
      onSelect={onSelectTime}
      onOpenChange={onOpenChange}
      onChange={onChangeTimePicker}
    />
  );
};

export default memo(SelectionTimeInput);
