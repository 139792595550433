import React from 'react';
import { Tag } from 'antd';
import cs from 'classnames';
import { mainTagColor } from '../../styles/styles-constants';
import './accessible-table.scss';

type AccessibleTableTagsProps = {
  tags?: string[];
  colored?: boolean;
  bordered?: boolean;
  colorsMap?: Record<string, string | undefined>;
};

const AccessibleTableTags = ({ colored = true, bordered = true, tags = [], colorsMap = {} }: AccessibleTableTagsProps) => (
  <>
    {tags
      .filter((item) => item)
      .map((tag) => (
        <Tag
          key={tag}
          className={cs('accessible-table-tags-cell', { borderless: !bordered, colorless: !colored })}
          color={colored ? colorsMap[tag] || mainTagColor : undefined}
        >
          {tag.toUpperCase()}
        </Tag>
      ))}
  </>
);

export default AccessibleTableTags;
