import React, { useState } from 'react';
import { Button, Dropdown } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import AccessibleTableFilterSelect from '../../components/accessible-table/accessible-table-filter-select';
import { ColumnItem } from '../../components/accessible-table/accessible-table-types';
import { DynamicColumnItem } from '../../hooks/use-resource-list-dynamic-columns';
import { NOOP } from '../../constants/common-constants';

type ResourceListDynamicColumnsMenuProps = {
  columns?: ColumnItem[];
  dynamicColumns?: DynamicColumnItem[];
  onChangeDynamicColumns?: (keys: string[]) => void;
};

const ResourceListDynamicColumnsMenu = (props: ResourceListDynamicColumnsMenuProps) => {
  const { columns = [], dynamicColumns = [], onChangeDynamicColumns = NOOP } = props;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Dropdown
      visible={isVisible}
      trigger={['click']}
      onVisibleChange={setIsVisible}
      overlay={
        <AccessibleTableFilterSelect
          className="dynamic-columns-menu"
          options={dynamicColumns as { value: any; text: string; isDisabled?: boolean }[]}
          initialFilters={columns.map(({ key }) => key)}
          onSelectFilters={(keys: string[]) => {
            onChangeDynamicColumns(keys);
            setIsVisible(false);
          }}
        />
      }
    >
      <Button icon={<SettingOutlined />} onClick={(e) => e.preventDefault()}>
        Columns
      </Button>
    </Dropdown>
  );
};

export default ResourceListDynamicColumnsMenu;
