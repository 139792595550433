import React, { useCallback } from 'react';

import { Workout } from '../../../types/workout-types';
import { Exercise } from '../../../types/exercises-types';
import { Resources } from '../../../types/resources-types';
import { useItemSelector } from '../../../hooks/use-selector';
import { getPageResourceRecord } from '../../../redux/resource-record/resource-record-selectors';

import MovementExerciseTitle from '../movement-exercise-title';
import DropdownRecordSelectionList from '../../../components/dropdown-record-selection-list/dropdown-record-selection-list';
import WorkoutMovementsSelectionFilters from './workout-movements-selection-filters';
import useWorkoutMovementsSelection, { TransferMovementsSelectionMode } from '../hooks/use-workout-movements-selection';
import '../workout-movemnts.scss';

type TransferMovementsSelectionProps = {
  placeholder?: string;
  selectedItems?: Exercise[];
  selectionMode?: TransferMovementsSelectionMode;
  onSelectItems: (elements: Exercise[]) => void;
};

const WorkoutMovementsSelection = (props: TransferMovementsSelectionProps) => {
  const {
    placeholder = 'Select one or multiple exercises',
    selectionMode = TransferMovementsSelectionMode.multiple,
    selectedItems = [],
    onSelectItems
  } = props;

  const record = useItemSelector({ resource: Resources.workouts }, getPageResourceRecord) as Workout | null;
  if (!record) return null;

  const {
    initialTypeFilter,
    typeFilter,
    patternFilters,
    motionFilters,
    equipmentFilters,
    isDropdownVisible,
    exercises,
    selectedExercises,
    searchInputValue,
    isTypeFilterAvailable,
    typeFilterTooltip,
    onChangeSearchInput,
    onSelectExercise,
    onChangeVisibility,
    onSaveSelectedExercises,
    onChangeTypeFilter,
    onChangePatternFilter,
    onChangeMotionFilter,
    onChangeEquipmentFilter,
    onClearAllFilters
  } = useWorkoutMovementsSelection({ selectionMode, selectedItems, session_type: record.session_type, onSelectItems });

  const isSingleModeSelection = selectionMode === TransferMovementsSelectionMode.single;
  const dropdownPlaceholder = !isSingleModeSelection && selectedItems.length ? `Selected ${selectedItems.length} exercises` : placeholder;
  const renderListItemTitle = (item: Exercise) => <MovementExerciseTitle withTooltip exercise={item} />;
  const renderDropdownButtonContent = useCallback(
    (items: Exercise[]) => {
      if (items.length) return <MovementExerciseTitle exercise={items[0]} />;
      return <span>{dropdownPlaceholder}</span>;
    },
    [dropdownPlaceholder]
  );

  return (
    <DropdownRecordSelectionList
      allowDuplicates
      placeholder={dropdownPlaceholder}
      inputPlaceholder="Search exercises by name"
      itemsList={exercises}
      selectedItems={selectedItems}
      selectedListItems={selectedExercises}
      isSingleModeSelection={isSingleModeSelection}
      libraryTitle="Exercises library"
      selectedItemsTitle="Selected exercises"
      searchSuffixComponent={
        <WorkoutMovementsSelectionFilters
          initialTypeFilter={initialTypeFilter}
          typeFilter={typeFilter}
          patternFilters={patternFilters}
          motionFilters={motionFilters}
          equipmentFilters={equipmentFilters}
          isTypeFilterAvailable={isTypeFilterAvailable}
          typeFilterTooltip={typeFilterTooltip}
          onChangeTypeFilter={onChangeTypeFilter}
          onChangePatternFilter={onChangePatternFilter}
          onChangeMotionFilter={onChangeMotionFilter}
          onChangeEquipmentFilter={onChangeEquipmentFilter}
          onClearAllFilters={onClearAllFilters}
        />
      }
      isDropdownVisible={isDropdownVisible}
      searchInputValue={searchInputValue}
      customRenderListItemTitle={renderListItemTitle}
      customRenderDropdownButtonContent={renderDropdownButtonContent}
      onChangeSearchInput={onChangeSearchInput}
      onSelectListItem={onSelectExercise}
      onChangeVisibility={onChangeVisibility}
      onSaveSelectedItems={onSaveSelectedExercises}
    />
  );
};

export default WorkoutMovementsSelection;
