import React from 'react';
import { Row, Col } from 'antd';

import { Resources } from '../../types/resources-types';
import StubPage from '../../components/stub-page/stub-page';
import VideoPlayer from '../../components/video-player/video-player';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import WorkoutVideoEditorSidebar from './workout-video-editor-sidebar';
import useWorkoutVideoEditor from './use-workout-video-editor';
import { AvailableWorkoutTypes } from 'src/utils/resources-dictionary-utils';

const WorkoutVideoEditorPage = () => {
  const { record, isLoading, movements, videoPlayerRef, selectedItemIndex, source, onSelectRow, onJumpToMovement, onSetAsStart, onSetAsEnd } =
    useWorkoutVideoEditor();

  if (!record) return null;

  if (!AvailableWorkoutTypes.includes(record?.session_type)) {
    const secondaryMessage = record ? `Content for Workout Movements with "${record?.session_type}" type is still in development.` : '';
    return <StubPage mainMessage="In development" secondaryMessage={secondaryMessage} />;
  }

  if (!source.isSupported) {
    return <StubPage mainMessage="Video is not available" secondaryMessage="Workout doesn't contain video, so first need to attach video." />;
  }

  return (
    <ResourceRecordContainer record={record} isLoading={isLoading} resource={Resources.workouts}>
      <Row align="top" justify="space-evenly" className="full-height">
        <Col span={12} className="full-height">
          <VideoPlayer withInnerRef innerRef={videoPlayerRef} source={source} />
        </Col>
        <Col span={12} className="full-height">
          <WorkoutVideoEditorSidebar
            movements={movements}
            selectedItemIndex={selectedItemIndex}
            onSelectRow={onSelectRow}
            onJumpToMovement={onJumpToMovement}
            onSetAsStart={onSetAsStart}
            onSetAsEnd={onSetAsEnd}
          />
        </Col>
      </Row>
    </ResourceRecordContainer>
  );
};

export default WorkoutVideoEditorPage;
