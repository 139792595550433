import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Descriptions, Divider, Row, Space, Typography } from 'antd';
import { DragOutlined, FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import api from '../../api';
import { Workout } from '../../types/workout-types';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { UpdateParams } from '../../types/request-entities-types';
import { getErrorMessage } from '../../utils/errors';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { getFormattedDate } from '../../utils/date-utils';
import { useItemSelector } from '../../hooks/use-selector';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { sessionTypesColorsMap } from '../../types/common-types';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { Program, ProgramElement } from '../../types/programs-types';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';
import useLocationHandler from '../../hooks/use-location-handler';
import useProgramAssetsTabs from './use-program-assets-tabs';

import DragHandle from '../../components/drag-handle';
import ContentTabs from '../../components/content-tabs/content-tabs';
import AccessibleTable from '../../components/accessible-table';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import AccessibleTableTextArea from '../../components/accessible-table/accessible-table-text-area';
import TransferWorkoutsToProgramModal from './transfer-workouts-to-program-modal';
import AccessibleTableImageCell from '../../components/accessible-table/accessible-table-image-cell';
import SelectResourceRecordContentPlans from '../../components/select-resource-record-content-plans';
import StubPage from '../../components/stub-page/stub-page';
import ResourceRecordSchedulePublishDate from '../../components/resource-record-schedule-publish-date';
import ResourceRecordStatusSelection from '../../components/resource-record-status-selection';
import SwitcherButton from '../../components/buttons/switcher-button';
import AccessibleTableTags from '../../components/accessible-table/accessible-table-tags';
import { getComparator, stableSort } from '../../utils/sort-utils';

type ElementData = Workout & { id: string; workoutId: string; index: string };

const getElementsData = (groupElements: ProgramElement[]) =>
  groupElements.map(({ id, workout }): ElementData => {
    return { ...workout, id: `${id}`, workoutId: workout.id, index: `${id}` };
  });

const ProgramRecordPage = () => {
  const timeZone = useSelector(selectUserTimeZone);
  const record = useItemSelector({ resource: Resources.programs }, getPageResourceRecord) as Program;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const { secondSubRoute: recordId } = useLocationHandler();

  const [elementsData, setElementsData] = useState<ElementData[]>([]);
  const elements = useMemo(() => getElementsData(stableSort(record?.elements ?? [], getComparator('asc', 'order'))), [record]);

  useEffect(() => {
    setElementsData(elements);
  }, [elements]);

  const onSetRecord = useAction(setResourceRecord);

  useResourceRecordData({ recordId, resource: Resources.programs });

  const { tabsConfig, isAssetsChanged, changedAssetsData } = useProgramAssetsTabs(record);

  const onChangeRecordData = (data: Record<string, any>, message = 'Program was successfully updated!') => {
    if (!record) return;

    const params = { id: recordId, data: { id: recordId, ...data }, previousData: { id: recordId } } as UpdateParams;

    api.common
      .update(Resources.programs, params)
      .then((nextRecordData: Omit<Program, 'elements'>) => {
        onSetRecord({ ...nextRecordData, elements: record.elements });
        onDisplaySuccessNotification(message);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onSaveProgramAssets = () => {
    if (!isAssetsChanged || !changedAssetsData) return;
    onChangeRecordData(changedAssetsData, 'Program assets was successfully updated!');
  };

  const elementsColumns = [
    {
      width: 70,
      key: 'sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      title: () => <DragOutlined />,
      render: () => <DragHandle />
    },
    {
      width: 70,
      key: 'image',
      title: 'Preview',
      dataIndex: 'image',
      render: (image: any) => <AccessibleTableImageCell value={image?.url} />
    },
    {
      width: 150,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (name: string, item: Record<string, any>) => (
        <AccessibleTableLinkedCell value={name} link={`/${Resources.workouts}/${item.workoutId}`} />
      )
    },
    {
      width: 150,
      key: 'production_id',
      title: 'Production id',
      dataIndex: 'production_id'
    },
    {
      width: 90,
      key: 'assets',
      title: 'Assets',
      dataIndex: 'assets',
      render: (value: any, item: Record<string, any>) => {
        const withVideoAsset = !!item?.intro_video?.id;
        const withImageAsset = !!item?.image?.id;
        return (
          <Space>
            {withVideoAsset && <VideoCameraOutlined />}
            {withImageAsset && <FileImageOutlined />}
          </Space>
        );
      }
    },
    {
      width: 150,
      key: 'session_type',
      title: 'Type',
      dataIndex: 'session_type',
      render: (type: string) => <AccessibleTableTags tags={[type]} colorsMap={sessionTypesColorsMap} />
    },
    {
      width: 150,
      key: 'status',
      title: 'Status',
      dataIndex: 'status'
    }
  ];

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.programs} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Program
      </Typography.Title>
      <Divider />
      <Descriptions bordered layout="horizontal" column={1} labelStyle={{ width: '30%', minWidth: '300px' }}>
        <Descriptions.Item label="Name">
          <AccessibleTableTextArea
            value={record?.name ?? '-'}
            onChange={(name: string) => onChangeRecordData({ name }, 'Program name was successfully updated!')}
            onCheckIsValid={(value: string) => !!value}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          <AccessibleTableTextArea
            textAreaRows={3}
            value={record?.description ?? '-'}
            onChange={(description: string) => onChangeRecordData({ description }, 'Program description was successfully updated!')}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Is lift">
          <SwitcherButton
            checked={record.is_lift}
            onChange={(is_lift: boolean) => onChangeRecordData({ is_lift }, 'Program field is_lift was successfully updated!')}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Is collection">
          <SwitcherButton
            checked={record.is_collection}
            onChange={(is_collection: boolean) => onChangeRecordData({ is_collection }, 'Program field is_collection was successfully updated!')}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Content plans">
          <SelectResourceRecordContentPlans resource={Resources.programs} />
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <ResourceRecordStatusSelection resource={Resources.programs} />
        </Descriptions.Item>
        <Descriptions.Item label="Scheduled Publish date">
          <ResourceRecordSchedulePublishDate resource={Resources.programs} />
        </Descriptions.Item>
        <Descriptions.Item label="Created date">{getFormattedDate(record?.created_date, timeZone)}</Descriptions.Item>
        <Descriptions.Item label="Updated date">{getFormattedDate(record?.updated_date, timeZone)}</Descriptions.Item>
        <Descriptions.Item label="Published date">{getFormattedDate(record?.published_date, timeZone)}</Descriptions.Item>
      </Descriptions>
      <Divider>Assets</Divider>
      <ContentTabs
        tabsConfig={tabsConfig}
        tabBarExtraContent={
          <Space direction="vertical">
            <Button type="primary" size="large" disabled={!isAssetsChanged} onClick={onSaveProgramAssets}>
              Save assets
            </Button>
          </Space>
        }
      />
      <Divider />
      <AccessibleTable
        isDraggable
        data={elementsData}
        columns={elementsColumns}
        renderTitle={() => (
          <Row align="middle" justify="space-between" className="ant-table-title">
            <Col span={16}>
              <Typography.Title level={4}>Workouts</Typography.Title>
            </Col>
            <Col span={8} className="playlist-record-title-col">
              <TransferWorkoutsToProgramModal program={record} />
            </Col>
          </Row>
        )}
      />
    </ResourceRecordContainer>
  );
};

export default ProgramRecordPage;
