import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Form, Input, Typography } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';
import useInstructorAssetsTabs from './use-instructor-assets-tabs';

import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { useNavigate } from 'react-router-dom';
import { Instructor } from '../../types/instructors-types';
import { RequestError } from '../../api/http-client';
import { useItemSelector } from '../../hooks/use-selector';
import { UpdateParams } from '../../types/request-entities-types';
import { useFormValues } from '../../hooks/use-form-values';
import { categoriesList } from '../../utils/resources-dictionary-utils';
import { getErrorMessage } from '../../utils/errors';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { getFormattedDate } from '../../utils/date-utils';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { stringValidator } from '../../validators/string-validator';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';

import StubPage from '../../components/stub-page/stub-page';
import ContentTabs from '../../components/content-tabs/content-tabs';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import SelectResourceRecordContentPlans from '../../components/select-resource-record-content-plans';
import ResourceRecordStatusSelection from '../../components/resource-record-status-selection';
import ResourceRecordSchedulePublishDate from '../../components/resource-record-schedule-publish-date';
import CheckboxSelectionList from '../../components/checkbox-selection-list/checkbox-selection-list';

const InstructorRecordPage = () => {
  const record = useItemSelector({ resource: Resources.instructors }, getPageResourceRecord) as Instructor;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const timeZone = useSelector(selectUserTimeZone);
  const onSetRecord = useAction(setResourceRecord);

  const { secondSubRoute: recordId } = useLocationHandler();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const initialData = useMemo(
    () => ({
      ...record,
      created_date: record?.created_date ? moment(record.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  useResourceRecordData({ recordId, resource: Resources.instructors });
  const { tabsConfig, isAssetsChanged, changedAssetsData } = useInstructorAssetsTabs(record);
  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinish = () => {
    const params = {
      id: recordId,
      previousData: { id: recordId },
      data: { id: recordId, ...changedFormValues, ...changedAssetsData }
    } as UpdateParams;

    api.common
      .update(Resources.instructors, params)
      .then((response: Instructor) => {
        onSetRecord(response);
        onDisplaySuccessNotification('Instructor record was successfully updated');
        onReset();
        navigate(`/${Resources.instructors}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.instructors} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Instructor
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item name="first_name" label="First name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Last name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="categories" label="Categories">
          <CheckboxSelectionList isMultiple options={categoriesList} />
        </Form.Item>
        <Form.Item validateFirst={false} label="Created date">
          <span>{getFormattedDate(record?.created_date, timeZone)}</span>
        </Form.Item>
        <Form.Item validateFirst={false} label="Updated date">
          <span>{getFormattedDate(record?.updated_date, timeZone)}</span>
        </Form.Item>
        <Form.Item validateFirst={false} label="Published date">
          <span>{getFormattedDate(record?.published_date, timeZone)}</span>
        </Form.Item>
        <Divider orientation="center" plain>
          Assets
        </Divider>
        <Form.Item label="Assets">
          <ContentTabs tabsConfig={tabsConfig} />
        </Form.Item>
        <Divider orientation="center" plain>
          Infos
        </Divider>
        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item name="bio" label="BIO">
          <Input />
        </Form.Item>
        <Form.Item name="quote" label="Quote">
          <Input />
        </Form.Item>
        <Form.Item name="hometown" label="Hometown">
          <Input />
        </Form.Item>
        <Form.Item name="favorite_movement" label="Favorite movement">
          <Input />
        </Form.Item>
        <Divider />
        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged && !isAssetsChanged}>
            Save
          </Button>
        </Form.Item>
      </Form>

      <Divider orientation="center" plain>
        Actions
      </Divider>

      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
        <Form.Item label="Content plans">
          <SelectResourceRecordContentPlans resource={Resources.instructors} />
        </Form.Item>
        <Form.Item label="Status">
          <ResourceRecordStatusSelection resource={Resources.instructors} />
        </Form.Item>
        <Form.Item label="Scheduled Publish date">
          <ResourceRecordSchedulePublishDate resource={Resources.instructors} />
        </Form.Item>
      </Form>

      <Divider />
    </ResourceRecordContainer>
  );
};

export default InstructorRecordPage;
