import api from '../../api';
import { Resources } from '../../types/resources-types';
import { GetListParams } from '../../types/request-entities-types';
import { getErrorMessage } from '../../utils/errors';
import { setResourceList, setResourceListError, startFetchingResourceList } from './resource-list-reducer';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { TypedDispatch, TypedThunk } from '../../types/redux-types';
import { selectIsResourceListLoading, selectResourceListDataKey } from './resource-list-selectors';
import { getResourceListDataKey } from './resource-list-utils';

type ResourceListDataProps = {
  params: GetListParams;
  resource: Resources;
  force?: boolean;
};

export const fetchResourceList =
  ({ resource, params, force }: ResourceListDataProps): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    const state = getState();
    const isLoading = selectIsResourceListLoading(state);
    const prevResourceListDataKey = selectResourceListDataKey(state);
    const resourceListDataKey = getResourceListDataKey(resource, params);
    const isDataKeyChanged = prevResourceListDataKey !== resourceListDataKey;

    if (isLoading || (!isDataKeyChanged && !force)) return;

    dispatch(startFetchingResourceList);

    api.common
      .getList(resource, params)
      .then(({ data: resourceList, total: totalRows }) => {
        dispatch(setResourceList({ resource, resourceList, totalRows, resourceListDataKey }));
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(setResourceListError(errorMessage));
        onDisplayErrorNotification(errorMessage);
      });
  };
