import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'antd';

import { Resources } from '../../types/resources-types';
import { Instructor } from '../../types/instructors-types';
import { PromotionTileType } from '../../types/promotion-tile-types';
import { usePreviousValue } from '../../hooks/use-previous-value';
import PromotionTileTypeRecordSelectionModal, { TypeSelectedRecord } from './promotion-tile-type-record-selection-modal';

type PromotionTileActionLinkCreatorProps = {
  type: PromotionTileType;
  value?: string;
  onChange?: (value: any) => void;
  onSetEntityId: (entityId: string | null) => void;
};

const PromotionTileActionLinkCreator = (props: PromotionTileActionLinkCreatorProps) => {
  const { type, value, onSetEntityId, onChange } = props;
  const prevType = usePreviousValue(type);

  const [actionLink, setActionLink] = useState('');

  const getRecordActionLink = (record: TypeSelectedRecord) => {
    if (type === PromotionTileType.program) {
      return `forme://studio/program/${record.id}`;
    }

    if (type === PromotionTileType.workout) {
      return `forme://studio/workout/${record.id}`;
    }

    if (type === PromotionTileType.trainer) {
      const instructorName = encodeURIComponent(`${(record as Instructor).first_name} ${(record as Instructor).last_name}`);
      return `forme://studio/on-demand?category=All&trainer=${instructorName}`;
    }

    return '';
  };

  const setAction = useCallback(
    (v: string | undefined) => {
      const newValue = type === (PromotionTileType.custom || PromotionTileType.popup) && v ? decodeURI(v) : v;
      setActionLink(newValue || '');
    },
    [type]
  );

  useEffect(() => {
    setAction(value);
  }, [value, setAction]);

  useEffect(() => {
    if (!prevType && type) setAction(value);
    if (prevType && prevType !== type && onChange) {
      onChange('');
      onSetEntityId(null);
    }
  }, [value, type, prevType, onChange, onSetEntityId, setAction]);

  const resource = useMemo(() => {
    if (type === PromotionTileType.program) return Resources.programs;
    if (type === PromotionTileType.trainer) return Resources.instructors;
    if (type === PromotionTileType.workout) return Resources.workouts;
    return null;
  }, [type]);

  const placeholder = useMemo(() => {
    if (type === PromotionTileType.program) return 'Select program and action will be created automatically';
    if (type === PromotionTileType.trainer) return 'Select trainer and action will be created automatically';
    if (type === PromotionTileType.workout) return 'Select workout and action will be created automatically';
    return 'Please enter custom action';
  }, [type]);

  const onSelectRecord = (selectedRecord: TypeSelectedRecord) => {
    if (onChange) {
      onSetEntityId(`${selectedRecord.id}`);
      onChange(getRecordActionLink(selectedRecord));
    }
  };

  if (type === PromotionTileType.custom || type === PromotionTileType.popup) {
    return <Input placeholder={placeholder} value={actionLink} onChange={onChange} />;
  }

  return <PromotionTileTypeRecordSelectionModal title={actionLink} placeholder={placeholder} resource={resource} onSelectRecord={onSelectRecord} />;
};

export default PromotionTileActionLinkCreator;
