import moment from 'moment';

export enum TimeType {
  HH = 'hh',
  MM = 'mm',
  SS = 'ss'
}

export enum DurationType {
  seconds = 'seconds',
  milliSeconds = 'milli_seconds'
}

export enum TimeDisplayFormat {
  hms = 'HH:mm:ss',
  hm = 'HH:mm'
}

const timeCoefficients = {
  [DurationType.seconds]: 1000,
  [DurationType.milliSeconds]: 1
};

export const getClovenTimeValue = (time: number, durationType = DurationType.milliSeconds) => {
  const timeCoefficient = timeCoefficients[durationType];
  const durationTime = moment.utc(time * timeCoefficient);
  const hours = moment(durationTime).format('HH');
  const minutes = moment(durationTime).format('mm');
  const seconds = moment(durationTime).format('ss');

  return { hours, minutes, seconds };
};

export const getMomentDurationTime = (durationTime: number, durationType = DurationType.seconds) => {
  const formatted = getFormattedDurationTime(durationTime, durationType);
  return moment(formatted, TimeDisplayFormat.hms);
};

export const getFormattedDurationTime = (durationTime: number, durationType = DurationType.seconds, displayFormat = TimeDisplayFormat.hms) => {
  const initialDuration = durationType === DurationType.seconds ? durationTime * 1000 : durationTime;
  return moment.utc(initialDuration).format(displayFormat);
};
