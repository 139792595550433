import React from 'react';
import { Button, Dropdown, Radio, RadioChangeEvent } from 'antd';
import './radio-group-dropdown.scss';

type RadioGroupDropdownProps = {
  title?: string | null;
  value: any;
  radioGroupList: { value: any; text: string }[];
  onChangeRadioGroup: (e: RadioChangeEvent) => void;
};

const RadioGroupDropdown = (props: RadioGroupDropdownProps) => {
  const { title = '', value, radioGroupList, onChangeRadioGroup } = props;

  return (
    <Dropdown
      overlay={
        <Radio.Group className="card-content-wrap select-radio-group-dropdown" onChange={onChangeRadioGroup} value={value}>
          {radioGroupList.map((item) => (
            <Radio key={item.value} value={item.value}>
              {item.text}
            </Radio>
          ))}
        </Radio.Group>
      }
      trigger={['click']}
    >
      <Button className="select-radio-group-dropdown-button" onClick={(e) => e.preventDefault()}>
        {title}
      </Button>
    </Dropdown>
  );
};

export default RadioGroupDropdown;
