import React, { memo, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';

import { NOOP } from '../../constants/common-constants';
import { Resources } from '../../types/resources-types';
import { ResourceRecord } from '../../types/resource-record-types';
import useDropdownRecordSelection from '../../hooks/use-dropdown-record-selection';
import DropdownRecordSelectionList, { ListItem } from '../dropdown-record-selection-list/dropdown-record-selection-list';

type FormItemRecordSelectionProps = {
  resource: Resources;
  value?: string;
  searchBy: string;
  sortFieldKey?: string;
  libraryTitle?: string;
  placeholder?: string;
  inputPlaceholder?: string;
  selectedItemsTitle?: string;
  withDeleteBtn?: boolean;
  additionalFilters?: Record<string, any>;
  onChange?: (value: string | null) => void;
};

const FormItemRecordSelection = (props: FormItemRecordSelectionProps) => {
  const {
    withDeleteBtn = true,
    resource,
    searchBy,
    sortFieldKey = 'id',
    selectedItemsTitle,
    libraryTitle,
    placeholder,
    inputPlaceholder,
    value,
    additionalFilters,
    onChange = NOOP
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const selectedItemsIds = useMemo(() => (value ? [value] : []), [value]);

  const onSelectItems = (selectedIds: string[]) => selectedIds.length && onChange(selectedIds[0]);
  const onRemoveItem = () => onChange(null);

  const {
    itemsList,
    inputPlaceholder: dropdownPlaceholder,
    selectedListItems,
    searchInputValue,
    onChangeSearchInput,
    onSelectItem,
    onApplySelectedExercises
  } = useDropdownRecordSelection({
    resource,
    searchBy,
    sortFieldKey,
    isVisible,
    placeholder,
    isSingleModeSelection: true,
    selectedItemsIds,
    additionalFilters,
    onSelectItems,
    onClose: () => setIsVisible(false)
  });

  return (
    <Row align="middle" justify="space-between">
      <Col flex={withDeleteBtn ? 22 : 24}>
        <DropdownRecordSelectionList
          isSingleModeSelection
          placeholder={dropdownPlaceholder}
          inputPlaceholder={inputPlaceholder}
          itemsList={itemsList}
          selectedListItems={selectedListItems}
          isDropdownVisible={isVisible}
          searchInputValue={searchInputValue}
          libraryTitle={libraryTitle}
          selectedItemsTitle={selectedItemsTitle}
          onChangeSearchInput={onChangeSearchInput}
          onSelectListItem={(item: ListItem) => onSelectItem(item as ResourceRecord)}
          onChangeVisibility={setIsVisible}
          onSaveSelectedItems={onApplySelectedExercises}
        />
      </Col>
      {withDeleteBtn && (
        <Col flex={2} className="form-item-record-selection-delete-btn" onClick={onRemoveItem}>
          <CloseSquareOutlined />
        </Col>
      )}
    </Row>
  );
};

export default memo(FormItemRecordSelection);
