import React, { useMemo } from 'react';
import { Button, Card, Space, Upload, Image, Typography } from 'antd';
import { UploadOutlined, FileImageOutlined, DeleteOutlined } from '@ant-design/icons';

import api from '../../api';
import { NOOP } from '../../constants/common-constants';
import { RcFile } from 'antd/es/upload';
import { getErrorMessage } from '../../utils/errors';
import { Image as ImageRecord } from '../../types/image-types';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import SelectImageModal from '../select-image-modal/select-image-modal';
import './form-components.scss';

type UploadImageOptions = RcCustomRequestOptions & { file: RcFile };

export type FormImageData = {
  id: number | null;
  url?: string;
  name?: string;
  path: string | null;
  isUploaded: boolean;
};

type FormItemImageProps = {
  value?: string;
  title?: string;
  withResize?: boolean;
  withTitle?: boolean;
  withRemove?: boolean;
  onChange?: (value: string | null) => void;
  onChangeImageData?: (formImageData: FormImageData | null) => void;
};

const FormItemImage = (props: FormItemImageProps) => {
  const { withResize = true, title, withTitle = false, withRemove = false, value, onChange = NOOP, onChangeImageData = NOOP } = props;

  const imageSrc = useMemo(() => {
    if (!withResize) return value;
    return `${value}?width=600`;
  }, [withResize, value]);

  const onSelectImage = (imageItem: ImageRecord) => {
    if (!imageItem || !imageItem?.path) return;
    const { id: imageId, url, path, name } = imageItem;

    onChange(url);
    onChangeImageData({ url, name, isUploaded: true, path: path as string, id: imageId });
  };

  const onUploadImage = ({ file }: UploadImageOptions) =>
    api.files
      .assets({ file })
      .then(({ url, name, cdnUrl, path, id }) => {
        onChange(cdnUrl);
        onChangeImageData({ url, name, isUploaded: true, path, id });
        onDisplaySuccessNotification('Image was successfully uploaded');
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });

  const onRemoveImage = () => {
    onChange(null);
    onChangeImageData(null);
  };

  return (
    <Space direction="vertical">
      {withTitle && <Typography.Title level={5}>{title}</Typography.Title>}
      {value ? (
        <Card
          hoverable
          className="form-item-image-card"
          bodyStyle={{ padding: '5px 10px 15px', minHeight: 200 }}
          cover={<Image width={300} src={imageSrc} alt="" />}
        />
      ) : (
        <div>
          <FileImageOutlined style={{ fontSize: 150 }} />
        </div>
      )}
      <Space>
        <SelectImageModal title="Select image" onSelectImage={onSelectImage} />
        <Upload name="file" showUploadList={false} customRequest={(options) => onUploadImage(options as UploadImageOptions)}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        {withRemove && value && (
          <Button danger icon={<DeleteOutlined />} onClick={onRemoveImage}>
            Remove
          </Button>
        )}
      </Space>
    </Space>
  );
};

export default FormItemImage;
