import React, { useMemo } from 'react';
import { Button, Divider, Form, Input, Row, Typography } from 'antd';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import { RequestError } from 'src/api/http-client';
import ContentTabs from 'src/components/content-tabs/content-tabs';
import StubPage from 'src/components/stub-page/stub-page';
import { commonFormValidateMessages } from 'src/constants/form-constants';
import { SOME_ERROR_MAIN_MESSAGE } from 'src/constants/messages';
import ResourceRecordContainer from 'src/containers/resource-record-container/resource-record-container';
import { useFormValues } from 'src/hooks/use-form-values';
import useLocationHandler from 'src/hooks/use-location-handler';
import { useResourceRecordData } from 'src/hooks/use-resource-record-data';
import { useItemSelector } from 'src/hooks/use-selector';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from 'src/redux/resource-record/resource-record-selectors';
import { selectUserTimeZone } from 'src/redux/settings/settings-selectors';
import { UpdateParams } from 'src/types/request-entities-types';
import { Resources } from 'src/types/resources-types';
import { Survey } from 'src/types/survey-types';
import { getFormattedDate } from 'src/utils/date-utils';
import { getErrorMessage } from 'src/utils/errors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from 'src/utils/notifications-utils';
import { jsonValidator } from 'src/validators/json-validator';
import { stringValidator } from 'src/validators/string-validator';
import useAssetsTabs from './hooks/use-assets-tabs';

const SurveyRecordPage = () => {
  const record = useItemSelector({ resource: Resources.surveys }, getPageResourceRecord) as Survey | null;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const { secondSubRoute: recordId } = useLocationHandler();
  const timeZone = useSelector(selectUserTimeZone);

  const navigate = useNavigate();

  const { tabsConfig, isAssetsChanged, changedAssetsData, resetAssets } = useAssetsTabs(record);

  useResourceRecordData({ recordId, resource: Resources.surveys });

  const [form] = Form.useForm();

  const initialData = useMemo(
    () => ({
      ...record,
      additional_info: record?.additional_info ? JSON.stringify(record.additional_info) : '',
      created_date: record?.created_date ? moment(record.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const isDataChanged = isFormValuesChanged || isAssetsChanged;

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
    resetAssets();
  };

  const onFinish = () => {
    const nextFormValues = {
      ...changedFormValues,
      ...changedAssetsData
    } as Survey;

    if (Object.hasOwn(changedFormValues, 'additional_info')) {
      nextFormValues.additional_info = changedFormValues.additional_info ? JSON.parse(changedFormValues.additional_info) : null;
    }

    const params = {
      id: recordId,
      data: {
        ...nextFormValues,
        id: recordId
      },
      previousData: { id: recordId }
    } as UpdateParams;

    api.common
      .update(Resources.surveys, params)
      .then(() => {
        onDisplaySuccessNotification('Survey record was successfully updated');
        onReset();
        navigate(`/${Resources.surveys}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.surveys} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Survey
      </Typography.Title>

      <Form
        form={form}
        layout="horizontal"
        className="record-form"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item name="title" label="Title" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="additional_info" label="Additional Info (JSON)" rules={[{ type: 'string' }, { validator: jsonValidator }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Created">{getFormattedDate(record.created_date, timeZone)}</Form.Item>
        <Form.Item label="Updated">{getFormattedDate(record.updated_date, timeZone)}</Form.Item>
        <Divider>Assets</Divider>
        <Form.Item>
          <ContentTabs tabsConfig={tabsConfig} />
        </Form.Item>
        <Divider>Steps</Divider>
        <Form.Item>
          <Button htmlType="button" onClick={() => navigate(`/${Resources.surveys}/${recordId}/steps`)}>
            Edit Steps
          </Button>
        </Form.Item>
        <Form.Item>
          <Row align="middle" justify="space-between">
            <div>
              <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isDataChanged} onClick={onReset}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit" disabled={!isDataChanged}>
                Save
              </Button>
            </div>
          </Row>
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default SurveyRecordPage;
