import React from 'react';
import { Divider } from 'antd';
import './underline-divided-title.scss';

type UnderlineDividedTitleProps = {
  title: string;
};

const UnderlineDividedTitle = ({ title }: UnderlineDividedTitleProps) => (
  <>
    <span className="underline-divided-title">{title}</span>
    <Divider className="divider-line" />
  </>
);

export default UnderlineDividedTitle;
