import React, { useEffect, useMemo } from 'react';

import { NOOP } from '../../../constants/common-constants';
import { usePreviousValue } from '../../../hooks/use-previous-value';
import { getArmUsedOptionsList, getInitialArmUsedValue } from '../movements-utils';
import FormItemSelection from '../../../components/form-components/form-item-selection';

type MovementArmUsedFieldProps = {
  value?: string;
  bordered?: boolean;
  isLiftVod?: boolean;
  className?: string;
  numberArmsUsed?: number | null;
  onChange?: (value: string | null) => void;
};

const MovementArmUsedField = (props: MovementArmUsedFieldProps) => {
  const { value, bordered = true, className, isLiftVod = false, numberArmsUsed, onChange = NOOP } = props;

  const armUsedOptionsList = useMemo(() => getArmUsedOptionsList(isLiftVod, numberArmsUsed), [isLiftVod, numberArmsUsed]);
  const initialArmUsedValue = useMemo(() => getInitialArmUsedValue(isLiftVod, numberArmsUsed), [isLiftVod, numberArmsUsed]);
  const prevInitialArmUsedValue = usePreviousValue(initialArmUsedValue);

  useEffect(() => {
    if (initialArmUsedValue !== prevInitialArmUsedValue) onChange(initialArmUsedValue);
  }, [initialArmUsedValue, prevInitialArmUsedValue, onChange]);

  return (
    <FormItemSelection
      bordered={bordered}
      className={className}
      value={value}
      options={armUsedOptionsList}
      disabled={!armUsedOptionsList.length}
      onChange={onChange}
    />
  );
};

export default MovementArmUsedField;
