import React, { memo } from 'react';
import moment from 'moment';
import { TimePicker } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import 'antd/dist/antd.css';

import { DurationType, getFormattedDurationTime, TimeDisplayFormat } from '../../utils/time-utils';
import './time-input.scss';

type TimeInputProps = {
  durationTime: number;
  format?: string;
  className?: string;
  size?: SizeType;
  allowClear?: boolean;
  disabled?: boolean;
  durationType?: DurationType;
  onChange: (timeValue: number) => void;
};

const TimeInput = (props: TimeInputProps) => {
  const {
    durationTime,
    format = 'mm:ss',
    className,
    disabled,
    size = 'small',
    allowClear,
    durationType = DurationType.milliSeconds,
    onChange
  } = props;

  const isSecondsDurationType = durationType === DurationType.seconds;
  const formattedTimeSeconds = getFormattedDurationTime(durationTime, durationType);
  const defaultValue = moment(formattedTimeSeconds, TimeDisplayFormat.hms);

  const getTimeValue = (timeValue: moment.Moment) => {
    const formattedTime = moment(timeValue).format(TimeDisplayFormat.hms);
    return isSecondsDurationType ? moment.duration(formattedTime).asSeconds() : moment.duration(formattedTime).asMilliseconds();
  };

  const onChangeTimePicker = (time: moment.Moment | null) => time && onChange(getTimeValue(time));

  return (
    <TimePicker
      size={size}
      format={format}
      showNow={false}
      showHour={false}
      disabled={disabled}
      value={defaultValue}
      className={className}
      allowClear={allowClear}
      onChange={onChangeTimePicker}
    />
  );
};

export default memo(TimeInput);
