import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_REDIRECT_URL } from '../config';

type AuthContextProviderProps = {
  children: React.ReactNode;
};

function AuthContextProvider({ children }: AuthContextProviderProps) {
  return (
    <Auth0Provider useRefreshTokens cacheLocation="localstorage" domain={AUTH0_DOMAIN} clientId={AUTH0_CLIENT_ID} redirectUri={AUTH0_REDIRECT_URL}>
      {children}
    </Auth0Provider>
  );
}

export default AuthContextProvider;
