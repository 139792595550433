import React from 'react';
import { Button, Divider, Form, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import useInstructorAssetsTabs from './use-instructor-assets-tabs';

import { Category } from '../../types/common-types';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { categoriesList } from '../../utils/resources-dictionary-utils';
import { stringValidator } from '../../validators/string-validator';

import ContentTabs from '../../components/content-tabs/content-tabs';
import CheckboxSelectionList from '../../components/checkbox-selection-list/checkbox-selection-list';

type CreateProgramDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

type InstructorCreateRequestData = {
  first_name: string;
  last_name: string;
  categories: Category[];
  description?: string;
  bio?: string;
  quote?: string;
  hometown?: string;
  favorite_movement?: string;
  face_image_id?: number;
  full_image_id?: number;
  intro_video_id?: number;
};

const initialFormData = {
  description: '',
  bio: '',
  quote: '',
  hometown: '',
  favorite_movement: '',
  face_image_id: undefined,
  full_image_id: undefined,
  intro_video_id: undefined
};

const CreateInstructorDialog = (props: CreateProgramDialogProps) => {
  const { isOpened, onClose } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { tabsConfig, changedAssetsData } = useInstructorAssetsTabs();
  const { changedFormValues, onValuesChange } = useFormValues({ initialData: initialFormData });

  const { first_name, last_name, categories } = changedFormValues;
  const isSubmitAvailable = first_name && last_name && Array.isArray(categories) && categories.length;

  const onReset = () => form.resetFields();

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const instructorRecord = form.getFieldsValue(true) as InstructorCreateRequestData;
    const params = { data: { ...instructorRecord, ...changedAssetsData } } as CreateParams;

    api.common
      .create(Resources.instructors, params)
      .then(({ data: { id: newRecordId } }) => {
        onDisplaySuccessNotification('Instructor record was successfully created');
        navigate(`/${Resources.instructors}/${newRecordId}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={900}
      footer={null}
      maskClosable={false}
      visible={isOpened}
      wrapClassName="modal-wrap"
      title="Create instructor record"
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialFormData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="first_name" label="First name" validateFirst rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Last name" validateFirst rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="categories" label="Categories" validateFirst rules={[{ required: true }, { type: 'array' }]}>
          <CheckboxSelectionList isMultiple options={categoriesList} />
        </Form.Item>
        <Divider orientation="center" plain>
          Assets
        </Divider>
        <Form.Item label="Assets">
          <ContentTabs tabsConfig={tabsConfig} />
        </Form.Item>
        <Divider orientation="center" plain>
          Infos
        </Divider>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="bio" label="BIO" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="quote" label="Quote" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="hometown" label="Hometown" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="favorite_movement" label="Favorite movement" rules={[{ type: 'string' }]}>
          <Input />
        </Form.Item>
        <Divider />

        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isSubmitAvailable}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateInstructorDialog;
