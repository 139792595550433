export enum Verbs {
  read = 'read',
  update = 'update',
  create = 'create',
  delete = 'delete'
}

export enum Resources {
  workouts = 'workouts',
  workoutFilters = 'workout-filters',
  promotionTiles = 'promotion-tiles',
  images = 'images',
  contentPlans = 'content-plans',
  videos = 'videos',
  studioAssets = 'studio-assets',
  studioWidgets = 'studio-widgets',
  studioConfigs = 'studio-configs',
  programs = 'programs',
  instructors = 'instructors',
  transcodingJobs = 'transcoding-jobs',
  exercises = 'exercises',
  surveys = 'surveys',
  groups = 'groups',
  cms = 'cms' // Super User Permission Resource,
}

export type Permission = `${Verbs}:${Resources}`;
export type PagePermissions = Record<string, Permission>;
export type PagesPermissions = Record<Resources, PagePermissions>;
