import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Row, Col, Button } from 'antd';

import { Resources } from '../../types/resources-types';
import { useContainerSize } from '../../hooks/use-container-size';
import { BASE_LAYOUT_CONTENT_PADDING } from '../../constants/style-constants';
import useWorkoutMovementsData from './hooks/use-workout-movements-data';
import useWorkoutMovements from './hooks/use-workout-movements';

import StubPage from '../../components/stub-page/stub-page';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import WorkoutMovementsHeaderActions from './workout-movements-header-actions';
import CreateMovementsBlockDrawer from './workout-movement-drawers/create-movements-block-drawer';
import CreateMovementDrawer from './workout-movement-drawers/create-movement-drawer';
import CreateTransitionDrawer from './workout-movement-drawers/create-transition-drawer';
import WorkoutMovementsTable from './workout-movement-table/workout-movements-table';
import EditMovementDrawer from './workout-movement-drawers/edit-movement-drawer';
import EditTransitionDrawer from './workout-movement-drawers/edit-transition-drawer';
import './workout-movemnts.scss';
import { FolderAddOutlined } from '@ant-design/icons';
import { AvailableWorkoutTypes } from 'src/utils/resources-dictionary-utils';

const WorkoutMovementPage = () => {
  const { record, isLoading, blocksOptionsList } = useWorkoutMovementsData();

  const {
    liftAttachmentsList,
    blockDrawerParams,
    onOpenCreateBlock,
    onOpenCreateMovement,
    onOpenCreateTransition,
    onCloseDrawerBlocks,
    onCreateMovementsBlock,
    onCreateMovement,
    onCreateTransition,
    onReorderBlockMovements,
    onDeleteMovement,
    onEditMovement,
    onOpenInsertMovementDrawer,
    onOpenInsertTransitionDrawer,
    onOpenEditMovementDrawer,
    onOpenEditTransitionDrawer,
    onRemoveBlock,
    onEditBlockName,
    onReorderBlocks
  } = useWorkoutMovements({ record });

  const navigate = useNavigate();
  const [{ height: pageHeight }, setPageRef] = useContainerSize();
  const [{ height: headerHeight }, setHeaderRef] = useContainerSize();
  const [{ height: footerHeight }, setFooterRef] = useContainerSize();

  const tableHeight = useMemo(
    () => pageHeight - headerHeight - footerHeight - BASE_LAYOUT_CONTENT_PADDING * 3,
    [pageHeight, headerHeight, footerHeight]
  );

  if (!record) return null;

  if (!AvailableWorkoutTypes.includes(record?.session_type)) {
    const secondaryMessage = record ? `Content for Workout Movements with "${record?.session_type}" type is still in development.` : '';
    return <StubPage mainMessage="In development" secondaryMessage={secondaryMessage} />;
  }

  return (
    <ResourceRecordContainer
      innerRef={setPageRef}
      record={record}
      resource={Resources.workouts}
      isLoading={isLoading}
      className="workout-movements-page"
    >
      <Row ref={setHeaderRef} align="middle" justify="space-between" className="header-section">
        <Col flex={16}>
          <Typography.Title className="movements-page-title" level={3}>
            {record.name} {record.production_id ? `(${record.production_id})` : ''}/ {record.session_type}
          </Typography.Title>
          <span className="movements-page-subtitle">{record.production_id}</span>
        </Col>
        <Col flex={8} className="movements-header-actions">
          <WorkoutMovementsHeaderActions
            isBlocksExist={!!(record?.blocks ?? []).length}
            onCreateNewBlock={onOpenCreateBlock}
            onCreateNewMovement={onOpenCreateMovement}
            onCreateNewTransition={onOpenCreateTransition}
          />
        </Col>
      </Row>
      <WorkoutMovementsTable
        record={record}
        tableHeight={tableHeight}
        onReorderBlockMovements={onReorderBlockMovements}
        onDeleteMovement={onDeleteMovement}
        onOpenInsertMovementDrawer={onOpenInsertMovementDrawer}
        onOpenInsertTransitionDrawer={onOpenInsertTransitionDrawer}
        onOpenEditMovementDrawer={onOpenEditMovementDrawer}
        onOpenEditTransitionDrawer={onOpenEditTransitionDrawer}
        onOpenCreateNewMovement={onOpenCreateMovement}
        onOpenCreateNewTransition={onOpenCreateTransition}
        onEditMovement={onEditMovement}
        onRemoveBlock={onRemoveBlock}
        onEditBlockName={onEditBlockName}
        onReorderBlocks={onReorderBlocks}
      />
      <Row ref={setFooterRef} gutter={8} align="middle" justify="space-between" className="movements-page-footer">
        <Col flex={24}>
          <Button block icon={<FolderAddOutlined />} onClick={onOpenCreateBlock}>
            Add block
          </Button>
        </Col>
        <Col className="return-button">
          <Button type="primary" onClick={() => navigate(`/workouts/${record.id}`)}>
            Return to workout
          </Button>
        </Col>
      </Row>
      <CreateMovementsBlockDrawer record={record} params={blockDrawerParams} onClose={onCloseDrawerBlocks} onCreateBlock={onCreateMovementsBlock} />
      <CreateMovementDrawer
        record={record}
        liftAttachmentsList={liftAttachmentsList}
        params={blockDrawerParams}
        blocksOptionsList={blocksOptionsList}
        onClose={onCloseDrawerBlocks}
        onCreateMovement={onCreateMovement}
      />
      <CreateTransitionDrawer
        params={blockDrawerParams}
        blocksOptionsList={blocksOptionsList}
        onClose={onCloseDrawerBlocks}
        onCreateTransition={onCreateTransition}
      />
      <EditMovementDrawer
        record={record}
        liftAttachmentsList={liftAttachmentsList}
        params={blockDrawerParams}
        blocksOptionsList={blocksOptionsList}
        onClose={onCloseDrawerBlocks}
        onEditMovement={onEditMovement}
      />
      <EditTransitionDrawer
        params={blockDrawerParams}
        blocksOptionsList={blocksOptionsList}
        onClose={onCloseDrawerBlocks}
        onEditTransition={onEditMovement}
      />
    </ResourceRecordContainer>
  );
};

export default WorkoutMovementPage;
