import React, { useState } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import api from '../../api';
import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';

import { Order, statusesColorsMap } from '../../types/common-types';
import { Program } from '../../types/programs-types';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { useSelector } from 'react-redux';
import { DragOutlined } from '@ant-design/icons';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { setResourceList } from '../../redux/resource-list/resource-list-reducer';
import { selectResourceListTotalRows } from '../../redux/resource-list/resource-list-selectors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';

import DragHandle from '../../components/drag-handle';
import CreateProgramDialog from './programs-create-dialog';
import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';
import CheckTagButton from '../../components/buttons/check-tag-button';
import MinusTagButton from '../../components/buttons/minus-tag-button';
import AccessibleTableTags from '../../components/accessible-table/accessible-table-tags';

const resource = Resources.programs;
const initialSortData = { sortField: 'order', sortOrder: Order.asc };
const searchFilterFields = ['name'];

const ProgramsListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const totalRows = useSelector(selectResourceListTotalRows);
  const onSetResourceList = useAction(setResourceList);

  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });
  useResourceListTableSorting({ ...initialSortData, resource });

  const onSortEnd = (orderedProgramsList: Record<string, any>[]) => {
    onSetResourceList({ resource, resourceList: orderedProgramsList as Program[], totalRows });

    api.common
      .updateListOrder(resource, { ids: orderedProgramsList.map((item) => item.id) })
      .then(({ data, total }) => {
        onSetResourceList({ resource, resourceList: data as Program[], totalRows: total });
        onDisplaySuccessNotification('Programs list was successfully sorted!');
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const programsColumns = [
    {
      width: 70,
      key: 'sort',
      dataIndex: 'sort',
      fixed: 'left' as FixedType,
      isRequired: true,
      className: 'drag-visible',
      title: () => <DragOutlined />,
      render: () => <DragHandle />
    },
    {
      width: 250,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left' as FixedType,
      isRequired: true,
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      )
    },
    {
      width: 150,
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (value: string) => <AccessibleTableTags colored bordered tags={[value]} colorsMap={statusesColorsMap} />
    },
    {
      width: 80,
      key: 'elements',
      title: 'Workouts',
      dataIndex: 'elements',
      render: (elements: any) => <span>{Array.isArray(elements) ? elements.length : elements}</span>
    },
    {
      width: 170,
      key: 'created_date',
      title: 'Created date',
      dataIndex: 'created_date',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 140,
      key: 'is_lift',
      title: 'Is lift',
      dataIndex: 'is_lift',
      render: (value: any) => (value ? <CheckTagButton /> : <MinusTagButton />)
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated date',
      dataIndex: 'updated_date',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 170,
      key: 'published_date',
      title: 'Published date',
      dataIndex: 'published_date',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  return (
    <ResourceListContainer
      isDraggable
      withCreate
      withSearchFilter
      withDynamicColumns
      withPagination={false}
      resource={resource}
      columns={programsColumns}
      searchFilterPlaceholder="Search programs by name..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onSortEnd={onSortEnd}
    >
      <CreateProgramDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default ProgramsListPage;
