import React from 'react';

import { Program } from '../../types/programs-types';
import useTransferWorkoutsToProgram from './use-transfer-workouts-to-program';
import TransferModal from '../../components/transfer-modal/transfer-modal';

type TransferWorkoutsToProgramModalProps = {
  program: Program;
};

const TransferWorkoutsToProgramModal = ({ program }: TransferWorkoutsToProgramModalProps) => {
  const {
    searchInputValue,
    targetKeys,
    selectedKeys,
    transferSource,
    totalSource,
    onChangeSearchInput,
    onChangeTransfer,
    onSelectChange,
    onCloseModalCallBack
  } = useTransferWorkoutsToProgram(program);

  return (
    <TransferModal
      withActions={false}
      modalTitle="Modify workouts"
      modalButtonTitle="Modify workouts"
      inputPlaceholder="Search workout by name"
      transferSectionsTitles={['Available workouts', 'Program workouts']}
      searchInputValue={searchInputValue}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      transferSource={transferSource}
      totalSource={totalSource}
      onChangeSearchInput={onChangeSearchInput}
      onChangeTransfer={onChangeTransfer}
      onSelectChange={onSelectChange}
      onCloseModalCallBack={onCloseModalCallBack}
    />
  );
};

export default TransferWorkoutsToProgramModal;
