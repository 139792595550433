import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Input, Row, Space, Typography } from 'antd';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { UpdateParams } from '../../types/request-entities-types';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { stringValidator } from '../../validators/string-validator';
import { useItemSelector } from '../../hooks/use-selector';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { PromotionTile, PromotionTileType } from '../../types/promotion-tile-types';
import { resourcesDictionaryUtils, promotionTileTypes } from '../../utils/resources-dictionary-utils';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectResourceRecordError,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading
} from '../../redux/resource-record/resource-record-selectors';

import StubPage from '../../components/stub-page/stub-page';
import FormItemSelection from '../../components/form-components/form-item-selection';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import PromotionTileActionLinkCreator from './promotion-tile-action-link-creator';
import FormItemImage, { FormImageData } from '../../components/form-components/form-item-image';
import RangePickerWithTimeZone from '../../components/range-picker/range-picker-with-time-zone';
import SelectResourceRecordTargetGroups from './promotion-tile-target-groups-selector';

const initialFormImageData = { id: null, isUploaded: false, path: null };

const PromotionTileRecordPage = () => {
  const record = useItemSelector({ resource: Resources.promotionTiles }, getPageResourceRecord) as PromotionTile;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const { secondSubRoute: recordId } = useLocationHandler();
  const onSetPromotionTileRecord = useAction(setResourceRecord);

  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useResourceRecordData({ recordId, resource: Resources.promotionTiles });

  const initialData = useMemo(() => {
    const startedAt = record?.started_at ? moment(record.started_at) : undefined;
    const expiredAt = record?.expired_at ? moment(record?.expired_at) : undefined;
    return { ...record, startsExpiresRange: [startedAt, expiredAt] };
  }, [record]);

  const [entityId, setEntityId] = useState<string | null>(null);

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });
  const [formImageData, setFormImageData] = useState<FormImageData | null>(initialFormImageData);

  const nextFormValues = useMemo(() => {
    const { startsExpiresRange, ...restFormValues } = changedFormValues;
    const [started_at, expired_at] = startsExpiresRange || [];
    const formValues = { ...restFormValues };

    if (started_at && expired_at) {
      formValues.started_at = started_at;
      formValues.expired_at = expired_at;
    }
    const isCustomType = formValues.type === PromotionTileType.custom;
    const additionalData = isCustomType || !entityId ? {} : { entity_id: entityId };
    if (!formImageData || !formImageData.isUploaded || !formImageData.path) return { ...formValues, ...additionalData };
    return { ...formValues, ...additionalData, image: formImageData.path };
  }, [entityId, changedFormValues, formImageData]);

  const onClearFormImageData = () => setFormImageData(formImageData);

  const onReset = () => {
    setIsProcessing(true);
    form.resetFields();
    onClearFormImageData();
    onClearChangedFormValues();

    setIsProcessing(false);
  };

  const onFinish = (isSaveAndContinue?: boolean) => {
    const params = { id: recordId, data: { id: recordId, ...nextFormValues }, previousData: { id: recordId } } as UpdateParams;
    setIsProcessing(true);
    if (record.type === PromotionTileType.custom) {
      if (params.data.action) {
        params.data.action = encodeURI(params.data.action);
      }
    }

    api.common
      .update(Resources.promotionTiles, params)
      .then((updatedPromotionTile: PromotionTile) => {
        setIsProcessing(false);
        onSetPromotionTileRecord(updatedPromotionTile);
        onDisplaySuccessNotification('Promotion Tile record was successfully updated');
        if (!isSaveAndContinue) navigate(`/${Resources.promotionTiles}`);
      })
      .catch((error: RequestError) => {
        setIsProcessing(false);
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.promotionTiles} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Promotion Tile
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <FormItemSelection options={resourcesDictionaryUtils} />
        </Form.Item>
        <Form.Item name="startsExpiresRange" label="Starts / Expires on" rules={[{ required: true }]}>
          <RangePickerWithTimeZone
            showTime
            placeholder={['Starts on', 'Expires on']}
            disabledDate={(current) => current && current < moment().endOf('day')}
          />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <FormItemSelection options={promotionTileTypes} />
        </Form.Item>
        <Form.Item
          label="Action"
          rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}
          shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}
        >
          {(params) => (
            <Form.Item name="action" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
              <PromotionTileActionLinkCreator type={params.getFieldValue('type') as PromotionTileType} onSetEntityId={setEntityId} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="image" label="Image" rules={[{ required: true }]}>
          <FormItemImage onChangeImageData={setFormImageData} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Target Groups">
          <SelectResourceRecordTargetGroups isEditable />
        </Form.Item>

        <Form.Item shouldUpdate colon={false} label=" ">
          {(params) => {
            const hasErrors = !!params.getFieldsError().filter(({ errors }) => errors.length).length;
            const isSubmitDisabled = !isFormValuesChanged || hasErrors;
            return (
              <Row align="middle" justify="end">
                <Space>
                  <Button htmlType="button" disabled={!isFormValuesChanged || isProcessing} onClick={onReset}>
                    Discard changes
                  </Button>
                  <Button type="primary" disabled={isSubmitDisabled || isProcessing} onClick={() => onFinish()}>
                    Save
                  </Button>
                  <Button type="primary" disabled={isSubmitDisabled || isProcessing} onClick={() => onFinish(true)}>
                    Save and continue editing
                  </Button>
                </Space>
              </Row>
            );
          }}
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default PromotionTileRecordPage;
