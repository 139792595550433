import React, { useMemo } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import cs from 'classnames';
import { VideoSourceItem } from './video-player';
import './video-player.scss';

type VideoPlayerSourcesSelectionProps = {
  sources: VideoSourceItem[];
  activeSource: VideoSourceItem | null;
  onSelectSource: (sourceValue: string) => void;
};

const VideoPlayerSourcesSelection = (props: VideoPlayerSourcesSelectionProps) => {
  const { sources, activeSource, onSelectSource } = props;

  const menuItems = useMemo(
    () =>
      sources.map((item) => ({
        key: item.value,
        disabled: !item.isSupported,
        className: cs('video-player-source-selection-menu-item', {
          active: item.value === activeSource?.value,
          disabled: !item.isSupported
        }),
        label: `${item.text} ${!item.isSupported ? '(not supported)' : ''}`,
        onClick: () => onSelectSource(item.value)
      })),
    [sources, activeSource, onSelectSource]
  );

  return (
    <Dropdown
      arrow={false}
      placement="topLeft"
      overlay={<Menu className="video-player-source-selection-menu" items={menuItems} />}
      className="video-player-source-selection"
    >
      <Button>
        Sources <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default VideoPlayerSourcesSelection;
