import { HttpClient, RequestMethods } from './http-client';
import { Resources } from '../types/resources-types';
import { Identifier } from '../types/request-entities-types';

export enum EntitiesSubUrls {
  status = 'status',
  archive = 'archive',
  elements = 'elements',
  elementsBatch = 'elements/batch',
  blocks = 'blocks',
  collapsedBlocks = 'collapsed-blocks',
  collapsedBlocksOrder = 'blocks/order',
  movements = 'movements',
  collapsedMovements = 'collapsed-movement',
  collapsedMovementsOrder = 'collapsed-movement/order',
  programElementsOrder = 'program_elements/order',
  elementsOrder = 'elements/order',
  movementsOrder = 'movements/order',
  schedulePublish = 'schedule_publish',
  contentPlans = 'content_plans',
  workoutBlock = 'blocks',
  sync = 'sync',
  isAssigned = 'is-assigned',
  steps = 'steps',
  order = 'order',
  promotionTargetGroups = 'target_groups'
}

export type GetResourceEntityParams = {
  resource: Resources;
  recordId: Identifier;
  entitySubUrl: EntitiesSubUrls;
};

export type ResourceEntityParams = {
  resource: Resources;
  recordId: Identifier;
  entitySubUrl: string;
  data?: Record<string, any>;
  method?: RequestMethods;
};

export type RemoveResourceEntityParams = {
  resource: Resources;
  recordId: Identifier;
  entitySubUrl: string;
};

const resourcesEntitiesApi = (baseUrl: string, httpClient: HttpClient) => ({
  getResourceEntity: (params: GetResourceEntityParams) => {
    const { resource, recordId, entitySubUrl } = params;
    const requestUrl = `${baseUrl}/${resource}/${recordId}/${entitySubUrl}`;
    return httpClient(requestUrl);
  },
  updateResourceEntity: (params: ResourceEntityParams) => {
    const { resource, recordId, entitySubUrl, data, method = RequestMethods.put } = params;
    const requestUrl = `${baseUrl}/${resource}/${recordId}/${entitySubUrl}`;
    return httpClient(requestUrl, { method, body: JSON.stringify(data) });
  },
  createResourceEntity: (params: ResourceEntityParams) => {
    const { resource, recordId, entitySubUrl, data } = params;
    const requestUrl = `${baseUrl}/${resource}/${recordId}/${entitySubUrl}`;
    return httpClient(requestUrl, {
      method: RequestMethods.post,
      body: JSON.stringify(data)
    });
  },
  removeResourceEntity: (params: RemoveResourceEntityParams) => {
    const { resource, recordId, entitySubUrl } = params;
    const requestUrl = `${baseUrl}/${resource}/${recordId}/${entitySubUrl}`;
    return httpClient(requestUrl, { method: RequestMethods.delete });
  }
});

export default resourcesEntitiesApi;
