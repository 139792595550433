import React from 'react';
import { Drawer, Form, Button, Col, Row, Space } from 'antd';

import { BlockDrawerParams, BlockDrawerType, CreateTransitionFormValues } from '../hooks/use-workout-movements';
import FormItemSelection from '../../../components/form-components/form-item-selection';
import WorkoutMovementDurationInput from '../workout-movement-duration-input';
import '../workout-movemnts.scss';

type CreateTransitionDrawerProps = {
  params: BlockDrawerParams;
  blocksOptionsList: { value: string | number; text: string }[];
  onClose: () => void;
  onCreateTransition: (formData: CreateTransitionFormValues) => void;
};

const CreateTransitionDrawer = (props: CreateTransitionDrawerProps) => {
  const { params, blocksOptionsList, onClose, onCreateTransition } = props;
  const { movement, order, blockId } = params;

  const isVisible = params.type === BlockDrawerType.createTransition;

  if (!isVisible) return null;

  const [form] = Form.useForm();
  const initialFormValues = {
    order,
    duration: movement?.duration ?? 0,
    block_id: movement?.block_id ?? blockId
  };

  const onFinishCreateTransitionForm = () => {
    onCreateTransition(form.getFieldsValue(true));
  };

  return (
    <Drawer maskClosable={false} title="Transition" width={800} onClose={onClose} visible={isVisible} bodyStyle={{ paddingBottom: 80 }}>
      <Form
        form={form}
        hideRequiredMark
        layout="vertical"
        initialValues={initialFormValues}
        className="create-movement-block-form"
        onFinish={onFinishCreateTransitionForm}
      >
        <Row align="middle" gutter={16}>
          <Col span={24}>
            <Form.Item
              name="block_id"
              className="full-width"
              label="Select block"
              rules={[{ required: true, message: 'Please select one or multiple movements' }]}
            >
              <FormItemSelection options={blocksOptionsList} />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" gutter={16}>
          <Col span={8}>
            <Form.Item name="duration" label="Duration" className="full-width">
              <WorkoutMovementDurationInput withSaveButton={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Drawer>
  );
};

export default CreateTransitionDrawer;
