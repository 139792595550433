import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Typography } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';

import api from '../../api';
import useLocationHandler from '../../hooks/use-location-handler';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { useNavigate } from 'react-router-dom';
import { RequestError } from '../../api/http-client';
import { UpdateParams } from '../../types/request-entities-types';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { getFormattedDate } from '../../utils/date-utils';
import { useItemSelector } from '../../hooks/use-selector';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { ContentPlan } from '../../types/content-plans-types';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { stringValidator } from '../../validators/string-validator';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';
import StubPage from '../../components/stub-page/stub-page';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';

const ContentPlanRecordPage = () => {
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const record = useItemSelector({ resource: Resources.contentPlans }, getPageResourceRecord) as ContentPlan;
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const timeZone = useSelector(selectUserTimeZone);
  const { secondSubRoute: recordId } = useLocationHandler();
  const onSetContentPlanRecord = useAction(setResourceRecord);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const initialData = useMemo(
    () => ({
      ...record,
      created_date: record?.created_date ? moment(record.created_date) : undefined,
      updated_date: record?.updated_date ? moment(record?.updated_date) : undefined
    }),
    [record]
  );

  useResourceRecordData({ recordId, resource: Resources.contentPlans });

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const onFinish = () => {
    const params = { id: recordId, data: { id: recordId, ...changedFormValues }, previousData: { id: recordId } } as UpdateParams;

    api.common
      .update(Resources.contentPlans, params)
      .then((contentPlanRecord: ContentPlan) => {
        onSetContentPlanRecord(contentPlanRecord);
        onDisplaySuccessNotification('Content plan record was successfully updated');
        onReset();
        navigate(`/${Resources.contentPlans}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.contentPlans} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Content plan
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string', max: 255 }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item validateFirst={false} label="Created date">
          <span>{getFormattedDate(record?.created_date ?? '', timeZone)}</span>
        </Form.Item>
        <Form.Item validateFirst={false} label="Updated date">
          <span>{getFormattedDate(record?.updated_date ?? '', timeZone)}</span>
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default ContentPlanRecordPage;
