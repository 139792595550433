import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Row, Col, Button, Modal } from 'antd';
import { Resources } from '../../types/resources-types';
import { useContainerSize } from '../../hooks/use-container-size';
import { BASE_LAYOUT_CONTENT_PADDING } from '../../constants/style-constants';
import { useResourceRecordData } from 'src/hooks/use-resource-record-data';
import { useSelector } from 'react-redux';
import { useItemSelector } from 'src/hooks/use-selector';
import useLocationHandler from 'src/hooks/use-location-handler';
import { getPageResourceRecord, selectIsResourceRecordLoading } from 'src/redux/resource-record/resource-record-selectors';
import { Survey, SurveyStep } from 'src/types/survey-types';
import ResourceRecordContainer from 'src/containers/resource-record-container/resource-record-container';
import { SurveyStepsTable } from './survey-steps-table';
import { SurveyStepDrawer } from './survey-step-drawer';
import { useSurveySteps } from './hooks/use-survey-steps';

const SurveyStepsPage = () => {
  const [editingStep, setEditingStep] = useState<SurveyStep | null>(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [isPublishConfirmVisible, setIsPublishConfirmVisible] = useState(false);

  const onCloseDrawer = () => {
    setIsDrawerOpened(false);
    setEditingStep(null);
  };

  const onEditStep = (step: SurveyStep) => {
    setEditingStep(step);
    setIsDrawerOpened(true);
  };

  const record = useItemSelector({ resource: Resources.surveys }, getPageResourceRecord) as Survey | null;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const { secondSubRoute: recordId } = useLocationHandler();
  useResourceRecordData({ recordId, resource: Resources.surveys });

  const { onReorderSteps, onCreateStep, onDeleteStep, onUpdateStep, onPublish } = useSurveySteps({ record, onStepUpdated: onCloseDrawer });

  const navigate = useNavigate();
  const [{ height: pageHeight }, setPageRef] = useContainerSize();
  const [{ height: headerHeight }, setHeaderRef] = useContainerSize();
  const [{ height: footerHeight }, setFooterRef] = useContainerSize();

  const tableHeight = useMemo(
    () => pageHeight - headerHeight - footerHeight - BASE_LAYOUT_CONTENT_PADDING * 3,
    [pageHeight, headerHeight, footerHeight]
  );

  if (!record) return null;

  return (
    <ResourceRecordContainer innerRef={setPageRef} record={record} resource={Resources.surveys} isLoading={isLoading}>
      <Row ref={setHeaderRef} align="middle" justify="space-between" className="header-section">
        <Col flex="auto">
          <Typography.Title level={3}>{record.title}</Typography.Title>
          <span>{record.name}</span>
        </Col>
        <Col style={{ paddingRight: 10 }}>
          <Button onClick={() => setIsPublishConfirmVisible(true)}>Publish</Button>
        </Col>
        <Col>
          <Button onClick={() => setIsDrawerOpened(true)}>Create new step</Button>
        </Col>
        <Modal visible={isPublishConfirmVisible} title="Confirm publish" onCancel={() => setIsPublishConfirmVisible(false)} onOk={() => onPublish()}>
          Are you sure you want to publish this survey?
        </Modal>
      </Row>
      <SurveyStepsTable
        record={record}
        tableHeight={tableHeight}
        onReorderSteps={onReorderSteps}
        onDeleteStep={onDeleteStep}
        onEditStep={onEditStep}
      />
      <Row ref={setFooterRef} gutter={8} align="middle" justify="space-between">
        <Col className="return-button">
          <Button type="primary" onClick={() => navigate(`/${Resources.surveys}/${record.id}`)}>
            Return to survey
          </Button>
        </Col>
      </Row>
      <SurveyStepDrawer
        record={editingStep}
        isOpened={isDrawerOpened}
        onClose={onCloseDrawer}
        onConfirm={editingStep ? onUpdateStep : onCreateStep}
      />
    </ResourceRecordContainer>
  );
};

export default SurveyStepsPage;
