import React from 'react';
import { Typography } from 'antd';
import './stub-page.scss';

type StubPageProps = {
  mainMessage: string;
  secondaryMessage?: string;
};

export default function StubPage(props: StubPageProps) {
  const { mainMessage, secondaryMessage } = props;

  return (
    <div className="stub-page-wrap">
      <Typography.Title level={2}>{mainMessage}</Typography.Title>
      {secondaryMessage && <Typography.Title level={4}>{secondaryMessage}</Typography.Title>}
    </div>
  );
}
