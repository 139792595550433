import React, { ChangeEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Space, Divider, List, Button, Image } from 'antd';
import { PlusOutlined, CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import cs from 'classnames';

import api from '../../api';
import { getErrorMessage } from '../../utils/errors';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import Loader from '../loader';
import './images-upload-modal.scss';
import { FileDropZone } from './file-drop-zone';

type FileItem = {
  id: string;
  file: File;
};

type ImageFileItem = {
  id: string;
  name: string;
  size: number;
  url: string | ArrayBuffer | null;
  isError?: boolean;
};

type ImagesUploadModalProps = {
  isOpened: boolean;
  onClose: () => void;
};

const MAX_IMAGES_COUNT = 25;

const ACCEPTABLE_FILES = '.png, .jpg, .jpeg';
const ACCEPTABLE_DROP_FILES = ACCEPTABLE_FILES.split(', ').map((str) => `image/${str.slice(1)}`);

const ImagesUploadModal = (props: ImagesUploadModalProps) => {
  const { isOpened, onClose } = props;

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<FileItem[]>([]);
  const [images, setImages] = useState<ImageFileItem[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const filesAllowedCount = MAX_IMAGES_COUNT - files.length;

  const onReset = () => {
    setFiles([]);
    setImages([]);
  };

  const onCloseModal = () => {
    onClose();
    onReset();
  };

  const onClearFileInput = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
  };

  const onAddImages = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const onRemoveFile = (fileId: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    setImages((prevImages) => prevImages.filter((image) => image.id !== fileId));
  };

  const onSetFileError = (fileId: string) => {
    setImages((prevImages) =>
      prevImages.map((image) => {
        if (image.id !== fileId) return image;
        return { ...image, isError: true };
      })
    );
  };

  const readFiles = (filesToRead: FileList | File[]) => {
    const filesArrayLength = filesAllowedCount >= filesToRead.length ? filesToRead.length : filesAllowedCount;
    const filesArray = Array.from({ length: filesArrayLength }, (_, i) => filesToRead[i]);

    filesArray.map((inputFile) => {
      if (!inputFile) return;

      const id = nanoid();
      const reader = new FileReader();

      reader.onloadend = () => {
        const url = reader.result;
        const size = Math.round(inputFile.size / 1024);
        const name = inputFile.name;

        setFiles((prevFiles) => [...prevFiles, { id, file: inputFile }]);
        setImages((prevImages) => [...prevImages, { id, url, size, name }]);
      };

      reader.readAsDataURL(inputFile);
    });
  };

  const onInputChange = (input: ChangeEvent<HTMLInputElement>) => {
    const { files: inputFiles } = input.currentTarget;
    if (!inputFiles || !inputFiles.length) return;

    readFiles(inputFiles);

    onClearFileInput();
  };

  const onUploadImage = ({ id, file }: FileItem) => {
    return api.files
      .assets({ file })
      .then(() => {
        onRemoveFile(id);
      })
      .catch((error) => {
        onSetFileError(id);
        onDisplayErrorNotification(`An error occurred and the file "${file.name}" was not loaded`);
        return Promise.reject(error);
      });
  };

  const onUploadImages = () => {
    setIsUploading(true);

    Promise.all(files.map(onUploadImage))
      .then(() => {
        setIsUploading(false);
        onDisplaySuccessNotification('All images was successfully uploaded');
        onCloseModal();
        navigate(0);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        setIsUploading(false);
        onDisplayErrorNotification(errorMessage);
      });
  };

  const onFileDrop = (dropped: File[]) => {
    const filtered = dropped.filter(({ type }) => ACCEPTABLE_DROP_FILES.includes(type));

    readFiles(filtered);
  };

  return (
    <Modal
      centered
      width={700}
      footer={null}
      destroyOnClose
      maskClosable={false}
      className="upload-images-modal-wrap"
      visible={isOpened}
      title="Upload images"
      onCancel={onCloseModal}
    >
      <div className="upload-images-wrapper">
        <div className="upload-images-content-wrapper">
          {isUploading && (
            <div className="upload-images-loader">
              <Loader />
            </div>
          )}
          <FileDropZone onDrop={onFileDrop}>
            <div className="upload-images-content">
              <List
                size="large"
                itemLayout="vertical"
                dataSource={images}
                className="upload-images-list"
                renderItem={(image) => (
                  <List.Item
                    key={image.id}
                    extra={<Image height={70} alt={image.name} src={image.url as string} />}
                    actions={[<Button key="remove" danger icon={<CloseOutlined />} onClick={() => onRemoveFile(image.id)} />]}
                    className={cs({ ['ant-list-item-error']: image.isError })}
                  >
                    <List.Item.Meta title={image.name} />
                  </List.Item>
                )}
              />
            </div>
          </FileDropZone>
        </div>
        <Divider />
        <div className="upload-images-footer">
          <input ref={inputRef} multiple type="file" className="upload-images-input" accept={ACCEPTABLE_FILES} onChange={onInputChange} />
          <Space>
            <Button icon={<PlusOutlined />} disabled={filesAllowedCount <= 0} onClick={onAddImages}>
              Add images
            </Button>
            <Button icon={<CloudUploadOutlined />} type="primary" disabled={!files.length} onClick={onUploadImages}>
              Upload
            </Button>
            <Button onClick={onCloseModal}>Close</Button>
          </Space>
        </div>
      </div>
    </Modal>
  );
};

export default ImagesUploadModal;
