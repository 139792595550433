import React, { useState } from 'react';
import { Button, InputNumber } from 'antd';

import { NOOP } from '../../constants/common-constants';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SaveOutlined } from '@ant-design/icons';

type AccessibleTableInputNumberEditCellProps = {
  value?: number;
  size?: SizeType;
  className?: string;
  minNumber?: number;
  maxNumber?: number;
  onChange?: (value: number) => void;
};

const AccessibleTableInputNumberEditCell = (props: AccessibleTableInputNumberEditCellProps) => {
  const { size, value = 0, className, minNumber, maxNumber, onChange = NOOP } = props;

  const [valueNumber, setValueNumber] = useState(value);
  const isDisabled = !Number.isInteger(valueNumber) || valueNumber === value;

  const onSaveChanges = () => onChange(valueNumber);

  return (
    <div className="accessible-table-input-number-edit-cell">
      <InputNumber
        className={className}
        size={size}
        min={minNumber}
        max={maxNumber}
        value={valueNumber}
        onChange={setValueNumber}
        onPressEnter={onSaveChanges}
      />
      <Button type="primary" className="accessible-table-text-area-button" disabled={isDisabled} icon={<SaveOutlined />} onClick={onSaveChanges} />
    </div>
  );
};

export default AccessibleTableInputNumberEditCell;
