import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Typography } from 'antd';
import 'antd/dist/antd.css';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { useAction } from '../../hooks/use-actions';
import { RequestError } from '../../api/http-client';
import { UpdateParams } from '../../types/request-entities-types';
import { StudioConfig } from '../../types/studio-configs';
import { useFormValues } from '../../hooks/use-form-values';
import { getErrorMessage } from '../../utils/errors';
import { useItemSelector } from '../../hooks/use-selector';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { useResourceRecordData } from '../../hooks/use-resource-record-data';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import {
  getPageResourceRecord,
  selectIsResourceRecordError,
  selectIsResourceRecordLoading,
  selectResourceRecordError
} from '../../redux/resource-record/resource-record-selectors';
import useLocationHandler from '../../hooks/use-location-handler';
import StubPage from '../../components/stub-page/stub-page';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import StudioConfigRecordForm from './studio-config-record-form';

const StudioConfigsRecordPage = () => {
  const record = useItemSelector({ resource: Resources.studioConfigs }, getPageResourceRecord) as StudioConfig;
  const isLoading = useSelector(selectIsResourceRecordLoading);
  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);
  const onSetRecord = useAction(setResourceRecord);

  const navigate = useNavigate();
  const { secondSubRoute: recordId } = useLocationHandler();

  useResourceRecordData({ recordId, resource: Resources.studioConfigs });

  const [form] = Form.useForm();

  const { isFormValuesChanged, changedFormValues, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData: record });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };
  const onFinish = () => {
    const params = {
      id: recordId,
      previousData: { id: recordId },
      data: { id: recordId, ...changedFormValues }
    } as UpdateParams;

    api.common
      .update(Resources.studioConfigs, params)
      .then((updatedConfig: StudioConfig) => {
        onSetRecord(updatedConfig);
        onDisplaySuccessNotification('Studio config record was successfully updated.');
        onReset();
        navigate(`/${Resources.studioConfigs}`);
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.studioConfigs} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Studio Config
      </Typography.Title>
      <StudioConfigRecordForm
        record={record}
        onReset={onReset}
        onFinish={onFinish}
        isFormValuesChanged={isFormValuesChanged}
        onValuesChange={onValuesChange}
      />
    </ResourceRecordContainer>
  );
};

export default StudioConfigsRecordPage;
