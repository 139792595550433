import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { getSubroutes } from '../../utils/router-utils';
import { capitalizeText } from '../../utils/common-utils';
import 'antd/dist/antd.css';
import './base-layout.scss';
import { Resources } from '../../types/resources-types';
import { useSelector } from 'react-redux';
import { selectResourceRecord } from '../../redux/resource-record/resource-record-selectors';
import { Workout } from '../../types/workout-types';

function Breadcrumbs() {
  const location = useLocation();
  const record = useSelector(selectResourceRecord);

  const { main, first, second, third } = getSubroutes(location);

  const firstBreadcrumbLabel = useMemo(() => {
    const isWorkoutResource = main === Resources.workouts;
    if (!isWorkoutResource || !record) return first;

    const workoutName = (record as Workout).name;
    const workoutISCI = (record as Workout).production_id;
    return `${workoutName}${workoutISCI ? ` (${workoutISCI})` : ''}`;
  }, [main, first, record]);

  const breadcrumbsConfigList = [
    { breadcrumb: main, link: `/${main}` },
    { breadcrumb: firstBreadcrumbLabel, link: `/${main}/${first}` },
    { breadcrumb: second, link: `/${main}/${first}/${second}` },
    { breadcrumb: third, link: `/${main}/${first}/${second}/${third}` }
  ];

  const breadcrumbsList = breadcrumbsConfigList
    .filter(({ breadcrumb }) => breadcrumb)
    .map((item) => ({ ...item, breadcrumb: capitalizeText(item.breadcrumb!) }));

  return (
    <Breadcrumb className="breadcrumbs">
      <Breadcrumb.Item key="home">Home</Breadcrumb.Item>
      {breadcrumbsList.map((item, index) => {
        const isLastBreadcrumb = index === breadcrumbsList.length - 1;
        return (
          <Breadcrumb.Item key={item.breadcrumb}>
            {isLastBreadcrumb ? item.breadcrumb : <Link to={item.link}>{item.breadcrumb}</Link>}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
