import { RootState } from '../../boot/store';
import { Resources } from '../../types/resources-types';
import { ResourceListState } from './resource-list-reducer';

export const selectResourceListState = (state: RootState): ResourceListState => state.resourceList;

export const selectResourceListType = (state: RootState): Resources | null => selectResourceListState(state).resource;
export const selectResourceListIsInitialized = (state: RootState): boolean => selectResourceListState(state).isInitialized;
export const selectIsResourceListLoading = (state: RootState): boolean => selectResourceListState(state).isLoading;
export const selectResourceListIsError = (state: RootState): boolean => selectResourceListState(state).isError;
export const selectResourceListError = (state: RootState): string => selectResourceListState(state).errorMessage;
export const selectResourceList = (state: RootState): Record<string, any>[] => selectResourceListState(state).resourceList;
export const selectResourceListTotalRows = (state: RootState): number => selectResourceListState(state).totalRows;
export const selectSelectedItemsIds = (state: RootState): string[] => selectResourceListState(state).selectedItemsIds;
export const selectResourceListDataKey = (state: RootState): string | null => selectResourceListState(state).resourceListDataKey;
