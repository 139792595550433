import React, { useEffect } from 'react';

import api from '../../api';
import useDebounce from '../../hooks/use-debounce';
import { NOOP } from '../../constants/common-constants';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { CondOperator } from '../../types/cond-operators';
import { Exercise, ExerciseOwner } from '../../types/exercises-types';
import { getUniqueOwnersFromExercises } from '../../utils/exercise-utils';
import { Order } from '../../types/common-types';

type UseExerciseOwnerFilter = {
  trainersList: ExerciseOwner[];
  selectedTrainers: ExerciseOwner[];
  searchInputValue: string;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectTrainer: (owner: ExerciseOwner) => void;
  onApplySelectedExercises: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type UseExerciseOwnerFilterParams = {
  isVisible: boolean;
  selectedItemsIds: string[];
  onSelectItems: (element: string[]) => void;
  onClose: () => void;
};

const commonRequestData = {
  pagination: { page: 1, perPage: 50 },
  sort: { field: 'id', order: 'ASC' }
};

const useExerciseOwnerFilter = (params: UseExerciseOwnerFilterParams): UseExerciseOwnerFilter => {
  const { isVisible, selectedItemsIds, onSelectItems = NOOP, onClose } = params;

  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [trainersList, setTrainersList] = React.useState<ExerciseOwner[]>([]);
  const [selectedTrainers, setSelectedTrainers] = React.useState<ExerciseOwner[]>([]);

  const debouncedSearchValue = useDebounce(searchInputValue);

  useEffect(() => {
    if (!isVisible) {
      setTrainersList([]);
      setSelectedTrainers([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible || !selectedItemsIds.length) return;
    const requestFiltersData = {
      [`owner.id||${CondOperator.IN}`]: selectedItemsIds.length ? selectedItemsIds : undefined
    };

    api.common
      .getList(Resources.exercises, { ...commonRequestData, filter: requestFiltersData })
      .then(({ data: exercises }: { data: Exercise[] }) => setSelectedTrainers(getUniqueOwnersFromExercises(exercises)))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [isVisible, selectedItemsIds]);

  useEffect(() => {
    if (!isVisible) return;
    const requestFiltersData = {
      [`is_public||${CondOperator.IN}`]: [false],
      [`owner_id||${CondOperator.NOT_NULL}`]: true,
      [`searchBy||owner.first_name,owner.last_name`]: debouncedSearchValue || undefined
    };

    api.exercises
      .getNonPaginatedExerciseList({ filter: requestFiltersData, sort: { field: 'owner.first_name', order: Order.asc } })
      .then(({ data: exercises }: { data: Exercise[] }) => setTrainersList(getUniqueOwnersFromExercises(exercises)))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [isVisible, debouncedSearchValue]);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value);

  const onSelectTrainer = (trainer: ExerciseOwner) => {
    const isChecked = !!selectedTrainers.find(({ id }) => trainer.id === id);
    if (!isChecked) return setSelectedTrainers([...selectedTrainers, trainer]);
    return setSelectedTrainers(selectedTrainers.filter((i) => i.id !== trainer.id));
  };

  const onApplySelectedExercises = () => {
    onSelectItems(selectedTrainers.map(({ id }) => `${id}`));
    onClose();
  };

  return {
    trainersList,
    selectedTrainers,
    searchInputValue,
    onChangeSearchInput,
    onSelectTrainer,
    onApplySelectedExercises
  };
};

export default useExerciseOwnerFilter;
