import React, { useMemo, useState } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { categoriesList, statusesList } from '../../utils/resources-dictionary-utils';
import { categoriesColorsMap, COLUMN_DESCEND, Order, statusesColorsMap } from '../../types/common-types';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';

import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import CreateInstructorDialog from './instructor-create-dialog';

const resource = Resources.instructors;
const filterTypes = { status: 'status', categories: 'categories' };
const initialSortData = { sortField: 'first_name', sortOrder: Order.asc };
const searchFilterFields = ['first_name', 'last_name'];

const InstructorsListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  useResourceListInitialRequestParams({ resource, sortField: 'first_name', sortOrder: Order.asc, searchFilterFields });
  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, activeFilterDropdown, onSetFilter, onResetFilters, onFilterDropdownChange } = useResourceListTableFilters({ filterTypes });

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;
    const statusFilter = filters[filterTypes.status];
    const categoriesFilter = filters[filterTypes.categories];
    apiFilters[`${filterTypes.status}||${CondOperator.IN}`] = statusFilter.length ? statusFilter : undefined;
    apiFilters[`${filterTypes.categories}||${CondOperator.ARRAY_OVERLAPS}`] = categoriesFilter.length ? categoriesFilter : undefined;
    return apiFilters;
  }, [filters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const instructorsColumns = [
    {
      width: 149,
      key: 'first_name',
      title: 'First name',
      dataIndex: 'first_name',
      fixed: 'left' as FixedType,
      isRequired: true,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('first_name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    {
      width: 150,
      key: 'last_name',
      title: 'Last name',
      dataIndex: 'last_name',
      fixed: 'left' as FixedType,
      isRequired: true,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('last_name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'status',
      title: 'Status',
      isVisible: activeFilterDropdown === filterTypes.status,
      activeFilters: filters[filterTypes.status],
      filtersList: statusesList,
      filterColorsMap: statusesColorsMap,
      filterDropdownType: filterTypes.status,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 220,
      key: 'categories',
      title: 'Categories',
      isVisible: activeFilterDropdown === filterTypes.categories,
      activeFilters: filters[filterTypes.categories],
      filtersList: categoriesList,
      filterColorsMap: categoriesColorsMap,
      filterDropdownType: filterTypes.categories,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'created_date',
      title: 'Created date',
      dataIndex: 'created_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated date',
      dataIndex: 'updated_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    }
  ];

  return (
    <ResourceListContainer
      withCreate
      withResetFilters
      withSearchFilter
      withDynamicColumns
      resource={resource}
      columns={instructorsColumns}
      searchFilterPlaceholder="Search instructors by first name or last name..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onResetFilters={onResetFilters}
    >
      <CreateInstructorDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default InstructorsListPage;
