import React, { memo, MutableRefObject, useRef } from 'react';
import { Player, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton, BigPlayButton } from 'video-react';
import cs from 'classnames';

import { NOOP } from '../../constants/common-constants';
import VideoHLSSource from './video-hls-source';
import VideoPlayerSourcesSelection from './video-player-source-selection';
import './video-player.scss';

export enum VideoCodec {
  h265 = 'h265',
  h264 = 'h264'
}

export type VideoSourceItem = {
  url: string;
  text: string;
  value: string;
  isHLS: boolean;
  isSupported: boolean;
  codec: VideoCodec;
};

type VideoPlayerProps = {
  withInnerRef?: boolean;
  withSourcesSelection?: boolean;
  innerRef?: MutableRefObject<Player | null>;
  source: VideoSourceItem | null;
  autoPlay?: boolean;
  sources?: VideoSourceItem[];
  className?: string;
  height?: number | string;
  width?: number | string;
  children?: JSX.Element | null;
  onChangeSelectedSource?: (source: VideoSourceItem) => void;
};

const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    withInnerRef,
    innerRef,
    source,
    className = '',
    autoPlay = false,
    withSourcesSelection = false,
    sources = [],
    height,
    width,
    children,
    onChangeSelectedSource = NOOP
  } = props;

  const defaultVideoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const videoPlayerRef = withInnerRef ? innerRef : defaultVideoPlayerRef;

  const onSelectSource = (sourceValue: string) => {
    const videoPlayer = videoPlayerRef?.current;
    const nextSelectedSource = sources.find((item) => item.value === sourceValue);
    if (!nextSelectedSource) return;
    if (videoPlayer) videoPlayer.load();
    onChangeSelectedSource(nextSelectedSource);
  };

  return (
    <Player
      playsInline
      preload="metadata"
      fluid={false}
      width={width}
      height={height}
      autoPlay={autoPlay}
      src={source?.url}
      ref={videoPlayerRef as any}
      className={cs('video-codec', className)}
    >
      {source?.isHLS && <VideoHLSSource autoPlay={autoPlay} source={source?.url} />}
      <BigPlayButton position="center" />
      <div className="video-codec-header-control-bar">{children}</div>
      <ControlBar className="video-codec-bottom-control-bar" autoHide={false}>
        <VolumeMenuButton vertical />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
        {withSourcesSelection && sources.length ? (
          <VideoPlayerSourcesSelection activeSource={source} sources={sources} onSelectSource={onSelectSource} />
        ) : null}
      </ControlBar>
    </Player>
  );
};

export default memo(VideoPlayer);
