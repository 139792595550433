import React, { useMemo } from 'react';
import { Button, Checkbox, Divider, Form, Input, Select } from 'antd';
import ContentTabs from 'src/components/content-tabs/content-tabs';
import { commonFormValidateMessages } from 'src/constants/form-constants';
import { useFormValues } from 'src/hooks/use-form-values';
import { stringValidator } from 'src/validators/string-validator';
import { SurveyStep, SurveyStepType } from 'src/types/survey-types';
import { jsonValidator } from 'src/validators/json-validator';
import useAssetsTabs from '../surveys/hooks/use-assets-tabs';
import { tryParseJsonObject } from '../../utils/common-utils';

type SurveyStepFormProps = {
  onClose: () => void;
  onConfirm: (updatedStep: SurveyStep) => void;
  record: SurveyStep | null;
};

const surveyStepType: SurveyStepType[] = [
  SurveyStepType.YESNO,
  SurveyStepType.SELECT,
  SurveyStepType.MULTI_SELECT,
  SurveyStepType.TIMER,
  SurveyStepType.REGULAR,
  SurveyStepType.FILE_UPLOAD
];

export const SurveyStepForm = (props: SurveyStepFormProps) => {
  const { onClose, onConfirm, record } = props;
  const [form] = Form.useForm();

  const initialData = useMemo(
    () => ({
      ...record,
      additional_fields: record?.additional_fields ? JSON.stringify(record.additional_fields) : ''
    }),
    [record]
  );

  const { changedFormValues, onValuesChange, isFormValuesChanged } = useFormValues({ initialData });

  const { tabsConfig, changedAssetsData, isAssetsChanged, resetAssets } = useAssetsTabs(record);

  const isDataChanged = isFormValuesChanged || isAssetsChanged;

  const onCloseHandler = () => {
    form.resetFields();
    resetAssets();
    onClose();
  };

  const onFinish = () => {
    const nextFormValues = {
      ...changedFormValues,
      ...changedAssetsData,
      // tryParseJsonObject may return null and in this case additional_fields will be cleared
      // return undefined in this case to prevent sending null to the BE
      additional_fields: tryParseJsonObject(changedFormValues.additional_fields) || undefined
    } as SurveyStep;

    if (record) {
      nextFormValues.id = record.id;
    }

    onConfirm(nextFormValues);
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={initialData}
      validateMessages={commonFormValidateMessages}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item name="title" label="Title" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
        <Input />
      </Form.Item>
      <Form.Item name="step_id" label="Step Id" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
        <Input />
      </Form.Item>
      <Form.Item name="category" label="Category" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
        <Input />
      </Form.Item>
      <Form.Item name="question" label="Question" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true, type: 'string' }]}>
        <Select options={surveyStepType.map((value) => ({ value }))} />
      </Form.Item>
      <Form.Item name="additional_fields" label="Additional Fields (JSON)" rules={[{ type: 'string' }, { validator: jsonValidator }]}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item valuePropName="checked" name="is_optional" label="Optional">
        <Checkbox />
      </Form.Item>

      <Divider>Assets</Divider>
      <ContentTabs tabsConfig={tabsConfig} />
      <Divider />

      <Form.Item label=" " colon={false}>
        <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" disabled={!isDataChanged}>
          {record ? 'Save' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};
