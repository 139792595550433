import { Resources, Verbs } from '../types/resources-types';
import { ResourceConfig, resourcesConfig } from '../constants/resources-constants';

export type ResourcesPermissionsMap = Record<Resources, ResourceConfig>;

export const getResourcesPermissionsMap = (permissionsList: string[] = []): ResourcesPermissionsMap => {
  const resourcesPermissionsMap = permissionsList.reduce((acc, permission) => {
    const [action, resource] = permission.split(':') as [Verbs, Resources];
    const resourceActions = acc[resource] || [];
    return { ...acc, [resource]: [...resourceActions, action] };
  }, {} as Record<Resources, Verbs[]>);

  const superUserPermissions = resourcesPermissionsMap[Resources.cms] || [];

  return resourcesConfig.reduce((acc, resourceConfig) => {
    const resourcePermissions = resourcesPermissionsMap[resourceConfig.key] || [];
    const permissions = [...superUserPermissions, ...resourcePermissions];

    const resourceItemConfig = {
      ...resourceConfig,
      isReadAllowed: permissions.includes(Verbs.read),
      isCreateAllowed: permissions.includes(Verbs.create),
      isDeleteAllowed: permissions.includes(Verbs.delete),
      isUpdateAllowed: permissions.includes(Verbs.update)
    };

    return { ...acc, [resourceConfig.key]: resourceItemConfig };
  }, {} as ResourcesPermissionsMap);
};
