import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { FixedType } from 'rc-table/lib/interface';
import prettyBytes from 'pretty-bytes';

import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { FilterSelectionType } from '../../components/accessible-table/accessible-table-filter-select';
import { AspectRatioTypes, VideosTypes } from '../../types/videos-types';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';
import { DurationType, getFormattedDurationTime } from '../../utils/time-utils';
import { aspectRatioFilterColorsMap, COLUMN_DESCEND, Order } from '../../types/common-types';
import { aspectRatioFiltersList, imageTypesList, isArchivedFiltersList, isHasAudioFiltersList } from '../../utils/resources-dictionary-utils';

import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

import ResourceListContainer from '../../containers/resource-list-container';
import CreateContentPlanDialog from './video-create-dialog';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import CheckTagButton from '../../components/buttons/check-tag-button';
import MinusTagButton from '../../components/buttons/minus-tag-button';

const resource = Resources.videos;
const filterTypes = { is_archived: 'is_archived', has_audio: 'has_audio', aspect_ratio: 'aspect_ratio' };
const initialSortData = { sortField: 'name', sortOrder: Order.asc };
const searchFilterFields = ['name'];
const initialFilters = { q: { not_assigned: false } };

const VideosListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();

  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);
  const [videoTypeFilter, setVideoTypeFilter] = React.useState<VideosTypes>(VideosTypes.all);

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { isInitialized, additionalFilters: urlFilters } = useResourceListUrlParams();

  const { not_assigned: notAssignedFilter } = urlFilters.q || { not_assigned: 'false' };
  const initialVideoTypeFilter = notAssignedFilter === 'true' ? VideosTypes.notAssigned : VideosTypes.all;

  useEffect(() => {
    if (!isInitialized) setVideoTypeFilter(initialVideoTypeFilter);
  }, [isInitialized, initialVideoTypeFilter]);

  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields, initialFilters });
  const { filters, activeFilterDropdown, onSetFilter, onResetFilters, onFilterDropdownChange } = useResourceListTableFilters({ filterTypes });

  const aspectRatioFilters = filters[filterTypes.aspect_ratio];

  const additionalFilters = useMemo(() => {
    const [isArchivedFilter] = filters[filterTypes.is_archived];
    const [hasAudioFilter] = filters[filterTypes.has_audio];
    const [aspectRatioFilter] = filters[filterTypes.aspect_ratio];
    const apiFilters = { q: { not_assigned: videoTypeFilter === VideosTypes.notAssigned } } as Record<string, any>;

    apiFilters[`${filterTypes.is_archived}||${CondOperator.EQUALS}`] = isArchivedFilter;
    apiFilters[`${filterTypes.has_audio}||${CondOperator.EQUALS}`] = hasAudioFilter;

    if (aspectRatioFilter !== AspectRatioTypes.other) {
      apiFilters[`${filterTypes.aspect_ratio}||${CondOperator.NOT_IN}`] = undefined;
      apiFilters[`${filterTypes.aspect_ratio}||${CondOperator.EQUALS}`] = aspectRatioFilter === AspectRatioTypes.all ? undefined : aspectRatioFilter;
    }

    if (aspectRatioFilter === AspectRatioTypes.other) {
      apiFilters[`${filterTypes.aspect_ratio}||${CondOperator.EQUALS}`] = undefined;
      apiFilters[`${filterTypes.aspect_ratio}||${CondOperator.NOT_IN}`] = [AspectRatioTypes['9:16'], AspectRatioTypes['16:9']];
    }

    return apiFilters;
  }, [filters, videoTypeFilter]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const onResetAllFilters = () => {
    onResetFilters();
    setVideoTypeFilter(VideosTypes.all);
  };

  const videosColumns = [
    {
      width: 250,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left' as FixedType,
      isRequired: true,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    {
      key: 'download_size',
      width: 130,
      title: 'Download size',
      render: (value: string, record: Record<string, any>) => {
        const studioSize = record?.meta_data?.sizes?.studio;
        return <span>{studioSize ? prettyBytes(studioSize) : '-'}</span>;
      }
    },
    {
      key: 'duration',
      width: 100,
      title: 'Duration',
      render: (value: string, record: Record<string, any>) => {
        const duration = record?.meta_data?.duration;
        return <span>{getFormattedDurationTime(Number(duration), DurationType.milliSeconds)}</span>;
      }
    },
    getAccessibleTableColumnConfig({
      width: 120,
      key: 'is_archived',
      title: 'Is archived',
      isVisible: activeFilterDropdown === filterTypes.is_archived,
      activeFilters: filters[filterTypes.is_archived],
      filtersList: isArchivedFiltersList,
      filterDropdownType: filterTypes.is_archived,
      selectionType: FilterSelectionType.radio,
      cellRender: (value: any) => (value ? <CheckTagButton /> : <MinusTagButton />),
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 120,
      key: 'has_audio',
      title: 'Has audio',
      isVisible: activeFilterDropdown === filterTypes.has_audio,
      activeFilters: filters[filterTypes.has_audio],
      filtersList: isHasAudioFiltersList,
      filterDropdownType: filterTypes.has_audio,
      selectionType: FilterSelectionType.radio,
      cellRender: (value: any) => (value ? <CheckTagButton /> : <MinusTagButton />),
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 130,
      key: 'aspect_ratio',
      title: 'Aspect ratio',
      isVisible: activeFilterDropdown === filterTypes.aspect_ratio,
      activeFilters: !aspectRatioFilters.length ? [AspectRatioTypes.all] : aspectRatioFilters,
      filtersList: aspectRatioFiltersList,
      filterColorsMap: aspectRatioFilterColorsMap,
      filterDropdownType: filterTypes.aspect_ratio,
      selectionType: FilterSelectionType.radio,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'created_date',
      title: 'Created at',
      dataIndex: 'created_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated at',
      dataIndex: 'updated_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    }
  ];

  const renderAdditionalFilters = (
    <Select style={{ width: 200 }} defaultValue={videoTypeFilter || ''} onChange={(filter) => filter && setVideoTypeFilter(filter as VideosTypes)}>
      {imageTypesList.map(({ value, text }) => (
        <Select.Option key={value} value={value}>
          {text}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <ResourceListContainer
      withCreate
      withSearchFilter
      withResetFilters
      withDynamicColumns
      resource={resource}
      columns={videosColumns}
      renderAdditionalFilters={renderAdditionalFilters}
      searchFilterPlaceholder="Search videos by name..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onResetFilters={onResetAllFilters}
    >
      <CreateContentPlanDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default VideosListPage;
