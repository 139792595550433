import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Drawer, Form, Button, Row, Input, Space, Col, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';

import { Workout } from '../../../types/workout-types';
import { Exercise } from '../../../types/exercises-types';
import { usePreviousValue } from '../../../hooks/use-previous-value';
import { commonFormValidateMessages } from '../../../constants/form-constants';
import { CompletionType, ExerciseType } from '../../../types/common-types';
import { BlockDrawerParams, BlockDrawerType } from '../hooks/use-workout-movements';
import { isCpsWorkoutType, liftVodCompletionTypeList, movementSetsList } from '../../../utils/resources-dictionary-utils';
import AccessibleTable from '../../../components/accessible-table';
import WorkoutMovementsSelection from './workout-movements-selection';
import FormItemSelection from '../../../components/form-components/form-item-selection';
import '../workout-movemnts.scss';
import { createArrayFromNumbersRange } from '../../../utils/common-utils';

type ExercisesTableDataItem = Exercise & { set: number; completion_type?: CompletionType; nanoId: string };

type CreateMovementsBlockDrawerProps = {
  record: Workout;
  params: BlockDrawerParams;
  onClose: () => void;
  onCreateBlock: (exercises: ExercisesTableDataItem[], blockName: string) => void;
};

const initialBlockNameValue = '';
const initialDefaultSet = 1;
const initialExercisesValue = [] as Exercise[];
const initialExercisesTableDataValue = [] as ExercisesTableDataItem[];

const setsList = movementSetsList.map(({ text, value }) => ({ text, value }));
const repsOptions = createArrayFromNumbersRange(1, 30).map((num: number) => ({
  text: `${num}`,
  value: `${num}`
}));

const CreateMovementsBlockDrawer = (props: CreateMovementsBlockDrawerProps) => {
  const { record, params, onClose, onCreateBlock } = props;

  const isPlannedSessionType = isCpsWorkoutType(record.session_type);
  const isVisible = params.type === BlockDrawerType.createBlock;
  const prevIsVisible = usePreviousValue(isVisible);

  const [defaultSet, setDefaultSet] = React.useState(initialDefaultSet);
  const [blockName, setBlockName] = React.useState(initialBlockNameValue);
  const [exercises, setExercises] = React.useState<Exercise[]>(initialExercisesValue);
  const [exercisesTableData, setExercisesTableData] = React.useState<ExercisesTableDataItem[]>(initialExercisesTableDataValue);

  const prevExercises = usePreviousValue(exercises);

  const accessibleTableData = useMemo(
    () =>
      exercisesTableData.map(
        (item: ExercisesTableDataItem & { nanoId?: string }, index): ExercisesTableDataItem & { nanoId: string; index: number } => ({
          ...item,
          index: ++index
        })
      ),
    [exercisesTableData]
  );

  useEffect(() => {
    setExercisesTableData((prevExercisesTableData) => prevExercisesTableData.map((item) => ({ ...item, set: defaultSet })));
  }, [defaultSet]);

  const onClearFormData = useCallback(() => {
    setDefaultSet(initialDefaultSet);
    setBlockName(initialBlockNameValue);
    setExercises(initialExercisesValue);
    setExercisesTableData(initialExercisesTableDataValue);
  }, []);

  useEffect(() => {
    if (prevIsVisible && !isVisible) onClearFormData();
  }, [isVisible, prevIsVisible, onClearFormData]);

  useEffect(() => {
    if (prevExercises !== exercises) {
      const nextExercisesTableData = exercises.map((item): ExercisesTableDataItem => {
        const dataItem: ExercisesTableDataItem = { ...item, set: defaultSet, nanoId: nanoid() };
        if (isPlannedSessionType) dataItem.completion_type = CompletionType.time;
        return { ...dataItem, nanoId: dataItem.nanoId || nanoid() };
      });
      setExercisesTableData(nextExercisesTableData);
    }
  }, [exercises, prevExercises, exercisesTableData, isPlannedSessionType, defaultSet]);

  const onChangeExercisesTableSets = (itemNanoId: string, set: number) => {
    const nextExercisesTableData = exercisesTableData.map((item) => (item.nanoId !== itemNanoId ? item : { ...item, set }));
    setExercisesTableData(nextExercisesTableData);
  };

  const onChangeCompletionType = (itemId: string, completion_type: CompletionType) => {
    const nextExercisesTableData = exercisesTableData.map((item) => (item.id !== itemId ? item : { ...item, completion_type }));
    setExercisesTableData(nextExercisesTableData);
  };

  const onRemoveExercisesTableSets = (nanoId: string) => {
    const nextExercisesTableData = exercisesTableData.filter((item) => item.nanoId !== nanoId);
    setExercisesTableData(nextExercisesTableData);
    setExercises(nextExercisesTableData);
  };

  const onChangeBlockNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    setBlockName(e.currentTarget.value);
  };

  const columnsMap = {
    index: {
      width: 40,
      key: 'index',
      dataIndex: 'index',
      title: ''
    },
    name: {
      width: 250,
      key: 'name',
      dataIndex: 'name',
      title: 'Name'
    },
    set: {
      width: 90,
      key: 'set',
      title: 'Set',
      dataIndex: 'set',
      render: (set: string, item: Record<string, any>) => (
        <FormItemSelection
          value={set}
          options={setsList}
          onChange={(value: string | null) => value && onChangeExercisesTableSets(item.nanoId, Number(value))}
        />
      )
    },
    completionType: {
      width: 100,
      key: 'completion_type',
      dataIndex: 'completion_type',
      title: 'Completion type',
      render: (value: string, item: Record<string, any>) => (
        <FormItemSelection
          value={value}
          bordered={false}
          options={liftVodCompletionTypeList}
          onChange={(completionType) => onChangeCompletionType(item.id, completionType as CompletionType)}
        />
      )
    },
    duration: {
      width: 100,
      key: 'duration',
      dataIndex: 'duration',
      title: 'Duration',
      render: (value: number | undefined, item: Record<string, any>) => {
        const isCompletionTypeTime = item.completion_type === CompletionType.time;
        if (isPlannedSessionType && !isCompletionTypeTime) return '';

        return (
          <InputNumber
            defaultValue={value ? value / 1000 : value}
            onBlur={(e) => {
              const parsed = parseInt(e.target.value);
              const duration = isNaN(parsed) ? undefined : parsed * 1000;
              const nextExercisesTableData = exercisesTableData.map((exercise) => (exercise.id !== item.id ? exercise : { ...exercise, duration }));
              setExercisesTableData(nextExercisesTableData);
            }}
          />
        );
      }
    },
    reps: {
      width: 100,
      key: 'reps',
      dataIndex: 'reps',
      title: 'Reps',
      render: (value: number | undefined, item: Record<string, any>) => {
        const isLiftExercise = item.type === ExerciseType.LIFT;
        const isMovement = item.is_movement;
        const isCompletionTypeReps = item.completion_type === CompletionType.reps;
        const isAvailable = isMovement && (isLiftExercise || (isPlannedSessionType && isCompletionTypeReps));
        if (!isAvailable) return '';

        return (
          <FormItemSelection
            options={repsOptions}
            value={value ? value.toString() : ''}
            onChange={(reps: string | null) => {
              const nextExercisesTableData = exercisesTableData.map((exercise) =>
                exercise.id !== item.id ? exercise : { ...exercise, reps: reps ? parseInt(reps) : null }
              );
              setExercisesTableData(nextExercisesTableData);
            }}
          />
        );
      }
    },
    remove: {
      width: 32,
      key: 'remove',
      title: '',
      dataIndex: 'remove',
      render: (set: string, item: Record<string, any>) => (
        <CloseOutlined style={{ fontSize: 16 }} onClick={() => onRemoveExercisesTableSets(item.nanoId)} />
      )
    }
  };

  const columns = [columnsMap.index, columnsMap.name, columnsMap.set, columnsMap.duration, columnsMap.reps, columnsMap.remove];
  const completionTypeColumns = [
    columnsMap.index,
    columnsMap.name,
    columnsMap.completionType,
    columnsMap.set,
    columnsMap.duration,
    columnsMap.reps,
    columnsMap.remove
  ];
  const selectedColumns = isPlannedSessionType ? completionTypeColumns : columns;

  const onFinishAddMovements = () => {
    onCreateBlock(exercisesTableData, blockName);
  };

  return (
    <Drawer maskClosable={false} destroyOnClose title="Block" width={1000} onClose={onClose} visible={isVisible} bodyStyle={{ paddingBottom: 80 }}>
      <Form layout="vertical" className="create-movement-block-form" validateMessages={commonFormValidateMessages} onFinish={onFinishAddMovements}>
        <Form.Item required label="Name block" className="full-width" rules={[{ required: true, type: 'string' }]}>
          <Input placeholder="Name block" value={blockName} onChange={onChangeBlockNameInput} />
        </Form.Item>

        <Row gutter={8} align="middle" justify="space-between">
          <Col span={18}>
            <Form.Item label="Exercises library">
              <WorkoutMovementsSelection selectedItems={exercises} onSelectItems={setExercises} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Set">
              <FormItemSelection
                value={`${defaultSet}`}
                options={setsList}
                onChange={(value: string | null) => value && setDefaultSet(Number(value))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Selected">
          <AccessibleTable
            bordered
            rowKey="nanoId"
            tableClassName="add-movements-to-box-table"
            data={accessibleTableData}
            columns={selectedColumns}
          />
          <Row justify="end">
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button disabled={!blockName || !blockName.trim()} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateMovementsBlockDrawer;
