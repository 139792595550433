import React from 'react';

import { Workout } from '../../types/workout-types';
import { VideoAssetElement } from '../../types/studio-assets';
import { ContentTabItemConfig } from '../../components/content-tabs/content-tabs';
import { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import useSelectImage from '../../hooks/use-select-image';
import useSelectVideo from '../../hooks/use-select-video';
import FormItemVideo from '../../components/form-components/form-item-video';
import FormItemImage, { FormImageData } from '../../components/form-components/form-item-image';

type ChangedAssetsData = {
  image_id?: number | null;
  intro_video_id?: number | null;
};

type UseWorkoutAssetsTabs = {
  isAssetsChanged: boolean;
  isImageChanged: boolean;
  isAssetVideoChanged: boolean;
  changedAssetsData: ChangedAssetsData | null;
  assetImage: FormImageData | null;
  assetVideo: VideoAssetElement | null;
  tabsConfig: ContentTabItemConfig[];
};

const useWorkoutAssetsTabs = (record?: Workout | null): UseWorkoutAssetsTabs => {
  const { assetImage, setAssetImage } = useSelectImage({ imageData: record?.image ?? null });
  const { assetVideo, setAssetVideo } = useSelectVideo({ videoData: record?.intro_video ?? null });

  const isImageChanged = `${assetImage?.id ?? ''}` !== `${record?.image?.id ?? ''}`;
  const isAssetVideoChanged = `${assetVideo?.id ?? ''}` !== `${record?.intro_video?.id ?? ''}`;

  const isAssetsChanged = isImageChanged || isAssetVideoChanged;

  const getChangedAssetsData = () => {
    const changedAssetsData = {} as ChangedAssetsData;
    if (!isAssetsChanged) return null;

    if (isImageChanged) changedAssetsData.image_id = assetImage ? Number(assetImage.id) : null;
    if (isAssetVideoChanged) changedAssetsData.intro_video_id = assetVideo ? Number(assetVideo.id) : null;

    return changedAssetsData;
  };

  const changedAssetsData = getChangedAssetsData();

  const tabsConfig = [
    {
      key: 'introVideo',
      title: 'Video',
      component: <FormItemVideo withRemove withTitle value={assetVideo as UseVideoSourceSelectionParams} onChangeVideoData={setAssetVideo} />
    },
    {
      key: 'image',
      title: 'Image',
      component: (
        <FormItemImage withRemove withTitle withResize={false} title={assetImage?.name} value={assetImage?.url} onChangeImageData={setAssetImage} />
      )
    }
  ];

  return {
    tabsConfig,
    assetImage,
    assetVideo,
    isAssetsChanged,
    isImageChanged,
    isAssetVideoChanged,
    changedAssetsData
  };
};

export default useWorkoutAssetsTabs;
