import React, { LegacyRef, useEffect, useMemo } from 'react';
import { VideoCameraOutlined, FileImageOutlined } from '@ant-design/icons';
import { Button, Row, Radio, RadioChangeEvent, Space } from 'antd';

import { NOOP } from '../../constants/common-constants';
import { usePreviousValue } from '../../hooks/use-previous-value';

export enum HasWorkoutVideoTypes {
  all = 'allWithVideoAndNot',
  withVideo = 'withVideo',
  withoutVideo = 'withoutVideo'
}

export enum HasWorkoutImageTypes {
  all = 'allWithImageAndNot',
  withImage = 'withImage',
  withoutImage = 'withoutImage'
}

type WorkoutListAssetsFiltersProps = {
  isVisible: boolean;
  innerRef?: LegacyRef<HTMLDivElement>;
  activeFilters: string[];
  onSelectFilters: (filters: string[] | Record<string, any>) => void;
  onClose: () => void;
};

const WorkoutListAssetsFilters = (props: WorkoutListAssetsFiltersProps) => {
  const { isVisible, activeFilters, innerRef = NOOP, onSelectFilters, onClose } = props;

  const prevIsVisible = usePreviousValue(isVisible);
  const prevInitialFilters = usePreviousValue(activeFilters);

  const assetsFilters = useMemo(() => {
    const activeVideoFilters = activeFilters.filter((item) =>
      Object.values(HasWorkoutVideoTypes).includes(item as HasWorkoutVideoTypes)
    ) as HasWorkoutVideoTypes[];
    const activeImageFilters = activeFilters.filter((item) =>
      Object.values(HasWorkoutImageTypes).includes(item as HasWorkoutImageTypes)
    ) as HasWorkoutImageTypes[];

    return {
      activeVideoFilter: activeVideoFilters.length ? activeVideoFilters[0] : HasWorkoutVideoTypes.all,
      activeImageFilter: activeImageFilters.length ? activeImageFilters[0] : HasWorkoutImageTypes.all
    };
  }, [activeFilters]);

  const [videoFilter, setVideoFilter] = React.useState<HasWorkoutVideoTypes>(HasWorkoutVideoTypes.all);
  const [imageFilter, setImageFilter] = React.useState<HasWorkoutImageTypes>(HasWorkoutImageTypes.all);

  useEffect(() => {
    const isJustClosed = !isVisible && prevIsVisible;
    const isFiltersChanged = activeFilters !== prevInitialFilters;

    if (isJustClosed || isFiltersChanged) {
      setVideoFilter(assetsFilters.activeVideoFilter);
      setImageFilter(assetsFilters.activeImageFilter);
    }
  }, [isVisible, prevIsVisible, activeFilters, prevInitialFilters, assetsFilters]);

  const onApplyFilter = () => {
    onSelectFilters({ videoFilter, imageFilter });
    onClose();
  };

  const onChangeVideoFilter = (e: RadioChangeEvent) => setVideoFilter(e.target.value as HasWorkoutVideoTypes);
  const onChangeImageFilter = (e: RadioChangeEvent) => setImageFilter(e.target.value as HasWorkoutImageTypes);

  return (
    <div ref={innerRef} className="column-filters-selection-wrap">
      <Row align="middle" justify="start" style={{ marginBottom: 5 }}>
        <Space size="middle">
          <VideoCameraOutlined style={{ fontSize: 18 }} />
          <Radio.Group onChange={onChangeVideoFilter} value={videoFilter}>
            <Radio value={HasWorkoutVideoTypes.all}>All</Radio>
            <Radio value={HasWorkoutVideoTypes.withVideo}>With</Radio>
            <Radio value={HasWorkoutVideoTypes.withoutVideo}>Without</Radio>
          </Radio.Group>
        </Space>
      </Row>
      <Row align="middle" justify="start" style={{ marginBottom: 5 }}>
        <Space size="middle">
          <FileImageOutlined style={{ fontSize: 18 }} />
          <Radio.Group onChange={onChangeImageFilter} value={imageFilter}>
            <Radio value={HasWorkoutImageTypes.all}>All</Radio>
            <Radio value={HasWorkoutImageTypes.withImage}>With</Radio>
            <Radio value={HasWorkoutImageTypes.withoutImage}>Without</Radio>
          </Radio.Group>
        </Space>
      </Row>
      <div className="apply-filter-actions">
        <Button type="primary" onClick={onApplyFilter}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default WorkoutListAssetsFilters;
