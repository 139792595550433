import React, { useEffect, useMemo } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { getErrorMessage } from '../../utils/errors';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { WorkoutFilterCategory } from '../../types/workout-filters-types';
import WorkoutFilterConditionFormField from './workout-filter-condition-form-field';
import { jsonValidator } from '../../validators/json-validator';
import { tryParseJsonObject } from '../../utils/common-utils';
import { stringValidator } from '../../validators/string-validator';

type CreateWorkoutFilterDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  category: WorkoutFilterCategory;
};

type WorkoutFilterCreateRequestData = {
  name: string;
  description?: string;
  short_description?: string;
  category: WorkoutFilterCategory;
  condition: string;
};

const CreateWorkoutFilterDialog = (props: CreateWorkoutFilterDialogProps) => {
  const { isOpened, onClose, category } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const initialFormData: WorkoutFilterCreateRequestData = useMemo(
    () => ({
      category,
      name: '',
      description: '',
      short_description: '',
      condition: JSON.stringify({})
    }),
    [category]
  );

  useEffect(() => {
    if (isOpened) form.setFieldsValue(initialFormData);
  }, [form, isOpened, initialFormData]);

  const onReset = () => form.resetFields();

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const workoutFilterRecord = form.getFieldsValue(true) as WorkoutFilterCreateRequestData;
    const params = { data: { ...workoutFilterRecord, condition: tryParseJsonObject(workoutFilterRecord.condition) } } as CreateParams;

    api.common
      .create(Resources.workoutFilters, params)
      .then(({ data: { id: newRecordId } }) => {
        onDisplaySuccessNotification('Workout filter record was successfully created');
        navigate(`/${Resources.workoutFilters}/${newRecordId}`);
        onCloseModal();
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      wrapClassName="modal-wrap"
      title="Create workout filter record"
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item name="category" label="Category" rules={[{ required: true }]}>
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="short_description" label="Short description" rules={[{ type: 'string' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item name="condition" label="Condition" rules={[{ required: true, type: 'string' }, { validator: jsonValidator }]}>
          <WorkoutFilterConditionFormField />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateWorkoutFilterDialog;
