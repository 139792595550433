import React from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import 'antd/dist/antd.css';

import { NOOP } from '../../constants/common-constants';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import { MomentDateFormat } from '../../utils/date-utils';

type DatePickerWithTimeZoneProps = {
  format?: string;
  value?: (string | null)[];
  showTime?: boolean;
  placeholder?: [string, string];
  className?: string;
  disabledDate?: (date: Moment) => boolean;
  onChange?: (value: (moment.Moment | null)[]) => void;
};

const { RangePicker } = DatePicker;
const disabledDateNOOP = () => false;

const RangePickerWithTimeZone = (props: DatePickerWithTimeZoneProps) => {
  const { format = MomentDateFormat.usDate, value, placeholder, className, showTime, disabledDate = disabledDateNOOP, onChange = NOOP } = props;

  const [startDate, endDate] = value || [];
  const timeZone = useSelector(selectUserTimeZone);
  const displayStartDate = startDate ? moment(startDate).tz(timeZone) : null;
  const displayEndDate = endDate ? moment(endDate).tz(timeZone) : null;

  const onChangeRangePicker = (dates?: RangeValue<moment.Moment>) => {
    const [nextStartValue, nextEndValue] = dates || [];
    const nextStartDate = nextStartValue ? moment.utc(nextStartValue) : null;
    const nextEndDate = nextEndValue ? moment.utc(nextEndValue) : null;
    onChange([nextStartDate, nextEndDate]);
  };

  return (
    <RangePicker
      format={format}
      showTime={showTime}
      className={className}
      disabledDate={disabledDate}
      placeholder={placeholder}
      value={[displayStartDate, displayEndDate]}
      onChange={onChangeRangePicker}
    />
  );
};

export default RangePickerWithTimeZone;
