import React from 'react';
import { Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

type CheckButtonProps = {
  label?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const CheckTagButton = ({ onClick, isActive = true, label = 'Yes' }: CheckButtonProps) => {
  const color = isActive ? 'green' : undefined;
  const style = { cursor: !onClick ? 'default' : 'pointer' };

  return (
    <Tag icon={<CloseCircleOutlined />} color={color} style={style} onClick={onClick}>
      {label}
    </Tag>
  );
};

export default CheckTagButton;
