type Location = Record<string, any> & {
  pathname: string;
};

export type Subroutes = {
  main: string | undefined;
  first: string | undefined;
  second: string | undefined;
  third: string | undefined;
  query: string | undefined;
};

export const getSubroutes = (location: Location): Subroutes => {
  const pathname = location?.pathname ?? '';
  const pathNameArr = pathname.split('/').slice(1);
  const [main, first, second, third] = pathNameArr;
  const query = location?.query;

  return { main, first, second, third, query };
};
