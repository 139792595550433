import { useEffect, useState } from 'react';
import { VideoAssetElement } from '../types/studio-assets';

type UseSelectVideoProps = {
  videoData: VideoAssetElement | null;
};

type UseSelectVideo = {
  assetVideo: VideoAssetElement | null;
  setAssetVideo: (studioAssetImage: VideoAssetElement | null) => void;
};

const useSelectVideo = ({ videoData }: UseSelectVideoProps): UseSelectVideo => {
  const [assetVideo, setAssetVideo] = useState<VideoAssetElement | null>(null);

  useEffect(() => {
    if (videoData) setAssetVideo(videoData);
  }, [videoData]);

  return { assetVideo, setAssetVideo };
};

export default useSelectVideo;
