import React, { useMemo } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { widgetTypeList } from '../../utils/resources-dictionary-utils';
import { COLUMN_DESCEND, Order } from '../../types/common-types';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';

import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';

const resource = Resources.studioWidgets;
const filterTypes = { type: 'type' };
const initialSortData = { sortField: 'id', sortOrder: Order.asc };
const searchFilterFields = ['id'];

const StudioWidgetsListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, activeFilterDropdown, onSetFilter, onResetFilters, onFilterDropdownChange } = useResourceListTableFilters({ filterTypes });
  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;
    const typeFilter = filters[filterTypes.type];
    apiFilters[`${filterTypes.type}||${CondOperator.IN}`] = typeFilter.length ? typeFilter : undefined;
    return apiFilters;
  }, [filters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const contentPlansListColumns = [
    {
      width: 150,
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
      fixed: 'left' as FixedType,
      isRequired: true,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('id'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    {
      width: 300,
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      fixed: 'left' as FixedType,
      isRequired: true
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'type',
      title: 'Type',
      isVisible: activeFilterDropdown === filterTypes.type,
      activeFilters: filters[filterTypes.type],
      filtersList: widgetTypeList,
      filterDropdownType: filterTypes.type,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'created_date',
      title: 'Created at',
      dataIndex: 'created_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated at',
      dataIndex: 'updated_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    }
  ];

  return (
    <ResourceListContainer
      withSearchFilter
      withResetFilters
      withDynamicColumns
      resource={resource}
      columns={contentPlansListColumns}
      searchFilterPlaceholder="Search studio widgets by id..."
      onResetFilters={onResetFilters}
    />
  );
};

export default StudioWidgetsListPage;
