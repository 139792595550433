import React from 'react';
import { Image } from 'antd';
import './accessible-table.scss';

type AccessibleTableImageCellProps = {
  value: string;
};

const AccessibleTableImageCell = (props: AccessibleTableImageCellProps) => {
  const { value } = props;

  return (
    <div className="accessible-table-image-cell">
      <Image height={80} src={`${value}?width=600`} alt="" />
    </div>
  );
};

export default AccessibleTableImageCell;
