import { put, takeEvery, select, call, all } from 'redux-saga/effects';
import { selectIsAuthenticated } from '../permissions/permissions-selectors';

import api from '../../api';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { setApiVersion, setDictionary } from './settings-reducer';

function* fetchConfig() {
  const isAuthenticated: boolean = yield select(selectIsAuthenticated);
  if (!isAuthenticated) return;

  try {
    const { config, dictionary } = yield all({
      config: call(api.settings.getConfig),
      dictionary: call(api.config.getDictionary)
    });

    yield put(setApiVersion(config.version));
    yield put(setDictionary(dictionary));
  } catch (error) {
    onDisplayErrorNotification(getErrorMessage(error as RequestError));
  }
}

function* settingsSaga() {
  yield takeEvery('permissions/finishAuthentication', fetchConfig);
}

export default settingsSaga;
