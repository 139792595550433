import { notification } from 'antd';
import { ReactNode } from 'react';

export enum NotificationTypes {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

type NotificationConfig = {
  type: NotificationTypes;
  message: string;
  description?: string;
  btn?: ReactNode;
  duration?: number;
};

export const openNotification = ({ type, message, description, btn, duration }: NotificationConfig) => {
  notification[type]({ message, description, btn, duration, placement: 'bottomRight' });
};

export const onDisplayInfoNotification = (message: string, description?: string) => {
  if (!message) return;
  openNotification({ type: NotificationTypes.info, message, description });
};

export const onDisplaySuccessNotification = (message: string, description?: string) => {
  if (!message) return;
  openNotification({ type: NotificationTypes.success, message, description });
};

export const onDisplayWarningNotification = (message: string, description?: string) => {
  if (!message) return;
  openNotification({ type: NotificationTypes.warning, message, description });
};

export const onDisplayErrorNotification = (message: string, description?: string) => {
  if (!message) return;
  openNotification({ type: NotificationTypes.error, message, description });
};
