import React, { ReactElement, useMemo } from 'react';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import DropdownRecordSelectionListOverlay from './dropdown-record-selection-list-overlay';
import './dropdown-record-selection-list.scss';

export type ListItem = { id: string | number; name: string };

type DropdownRecordSelectionListProps<T> = {
  placeholder?: string;
  inputPlaceholder?: string;
  allowDuplicates?: boolean;
  isDropdownVisible?: boolean;
  isSingleModeSelection?: boolean;
  searchSuffixComponent?: React.ReactNode;
  selectedItems?: T[];
  itemsList?: T[];
  selectedListItems?: T[];
  searchInputValue: string;
  libraryTitle?: string;
  selectedItemsTitle?: string;
  customRenderDropdownButtonContent?: (items: T[]) => ReactElement;
  customRenderListItemTitle?: (item: T) => ReactElement;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectListItem: (item: T, isDuplicate?: boolean) => void;
  onChangeVisibility: (isVisible: boolean) => void;
  onSaveSelectedItems: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const DropdownRecordSelectionList = <T extends ListItem>(props: DropdownRecordSelectionListProps<T>) => {
  const {
    placeholder = 'Select one or multiple items',
    inputPlaceholder = 'Search items by name',
    itemsList = [],
    selectedItems = [],
    selectedListItems = [],
    isSingleModeSelection,
    searchSuffixComponent,
    isDropdownVisible,
    searchInputValue,
    libraryTitle,
    selectedItemsTitle,
    customRenderListItemTitle,
    customRenderDropdownButtonContent,
    allowDuplicates,
    onChangeSearchInput,
    onSelectListItem,
    onChangeVisibility,
    onSaveSelectedItems
  } = props;

  const renderDropdownButtonContent = useMemo(() => {
    if (customRenderDropdownButtonContent) return customRenderDropdownButtonContent(selectedItems);
    if (isSingleModeSelection && selectedItems.length) return selectedItems[0]?.name ?? placeholder;
    return placeholder;
  }, [isSingleModeSelection, selectedItems, placeholder, customRenderDropdownButtonContent]);

  return (
    <Dropdown
      trigger={['click']}
      visible={isDropdownVisible}
      onVisibleChange={onChangeVisibility}
      className="dropdown-record-selection-list-wrap"
      overlay={
        <div className="dropdown-record-selection-list-overlay card-content-wrap">
          <DropdownRecordSelectionListOverlay
            allowDuplicates={allowDuplicates}
            isSingleModeSelection={isSingleModeSelection}
            itemsList={itemsList}
            inputPlaceholder={inputPlaceholder}
            selectedListItems={selectedListItems}
            searchSuffixComponent={searchSuffixComponent}
            searchInputValue={searchInputValue}
            libraryTitle={libraryTitle}
            selectedItemsTitle={selectedItemsTitle}
            customRenderListItemTitle={customRenderListItemTitle}
            onChangeSearchInput={onChangeSearchInput}
            onSelectListItem={onSelectListItem}
            onSaveSelectedItems={onSaveSelectedItems}
            onClose={() => onChangeVisibility(false)}
          />
        </div>
      }
    >
      <Button className="movement-record-selection-button">
        {renderDropdownButtonContent}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default DropdownRecordSelectionList;
