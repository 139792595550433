import React from 'react';
import { Exercise } from '../../types/exercises-types';
import { Space, Tag, Tooltip } from 'antd';
import cs from 'classnames';

import { NOOP } from '../../constants/common-constants';
import { ExerciseType } from '../../types/common-types';
import './workout-movemnts.scss';

type MovementExerciseTitleProps = {
  exercise?: Exercise | null;
  withTooltip?: boolean;
  onClick?: () => void;
};

const movementExerciseTagConfig = {
  [ExerciseType.STUDIO]: { color: 'gold', label: 'S' },
  [ExerciseType.LIFT]: { color: 'blue', label: 'L' },
  [ExerciseType.TPI]: { color: 'yellow', label: 'T' }
};

const MovementExerciseTitle = ({ exercise, withTooltip = false, onClick }: MovementExerciseTitleProps) => {
  const tagException = exercise?.id === '0000000-0000-0000-0000-444444444444' || exercise?.name === 'Transition';
  const tagConfig = movementExerciseTagConfig[exercise?.type as ExerciseType];
  const withTag = tagConfig && !tagException;

  return (
    <span className={cs('movement-exercise-title', { ['clickable']: !!onClick })} onClick={onClick || NOOP}>
      <Space>
        <Tag color={tagConfig.color} className={cs({ ['hidden-tag']: !withTag })}>
          {tagConfig.label}
        </Tag>
        {withTooltip && exercise?.name ? (
          <Tooltip placement="topRight" title={exercise?.name}>
            {exercise?.name}
          </Tooltip>
        ) : (
          exercise?.name
        )}
      </Space>
    </span>
  );
};

export default MovementExerciseTitle;
