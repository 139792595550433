import React, { useMemo } from 'react';
import { Drawer, Form, Button, Col, Row, Space, InputNumber } from 'antd';

import { Exercise } from '../../../types/exercises-types';
import { OptionListItem } from '../../../redux/settings/settings-utils';
import { Workout, WorkoutMovement } from '../../../types/workout-types';
import { FormValues, useFormValues } from '../../../hooks/use-form-values';
import { BlockDrawerParams, BlockDrawerType } from '../hooks/use-workout-movements';
import { CompletionType, ExerciseType, LimbUsedEnum } from '../../../types/common-types';
import {
  isCpsWorkoutType,
  liftVodCompletionTypeList,
  movementHugsList,
  movementSetsList,
  movementsRepsList
} from '../../../utils/resources-dictionary-utils';
import FormItemTimeInput from '../../../components/form-components/form-item-time-input';
import FormItemSelection from '../../../components/form-components/form-item-selection';
import MovementArmUsedField from './movement-arm-used-field';
import CreateMovementsFormExerciseSelection from './create-movements-form-exercise-selection';
import WorkoutMovementDurationInput from '../workout-movement-duration-input';
import LimbUsedFormItemSelection from './limb-used-form-item-selection';
import '../workout-movemnts.scss';

type EditMovementDrawerProps = {
  record: Workout;
  params: BlockDrawerParams;
  liftAttachmentsList: OptionListItem[];
  blocksOptionsList: { value: string | number; text: string }[];
  onClose: () => void;
  onEditMovement: (movement: Partial<WorkoutMovement>) => void;
};

const EditMovementDrawer = (props: EditMovementDrawerProps) => {
  const { record, liftAttachmentsList, blocksOptionsList, params, onClose, onEditMovement } = props;
  const { type: drawerType, movement } = params;

  const isPlannedSessionType = isCpsWorkoutType(record.session_type);
  const isVisible = drawerType === BlockDrawerType.editMovement;

  if (!isVisible || !movement) return null;

  const { id, set, order, block_id, end_time, duration, attachment = null, limb_used = null, arm_used = null, completion_type } = movement;

  const [completionType, setCompletionType] = React.useState<CompletionType>(completion_type);
  const [exercise, setExercise] = React.useState<Exercise>(movement.exercise);

  const isLiftVod = exercise?.type === ExerciseType.LIFT;
  const isCompletionTypeTime = completionType === CompletionType.time;
  const isCompletionTypeReps = completionType === CompletionType.reps;
  const exerciseAttachments = [exercise?.attachment, ...(exercise?.alternative_attachments ?? [])].filter((item) => item);
  const attachmentsList = liftAttachmentsList.filter((item) => exerciseAttachments.includes(item.value));

  const [form] = Form.useForm();

  const initialData = {
    set,
    order,
    arm_used,
    limb_used,
    attachment,
    completion_type,
    hug: movement.hug,
    duration: duration,
    block_id: `${block_id}`,
    movements: [exercise.id],
    reps: !movement.reps && movement.reps !== 0 ? 0 : movement.reps
  };

  const { changedFormValues, isFormValuesChanged, onValuesChange, onClearChangedFormValues } = useFormValues({ initialData });

  const onReset = () => {
    form.resetFields();
    onClearChangedFormValues();
  };

  const requestData = useMemo(() => {
    const { movements, ...restFields } = changedFormValues;
    const editedFormFields = movements ? { ...restFields, exercise_id: movements[0] } : restFields;
    if (!isLiftVod) editedFormFields.arm_used = LimbUsedEnum.none;
    return { id, ...editedFormFields };
  }, [id, isLiftVod, changedFormValues]);

  const onFinishEditMovementForm = () => {
    onEditMovement(requestData);
    onClose();
  };

  const onFormValuesChange = (changedValues: FormValues) => {
    if (changedValues.completion_type) setCompletionType(changedValues.completion_type);
    onValuesChange(changedValues);
  };

  return (
    <Drawer
      maskClosable={false}
      destroyOnClose
      title="Edit movement"
      width={800}
      onClose={onClose}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        className="create-movement-block-form"
        onFinish={onFinishEditMovementForm}
        onValuesChange={onFormValuesChange}
      >
        <Row>
          <Form.Item name="block_id" className="full-width" label="Select block" rules={[{ required: true, message: 'Please select movement' }]}>
            <FormItemSelection options={blocksOptionsList} />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item name="movements" className="full-width" label="Select movement" rules={[{ required: true, message: 'Please select movement' }]}>
            <CreateMovementsFormExerciseSelection
              initialSelectedExercises={[exercise]}
              onSelectExercises={(exercises) => setExercise(exercises[0])}
            />
          </Form.Item>
        </Row>
        {isPlannedSessionType && (
          <Row>
            <Form.Item className="full-width" name="completion_type" label="Completion type">
              <FormItemSelection options={liftVodCompletionTypeList} />
            </Form.Item>
          </Row>
        )}
        <Row align="middle" gutter={16}>
          {!isPlannedSessionType && (
            <Col span={8}>
              <Form.Item label="End timestamp" className="full-width">
                <FormItemTimeInput typingAllowed disabled value={`${end_time}`} size="middle" className="full-width" />
              </Form.Item>
            </Col>
          )}
          {(!isPlannedSessionType || isCompletionTypeTime) && (
            <Col span={8}>
              <Form.Item name="duration" label="Duration">
                <WorkoutMovementDurationInput withSaveButton={false} />
              </Form.Item>
            </Col>
          )}
          {(isLiftVod || (isPlannedSessionType && isCompletionTypeReps)) && (
            <Col span={8}>
              <Form.Item name="reps" label="Reps">
                <FormItemSelection className="full-width" options={movementsRepsList} />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item name="set" label="Set" className="full-width" rules={[{ type: 'number' }]}>
              <FormItemSelection options={movementSetsList} />
            </Form.Item>
          </Col>
          {isPlannedSessionType && completionType !== CompletionType.transition && (
            <Col span={8}>
              <Form.Item className="full-width" name="resistance" label="Resistance">
                <InputNumber min={0} max={50} />
              </Form.Item>
            </Col>
          )}
          {isLiftVod && (
            <>
              <Col span={8}>
                <Form.Item name="attachment" label="Attachments">
                  <FormItemSelection options={attachmentsList} disabled={!attachmentsList.length} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="limb_used" label="Limb">
                  <LimbUsedFormItemSelection exercise={exercise} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="arm_used" label="Cables">
                  <MovementArmUsedField isLiftVod={isLiftVod} numberArmsUsed={exercise?.number_arms_used} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="hug" label="Hug">
                  <FormItemSelection options={movementHugsList} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row justify="end">
          <Space>
            <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
              Reset
            </Button>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged}>
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditMovementDrawer;
