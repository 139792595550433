import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isEqual } from '../utils/common-utils';
import { Resources } from '../types/resources-types';
import { useAction } from './use-actions';
import { GetListParams } from '../types/request-entities-types';
import { usePreviousValue } from './use-previous-value';
import { fetchResourceList } from '../redux/resource-list/resource-list-actions';
import { clearResourceListData } from '../redux/resource-list/resource-list-reducer';
import { selectIsResourceListLoading, selectResourceListIsError } from '../redux/resource-list/resource-list-selectors';
import useResourceListUrlActions from './use-resource-list-url-actions';
import useResourceListUrlParams from './use-resource-list-url-params';
import api from '../api';

/**
 *
 */
type UseResourceListData = {
  searchInputValue: string;
  page: number;
  rowsPerPage: number;
  onChangePage: (nextPage: number) => void;
  onChangeRowsPerPage: (nextRowsPerPage: number) => void;
  onChangeSearchValue: (value: string) => void;
  onDelete: (id: string) => void;
  refetch: () => void;
};

/**
 *
 */
export const useResourceListData = (resource: Resources): UseResourceListData => {
  const isError = useSelector(selectResourceListIsError);
  const isResourceListLoading = useSelector(selectIsResourceListLoading);
  const { requestParams, page, rowsPerPage, searchInputValue } = useResourceListUrlParams();
  const prevRequestParams = usePreviousValue(requestParams);
  const isRequestParamsChanged = requestParams && !isEqual(requestParams, prevRequestParams);

  const onFetchResourceList = useAction(fetchResourceList);
  const onClearResourceListData = useAction(clearResourceListData);
  const { onChangeSearchValue, onChangeRowsPerPage, onChangePage } = useResourceListUrlActions(resource);

  useEffect(() => () => onClearResourceListData(), [onClearResourceListData]);

  const refetch = useCallback(
    (force?: boolean) => onFetchResourceList({ resource, params: requestParams as GetListParams, force }),
    [onFetchResourceList, resource, requestParams]
  );

  useEffect(() => {
    if (isRequestParamsChanged && !isError && !isResourceListLoading) {
      refetch();
    }
  }, [isRequestParamsChanged, isError, isResourceListLoading, refetch]);

  const onDelete = useCallback(
    async (id: string) => {
      await api.common.delete(resource, { id });
      refetch(true);
    },
    [refetch, resource]
  );

  return {
    page,
    rowsPerPage,
    searchInputValue,
    onChangePage,
    onChangeSearchValue,
    onChangeRowsPerPage,
    onDelete,
    refetch
  };
};
