import React from 'react';
import { EyeOutlined } from '@ant-design/icons';

import { Image } from 'antd';
import BasicModal from '../basic-modal/basic-modal';
import './accessible-table.scss';
import { ImageAssetElement } from '../../types/studio-assets';

type StudioAssetsImagePreviewCellProps = {
  image: ImageAssetElement;
};

const AccessibleTableImagePreviewCell = ({ image }: StudioAssetsImagePreviewCellProps) => {
  return (
    <span className="accessible-table-preview-cell">
      {image?.name}
      <BasicModal title={image?.name} withActions={false} buttonIcon={<EyeOutlined className="watch-icon" />} buttonType="text">
        <div className="accessible-table-preview-cell-modal">
          <Image height={300} src={image.url} alt="" />
        </div>
      </BasicModal>
    </span>
  );
};

export default AccessibleTableImagePreviewCell;
