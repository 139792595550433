import React, { useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { nanoid } from 'nanoid';

import api from '../../../api';
import useDebounce from '../../../hooks/use-debounce';
import { NOOP } from '../../../constants/common-constants';
import { Exercise } from '../../../types/exercises-types';
import { RequestError } from '../../../api/http-client';
import { CondOperator } from '../../../types/cond-operators';
import { getErrorMessage } from '../../../utils/errors';
import { usePreviousValue } from '../../../hooks/use-previous-value';
import { onDisplayErrorNotification } from '../../../utils/notifications-utils';
import { ExerciseType, MovementFocus, MovementPattern, PlaneOfMotion, SessionType, WorkoutEquipment } from '../../../types/common-types';
import { isLiftWorkoutType } from 'src/utils/resources-dictionary-utils';

export enum TransferMovementsSelectionMode {
  single = 'single',
  multiple = 'multiple'
}

type UseTransferMovements = {
  initialTypeFilter?: ExerciseType;
  typeFilter?: ExerciseType;
  patternFilters: MovementPattern[];
  musclesFilters: MovementFocus[];
  motionFilters: PlaneOfMotion[];
  equipmentFilters: WorkoutEquipment[];
  isDropdownVisible: boolean;
  isTypeFilterAvailable: boolean;
  typeFilterTooltip?: string;
  exercises: Exercise[];
  selectedExercises: Exercise[];
  onSelectExercise: (exercise: Exercise, isDuplicate?: boolean) => void;
  searchInputValue: string;
  onSaveSelectedExercises: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeVisibility: (isVisible: boolean) => void;
  onChangeTypeFilter: (e: RadioChangeEvent) => void;
  onChangePatternFilter: (patternFilter: MovementPattern, checked: boolean) => void;
  onChangeMusclesFilter: (patternFilter: MovementFocus, checked: boolean) => void;
  onChangeMotionFilter: (filter: PlaneOfMotion, checked: boolean) => void;
  onChangeEquipmentFilter: (filter: WorkoutEquipment, checked: boolean) => void;
  onClearAllFilters: () => void;
};

type useTransferMovementsParams = {
  session_type: SessionType;
  selectionMode: TransferMovementsSelectionMode;
  selectedItems: Exercise[];
  onSelectItems: (element: Exercise[]) => void;
};

export type MovementsTagsFilters = MovementPattern | MovementFocus | PlaneOfMotion | WorkoutEquipment;

const useWorkoutMovementsSelection = (params: useTransferMovementsParams): UseTransferMovements => {
  const { session_type, selectionMode, selectedItems, onSelectItems = NOOP } = params;

  const isLift = isLiftWorkoutType(session_type);
  const isTpi = session_type === SessionType.TpiVOD || session_type === SessionType.TpiPlannedSession;
  const initialTypeFilter = isTpi ? ExerciseType.TPI : !isLift ? ExerciseType.STUDIO : undefined;
  const isTypeFilterAvailable = isLift || isTpi;
  const typeFilterTooltip = !isTypeFilterAvailable ? 'Sort by filter cannot be changed as the workout type is Studio' : undefined;

  const [typeFilter, setTypeFilter] = useState<ExerciseType | undefined>(initialTypeFilter);
  const [patternFilters, setPatternFilters] = useState<MovementPattern[]>([]);
  const [musclesFilters, setMusclesFilters] = useState<MovementFocus[]>([]);
  const [motionFilters, setMotionFilters] = useState<PlaneOfMotion[]>([]);
  const [equipmentFilters, setEquipmentFilters] = useState<WorkoutEquipment[]>([]);

  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [exercises, setExercises] = React.useState<Exercise[]>([]);
  const [selectedExercises, setSelectedExercises] = React.useState<(Exercise & { nanoId?: string })[]>([]);

  const debouncedSearchValue = useDebounce(searchInputValue);
  const prevIsDropdownVisible = usePreviousValue(isDropdownVisible);

  useEffect(() => {
    const justClosed = prevIsDropdownVisible && !isDropdownVisible;
    const justOpened = !prevIsDropdownVisible && isDropdownVisible;
    if (justOpened) setSelectedExercises(selectedItems);
    if (justClosed) {
      setSelectedExercises([]);
      setSearchInputValue('');
    }
  }, [isDropdownVisible, prevIsDropdownVisible, selectedItems]);

  useEffect(() => {
    if (!isDropdownVisible) return;

    api.exercises
      .getNonPaginatedExerciseList({
        sort: { field: 'name', order: 'ASC' },
        filter: {
          'searchBy||name': debouncedSearchValue,
          [`type||${CondOperator.EQUALS}`]: typeFilter,
          [`movement_pattern||${CondOperator.IN}`]: patternFilters.length ? patternFilters : undefined,
          [`plane_of_motion||${CondOperator.IN}`]: motionFilters.length ? motionFilters : undefined,
          [`equipment||${CondOperator.IN}`]: equipmentFilters.length ? equipmentFilters : undefined,
          // Filter in only FORME official exercises (non-trainer created)
          [`is_public||${CondOperator.IN}`]: [true],
          [`owner_id||${CondOperator.IS_NULL}`]: true
        }
      })
      .then(({ data: response }: any) => {
        setExercises(response.map((item: Exercise) => ({ ...item, key: item.id })));
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  }, [isDropdownVisible, debouncedSearchValue, typeFilter, patternFilters, musclesFilters, motionFilters, equipmentFilters]);

  const onChangeVisibility = (flag: boolean) => setIsDropdownVisible(flag);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value);

  const onSelectExercise = (exercise: Exercise & { nanoId?: string }, isDuplicate?: boolean) => {
    if (selectionMode === TransferMovementsSelectionMode.single) return setSelectedExercises([exercise]);
    const isChecked = !!selectedExercises.find(({ id }) => exercise.id === id);
    if (!isChecked || isDuplicate) return setSelectedExercises([...selectedExercises, { ...exercise, nanoId: nanoid() }]);
    return setSelectedExercises(
      selectedExercises.filter((item) => {
        if (item.nanoId && exercise.nanoId) return item.nanoId !== exercise.nanoId;
        return item.id !== exercise.id;
      })
    );
  };

  const onSaveSelectedExercises = () => {
    onSelectItems(selectedExercises);
    onChangeVisibility(false);
  };

  const onChangeTypeFilter = (e: RadioChangeEvent) => {
    setTypeFilter(e.target.value as ExerciseType | undefined);
  };

  const getNextFilters = <T extends MovementsTagsFilters>(filter: T, prevFilters: T[], checked: boolean): T[] =>
    checked ? [...prevFilters, filter] : prevFilters.filter((item) => item !== filter);

  const onChangePatternFilter = (filter: MovementPattern, checked: boolean) => {
    setPatternFilters(getNextFilters(filter, patternFilters, checked));
  };

  const onChangeMusclesFilter = (filter: MovementFocus, checked: boolean) => {
    setMusclesFilters(getNextFilters(filter, musclesFilters, checked));
  };

  const onChangeMotionFilter = (filter: PlaneOfMotion, checked: boolean) => {
    setMotionFilters(getNextFilters(filter, motionFilters, checked));
  };

  const onChangeEquipmentFilter = (filter: WorkoutEquipment, checked: boolean) => {
    setEquipmentFilters(getNextFilters(filter, equipmentFilters, checked));
  };

  const onClearAllFilters = () => {
    setTypeFilter(initialTypeFilter);
    setPatternFilters([]);
    setMusclesFilters([]);
    setMotionFilters([]);
    setEquipmentFilters([]);
  };

  return {
    initialTypeFilter,
    isTypeFilterAvailable,
    typeFilterTooltip,
    typeFilter,
    patternFilters,
    musclesFilters,
    motionFilters,
    equipmentFilters,
    isDropdownVisible,
    exercises,
    selectedExercises,
    searchInputValue,
    onSaveSelectedExercises,
    onSelectExercise,
    onChangeSearchInput,
    onChangeVisibility,
    onChangeTypeFilter,
    onChangePatternFilter,
    onChangeMusclesFilter,
    onChangeMotionFilter,
    onChangeEquipmentFilter,
    onClearAllFilters
  };
};

export default useWorkoutMovementsSelection;
