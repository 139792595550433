import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row, Typography } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';

import { useAction } from '../../hooks/use-actions';
import { VidePlayers } from '../../types/video-player-types';
import { setVideoPlayer } from '../../redux/settings/settings-reducer';
import { selectVideoPlayer } from '../../redux/settings/settings-selectors';
import { videoPlayersConfigMap, videoPlayersOptionsList } from '../../constants/video-players';
import BasicModal from '../../components/basic-modal/basic-modal';
import FormItemSelection from '../../components/form-components/form-item-selection';
import { ButtonType } from 'antd/lib/button/button';

type SelectVideoPlayerModalProps = {
  buttonType?: ButtonType;
  buttonTitle?: string;
  withButtonTitle?: boolean;
  buttonIcon?: ReactElement;
};

const SelectVideoPlayerModal = (props: SelectVideoPlayerModalProps) => {
  const { buttonIcon = <VideoCameraOutlined />, buttonTitle = 'Select video player', buttonType = 'text', withButtonTitle = true } = props;

  const userVideoPlayer = useSelector(selectVideoPlayer);
  const onSetVideoPlayer = useAction(setVideoPlayer);
  const userVideoPlayerOption = videoPlayersConfigMap[userVideoPlayer];

  const [player, setPlayer] = useState(userVideoPlayer);
  const onSetPlayer = () => onSetVideoPlayer(player);

  return (
    <BasicModal
      key="model"
      title="Select video player"
      buttonTitle={withButtonTitle ? buttonTitle : undefined}
      tooltipLabel=""
      okText="Save"
      buttonType={buttonType}
      modalWidth={700}
      buttonIcon={buttonIcon}
      onSuccess={onSetPlayer}
    >
      <div>
        <Typography.Title level={5}>{`Settings video player: ${userVideoPlayerOption.text}`}</Typography.Title>
        <Divider />
        <Row align="middle">
          <Col span={6} className="row-title">
            Available video players:
          </Col>
          <Col span={18}>
            <FormItemSelection
              value={player}
              placeholder="select video player"
              options={videoPlayersOptionsList}
              onChange={(nextPlayer) => setPlayer(nextPlayer as VidePlayers)}
            />
          </Col>
        </Row>
      </div>
    </BasicModal>
  );
};

export default SelectVideoPlayerModal;
