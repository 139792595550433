import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Form, Input, Row, Typography } from 'antd';
import ReactJson from 'react-json-view';
import prettyBytes from 'pretty-bytes';
import 'antd/dist/antd.css';
import 'video-react/dist/video-react.css';

import { Resources } from '../../types/resources-types';
import { getFormattedDate } from '../../utils/date-utils';
import { SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages';
import { transcodingJobStateColors } from '../../types/common-types';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { DurationType, getFormattedDurationTime } from '../../utils/time-utils';
import { selectIsResourceRecordError, selectResourceRecordError } from '../../redux/resource-record/resource-record-selectors';

import useVideoRecord from './use-video-record';
import StubPage from '../../components/stub-page/stub-page';
import AccessibleTable from '../../components/accessible-table';
import AccessibleTableTags from '../../components/accessible-table/accessible-table-tags';
import ResourceRecordContainer from '../../containers/resource-record-container/resource-record-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import CheckTagButton from '../../components/buttons/check-tag-button';
import MinusTagButton from '../../components/buttons/minus-tag-button';
import ConfirmButton from '../../components/buttons/confirm-button';
import VideoPlayerWithTroubleShooting from '../../components/video-player/video-player-with-trouble-shooting';
import { stringValidator } from '../../validators/string-validator';

const VideoRecordPage = () => {
  const {
    record,
    isLoading,
    form,
    initialData,
    studioSize,
    duration,
    timeZone,
    isFormValuesChanged,
    transcodingJobs,
    onReset,
    onFinish,
    onValuesChange,
    onArchiveVideo
  } = useVideoRecord();

  const isError = useSelector(selectIsResourceRecordError);
  const resourceErrorMessage = useSelector(selectResourceRecordError);

  const transcodingJobsColumns = [
    {
      width: 300,
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
      render: (transcodingJobId: string, item: Record<string, any>) => (
        <AccessibleTableLinkedCell value={transcodingJobId} link={`/${Resources.transcodingJobs}/${item.id}`} />
      )
    },
    {
      width: 150,
      key: 'state',
      title: 'State',
      dataIndex: 'state',
      render: (state: string) => <AccessibleTableTags tags={[state]} colorsMap={transcodingJobStateColors} />
    },
    {
      width: 170,
      key: 'create_time',
      title: 'Create time',
      dataIndex: 'create_time',
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  if (isError || resourceErrorMessage) {
    return <StubPage mainMessage={SOME_ERROR_MAIN_MESSAGE} secondaryMessage={resourceErrorMessage} />;
  }

  if (!record) {
    return null;
  }

  return (
    <ResourceRecordContainer resource={Resources.videos} isLoading={isLoading} record={record}>
      <Typography.Title className="page-title" level={3}>
        Change Video
      </Typography.Title>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialData}
        validateMessages={commonFormValidateMessages}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Id">{initialData.id}</Form.Item>
        <Form.Item label="UUID">{initialData.uuid}</Form.Item>
        <Form.Item label="Archive">{record.is_archived ? <CheckTagButton /> : <MinusTagButton />}</Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, type: 'string' }, { validator: stringValidator }]}>
          <Input />
        </Form.Item>
        <Form.Item validateFirst={false} label="Video">
          <VideoPlayerWithTroubleShooting data={record} />
        </Form.Item>
        <Form.Item validateFirst={false} label="Download size">
          <span>{studioSize ? prettyBytes(studioSize) : '-'}</span>
        </Form.Item>
        <Form.Item validateFirst={false} label="Duration">
          <span>{getFormattedDurationTime(Number(duration), DurationType.milliSeconds)}</span>
        </Form.Item>
        <Form.Item label="Has audio">{record.has_audio ? <CheckTagButton /> : <MinusTagButton />}</Form.Item>
        <Form.Item label="Aspect ratio">{record?.aspect_ratio ?? '-'}</Form.Item>
        <Form.Item validateFirst={false} label="Created date">
          <span>{getFormattedDate(record?.created_date ?? '', timeZone)}</span>
        </Form.Item>
        <Form.Item validateFirst={false} label="Updated date">
          <span>{getFormattedDate(record?.updated_date ?? '', timeZone)}</span>
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} disabled={!isFormValuesChanged} onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isFormValuesChanged}>
            Save
          </Button>
        </Form.Item>

        {!record.is_archived && (
          <>
            <Divider orientation="center" plain>
              Actions
            </Divider>

            <Form.Item label="Archive">
              <ConfirmButton title="This operation is can not be reverted" buttonLabel="Archive" okText="Archive" onConfirm={onArchiveVideo} />
            </Form.Item>
          </>
        )}

        <Divider />

        <AccessibleTable
          data={transcodingJobs}
          columns={transcodingJobsColumns}
          renderTitle={() => (
            <Row align="middle" justify="space-between">
              <Typography.Title level={4}>Transcoding Jobs</Typography.Title>
            </Row>
          )}
        />

        <Divider />

        <Form.Item label="Meta data">
          <ReactJson src={record?.meta_data?.streams} name={false} />
        </Form.Item>
      </Form>
    </ResourceRecordContainer>
  );
};

export default VideoRecordPage;
