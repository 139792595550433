import React from 'react';
import { Modal, Button } from 'antd';

type CreateVideoDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

const CreateVideoDialog = (props: CreateVideoDialogProps) => {
  const { isOpened, onClose } = props;

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      visible={isOpened}
      maskClosable={false}
      wrapClassName="modal-wrap"
      title="Create video record"
      footer={[
        <Button key="cancel" htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
          Ok
        </Button>
      ]}
      onCancel={onClose}
    >
      <>
        <br />
        Video will be created automatically by transcoding pipeline after upload to{' '}
        <a target="_blank" rel="noreferrer" href="https://console.cloud.google.com/storage/browser/formelife-video-upload">
          {' '}
          [formelife-video-upload]{' '}
        </a>
        bucket
        <br />
        <br />
      </>
    </Modal>
  );
};

export default CreateVideoDialog;
