import momentTimezone from 'moment-timezone';

export enum MomentDateFormat {
  usDate = `MM/DD/YYYY, h:mm:ss a`,
  usFullDate = `MMM DD YYYY, h:mm:ss a`,
  usShortOnlyDate = 'MM/DD/YY',
  usShortDateWithTime = 'MM/DD/YY, h:mm a'
}

export const getFormattedDate = (date: Date | string | null | undefined, timeZone: string, format = MomentDateFormat.usShortDateWithTime) => {
  if (!date) return '-';
  return momentTimezone(date).tz(timeZone).format(format);
};
