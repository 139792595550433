import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DragOutlined } from '@ant-design/icons';

import { Exercise } from '../../../types/exercises-types';
import { ColumnItem } from '../../../components/accessible-table/accessible-table-types';
import { selectDictionary } from '../../../redux/settings/settings-selectors';
import { getArmUsedOptionsList } from '../movements-utils';
import { getDictionaryItemConfig } from '../../../redux/settings/settings-utils';
import { DurationType, getFormattedDurationTime } from '../../../utils/time-utils';
import { Workout, WorkoutMovement } from '../../../types/workout-types';
import {
  bilateralLimbUsedEnumList,
  isCpsWorkoutType,
  liftVodCompletionTypeList,
  limbUsedEnumList,
  movementHugsList,
  movementSetsList,
  movementsRepsList,
  unilateralLimbUsedEnumList
} from '../../../utils/resources-dictionary-utils';
import { ArmUsedEnum, CompletionType, ExerciseType, LimbUsedEnum, SessionType, SideUsageType } from '../../../types/common-types';
import { EditBlockNameParams, ReorderBlocksParams } from '../hooks/use-workout-movements';

import DragHandle from '../../../components/drag-handle';
import AccessibleTable from '../../../components/accessible-table';
import FormItemSelection from '../../../components/form-components/form-item-selection';
import MovementExerciseTitle from '../movement-exercise-title';
import WorkoutMovementTableContextMenu from './workout-movement-table-context-menu';
import WorkoutMovementDurationInput from '../workout-movement-duration-input';
import WorkoutMovementsTableTitle from './workout-movements-table-title';
import AccessibleTableInputNumberEditCell from 'src/components/accessible-table/accessible-table-input-number-edit-cell';

type WorkoutMovementsTableProps = {
  record: Workout;
  tableHeight: number;
  onReorderBlockMovements: (blockId: string, nextBlockMovementsOrder: WorkoutMovement[]) => void;
  onDeleteMovement: (blockId: string, movementId: string) => void;
  onOpenInsertMovementDrawer: (blockId: string, order?: number) => void;
  onOpenInsertTransitionDrawer: (order: number, blockId: string) => void;
  onOpenEditMovementDrawer: (movement: WorkoutMovement) => void;
  onOpenEditTransitionDrawer: (movement: WorkoutMovement) => void;
  onEditMovement: (movement: Partial<WorkoutMovement>) => void;
  onRemoveBlock: (blockId: string) => void;
  onOpenCreateNewMovement: (editMovement?: WorkoutMovement) => void;
  onOpenCreateNewTransition: (editMovement?: WorkoutMovement) => void;
  onEditBlockName: (editBlockNameParams: EditBlockNameParams) => void;
  onReorderBlocks: (reorderBlocksParams: ReorderBlocksParams) => void;
};

type ContextMenuParams = {
  position: { x: number; y: number };
  isContextMenuVisible: boolean;
  contextMenuRecord: WorkoutMovement | null;
};

const initialContextMenuParams = {
  position: { x: 0, y: 0 },
  contextMenuRecord: null,
  isContextMenuVisible: false
};

const WorkoutMovementsTable = (props: WorkoutMovementsTableProps) => {
  const {
    record,
    tableHeight,
    onReorderBlockMovements,
    onDeleteMovement,
    onOpenInsertMovementDrawer,
    onOpenInsertTransitionDrawer,
    onOpenEditMovementDrawer,
    onOpenEditTransitionDrawer,
    onEditMovement,
    onOpenCreateNewMovement,
    onOpenCreateNewTransition,
    onRemoveBlock,
    onEditBlockName,
    onReorderBlocks
  } = props;

  const blocksIds = record.blocks.map((item) => item.id).filter((item) => !!item);
  const isPlannedSessionType = isCpsWorkoutType(record?.session_type);
  const isLift = record?.session_type === SessionType.LiftVOD || record?.session_type === SessionType.LiftPlannedSession;
  const dictionary = useSelector(selectDictionary);

  const { optionsList: liftAttachmentsList } = getDictionaryItemConfig(dictionary, 'lift_attachment');
  const [contextMenuParams, setContextMenuParams] = useState<ContextMenuParams>(initialContextMenuParams);
  const { isContextMenuVisible, position, contextMenuRecord } = contextMenuParams;

  const getIsLiftExercise = (item: Exercise) => item.type === ExerciseType.LIFT;

  const getAvailableExerciseAttachments = useCallback(
    (exercise: Exercise) => {
      const exerciseAttachments = [exercise?.attachment, ...(exercise?.alternative_attachments ?? [])].filter((item) => item);
      return liftAttachmentsList.filter((item) => exerciseAttachments.includes(item.value));
    },
    [liftAttachmentsList]
  );

  const getAvailableLimbs = (exercise: Exercise) => {
    if (exercise?.side_usage_type === SideUsageType.bilateral) return bilateralLimbUsedEnumList;
    if (exercise?.side_usage_type === SideUsageType.unilateral) return unilateralLimbUsedEnumList;
    return limbUsedEnumList;
  };

  const blocksData = useMemo(() => {
    let globalIndex = 0;
    return record.blocks.map((item) => ({
      ...item,
      movements: item.movements.map((movement) => {
        globalIndex = globalIndex + 1;
        return { ...movement, index: movement.order, rowIndex: globalIndex };
      })
    }));
  }, [record.blocks]);

  const onExerciseNameClick = useCallback(
    (rowData: WorkoutMovement, isTransition: boolean) => (isTransition ? onOpenEditTransitionDrawer(rowData) : onOpenEditMovementDrawer(rowData)),
    [onOpenEditTransitionDrawer, onOpenEditMovementDrawer]
  );

  const columnsMap = {
    index: {
      key: 'rowIndex',
      width: 70,
      dataIndex: 'rowIndex',
      fixed: true,
      title: 'Index'
    },
    sort: {
      key: 'sort',
      width: 70,
      dataIndex: 'sort',
      fixed: true,
      className: 'drag-visible',
      title: () => <DragOutlined />,
      render: () => <DragHandle />
    },
    exerciseName: {
      width: 300,
      fixed: true,
      key: 'exercise.name',
      title: 'Exercise',
      dataIndex: 'exercise.name',
      render: (value: string, item: Record<string, any>) => {
        const isTransition = item.completion_type === CompletionType.transition;
        return <MovementExerciseTitle exercise={item?.exercise} onClick={() => onExerciseNameClick(item as WorkoutMovement, isTransition)} />;
      }
    },
    duration: {
      width: 140,
      key: 'duration',
      title: 'Duration',
      dataIndex: 'duration',
      render: (value: string, movement: Record<string, any>) => {
        const isCompletionTypeTime = movement.completion_type === CompletionType.time;
        if (isPlannedSessionType && !isCompletionTypeTime) return '';
        return (
          <WorkoutMovementDurationInput
            value={value}
            onChange={(nextDuration) => {
              onEditMovement({ id: movement.id, duration: nextDuration });
            }}
          />
        );
      }
    },
    exerciseEndTime: {
      width: 140,
      key: 'end_time',
      title: 'End time',
      dataIndex: 'end_time',
      render: (value: string) => {
        return <span>{getFormattedDurationTime(Number(value), DurationType.milliSeconds)}</span>;
      }
    },
    set: {
      width: 120,
      key: 'set',
      dataIndex: 'set',
      title: 'Set',
      render: (value: string, movement: Record<string, any>) => {
        if (movement.completion_type === CompletionType.transition) return '';
        return (
          <FormItemSelection
            value={value}
            bordered={false}
            className="full-width"
            options={movementSetsList}
            onChange={(nextSet) => {
              onEditMovement({ id: movement.id, set: Number(nextSet) });
            }}
          />
        );
      }
    },
    attachment: {
      width: 180,
      key: 'attachment',
      dataIndex: 'attachment',
      title: 'Attachment',
      render: (value: string, movement: Record<string, any>) => {
        if (!getIsLiftExercise(movement.exercise)) return '';
        const attachmentsOptionsList = getAvailableExerciseAttachments(movement.exercise);
        return (
          <FormItemSelection
            value={value}
            bordered={false}
            className="full-width"
            disabled={!attachmentsOptionsList.length}
            options={attachmentsOptionsList}
            onChange={(nextAttachment) => {
              onEditMovement({ id: movement.id, attachment: nextAttachment });
            }}
          />
        );
      }
    },
    limb: {
      width: 150,
      key: 'limb_used',
      dataIndex: 'limb_used',
      title: 'Limb',
      render: (value: string, movement: Record<string, any>) => {
        if (!getIsLiftExercise(movement.exercise)) return '';
        const limbUsedOptionsList = getAvailableLimbs(movement.exercise);
        return (
          <FormItemSelection
            value={value}
            bordered={false}
            className="full-width"
            options={limbUsedOptionsList}
            onChange={(nextLimb) => {
              onEditMovement({ id: movement.id, limb_used: nextLimb as LimbUsedEnum });
            }}
          />
        );
      }
    },
    cables: {
      width: 120,
      key: 'arm_used',
      dataIndex: 'arm_used',
      title: 'Cables',
      render: (value: string, movement: Record<string, any>) => {
        if (!getIsLiftExercise(movement.exercise)) return '';
        return (
          <FormItemSelection
            value={value}
            bordered={false}
            className="full-width"
            options={getArmUsedOptionsList(isLift, movement?.exercise?.number_arms_used)}
            onChange={(nextArmUsed) => {
              onEditMovement({ id: movement.id, arm_used: nextArmUsed as ArmUsedEnum });
            }}
          />
        );
      }
    },
    hug: {
      width: 150,
      key: 'hug',
      dataIndex: 'hug',
      title: 'Hug',
      render: (value: string, movement: Record<string, any>) => {
        if (!getIsLiftExercise(movement.exercise)) return '';
        return (
          <FormItemSelection
            value={value}
            bordered={false}
            className="full-width"
            options={movementHugsList}
            onChange={(nextHug) => {
              onEditMovement({ id: movement.id, hug: nextHug });
            }}
          />
        );
      }
    },
    reps: {
      width: 150,
      key: 'reps',
      dataIndex: 'reps',
      title: 'Reps',
      render: (value: number, movement: Record<string, any>) => {
        const isLiftExercise = getIsLiftExercise(movement.exercise);
        const isMovement = movement.exercise.is_movement;
        const isCompletionTypeReps = movement.completion_type === CompletionType.reps;
        const isAvailable = isMovement && (isLiftExercise || (isPlannedSessionType && isCompletionTypeReps));
        if (!isAvailable) return '';
        return (
          <FormItemSelection
            bordered={false}
            className="full-width"
            options={movementsRepsList}
            value={`${value}`}
            onChange={(nextReps) => {
              onEditMovement({ id: movement.id, reps: Number(nextReps) });
            }}
          />
        );
      }
    },
    completionType: {
      width: 150,
      key: 'completion_type',
      dataIndex: 'completion_type',
      title: 'Completion type',
      render: (value: string, movement: Record<string, any>) => {
        if (!movement.exercise.is_movement) return '';
        return (
          <FormItemSelection
            value={value}
            bordered={false}
            className="full-width"
            options={liftVodCompletionTypeList}
            onChange={(nextCompletionType) => {
              onEditMovement({ id: movement.id, completion_type: nextCompletionType as CompletionType });
            }}
          />
        );
      }
    },
    resistance: {
      width: 150,
      key: 'resistance',
      dataIndex: 'resistance',
      title: 'Resistance',
      render: (value: number, movement: Record<string, any>) => {
        if (movement.completion_type === CompletionType.transition) return '';
        return (
          <AccessibleTableInputNumberEditCell
            value={value}
            onChange={(resistance) => {
              onEditMovement({ id: movement.id, resistance: resistance });
            }}
            minNumber={0}
            maxNumber={50}
          />
        );
      }
    }
  };

  const commonColumns = [columnsMap.index, columnsMap.sort, columnsMap.exerciseName, columnsMap.duration];
  const vodColumns = [...commonColumns, columnsMap.exerciseEndTime, columnsMap.set];
  const vodLiftColumns = [
    ...commonColumns,
    columnsMap.reps,
    columnsMap.exerciseEndTime,
    columnsMap.set,
    columnsMap.attachment,
    columnsMap.limb,
    columnsMap.cables,
    columnsMap.hug
  ];

  const plannedSessionVodColumns = [
    columnsMap.index,
    columnsMap.sort,
    columnsMap.exerciseName,
    columnsMap.completionType,
    columnsMap.duration,
    columnsMap.reps,
    columnsMap.set,
    columnsMap.resistance
  ];
  const plannedSessionLiftVodColumns = [...plannedSessionVodColumns, columnsMap.attachment, columnsMap.limb, columnsMap.cables, columnsMap.hug];

  const columnsBySessionTypeMap = {
    [SessionType.VideoonDemand]: vodColumns,
    [SessionType.TpiVOD]: vodColumns,
    [SessionType.LiftVOD]: vodLiftColumns,
    [SessionType.PlannedSession]: plannedSessionVodColumns,
    [SessionType.TpiPlannedSession]: plannedSessionVodColumns,
    [SessionType.LiftPlannedSession]: plannedSessionLiftVodColumns
  } as Record<SessionType, ColumnItem[]>;

  const elementsColumns = columnsBySessionTypeMap[record.session_type] || [];

  const getRowClassName = (movement: Record<string, any>) => (movement.completion_type === CompletionType.transition ? 'highlighted-row' : '');

  const onClickOutside = () => {
    setContextMenuParams(initialContextMenuParams);
    document.removeEventListener(`click`, onClickOutside);
  };

  const onRowContextMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, rowData: Record<string, any>) => {
    e.preventDefault();

    if (!isContextMenuVisible) {
      document.addEventListener(`click`, onClickOutside);
    }

    setContextMenuParams({
      position: { x: e.clientX, y: e.clientY },
      isContextMenuVisible: true,
      contextMenuRecord: rowData as WorkoutMovement
    });
  };

  return (
    <div className="workout-movements-table" style={{ height: tableHeight }}>
      {blocksData.map((block) => {
        const { id: blockId, movements } = block;
        return (
          <span key={blockId}>
            <AccessibleTable
              stickyTitle
              isDraggable
              key={blockId}
              data={movements}
              renderTitle={() => (
                <WorkoutMovementsTableTitle
                  blocksIds={blocksIds}
                  movementBlock={block}
                  onReorderBlocks={onReorderBlocks}
                  onRemoveBlock={onRemoveBlock}
                  onEditBlockName={onEditBlockName}
                  onOpenInsertMovementDrawer={onOpenInsertMovementDrawer}
                />
              )}
              columns={elementsColumns}
              rowClassName={getRowClassName}
              onRowContextMenu={onRowContextMenu}
              onSortEnd={(nextMovementsOrder: Record<string, any>[]) => onReorderBlockMovements(blockId, nextMovementsOrder as WorkoutMovement[])}
            />
            <span className="workout-movements-table-space" />
          </span>
        );
      })}
      <WorkoutMovementTableContextMenu
        position={position}
        isVisible={isContextMenuVisible}
        rowData={contextMenuRecord}
        onDeleteMovement={onDeleteMovement}
        onOpenInsertMovementDrawer={onOpenInsertMovementDrawer}
        onOpenInsertTransitionDrawer={onOpenInsertTransitionDrawer}
        onOpenEditMovementDrawer={onOpenEditMovementDrawer}
        onOpenEditTransitionDrawer={onOpenEditTransitionDrawer}
        onOpenCreateNewMovement={onOpenCreateNewMovement}
        onOpenCreateNewTransition={onOpenCreateNewTransition}
      />
    </div>
  );
};

export default WorkoutMovementsTable;
