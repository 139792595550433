import { QuerySort, RequestQueryBuilder } from '@nestjsx/crud-request';
import { GetListParams } from 'src/types/request-entities-types';
import { Resources } from '../types/resources-types';
import { composeSearch } from './api-utils';
import { HttpClient } from './http-client';

const exercisesApi = (baseUrl: string, httpClient: HttpClient) => ({
  getNonPaginatedExerciseList: ({ filter, sort }: Pick<GetListParams, 'filter' | 'sort'>) => {
    const encodedQueryFilter = RequestQueryBuilder.create({ search: composeSearch(filter) })
      .sortBy(sort as QuerySort)
      .query();
    const requestUrl = `${baseUrl}/${Resources.exercises}?${encodedQueryFilter}`;

    return httpClient(requestUrl);
  }
});

export default exercisesApi;
