import { useEffect, useState } from 'react';
import { FormImageData } from '../components/form-components/form-item-image';
import { ImageAssetElement } from '../types/studio-assets';

type UseSelectImageProps = {
  imageData: ImageAssetElement | null;
};

type UseSelectImage = {
  assetImage: FormImageData | null;
  setAssetImage: (studioAssetImage: FormImageData | null) => void;
};

const useSelectImage = ({ imageData }: UseSelectImageProps): UseSelectImage => {
  const [assetImage, setAssetImage] = useState<FormImageData | null>(null);

  useEffect(() => {
    if (imageData) {
      setAssetImage({ ...imageData, isUploaded: false });
    }
  }, [imageData]);

  return { assetImage, setAssetImage };
};

export default useSelectImage;
