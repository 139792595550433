import { useEffect } from 'react';

import { Resources } from '../types/resources-types';
import { useAction } from './use-actions';
import { useItemSelector } from './use-selector';
import { usePreviousValue } from './use-previous-value';
import { fetchResourceRecord } from '../redux/resource-record/resource-record-actions';
import { clearResourceRecordData } from '../redux/resource-record/resource-record-reducer';
import { getResourcePermissions } from '../redux/permissions/permissions-selectors';

type UseResourceRecordDataParams = {
  resource: Resources;
  recordId?: string;
};

/**
 *
 */
export const useResourceRecordData = (params: UseResourceRecordDataParams) => {
  const { recordId, resource } = params;

  const { isReadAllowed } = useItemSelector({ resource }, getResourcePermissions);
  const prevIsReadAllowed = usePreviousValue(isReadAllowed);

  const onClearRecordData = useAction(clearResourceRecordData);
  const onFetchRecord = useAction(fetchResourceRecord);

  useEffect(() => {
    const isReadAllowedChanged = isReadAllowed !== prevIsReadAllowed;

    if (recordId && resource && isReadAllowed && isReadAllowedChanged) {
      onFetchRecord(recordId, resource);
    }

    return () => onClearRecordData();
  }, [resource, recordId, isReadAllowed, prevIsReadAllowed, onFetchRecord, onClearRecordData]);
};
