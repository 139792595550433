import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from './store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type AppProviderProps = {
  children: ReactNode;
};

function AppProviders({ children }: AppProviderProps) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>{children}</Provider>
    </DndProvider>
  );
}

export default AppProviders;
