import React, { useMemo, useState } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';
import {
  exerciseComplexityList,
  exerciseTypeList,
  hasVideoFiltersList,
  primeMoverList,
  statusesList,
  trainerCreatedFiltersList
} from '../../utils/resources-dictionary-utils';
import { COLUMN_DESCEND, exerciseComplexityColors, Order, primeMoverColors, statusesColorsMap } from '../../types/common-types';

import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';
import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import CreateExerciseDialog from './exercise-create-dialog';
import CheckTagButton from '../../components/buttons/check-tag-button';
import MinusTagButton from '../../components/buttons/minus-tag-button';
import { FilterSelectionType } from '../../components/accessible-table/accessible-table-filter-select';
import { HasVideoTypes } from '../../types/videos-types';
import { TrainerCreatedTypes } from '../../types/exercises-types';
import ExerciseOwnerFilterDropdown from './exercise-owner-filter-dropdown';

const resource = Resources.exercises;
const searchFilterFields = ['name'];
const initialSortData = { sortField: 'name', sortOrder: Order.asc };
const filterTypes = {
  type: 'type',
  modality: 'modality',
  complexity: 'complexity',
  prime_mover_1: 'prime_mover_1',
  intro_video_id: 'intro_video_id',
  trainer_created: 'trainer_created',
  ['owner.id']: 'owner.id'
};
const defaultFilters = {
  ...Object.keys(filterTypes).reduce((acc, key) => ({ ...acc, [key]: [] }), {}),
  [filterTypes['intro_video_id']]: [HasVideoTypes.all],
  [filterTypes['trainer_created']]: [TrainerCreatedTypes.all]
};

const ExercisesListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, filtersOperators, activeFilterDropdown, onSetFilter, onSetFilters, onFilterDropdownChange } = useResourceListTableFilters({
    filterTypes
  });
  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  const introVideoFilter = useMemo(() => {
    const activeIntroVideoFilters = filters[filterTypes['intro_video_id']];
    return Array.isArray(activeIntroVideoFilters) ? activeIntroVideoFilters : [activeIntroVideoFilters];
  }, [filters]);

  const introVideoFilterOption = useMemo(() => {
    const operator = filtersOperators[filterTypes['intro_video_id']];
    const withVideo = introVideoFilter[0] === HasVideoTypes.withVideo;
    const withoutVideo = introVideoFilter[0] === HasVideoTypes.withoutVideo;
    const isEqual = operator === CondOperator.EQUALS;
    const isNotEqual = operator === CondOperator.NOT_EQUALS;

    if (withoutVideo || (isEqual && `${introVideoFilter[0]}` === '-1')) return [HasVideoTypes.withoutVideo];
    if (withVideo || (isNotEqual && `${introVideoFilter[0]}` === '-1')) return [HasVideoTypes.withVideo];
    return [HasVideoTypes.all];
  }, [introVideoFilter, filtersOperators]);

  const introVideoFilters = useMemo(() => {
    switch (introVideoFilter[0]) {
      case HasVideoTypes.withVideo: {
        return {
          [`${filterTypes['intro_video_id']}||${CondOperator.NOT_NULL}`]: true,
          [`${filterTypes['intro_video_id']}||${CondOperator.IS_NULL}`]: undefined
        };
      }
      case HasVideoTypes.withoutVideo: {
        return {
          [`${filterTypes['intro_video_id']}||${CondOperator.NOT_NULL}`]: undefined,
          [`${filterTypes['intro_video_id']}||${CondOperator.IS_NULL}`]: true
        };
      }
      case HasVideoTypes.all: {
        return {
          [`${filterTypes['intro_video_id']}||${CondOperator.NOT_NULL}`]: undefined,
          [`${filterTypes['intro_video_id']}||${CondOperator.IS_NULL}`]: undefined
        };
      }
      default:
        return {};
    }
  }, [introVideoFilter]);

  const trainerCreatedFilter = useMemo(() => {
    const activeTrainerCreatedFilters = filters[filterTypes['trainer_created']];
    return Array.isArray(activeTrainerCreatedFilters) ? activeTrainerCreatedFilters : [activeTrainerCreatedFilters];
  }, [filters]);

  const trainerCreatedFilterOption = useMemo(() => {
    const operator = filtersOperators[filterTypes['trainer_created']];
    const trainerCreated = trainerCreatedFilter[0] === TrainerCreatedTypes.trainerCreated;
    const notTrainerCreated = trainerCreatedFilter[0] === TrainerCreatedTypes.notTrainerCreated;
    const isEqual = operator === CondOperator.EQUALS;
    const isNotEqual = operator === CondOperator.NOT_EQUALS;

    if (notTrainerCreated || (isEqual && `${trainerCreatedFilter[0]}` === '-1')) return [TrainerCreatedTypes.notTrainerCreated];
    if (trainerCreated || (isNotEqual && `${trainerCreatedFilter[0]}` === '-1')) return [TrainerCreatedTypes.trainerCreated];
    return [TrainerCreatedTypes.all];
  }, [trainerCreatedFilter, filtersOperators]);

  const trainerCreatedFilters = useMemo(() => {
    switch (trainerCreatedFilter[0]) {
      case TrainerCreatedTypes.trainerCreated: {
        return {
          [`is_public||${CondOperator.IN}`]: [false],
          [`owner_id||${CondOperator.IS_NULL}`]: undefined,
          [`owner_id||${CondOperator.NOT_NULL}`]: true
        };
      }
      case TrainerCreatedTypes.notTrainerCreated: {
        return {
          [`is_public||${CondOperator.IN}`]: [true],
          [`owner_id||${CondOperator.IS_NULL}`]: true,
          [`owner_id||${CondOperator.NOT_NULL}`]: undefined
        };
      }
      case TrainerCreatedTypes.all: {
        return {
          [`is_public||${CondOperator.IN}`]: [true, false],
          [`owner_id||${CondOperator.IS_NULL}`]: undefined,
          [`owner_id||${CondOperator.NOT_NULL}`]: undefined
        };
      }
      default:
        return {};
    }
  }, [trainerCreatedFilter]);

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;
    const complexityFilter = filters[filterTypes.complexity];
    const modalityFilter = filters[filterTypes.modality];
    const primeMoverFilter = filters[filterTypes.prime_mover_1];
    const typeFilter = filters[filterTypes.type];
    const trainerIdFilter = filters[filterTypes['owner.id']];

    apiFilters[`${filterTypes.complexity}||${CondOperator.IN}`] = complexityFilter && complexityFilter.length ? complexityFilter : undefined;
    apiFilters[`${filterTypes.modality}||${CondOperator.IN}`] = modalityFilter && modalityFilter.length ? modalityFilter : undefined;
    apiFilters[`${filterTypes.prime_mover_1}||${CondOperator.IN}`] = primeMoverFilter && primeMoverFilter.length ? primeMoverFilter : undefined;
    apiFilters[`${filterTypes.type}||${CondOperator.IN}`] = typeFilter && typeFilter.length ? typeFilter : undefined;
    apiFilters[`${filterTypes['owner.id']}||${CondOperator.IN}`] = trainerIdFilter.length ? trainerIdFilter : undefined;

    return { ...apiFilters, ...introVideoFilters, ...trainerCreatedFilters };
  }, [filters, introVideoFilters, trainerCreatedFilters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const onResetFilters = () => onSetFilters(defaultFilters);

  const exercisesColumns = [
    {
      width: 250,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      fixed: 'left' as FixedType,
      isRequired: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    getAccessibleTableColumnConfig({
      width: 120,
      key: 'complexity',
      title: 'Complexity',
      isVisible: activeFilterDropdown === filterTypes.complexity,
      activeFilters: filters[filterTypes.complexity],
      filtersList: exerciseComplexityList,
      filterDropdownType: filterTypes.complexity,
      filterColorsMap: exerciseComplexityColors,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 140,
      key: 'prime_mover_1',
      title: 'Prime Mover',
      isVisible: activeFilterDropdown === filterTypes.prime_mover_1,
      activeFilters: filters[filterTypes.prime_mover_1],
      filtersList: primeMoverList,
      filterDropdownType: filterTypes.prime_mover_1,
      filterColorsMap: primeMoverColors,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 100,
      key: 'type',
      title: 'Type',
      isVisible: activeFilterDropdown === filterTypes.type,
      activeFilters: filters[filterTypes.type],
      filtersList: exerciseTypeList,
      filterDropdownType: filterTypes.type,
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 110,
      key: 'intro_video',
      title: 'Has video',
      isVisible: activeFilterDropdown === filterTypes['intro_video_id'],
      activeFilters: introVideoFilterOption,
      filtersList: hasVideoFiltersList,
      filterDropdownType: filterTypes['intro_video_id'],
      selectionType: FilterSelectionType.radio,
      cellRender: (value: any) => (!!value ? <CheckTagButton /> : <MinusTagButton />),
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'trainer_created',
      title: 'Trainer created',
      isVisible: activeFilterDropdown === filterTypes['trainer_created'],
      activeFilters: trainerCreatedFilterOption,
      filtersList: trainerCreatedFiltersList,
      filterDropdownType: filterTypes['trainer_created'],
      selectionType: FilterSelectionType.radio,
      cellRender: (value: any, record: Record<string, any>) => (!record.is_public && record.owner ? <CheckTagButton /> : <MinusTagButton />),
      onSetFilter,
      onFilterDropdownChange
    }),
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'owner.id',
      title: 'Created by',
      isVisible: activeFilterDropdown === filterTypes['owner.id'],
      activeFilters: filters[filterTypes['owner.id']],
      filtersList: statusesList,
      filterDropdownType: filterTypes['owner.id'],
      filterColorsMap: statusesColorsMap,
      CustomFilterDropdown: ExerciseOwnerFilterDropdown,
      cellRender: (value: any, record: Record<string, any>) =>
        record?.owner && <span>{`${record.owner?.first_name} ${record.owner?.last_name}`}</span>,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'created_date',
      title: 'Created date',
      dataIndex: 'created_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated date',
      dataIndex: 'updated_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    }
  ];

  return (
    <ResourceListContainer
      withCreate
      withResetFilters
      withSearchFilter
      withDynamicColumns
      resource={resource}
      columns={exercisesColumns}
      searchFilterPlaceholder="Search exercises by name..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onResetFilters={onResetFilters}
    >
      <CreateExerciseDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default ExercisesListPage;
