import { Video } from './videos-types';

export enum TranscodingJobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export type TranscodingJob = {
  id: string;
  state: TranscodingJobState;
  name: string;
  create_time: Date;
  start_time: Date;
  end_time: Date;
  error: string | null;
  video_upload_path: string | null;
  video: Video | null;
  config: Record<string, unknown>;
};
