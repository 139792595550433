import { useEffect, useMemo, useState } from 'react';
import { VideoSourceItem, VideoCodec } from '../components/video-player/video-player';
import { BrowserType, getBrowserType } from '../utils/browser-utils';

export type UseVideoSourceSelectionParams = {
  name: string;
  cdn_url: string;
  studio_url: string;
  mobile_url: string;
  web_url: string;
  streaming_video_url: string;
};

type UseVideoSourceSelection = {
  videoSources: VideoSourceItem[];
  selectedSource: VideoSourceItem | null;
  setSelectedSource: (source: VideoSourceItem) => void;
};

const useVideoSourceSelection = (params: UseVideoSourceSelectionParams): UseVideoSourceSelection => {
  const browserType = getBrowserType();
  const isChrome = browserType === BrowserType.chrome;

  const videoSources = useMemo(
    () => [
      {
        value: 'web_url',
        text: 'Web download version',
        url: params?.web_url ?? '',
        codec: VideoCodec.h265,
        isHLS: false,
        isSupported: !!params?.web_url
      },
      {
        value: 'studio_url',
        text: 'Studio download version',
        url: params?.studio_url ?? '',
        codec: VideoCodec.h265,
        isHLS: false,
        isSupported: !isChrome && !!params?.studio_url
      },
      {
        value: 'mobile_url',
        text: 'Mobile streaming version',
        url: params?.mobile_url ?? '',
        codec: VideoCodec.h265,
        isHLS: false,
        isSupported: !isChrome && !!params?.mobile_url
      },
      {
        value: 'streaming_video_url',
        text: 'Studio streaming version',
        url: params?.streaming_video_url ?? '',
        codec: VideoCodec.h264,
        isHLS: true,
        isSupported: false
      }
    ],
    [params, isChrome]
  );

  const [selectedSource, setSelectedSource] = useState<VideoSourceItem | null>(null);

  useEffect(() => {
    setSelectedSource(videoSources[0]);
  }, [videoSources]);

  return {
    videoSources,
    selectedSource,
    setSelectedSource
  };
};

export default useVideoSourceSelection;
