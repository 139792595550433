import React from 'react';
import { Checkbox, Row, Col, Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import cs from 'classnames';

import { NOOP } from '../../constants/common-constants';
import './checkbox-selection-list.scss';
import { getConfirmationModal } from '../../utils/modals-utils';

enum CheckboxListColumnsNumber {
  'one' = 1,
  'two' = 2,
  'three' = 3,
  'four' = 4,
  'five' = 5
}

type OptionItem = { value: string | number; text: string; isHighlighted?: boolean };

type CheckboxListType = {
  withConfirmModal?: boolean;
  confirmTitle?: string;
  confirmDescription?: string;
  value?: CheckboxValueType | CheckboxValueType[];
  className?: string;
  isMultiple?: boolean;
  onChange?: (value: CheckboxValueType | CheckboxValueType[]) => void;
  options: OptionItem[];
  columnsNumber?: CheckboxListColumnsNumber;
};

const CheckboxSelectionList = (props: CheckboxListType) => {
  const {
    confirmTitle,
    confirmDescription,
    withConfirmModal = false,
    className,
    isMultiple,
    options = [],
    value,
    columnsNumber = CheckboxListColumnsNumber.four,
    onChange = NOOP
  } = props;

  const columnSpan = Math.floor(24 / columnsNumber);
  const checkboxValue = Array.isArray(value) ? value : [value];

  const getConfirmDescription = (selectedValues: CheckboxValueType[]) => {
    if (confirmDescription) return confirmDescription;

    const addedValues = selectedValues.filter((x) => !checkboxValue.includes(x));
    const removedValues = checkboxValue.filter((x) => !selectedValues.includes(x!));

    const [addedValue] = addedValues;
    const [removedValue] = removedValues;

    const selectedOption = options.find((item) => item.value === addedValue || item.value === removedValue);

    if (!selectedOption) return 'Are you sure you want to apply selected option?';

    return (
      <div>
        <div>
          You have selected option: <b>{selectedOption?.text}</b>
        </div>
        <div>Are you sure you want to {`${addedValue ? 'apply' : 'remove'}`} it?</div>
      </div>
    );
  };

  const onChangeCheckboxGroup = (selectedValues: CheckboxValueType[]) => onChange(isMultiple ? selectedValues : selectedValues[0]);

  const confirm = (selectedValues: CheckboxValueType[]) =>
    getConfirmationModal({
      width: 450,
      title: confirmTitle,
      description: getConfirmDescription(selectedValues),
      onConfirm: () => onChangeCheckboxGroup(selectedValues)
    });

  return (
    <Checkbox.Group
      className={cs('full-width', className)}
      value={checkboxValue as CheckboxValueType[]}
      onChange={withConfirmModal ? confirm : onChangeCheckboxGroup}
    >
      <Row>
        {options.map((item) => (
          <Col key={item.value} span={columnSpan} className="col-checkbox-selection-item">
            <Checkbox value={item.value}>
              <span className={cs('checkbox-selection-item', { highlighted: item.isHighlighted })}>
                <Tooltip placement="topLeft" title={item.text}>
                  {item.text}
                </Tooltip>
              </span>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export default CheckboxSelectionList;
