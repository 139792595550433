import React, { useEffect } from 'react';

import api from '../../api';
import useDebounce from '../../hooks/use-debounce';
import { NOOP } from '../../constants/common-constants';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { getErrorMessage } from '../../utils/errors';
import { onDisplayErrorNotification } from '../../utils/notifications-utils';
import { Instructor } from '../../types/instructors-types';
import { CondOperator } from '../../types/cond-operators';
import { ContentStatus } from '../../types/statuses-types';

type UseWorkoutTrainerFilter = {
  trainersList: Instructor[];
  selectedTrainers: Instructor[];
  searchInputValue: string;
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectTrainer: (exercise: Instructor) => void;
  onApplySelectedExercises: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type UseWorkoutTrainerFilterParams = {
  isVisible: boolean;
  selectedItemsIds: string[];
  onSelectItems: (element: string[]) => void;
  onClose: () => void;
};

const commonRequestData = {
  pagination: { page: 1, perPage: 50 },
  sort: { field: 'first_name', order: 'ASC' }
};

const useWorkoutTrainerFilter = (params: UseWorkoutTrainerFilterParams): UseWorkoutTrainerFilter => {
  const { isVisible, selectedItemsIds, onSelectItems = NOOP, onClose } = params;

  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [trainersList, setTrainersList] = React.useState<Instructor[]>([]);
  const [selectedTrainers, setSelectedTrainers] = React.useState<Instructor[]>([]);

  const debouncedSearchValue = useDebounce(searchInputValue);

  useEffect(() => {
    if (!isVisible) {
      setTrainersList([]);
      setSelectedTrainers([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible || !selectedItemsIds.length) return;
    const requestFiltersData = {
      [`id||${CondOperator.IN}`]: selectedItemsIds.length ? selectedItemsIds : undefined,
      [`status||${CondOperator.NOT_IN}`]: [ContentStatus.archived]
    };

    api.common
      .getList(Resources.instructors, { ...commonRequestData, filter: requestFiltersData })
      .then(({ data: response }: { data: Instructor[] }) => setSelectedTrainers(response.map((item) => ({ ...item, key: item.id }))))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [isVisible, selectedItemsIds]);

  useEffect(() => {
    if (!isVisible) return;
    const requestFiltersData = { 'searchBy||first_name,last_name': debouncedSearchValue };

    api.common
      .getList(Resources.instructors, { ...commonRequestData, filter: requestFiltersData })
      .then(({ data: response }: { data: Instructor[] }) => setTrainersList(response.map((item) => ({ ...item, key: item.id }))))
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)));
  }, [isVisible, debouncedSearchValue]);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value);

  const onSelectTrainer = (trainer: Instructor) => {
    const isChecked = !!selectedTrainers.find(({ id }) => trainer.id === id);
    if (!isChecked) return setSelectedTrainers([...selectedTrainers, trainer]);
    return setSelectedTrainers(selectedTrainers.filter((i) => i.id !== trainer.id));
  };

  const onApplySelectedExercises = () => {
    onSelectItems(selectedTrainers.map(({ id }) => `${id}`));
    onClose();
  };

  return {
    trainersList,
    selectedTrainers,
    searchInputValue,
    onChangeSearchInput,
    onSelectTrainer,
    onApplySelectedExercises
  };
};

export default useWorkoutTrainerFilter;
