import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Form, Modal } from 'antd';

import api from '../../api';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { CreateParams } from '../../types/request-entities-types';
import { ContentStatus } from '../../types/statuses-types';
import { getErrorMessage } from '../../utils/errors';
import { UserDevicePlatform } from '../../types/common-types';
import { commonFormValidateMessages } from '../../constants/form-constants';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { CopyPromotionTiles } from '../../types/promotion-tile-types';
import { resourcesDictionaryUtils, userDevicePlatformList } from '../../utils/resources-dictionary-utils';
import FormItemSelection, { SelectionOptionItem } from '../../components/form-components/form-item-selection';
import { selectResourceList } from 'src/redux/resource-list/resource-list-selectors';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

type CopyPromotionTileDialogProps = {
  from_platform: UserDevicePlatform;
  to_platform: UserDevicePlatform;
  isOpened: boolean;
  onClose: () => void;
};

const CopyPromotionTileDialog = (props: CopyPromotionTileDialogProps) => {
  const { from_platform, isOpened, to_platform, onClose } = props;
  const userDevicePlatformListFiltered = useMemo(() => userDevicePlatformList.filter((option) => option.value !== from_platform), [from_platform]);
  const promotionList = createStructuredSelector({
    resourceList: selectResourceList
  });
  const promotionListData = useSelector(promotionList).resourceList;
  const modifiedPromotionList = promotionListData.flatMap((m) => {
    const n = { text: '', value: '' };
    n['text'] = m.name;
    n['value'] = m.id;
    return n as SelectionOptionItem;
  });
  const [form] = Form.useForm();

  const initialFormData = useMemo(
    () => ({
      status: ContentStatus.created,
      from_platform,
      to_platform
    }),
    [from_platform, to_platform]
  );

  useEffect(() => {
    if (isOpened) form.setFieldsValue(initialFormData);
  }, [form, isOpened, initialFormData]);
  const getFormValues = useCallback((formValues: Partial<CopyPromotionTiles>) => {
    const { ...restFormValues } = formValues;
    const promotionTileCopyRequest: Partial<CopyPromotionTiles> = { ...restFormValues };
    return { ...promotionTileCopyRequest };
  }, []);
  const onReset = () => {
    form.resetFields();
  };

  const onCloseModal = () => {
    onReset();
    onClose();
  };

  const onFinish = () => {
    const promotionTileCopyRequest = getFormValues(form.getFieldsValue(true));
    const params = {
      data: {
        ids: promotionTileCopyRequest.ids,
        toPlatform: promotionTileCopyRequest.toPlatform,
        status: promotionTileCopyRequest.status
      },
      subUrl: 'copyTo'
    } as CreateParams;
    api.common
      .create(Resources.promotionTiles, params)
      .then((res) => {
        if (res.data.success) {
          onDisplaySuccessNotification('Promotion Tile record(s) were successfully copied');
        } else if (res.data.errorMessage) {
          onDisplayErrorNotification(`Promotion Tiles ${res.data.promotionTile} ${res.data.errorMessage}`);
        }
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={900}
      footer={null}
      visible={isOpened}
      maskClosable={false}
      wrapClassName="modal-wrap"
      title="Copy promotion tile(s)"
      onCancel={onCloseModal}
    >
      <Form form={form} layout="horizontal" validateMessages={commonFormValidateMessages} onFinish={onFinish}>
        <Form.Item name="from_platform" label="From Platform" initialValue={from_platform} rules={[{ required: true }]}>
          <FormItemSelection options={userDevicePlatformList} disabled />
        </Form.Item>
        <Form.Item name="toPlatform" label="To Platform" rules={[{ required: true }]}>
          <FormItemSelection options={userDevicePlatformListFiltered} />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <FormItemSelection disabled options={resourcesDictionaryUtils} />
        </Form.Item>
        <Form.Item name="ids" label="Promotion Tiles" rules={[{ required: true }]}>
          <FormItemSelection isMultiple options={modifiedPromotionList as SelectionOptionItem[]} />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={from_platform === form.getFieldValue(to_platform)}>
            Copy
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CopyPromotionTileDialog;
