import React from 'react';
import { Col, Divider, Dropdown, Row } from 'antd';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import { BASE_BLUE_COLOR } from '../../../constants/style-constants';
import { ExerciseType, MovementPattern, PlaneOfMotion, WorkoutEquipment } from '../../../types/common-types';
import { exerciseTypeList, movementPatternsList, planeOfMotionList, workoutEquipmentList } from '../../../utils/resources-dictionary-utils';
import DropdownSelectionTagFilters from '../../../components/dropdown-section-tag-filters/dropdown-selection-tag-filters';
import DropdownSelectionRadioFilters from '../../../components/dropdown-section-radio-filters/dropdown-selection-radio-filters';

type WorkoutMovementsSelectionFiltersProps = {
  initialTypeFilter?: ExerciseType;
  isTypeFilterAvailable: boolean;
  typeFilter?: ExerciseType;
  patternFilters: MovementPattern[];
  motionFilters: PlaneOfMotion[];
  equipmentFilters: WorkoutEquipment[];
  typeFilterTooltip?: string;
  onChangeTypeFilter: (e: RadioChangeEvent) => void;
  onChangePatternFilter: (patternFilter: MovementPattern, checked: boolean) => void;
  onChangeMotionFilter: (filter: PlaneOfMotion, checked: boolean) => void;
  onChangeEquipmentFilter: (filter: WorkoutEquipment, checked: boolean) => void;
  onClearAllFilters: () => void;
};

const WorkoutMovementsSelectionFilters = (props: WorkoutMovementsSelectionFiltersProps) => {
  const { initialTypeFilter, typeFilter, patternFilters, motionFilters, equipmentFilters, isTypeFilterAvailable, typeFilterTooltip } = props;

  const [visible, setVisible] = React.useState(false);

  const isSomeFiltersApplied = initialTypeFilter !== typeFilter || patternFilters.length || motionFilters.length || equipmentFilters.length;
  const filtersOptionsList = [...exerciseTypeList, { text: 'All', value: undefined }];

  const onChangeTypeFilter = (e: RadioChangeEvent) => {
    setVisible(true);
    props.onChangeTypeFilter(e);
  };

  const onChangePatternFilter = (patternFilter: MovementPattern, checked: boolean) => {
    setVisible(true);
    props.onChangePatternFilter(patternFilter, checked);
  };

  const onChangeMotionFilter = (filter: PlaneOfMotion, checked: boolean) => {
    setVisible(true);
    props.onChangeMotionFilter(filter, checked);
  };

  const onChangeEquipmentFilter = (filter: WorkoutEquipment, checked: boolean) => {
    setVisible(true);
    props.onChangeEquipmentFilter(filter, checked);
  };

  const onClearAllFilters = () => {
    setVisible(true);
    props.onClearAllFilters();
  };

  return (
    <Dropdown
      arrow
      trigger={['click']}
      placement="bottomRight"
      visible={visible}
      onVisibleChange={setVisible}
      overlay={
        <div className="card-content-wrap movements-selection-filters">
          <Row align="middle" justify="space-between" className="movements-selection-filters-title">
            <Col flex={16}>Exercise filters</Col>
            <Col flex={8} className="clear-button" onClick={onClearAllFilters}>
              Clear All
            </Col>
          </Row>
          <Divider />
          <DropdownSelectionRadioFilters
            title="SORT BY"
            tooltip={typeFilterTooltip}
            disabled={!isTypeFilterAvailable}
            filter={typeFilter as ExerciseType}
            options={filtersOptionsList}
            onChangeFilter={onChangeTypeFilter}
          />
          <Divider />
          <DropdownSelectionTagFilters
            title="PATTERN"
            filters={patternFilters}
            options={movementPatternsList}
            onChangeFilter={onChangePatternFilter}
          />
          <Divider />
          <DropdownSelectionTagFilters title="MOTION" filters={motionFilters} options={planeOfMotionList} onChangeFilter={onChangeMotionFilter} />
          <Divider />
          <DropdownSelectionTagFilters
            title="EQUIPMENT"
            filters={equipmentFilters}
            options={workoutEquipmentList}
            onChangeFilter={onChangeEquipmentFilter}
          />
        </div>
      }
    >
      {isSomeFiltersApplied ? (
        <FilterFilled style={{ fontSize: 16, color: BASE_BLUE_COLOR }} onClick={(e) => e.preventDefault()} />
      ) : (
        <FilterOutlined style={{ fontSize: 16, color: BASE_BLUE_COLOR }} onClick={(e) => e.preventDefault()} />
      )}
    </Dropdown>
  );
};

export default WorkoutMovementsSelectionFilters;
