import React, { useEffect, useMemo, useState } from 'react';

import api from '../../api';
import useDebounce from '../../hooks/use-debounce';
import { Workout } from '../../types/workout-types';
import { uniqueBy } from '../../utils/common-utils';
import { useAction } from '../../hooks/use-actions';
import { Resources } from '../../types/resources-types';
import { RequestError } from '../../api/http-client';
import { SessionType } from '../../types/common-types';
import { getErrorMessage } from '../../utils/errors';
import { EntitiesSubUrls } from '../../api/resources-entities-api';
import { TransferDirection } from 'antd/es/transfer';
import { setResourceRecord } from '../../redux/resource-record/resource-record-reducer';
import { Program, ProgramElement } from '../../types/programs-types';
import { onDisplayErrorNotification, onDisplaySuccessNotification } from '../../utils/notifications-utils';
import { CondOperator } from 'src/types/cond-operators';

const noLiftTypeFilter = { [`session_type||${CondOperator.NOT_IN}`]: [SessionType.LiftVOD] };

type TransferSourceItem = Workout & { key: string; elementId?: string; disabled?: boolean; comment?: string };

type UseTransferWorkoutsToProgram = {
  searchInputValue: string;
  targetKeys: string[];
  totalSource: number;
  selectedKeys: string[];
  transferSource: TransferSourceItem[];
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTransfer: (extTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => void;
  onSelectChange: (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => void;
  onCloseModalCallBack: () => void;
};

const useTransferWorkoutsToProgram = (program: Program): UseTransferWorkoutsToProgram => {
  const { id: recordId, elements = [], is_collection } = program;
  const onSetProgramRecord = useAction(setResourceRecord);

  const programElements = useMemo(
    () =>
      elements.map((item: ProgramElement) => ({
        elementId: item.id,
        ...item.workout,
        key: item.workout.id,
        name: `${item.workout.name} ${item.workout.production_id ? `(${item.workout.production_id})` : ''}`
      })),
    [elements]
  );
  const initialTargetKeys = useMemo(() => programElements.map((item) => item.key), [programElements]);

  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [workouts, setWorkouts] = React.useState<(Workout & { key: string; title: string })[]>([]);
  const [totalSource, setTotalSource] = React.useState<number>(0);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const debouncedSearchValue = useDebounce(searchInputValue.trim());
  const transferSource = useMemo(() => uniqueBy([...workouts, ...programElements], 'key') as TransferSourceItem[], [workouts, programElements]);

  useEffect(() => {
    const transferSourceKeys = new Set(transferSource.map((_) => _.id));
    setSelectedKeys((keys) => keys.filter((key) => transferSourceKeys.has(key)));
  }, [transferSource]);

  useEffect(() => {
    setTargetKeys(initialTargetKeys);
  }, [initialTargetKeys]);

  useEffect(() => {
    api.common
      .getList(Resources.workouts, {
        pagination: { page: 1, perPage: 100 },
        filter: {
          'searchBy||name,production_id': debouncedSearchValue,
          ...(is_collection ? {} : { [`is_in_program||${CondOperator.EQUALS}`]: true }),
          //TODO: modify below filter when forme_equipment column gets dropped and Type includes Lift and Tpi Planned sessions
          ...(program.is_lift ? {} : noLiftTypeFilter)
        },
        sort: { field: 'name', order: 'ASC' }
      })
      .then(({ data: response, total }: any) => {
        setTotalSource(total);
        setWorkouts(
          response.map((item: Workout) => {
            return {
              ...item,
              name: `${item.name} ${item.production_id ? `(${item.production_id})` : ''}`,
              key: item.id
            };
          })
        );
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error);
        onDisplayErrorNotification(errorMessage);
      });
  }, [debouncedSearchValue, program.is_lift, is_collection]);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value);

  const onAddElements = async (newElements: string[]) =>
    api.resourcesEntities.createResourceEntity({
      resource: Resources.programs,
      recordId: program.id,
      entitySubUrl: EntitiesSubUrls.elementsBatch,
      data: { elements: newElements.map((newElementId) => ({ workout_id: newElementId })) }
    });

  const onAddElementsToProgram = async (newElementsIds: string[]) => {
    try {
      setIsRequestLoading(true);
      const newProgramRecord = await onAddElements(newElementsIds);
      setTargetKeys((prevTargetKeys) => [...prevTargetKeys, ...newElementsIds]);
      onSetProgramRecord(newProgramRecord);
      onDisplaySuccessNotification('All elements was successfully uploaded');
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error as RequestError);
      onDisplayErrorNotification(errorMessage);
    } finally {
      setIsRequestLoading(false);
    }
  };

  const onRemoveElement = async (elementKey: string) => {
    const element = programElements.find((item) => item?.key === elementKey);
    if (!element || !program) return;

    try {
      setIsRequestLoading(true);
      await api.resourcesEntities.removeResourceEntity({
        recordId,
        resource: Resources.programs,
        entitySubUrl: `${EntitiesSubUrls.elements}/${element.elementId}`
      });

      setTargetKeys(targetKeys.filter((item) => item !== elementKey));
      onSetProgramRecord({ ...program, elements: elements.filter((item) => item.id !== element.elementId) });
      onDisplaySuccessNotification('Element was successfully removed from program');
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error as RequestError);
      onDisplayErrorNotification(errorMessage);
    } finally {
      setIsRequestLoading(false);
    }
  };

  const onChangeTransfer = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    if (isRequestLoading) return;
    if (direction === 'left') return onRemoveElement(moveKeys[0]);
    return onAddElementsToProgram(moveKeys);
  };

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onCloseModalCallBack = () => {
    setSearchInputValue('');
  };

  return {
    targetKeys,
    selectedKeys,
    transferSource,
    totalSource,
    searchInputValue,
    onSelectChange,
    onChangeTransfer,
    onChangeSearchInput,
    onCloseModalCallBack
  };
};

export default useTransferWorkoutsToProgram;
