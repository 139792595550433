import React, { useState } from 'react';
import { Space } from 'antd';
import { FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { FixedType } from 'rc-table/lib/interface';
import AccessibleTableLinkedCell from 'src/components/accessible-table/accessible-table-linked-cell';
import ResourceListContainer from 'src/containers/resource-list-container';
import useResourceListInitialRequestParams from 'src/hooks/use-resource-list-initial-request-params';
import useResourceListTableSorting from 'src/hooks/use-resource-list-table-sorting';
import { COLUMN_DESCEND, Order } from 'src/types/common-types';
import { Resources } from 'src/types/resources-types';
import useResourceListUrlParams from 'src/hooks/use-resource-list-url-params';
import AccessibleTableDateCell from 'src/components/accessible-table/accessible-table-date-cell';
import { SurveyCreateDialog } from './survey-create-dialog';
import useResourceListTableFilters from 'src/hooks/use-resource-list-table-filters';
import { getAccessibleTableColumnConfig } from 'src/components/accessible-table/get-accessible-table-column-config';
import SurveyListAssetsFilters, { HasSurveyImageTypes, HasSurveyVideoTypes } from './survey-list-assets-filter';
import useResourceListAdditionalFilters from 'src/hooks/use-resource-list-additional-filters';
import useAssetsFilters from './hooks/use-assets-filters';

const resource = Resources.surveys;

const initialSortData = { sortField: 'created_date', sortOrder: Order.desc };

const searchFilterFields = ['title', 'name'];

const filterTypes = {
  assets: 'assets',
  ['video.id']: 'video.id',
  ['image.id']: 'image.id'
};

const defaultFilters = {
  ...Object.keys(filterTypes).reduce((acc, key) => ({ ...acc, [key]: [] }), {}),
  [filterTypes['video.id']]: [HasSurveyVideoTypes.all],
  [filterTypes['image.id']]: [HasSurveyImageTypes.all]
};

const filtersAssetsList = [
  { value: filterTypes['video.id'], text: 'Video', textIcon: <VideoCameraOutlined /> },
  { value: filterTypes['image.id'], text: 'Image', textIcon: <FileImageOutlined /> }
];

const SurveyListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();
  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, activeFilterDropdown, filtersOperators, onSetFilters, onFilterDropdownChange } = useResourceListTableFilters({
    filterTypes: filterTypes
  });
  const { filterOptions, assetsFilters, onSetAssetFilter } = useAssetsFilters({ filters, filtersOperators, filterTypes, onSetFilters });

  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  useResourceListAdditionalFilters({ resource, additionalFilters: assetsFilters });

  const onResetFilters = () => onSetFilters(defaultFilters);

  const columns = [
    {
      width: 250,
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      fixed: 'left' as FixedType,
      isRequired: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('title'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    {
      width: 200,
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      fixed: 'left' as FixedType,
      isRequired: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('name'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    getAccessibleTableColumnConfig({
      width: 90,
      key: 'assets',
      title: 'Assets',
      isVisible: activeFilterDropdown === filterTypes.assets,
      activeFilters: filterOptions,
      filtersList: filtersAssetsList,
      filterDropdownType: filterTypes.assets,
      CustomFilterDropdown: SurveyListAssetsFilters,
      cellRender: (value: any, record: Record<string, any>) => {
        const withVideoAsset = record?.video?.id && record?.video?.id > 0;
        const withImageAsset = record?.image?.id && record?.image?.id > 0;
        return (
          <Space>
            {withVideoAsset && <VideoCameraOutlined />}
            {withImageAsset && <FileImageOutlined />}
          </Space>
        );
      },
      onSetFilter: onSetAssetFilter,
      onFilterDropdownChange
    }),
    {
      width: 100,
      key: 'steps_count',
      title: 'Steps Count',
      dataIndex: 'steps',
      render: (steps: any) => <span>{steps?.length ?? '-'}</span>
    },
    {
      width: 100,
      key: 'version',
      title: 'Version',
      dataIndex: 'version',
      render: (version: any) => <span>{(version === -1 ? 'Draft' : version?.toString()) ?? '-'}</span>
    },
    {
      width: 170,
      sorter: true,
      key: 'created_date',
      title: 'Created date',
      dataIndex: 'created_date',
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      onHeaderCell,
      render: (date: string) => <AccessibleTableDateCell date={date} />
    },
    {
      width: 170,
      sorter: true,
      key: 'updated_date',
      title: 'Updated date',
      dataIndex: 'updated_date',
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      onHeaderCell,
      render: (date: string) => <AccessibleTableDateCell date={date} />
    }
  ];

  return (
    <ResourceListContainer
      withCreate
      withResetFilters
      withSearchFilter
      withDynamicColumns
      resource={resource}
      columns={columns}
      searchFilterPlaceholder="Search surveys by name, title or id..."
      onCreateClick={() => setIsCreateDialogOpened(true)}
      onResetFilters={onResetFilters}
    >
      <SurveyCreateDialog isOpened={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
    </ResourceListContainer>
  );
};

export default SurveyListPage;
