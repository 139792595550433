import { useLocation } from 'react-router-dom';

type UseLocationHandler = {
  mainSubRoute?: string;
  secondSubRoute?: string;
  thirdSubRoute?: string;
};

/**
 *
 */
const useLocationHandler = (): UseLocationHandler => {
  const location = useLocation();
  const [, mainSubRoute, secondSubRoute, thirdSubRoute] = location.pathname.split('/');
  return { mainSubRoute, secondSubRoute, thirdSubRoute };
};

export default useLocationHandler;
