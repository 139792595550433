import React from 'react';
import { EyeOutlined } from '@ant-design/icons';

import { VideoAssetElement } from '../../types/studio-assets';
import BasicModal from '../basic-modal/basic-modal';
import VideoPlayerWithTroubleShooting from '../video-player/video-player-with-trouble-shooting';
import './accessible-table.scss';

type StudioAssetsVideoPreviewCellProps = {
  video: VideoAssetElement;
};

const AccessibleTableVideoPreviewCell = ({ video }: StudioAssetsVideoPreviewCellProps) => {
  return (
    <span className="accessible-table-preview-cell">
      {video?.name}
      <BasicModal title={video?.name} withActions={false} buttonIcon={<EyeOutlined className="watch-icon" />} buttonType="text">
        <div className="accessible-table-preview-cell-modal">
          <VideoPlayerWithTroubleShooting data={video} />
        </div>
      </BasicModal>
    </span>
  );
};

export default AccessibleTableVideoPreviewCell;
