import VideoPlayer from './video-player';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Space } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import { selectVideoPlayer } from '../../redux/settings/settings-selectors';
import { videoPlayersConfigMap } from '../../constants/video-players';
import useVideoSourceSelection, { UseVideoSourceSelectionParams } from '../../hooks/use-video-source-selection';
import FormItemSelection from '../form-components/form-item-selection';
import SelectVideoPlayerModal from '../../containers/base-layout/select-video-palayer-modal';

type VideoPlayerWithTroubleShootingProps = {
  data: UseVideoSourceSelectionParams;
  withTroubleShooting?: boolean;
  troubleShootingRowJustify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
};

const VideoPlayerWithTroubleShooting = ({
  data,
  withTroubleShooting = true,
  troubleShootingRowJustify = 'space-between'
}: VideoPlayerWithTroubleShootingProps) => {
  const userVideoCodec = useSelector(selectVideoPlayer);
  const videoPlayerConfig = videoPlayersConfigMap[userVideoCodec];

  const { videoSources, selectedSource, setSelectedSource } = useVideoSourceSelection(data);

  const {
    videoSources: videoPlayerSources,
    selectedSource: selectedVideoPlayerSource,
    setSelectedSource: setSelectedVideoPlayerSource
  } = useVideoSourceSelection(data);

  const onSelectVideoPlayerSource = (videoPlayerSourceValue: string | null) => {
    const nextSelectedSource = videoPlayerSources.find((item) => item.value === videoPlayerSourceValue);
    if (!nextSelectedSource) return;
    setSelectedVideoPlayerSource(nextSelectedSource);
  };

  return (
    <Space direction="vertical" size="middle" style={{ maxWidth: '100%' }}>
      <VideoPlayer sources={videoSources} source={selectedSource} onChangeSelectedSource={setSelectedSource}>
        {withTroubleShooting ? (
          <Row align="middle" justify={troubleShootingRowJustify} gutter={16} className="video-trouble-shooting">
            <Col>
              <FormItemSelection
                showSearch={false}
                className="trouble-shooting-selection"
                dropdownClassName="trouble-shooting-dropdown"
                placeholder="select source"
                options={videoPlayerSources}
                value={selectedVideoPlayerSource?.value}
                onChange={onSelectVideoPlayerSource}
              />
            </Col>
            <Col>
              <Space>
                <Button
                  type="default"
                  disabled={!selectedVideoPlayerSource?.url}
                  href={`${videoPlayerConfig.scheme}${encodeURIComponent(selectedVideoPlayerSource?.url || '')}`}
                >
                  Run in {videoPlayerConfig.btnLabel}
                </Button>
                <SelectVideoPlayerModal withButtonTitle={false} buttonIcon={<SettingOutlined />} buttonType="default" />
              </Space>
            </Col>
          </Row>
        ) : null}
      </VideoPlayer>
    </Space>
  );
};

export default VideoPlayerWithTroubleShooting;
