import React from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import SelectTimeZoneModal from './select-time-zone-modal';
import SelectVideoPlayerModal from './select-video-palayer-modal';

const SettingsMenu = () => {
  const menuItems = [
    { key: 1, label: <SelectTimeZoneModal /> },
    { key: 2, label: <SelectVideoPlayerModal /> }
  ];

  return (
    <Dropdown overlay={<Menu items={menuItems} />}>
      <Button>
        <SettingOutlined />
      </Button>
    </Dropdown>
  );
};

export default SettingsMenu;
