import { useCallback } from 'react';

import { SortOrder } from 'antd/es/table/interface';
import { Resources } from '../types/resources-types';
import { ColumnItem } from '../components/accessible-table/accessible-table-types';
import { COLUMN_ASCEND, COLUMN_DESCEND, Order } from '../types/common-types';
import useResourceListUrlActions from './use-resource-list-url-actions';
import useResourceListUrlParams from './use-resource-list-url-params';

type UseResourceListTableSortingParams = {
  resource: Resources;
  sortField: string;
  sortOrder: Order;
};

type UseResourceListTableSorting = {
  sortField: string;
  sortOrder: Order;
  getColumnSortOrder: (columnKey: string) => SortOrder | null;
  onHeaderCell: (column: any) => { onClick: () => void };
};

const useResourceListTableSorting = (params: UseResourceListTableSortingParams): UseResourceListTableSorting => {
  const { resource, sortField: initialSortField, sortOrder: initialSortOrder } = params;

  const { sortField = initialSortField, sortOrder = initialSortOrder } = useResourceListUrlParams();
  const { onChangeSort } = useResourceListUrlActions(resource);

  const getColumnSortOrder = (columnKey: string): SortOrder => {
    if (sortField !== columnKey) return null;
    if (sortOrder === Order.asc) return COLUMN_ASCEND;
    if (sortOrder === Order.desc) return COLUMN_DESCEND;
    return null;
  };

  const onSort = useCallback(
    (column: ColumnItem) => {
      const { key: columnSortField, sortOrder: columnSortOrder } = column;

      if (!columnSortField) return;
      if (!columnSortOrder) onChangeSort({ sortField: columnSortField, sortOrder: initialSortOrder });
      if (columnSortOrder === COLUMN_ASCEND) onChangeSort({ sortField: columnSortField, sortOrder: Order.desc });
      if (columnSortOrder === COLUMN_DESCEND) onChangeSort({ sortField: columnSortField, sortOrder: Order.asc });
    },
    [initialSortOrder, onChangeSort]
  );

  const onHeaderCell = useCallback((column: any) => ({ onClick: () => onSort(column) }), [onSort]);

  return {
    sortField,
    sortOrder,
    getColumnSortOrder,
    onHeaderCell
  };
};

export default useResourceListTableSorting;
