import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row, Typography } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import momentTimezone from 'moment-timezone';

import { useAction } from '../../hooks/use-actions';
import { setUserTimeZone } from '../../redux/settings/settings-reducer';
import { selectUserTimeZone } from '../../redux/settings/settings-selectors';
import BasicModal from '../../components/basic-modal/basic-modal';
import TimeZonePicker from '../../components/time-zone-picker';

const SelectTimeZoneModal = () => {
  const currentTimezone = momentTimezone.tz.guess();
  const userTimeZone = useSelector(selectUserTimeZone);
  const onSetUserTimeZone = useAction(setUserTimeZone);

  const [timeZone, setTimeZone] = useState(userTimeZone);
  const onSetTimeZone = () => onSetUserTimeZone(timeZone);

  return (
    <BasicModal
      key="model"
      title="Select time zone"
      buttonTitle="Select time zone"
      tooltipLabel=""
      okText="Save"
      buttonType="text"
      modalWidth={700}
      buttonIcon={<FieldTimeOutlined />}
      onSuccess={onSetTimeZone}
    >
      <div>
        <Typography.Title level={5}>{`Settings time zone: ${userTimeZone}`}</Typography.Title>
        {userTimeZone !== currentTimezone && <Typography.Title level={5}>{`Your Timezone: ${currentTimezone}`}</Typography.Title>}
        <Divider />
        <Row align="middle">
          <Col span={6} className="row-title">
            Timezone List:
          </Col>
          <Col span={18}>
            <TimeZonePicker value={timeZone} onChange={setTimeZone} />
          </Col>
        </Row>
      </div>
    </BasicModal>
  );
};

export default SelectTimeZoneModal;
