import React from 'react';
import { Button, Typography } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import { Video } from '../../types/videos-types';
import VideoPlayerWithTroubleShooting from '../video-player/video-player-with-trouble-shooting';

type SelectVideoItemProps = {
  video: Video;
  onSelect: (video: Video) => void;
};

const SelectVideoItem = (props: SelectVideoItemProps) => {
  const { video, onSelect } = props;

  return (
    <div className="select-video-item">
      <Typography.Title className="select-video-item-title" level={5}>
        {video.name}
      </Typography.Title>
      <VideoPlayerWithTroubleShooting data={video} troubleShootingRowJustify="space-between" />
      <div className="select-video-item-footer">
        <Button type="primary" icon={<SelectOutlined />} size="large" className="full-width" onClick={() => onSelect(video)}>
          Select
        </Button>
      </div>
    </div>
  );
};

export default SelectVideoItem;
