import React, { memo, useEffect, useMemo } from 'react';
import { Button, Col, Divider, Drawer, Form, InputNumber, Row, Space } from 'antd';

import { Workout } from '../../../types/workout-types';
import { Exercise } from '../../../types/exercises-types';
import { FormValues } from '../../../hooks/use-form-values';
import { OptionListItem } from '../../../redux/settings/settings-utils';
import { BlockDrawerParams, BlockDrawerType, CreateMovementsFormValues } from '../hooks/use-workout-movements';
import { CompletionType, ExerciseType, LimbUsedEnum, MovementHugs } from '../../../types/common-types';
import {
  isCpsWorkoutType,
  liftVodCompletionTypeList,
  movementHugsList,
  movementSetsList,
  movementsRepsList
} from '../../../utils/resources-dictionary-utils';
import FormItemSelection from '../../../components/form-components/form-item-selection';
import MovementArmUsedField from './movement-arm-used-field';
import CreateMovementsFormExerciseSelection from './create-movements-form-exercise-selection';
import WorkoutMovementDurationInput from '../workout-movement-duration-input';
import LimbUsedFormItemSelection from './limb-used-form-item-selection';
import '../workout-movemnts.scss';

type CreateMovementsDrawerProps = {
  record: Workout;
  params: BlockDrawerParams;
  liftAttachmentsList: OptionListItem[];
  blocksOptionsList: { value: string | number; text: string }[];
  onClose: () => void;
  onCreateMovement: (formData: CreateMovementsFormValues) => void;
};

const initialCompletionType = CompletionType.time;

const CreateMovementDrawer = (props: CreateMovementsDrawerProps) => {
  const { record, liftAttachmentsList, blocksOptionsList, params, onClose, onCreateMovement } = props;
  const { type: drawerType, movement, order, blockId } = params;

  const isVisible = drawerType === BlockDrawerType.createMovement;
  const isPlannedSessionType = isCpsWorkoutType(record.session_type);

  if (!isVisible) return null;

  const [form] = Form.useForm();

  const defaultFormValues = {
    order,
    set: 1,
    reps: 0,
    duration: 0,
    attachments: null,
    hug: MovementHugs.a,
    block_id: blockId,
    limb_used: LimbUsedEnum.none,
    resistance: 0,
    completion_type: initialCompletionType
  };

  const cloneMovementFormValues = { ...(movement || {}), order: null, movements: movement?.exercise ? [movement.exercise.id] : [] };
  const initialFormValues = movement ? cloneMovementFormValues : defaultFormValues;

  const [completionType, setCompletionType] = React.useState<CompletionType>(initialCompletionType);
  const [exercises, setExercises] = React.useState<Exercise[]>(movement?.exercise ? [movement?.exercise] : []);

  const [selectedExercise] = exercises;

  const isLiftVod = selectedExercise?.type === ExerciseType.LIFT;
  const isCompletionTypeTime = completionType === CompletionType.time;
  const isCompletionTypeReps = completionType === CompletionType.reps;

  const exerciseAttachments = useMemo(
    () => [selectedExercise?.attachment, ...(selectedExercise?.alternative_attachments ?? [])].filter((item) => item),
    [selectedExercise]
  );
  const attachmentsList = useMemo(
    () => liftAttachmentsList.filter((item) => exerciseAttachments.includes(item.value)),
    [liftAttachmentsList, exerciseAttachments]
  );

  useEffect(() => {
    if (attachmentsList.length === 1) {
      form.setFieldsValue({
        attachment: attachmentsList[0].value
      });
    }
  }, [attachmentsList, form]);

  const onFinishCreateMovementForm = () => {
    const formValues = form.getFieldsValue(true);
    const createMovementData = { ...formValues, isLiftVod };
    if (!isLiftVod) createMovementData.arm_used = LimbUsedEnum.none;
    onCreateMovement(createMovementData);
  };

  const onFormValuesChange = (changedValues: FormValues) => {
    if (changedValues.completion_type) setCompletionType(changedValues.completion_type);
  };

  return (
    <Drawer maskClosable={false} destroyOnClose title="Movement" width={800} onClose={onClose} visible={isVisible} bodyStyle={{ paddingBottom: 80 }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialFormValues}
        className="create-movement-block-form"
        onValuesChange={onFormValuesChange}
        onFinish={onFinishCreateMovementForm}
      >
        <Row>
          <Form.Item name="block_id" className="full-width" label="Select block" rules={[{ required: true, message: 'Please select movement' }]}>
            <FormItemSelection options={blocksOptionsList} />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item name="movements" className="full-width" label="Select exercise" rules={[{ required: true, message: 'Please select exercise' }]}>
            <CreateMovementsFormExerciseSelection initialSelectedExercises={exercises} onSelectExercises={setExercises} />
          </Form.Item>
        </Row>
        {isPlannedSessionType && (
          <Row>
            <Form.Item className="full-width" name="completion_type" label="Completion type">
              <FormItemSelection options={liftVodCompletionTypeList} />
            </Form.Item>
          </Row>
        )}
        <Row align="middle" gutter={16}>
          {(!isPlannedSessionType || isCompletionTypeTime) && (
            <Col span={8}>
              <Form.Item name="duration" label="Duration">
                <WorkoutMovementDurationInput withSaveButton={false} />
              </Form.Item>
            </Col>
          )}
          {(isLiftVod || (isPlannedSessionType && isCompletionTypeReps)) && (
            <Col span={8}>
              <Form.Item name="reps" label="Reps">
                <FormItemSelection className="full-width" options={movementsRepsList} />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item name="set" label="Set">
              <FormItemSelection options={movementSetsList} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="resistance" label="Resistance">
              <InputNumber min={0} max={50} />
            </Form.Item>
          </Col>
          {isLiftVod && (
            <>
              <Col span={8}>
                <Form.Item name="hug" label="Hug">
                  <FormItemSelection options={movementHugsList} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="attachment" label="Attachments">
                  <FormItemSelection options={attachmentsList} disabled={!attachmentsList.length} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="limb_used" label="Limb">
                  <LimbUsedFormItemSelection exercise={selectedExercise} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="arm_used" label="Cables">
                  <MovementArmUsedField isLiftVod={isLiftVod} numberArmsUsed={selectedExercise?.number_arms_used} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Divider orientation="center" plain />
        <Row justify="end">
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Drawer>
  );
};

export default memo(CreateMovementDrawer);
