export enum BrowserType {
  chrome = 'chrome',
  firefox = 'firefox',
  safari = 'safari',
  opera = 'opera',
  edge = 'edge'
}

export const getBrowserType = (): BrowserType | null => {
  const userAgent = navigator.userAgent;

  const isChrome = !!userAgent.match(/chrome|chromium|crios/i);
  const isFirefox = !!userAgent.match(/firefox|fxios/i);
  const isSafari = !!userAgent.match(/safari/i);
  const isOpera = !!userAgent.match(/opr\//i);
  const isEdge = !!userAgent.match(/edg/i);

  if (isChrome) return BrowserType.chrome;
  if (isFirefox) return BrowserType.firefox;
  if (isSafari) return BrowserType.safari;
  if (isOpera) return BrowserType.opera;
  if (isEdge) return BrowserType.edge;

  return null;
};
