import React, { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

type FileDropZoneProps = {
  children: ReactNode;
  onDrop: (files: File[]) => void;
};

export function FileDropZone({ children, onDrop }: FileDropZoneProps) {
  const [, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        onDrop(item.files);
      }
    }),
    []
  );

  return <div ref={drop}>{children}</div>;
}
