import { TransferMovementsSelectionMode } from '../hooks/use-workout-movements-selection';
import WorkoutMovementsSelection from './workout-movements-selection';
import React, { useEffect } from 'react';
import { Exercise } from '../../../types/exercises-types';
import { NOOP } from '../../../constants/common-constants';

type CreateMovementsFormExerciseSelectionProps = {
  value?: string[];
  selectionMode?: TransferMovementsSelectionMode;
  initialSelectedExercises?: Exercise[];
  onSelectExercises?: (exercise: Exercise[]) => void;
  onChange?: (value: string[]) => void;
};

const CreateMovementsFormExerciseSelection = (props: CreateMovementsFormExerciseSelectionProps) => {
  const {
    value = [],
    initialSelectedExercises = [],
    selectionMode = TransferMovementsSelectionMode.single,
    onChange = NOOP,
    onSelectExercises = NOOP
  } = props;
  const [exercises, setExercises] = React.useState<Exercise[]>(initialSelectedExercises);

  useEffect(() => {
    if (!value.length && exercises.length) {
      setExercises([]);
    }
  }, [value, exercises.length]);

  const onSelectItems = (selectedExercises: Exercise[]) => {
    setExercises(selectedExercises);
    onSelectExercises(selectedExercises);
    onChange(selectedExercises.map(({ id }) => id));
  };

  return (
    <WorkoutMovementsSelection placeholder="Select exercise" selectionMode={selectionMode} selectedItems={exercises} onSelectItems={onSelectItems} />
  );
};

export default CreateMovementsFormExerciseSelection;
