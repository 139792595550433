import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

type ConfirmationModalProps = {
  icon?: React.ReactNode;
  okText?: string;
  cancelText?: string;
  title?: string;
  description?: string | ReactNode;
  width?: number;
  isDanger?: boolean;
  onConfirm: () => void;
};

export const getConfirmationModal = ({
  icon = <ExclamationCircleOutlined />,
  title = 'Confirm',
  description,
  okText = 'Confirm',
  cancelText = 'Cancel',
  width,
  isDanger,
  onConfirm
}: ConfirmationModalProps): {
  destroy: (...args: any[]) => void;
  update: (configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps)) => void;
} =>
  Modal.confirm({
    title,
    width,
    icon,
    content: description,
    okText: okText,
    cancelText: cancelText,
    okButtonProps: { danger: isDanger },
    onOk: onConfirm
  });
