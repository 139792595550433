import React, { useMemo, useState } from 'react';
import { FixedType } from 'rc-table/lib/interface';

import { AssetType } from '../../types/studio-assets';
import { Resources } from '../../types/resources-types';
import { CondOperator } from '../../types/cond-operators';
import { assetTypeList } from '../../utils/resources-dictionary-utils';
import { COLUMN_DESCEND, Order } from '../../types/common-types';
import { getAccessibleTableColumnConfig } from '../../components/accessible-table/get-accessible-table-column-config';

import useResourceListTableSorting from '../../hooks/use-resource-list-table-sorting';
import useResourceListTableFilters from '../../hooks/use-resource-list-table-filters';
import useResourceListInitialRequestParams from '../../hooks/use-resource-list-initial-request-params';
import useResourceListAdditionalFilters from '../../hooks/use-resource-list-additional-filters';
import useResourceListUrlParams from '../../hooks/use-resource-list-url-params';

import ResourceListContainer from '../../containers/resource-list-container';
import AccessibleTableDateCell from '../../components/accessible-table/accessible-table-date-cell';
import AccessibleTableLinkedCell from '../../components/accessible-table/accessible-table-linked-cell';
import AccessibleTableVideoPreviewCell from '../../components/accessible-table/accessible-table-video-preview-cell';
import AccessibleTableImagePreviewCell from '../../components/accessible-table/accessible-table-image-preview-cell';
import { StudioAssetsNewRecordModal } from './studio-assets-new-record-modal';

const resource = Resources.studioAssets;
const filterTypes = { type: 'type' };
const searchFilterFields = ['id'];
const initialSortData = { sortField: 'id', sortOrder: Order.asc };

const StudioAssetsListPage = () => {
  const { searchInputValue } = useResourceListUrlParams();

  const [open, setOpen] = useState(false);

  const { getColumnSortOrder, onHeaderCell } = useResourceListTableSorting({ ...initialSortData, resource });
  const { filters, activeFilterDropdown, onSetFilter, onResetFilters, onFilterDropdownChange } = useResourceListTableFilters({
    filterTypes: filterTypes
  });
  useResourceListInitialRequestParams({ ...initialSortData, resource, searchFilterFields });

  const additionalFilters = useMemo(() => {
    const apiFilters = {} as Record<string, any>;
    const typeFilter = filters[filterTypes.type];
    apiFilters[`${filterTypes.type}||${CondOperator.IN}`] = typeFilter.length ? typeFilter : undefined;
    return apiFilters;
  }, [filters]);

  useResourceListAdditionalFilters({ resource, additionalFilters });

  const contentPlansListColumns = [
    {
      width: 200,
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
      fixed: 'left' as FixedType,
      isRequired: true,
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('id'),
      render: (value: string, record: Record<string, any>) => (
        <AccessibleTableLinkedCell value={value} highlight={searchInputValue} link={`/${resource}/${record?.id}`} />
      ),
      onHeaderCell
    },
    {
      width: 300,
      key: 'name',
      title: 'Asset name',
      dataIndex: 'name',
      fixed: 'left' as FixedType,
      isRequired: true,
      render: (_: any, record: Record<string, any>) => {
        const { type, video, image } = record;
        if (type === AssetType.video && video) return <AccessibleTableVideoPreviewCell video={video} />;
        if (type === AssetType.image && image) return <AccessibleTableImagePreviewCell image={image} />;
        return <span />;
      }
    },
    getAccessibleTableColumnConfig({
      width: 150,
      key: 'type',
      title: 'Type',
      isVisible: activeFilterDropdown === filterTypes.type,
      activeFilters: filters[filterTypes.type],
      filtersList: assetTypeList,
      filterDropdownType: filterTypes.type,
      onSetFilter,
      onFilterDropdownChange
    }),
    {
      width: 170,
      key: 'created_date',
      title: 'Created at',
      dataIndex: 'created_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('created_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    },
    {
      width: 170,
      key: 'updated_date',
      title: 'Updated at',
      dataIndex: 'updated_date',
      sorter: true,
      defaultSortOrder: COLUMN_DESCEND,
      sortOrder: getColumnSortOrder('updated_date'),
      render: (date: string) => <AccessibleTableDateCell date={date} />,
      onHeaderCell
    }
  ];

  return (
    <>
      <ResourceListContainer
        withSearchFilter
        withResetFilters
        withDynamicColumns
        resource={resource}
        columns={contentPlansListColumns}
        searchFilterPlaceholder="Search studio assets by id..."
        onResetFilters={onResetFilters}
        withCreate
        onCreateClick={() => setOpen(true)}
      />
      <StudioAssetsNewRecordModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default StudioAssetsListPage;
