import { ResourceRecord } from '../types/resource-record-types';
import { ListItem } from '../components/dropdown-record-selection-list/dropdown-record-selection-list';
import { Resources } from '../types/resources-types';
import { Instructor } from '../types/instructors-types';
import { mainTagColor } from '../styles/styles-constants';
import { defaultColorsList } from '../constants/common-constants';

export const getDifferenceFields = (initialObject: Record<string, any> | null, changedObject: Record<string, any>) => {
  if (!initialObject) return changedObject;

  return Object.keys(changedObject).reduce((acc: Record<string, any>, key) => {
    const changedKeyValue = changedObject[key];
    const initialKeyValue = initialObject[key];
    if (changedKeyValue !== initialKeyValue) {
      acc[key] = changedKeyValue;
    }
    return acc;
  }, {});
};

export const tryParseJsonObject = (jsonString: string): Record<string, unknown> | null => {
  try {
    const jsonObject = JSON.parse(jsonString);

    if (jsonObject && typeof jsonObject === 'object') {
      return jsonObject;
    }
  } catch (e) {
    return null;
  }

  return null;
};

export const upperCaseText = (value: string) => value.toUpperCase();
export const capitalizeText = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const getEnumOptionsList = <T>(enumObject: T, transformTextFunc?: (value: string) => void): { text: string; value: string }[] =>
  Object.values(enumObject).map((item) => ({ text: transformTextFunc ? transformTextFunc(`${item}`) : item, value: item }));

export const getEnumColoredOptionsList = (
  colorsMap: Record<string, string>,
  transformTextFunc?: (value: string) => string
): { text: string; value: string; color: string }[] =>
  Object.keys(colorsMap).map((item) => ({
    text: transformTextFunc ? transformTextFunc(`${item}`) : item,
    value: item,
    color: colorsMap[item]
  }));

export const getNormalizedResourceItemsList = (resource: Resources, list: ResourceRecord[]): ListItem[] =>
  list.map((item): ListItem => {
    const itemId = `${item.id}`;

    if (resource === Resources.instructors) {
      return { ...item, id: itemId, name: `${(item as Instructor).first_name} ${(item as Instructor).last_name}` } as ListItem;
    }
    if (resource === Resources.studioAssets) {
      return { ...item, id: itemId, name: '' } as ListItem;
    }
    return item as ListItem;
  });

export const getDefaultOptionsList = (dictionaryList: string[], withColor = true): { text: string; value: string; color?: string }[] =>
  dictionaryList.map((item, index) => ({ value: item, text: item, color: withColor ? defaultColorsList[index] || mainTagColor : undefined }));

export const getDefaultColorsMap = (dictionaryList: string[]): Record<string, string | undefined> =>
  dictionaryList.reduce((acc, item, index) => ({ ...acc, [item]: defaultColorsList[index] || mainTagColor }), {});

export const createArrayFromNumbersRange = (start: number, end: number) => {
  if (start === end) return [];
  return Array.from({ length: end - start + 1 }, (_, i) => i + start);
};

export const getAlphabeticalSort = (array: string[]) => array.sort((a, b) => a.localeCompare(b));
export const getAlphabeticalSortBy = (array: Record<string, any>[], key: string) => array.sort((a, b) => a[key].localeCompare(b[key]));
export const getUniqArray = (values: string[]) => Array.from(new Set(values));
export const isEqual = (firstValue: unknown, secondValue: unknown) => JSON.stringify(firstValue) === JSON.stringify(secondValue);
export const uniqueBy = (array: Record<string, any>, key: string) =>
  array.filter((x: Record<string, any>, xi: number) => !array.slice(xi + 1).some((y: Record<string, any>) => y[key] === x[key]));
export const uniqueArray = (array: string[]) => Array.from(new Set(array));

export const stringHasTheWhiteSpaceOrNot = (value = '') => value.indexOf(' ') >= 0;

export const isValidRegExp = (value = '') => {
  try {
    new RegExp(`(${value})`);
  } catch (_) {
    return false;
  }
  return true;
};
