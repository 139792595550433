import React from 'react';
import { Col, Input, Row } from 'antd';
import ReactJson from 'react-json-view';
import { tryParseJsonObject } from '../../utils/common-utils';

type WorkoutFilterConditionFormFieldProps = {
  value?: any;
  onChange?: (value: any) => void;
};

const WorkoutFilterConditionFormField = ({ value, onChange }: WorkoutFilterConditionFormFieldProps) => (
  <Row align="top" justify="space-between" style={{ minHeight: 150 }}>
    <Col span={12}>
      <Input.TextArea rows={6} value={value} onChange={onChange} />
    </Col>
    <Col span={12}>
      <ReactJson src={tryParseJsonObject(value) as Record<string, unknown>} />
    </Col>
  </Row>
);

export default WorkoutFilterConditionFormField;
