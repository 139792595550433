import React, { ReactElement, useRef } from 'react';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';
import { SortOrder } from 'antd/lib/table/interface';

import { NOOP } from '../../constants/common-constants';
import { COLUMN_DESCEND } from '../../types/common-types';
import { FilterType } from '../../hooks/use-resource-list-table-filters';
import { ColumnItem } from './accessible-table-types';
import { BASE_BLUE_COLOR } from '../../constants/style-constants';
import useOutsideClick from '../../hooks/use-outside-click';
import AccessibleTableTags from './accessible-table-tags';
import AccessibleTableFilterSelect, { FilterSelectionType } from './accessible-table-filter-select';
import { HasWorkoutImageTypes, HasWorkoutVideoTypes } from '../../pages/workouts/workout-list-assets-filters';

type AccessibleTableEnumFilterColumnConfigParams = {
  width: number;
  key: string;
  title: string;
  isVisible: boolean;
  colored?: boolean;
  bordered?: boolean;
  activeFilters: string | string[];
  filtersList: { value: any; text: string; textIcon?: React.ReactNode; color?: string }[];
  filterDropdownType: FilterType;
  selectionType?: FilterSelectionType;
  filterColorsMap?: Record<string, string | undefined>;
  cellRender?: (value: any, record: Record<string, any>) => ReactElement | JSX.Element;
  CustomFilterDropdown?: any;
  sorter?: boolean;
  defaultSortOrder?: SortOrder;
  sortOrder?: SortOrder;
  onSetFilter: (filterType: FilterType, value: string[]) => void;
  onFilterDropdownChange: (isActive: boolean, filterDropdownType: FilterType) => void;
  onHeaderCell?: (column: any) => { onClick: () => void };
};

const excludedFiltersList = ['all', HasWorkoutVideoTypes.all, HasWorkoutImageTypes.all];

export const getAccessibleTableColumnConfig = (params: AccessibleTableEnumFilterColumnConfigParams): ColumnItem => {
  const {
    key,
    title,
    isVisible,
    width,
    activeFilters,
    filterColorsMap,
    filtersList,
    filterDropdownType,
    selectionType = FilterSelectionType.checkbox,
    cellRender,
    colored = true,
    bordered = true,
    CustomFilterDropdown,
    sorter = false,
    defaultSortOrder = COLUMN_DESCEND,
    sortOrder = null,
    onHeaderCell = NOOP,
    onSetFilter,
    onFilterDropdownChange
  } = params;

  const filterDropdownRef = useRef(null);
  const filters = Array.isArray(activeFilters) ? activeFilters : [activeFilters];
  const isActiveFiltersExists = !!filters.filter((item) => !!item && !excludedFiltersList.includes(item)).length;

  const onOpenFilterDropdown = () => onFilterDropdownChange(true, filterDropdownType);
  const onCloseFilterDropdown = () => onFilterDropdownChange(false, filterDropdownType);
  const onFilterDropdownVisibleChange = (isActive: boolean) => isActive && onOpenFilterDropdown();

  useOutsideClick({ ref: filterDropdownRef, isActive: isVisible, callback: onCloseFilterDropdown });

  const onComplexityFilter = (value: any) => {
    onSetFilter(filterDropdownType, value);
    return true;
  };

  const onSelectComplexityFilter = (value: string[]) => {
    onComplexityFilter(value);
    onCloseFilterDropdown();
  };

  const renderAccessibleTableTags = (value: any, record: Record<string, any>) => {
    if (cellRender) return cellRender(value, record);
    const tags = (Array.isArray(value) ? value : [value]).map((valueItem) => {
      const valueListOption = filtersList.find((item) => item.value === valueItem || `${item.text}`.toLowerCase() === `${valueItem}`.toLowerCase());
      return (valueListOption && valueListOption?.value) || valueItem;
    });
    return <AccessibleTableTags colored={colored} bordered={bordered} tags={tags} colorsMap={filterColorsMap} />;
  };

  const renderFilterDropdown = () => {
    if (CustomFilterDropdown) {
      return (
        <CustomFilterDropdown
          innerRef={filterDropdownRef}
          isVisible={isVisible}
          activeFilters={filters}
          onSelectFilters={onSelectComplexityFilter}
          onClose={onCloseFilterDropdown}
        />
      );
    }
    return (
      <AccessibleTableFilterSelect
        isVisible={isVisible}
        innerRef={filterDropdownRef}
        selectionType={selectionType}
        options={filtersList}
        initialFilters={filters}
        onSelectFilters={onSelectComplexityFilter}
      />
    );
  };

  const renderFilterIcon = () => {
    if (!isActiveFiltersExists) return <FilterOutlined />;
    return <FilterFilled style={{ color: BASE_BLUE_COLOR }} />;
  };

  const sortParams = {
    sorter,
    sortOrder,
    defaultSortOrder,
    onHeaderCell
  };

  const filterParams = {
    width,
    key,
    title,
    dataIndex: key,
    filterIcon: renderFilterIcon(),
    filterDropdownVisible: isVisible,
    filterDropdown: renderFilterDropdown,
    render: renderAccessibleTableTags,
    onFilter: onComplexityFilter,
    onFilterDropdownVisibleChange
  };

  return { ...filterParams, ...sortParams };
};
